import React from 'react';
import ReactTooltip from 'react-tooltip';

import Select from '../../app-components/select';
import Icon from '@mdi/react';
import { mdiHelpCircleOutline } from '@mdi/js';

export const Row = ({ children, className = '' }) => (
  <div className={`d-flex align-items-center w-100 mt-3 ${className}`}>
    {children}
  </div>
);

export const Input = ({
  name,
  label,
  className,
  helperText,
  helperDirection = 'column',
  value,
  accept,
  step,
  onChange,
  onBlur,
  pattern,
  type = 'text',
  placeholder,
  readOnly,
  isDisabled,
  isRequired,
  isMultiple,
  maxLength = 100,
}) => {
  const showRequired = isRequired && !value;

  // @ TODO make the error CSS for file inputs look pretty
  if (type === 'file') {
    return (
      <>
        {label && (
          <label htmlFor={name} className={`mr-2 mb-0 w-100 ${className}`}>
            <small>{label}</small>
          </label>
        )}
        <div className={`d-flex flex-${helperDirection} w-100`}>
          <input
            className={'w-100'}
            id={name}
            name={name}
            disabled={isDisabled}
            value={value}
            accept={accept}
            onChange={onChange}
            onBlur={onBlur}
            pattern={pattern}
            type={type}
            step={step}
            placeholder={placeholder}
            readOnly={readOnly}
            maxLength={maxLength}
            multiple={isMultiple}
          />
          <p
            className={helperDirection === 'row' ? 'm-0 ml-2' : 'm-0'}
            style={{ fontSize: 'smaller' }}
          >
            <i>{helperText}</i>
          </p>
        </div>
      </>
    );
  }

  if (helperText) {
    return (
      <>
        {label && (
          <label htmlFor={name} className={`mr-2 mb-0 w-100 ${className}`}>
            <small>{label}</small>
          </label>
        )}
        <div className={`d-flex flex-${helperDirection} w-100`}>
          <input
            className={`form-control w-100 mt-1${showRequired ? ' is-invalid' : ''}`}
            id={name}
            name={name}
            disabled={isDisabled}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            pattern={pattern}
            type={type}
            step={step}
            placeholder={placeholder}
            readOnly={readOnly}
            maxLength={maxLength}
          />

          <p
            className={helperDirection === 'row' ? 'm-0 ml-2' : 'm-0'}
            style={{ fontSize: 'smaller' }}
          >
            <i>{helperText}</i>
          </p>
        </div>
      </>
    );
  } else {
    return (
      <>
        {label && (
          <label htmlFor={name} className={`mr-2 mb-0 w-100 ${className}`}>
            <small>{label}</small>
          </label>
        )}
        <input
          className={`form-control w-100${showRequired ? ' is-invalid' : ''}`}
          id={name}
          name={name}
          disabled={isDisabled}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          pattern={pattern}
          type={type}
          step={step}
          placeholder={placeholder}
          readOnly={readOnly}
          maxLength={maxLength}
        />
      </>
    );
  }
};

export const SelectCustomLabel = ({
  name,
  label,
  options,
  value,
  onChange,
  onBlur,
  onSelect,
  helperText,
  helperDirection,
  hasHelperIcon,
  helperIconId,
  helperContent,
  className,
  defaultValue,
  isRequired,
  isDisabled = false,
}) => {
  const showRequired = isRequired && !value;

  if (helperText) {
    return (
      <>
        {label && (
          <label htmlFor={name} className={`mr-2 mb-0 w-50${className}`}>
            <small>{label}</small>
          </label>
        )}
        {hasHelperIcon && (
          <>
            <Icon
              path={mdiHelpCircleOutline}
              data-tip
              data-for={helperIconId}
              style={{ marginBottom: '8px' }}
              size={'16px'}
            />
            <ReactTooltip id={helperIconId} effect='solid' place='bottom'>
              <span>{helperContent}</span>
            </ReactTooltip>
          </>
        )}
        <div className={`d-flex flex-${helperDirection} w-100`}>
          <Select
            id={name}
            name={name}
            options={options}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onSelect={onSelect}
            className={showRequired ? 'is-invalid' : ''}
            defaultOption={defaultValue}
            isDisabled={isDisabled}
          />
          <p className={helperDirection === 'row' ? 'm-0 ml-2' : 'm-0'}>
            <i>{helperText}</i>
          </p>
        </div>
      </>
    );
  } else {
    return (
      <>
        {label && (
          <label htmlFor={name} className={`mr-2 mb-0 w-50 ${className}`}>
            <small>{label}</small>
          </label>
        )}
        {hasHelperIcon && (
          <>
            <Icon
              path={mdiHelpCircleOutline}
              data-tip
              data-for={helperIconId}
              style={{ marginBottom: '8px' }}
              size={'16px'}
            />
            <ReactTooltip id={helperIconId} effect='solid' place='bottom'>
              <span>{helperContent}</span>
            </ReactTooltip>
          </>
        )}
        <Select
          id={name}
          name={name}
          options={options}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onSelect={onSelect}
          className={showRequired ? 'is-invalid' : ''}
          defaultOption={defaultValue}
          isDisabled={isDisabled}
        />
      </>
    );
  }
};

export const TextArea = ({
  value,
  name,
  label,
  onChange,
  onBlur,
  rowCount = 3,
  className = '',
  isRequired,
  isDisabled,
  readOnly,
  maxLength = 4000,
}) => {
  const showRequired = isRequired && !value;

  return (
    <>
      {label && (
        <label htmlFor={name} className={`mr-2 mb-0 w-50 ${className}`}>
          <small>{label}</small>
        </label>
      )}

      <textarea
        rows={rowCount}
        className={`form-control w-100${showRequired ? ' is-invalid' : ''}`}
        id={name}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={isDisabled}
        readOnly={readOnly}
        maxLength={maxLength}
      />
      <small>{`${value ? value.length : 0}/${maxLength}`}</small>
    </>
  );
};
