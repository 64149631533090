import React, { useCallback, useEffect } from 'react';
import { connect } from 'redux-bundler-react';

import FocusTrap from 'focus-trap-react';

const SecondaryModal = connect(
  'doSecondaryModalClose',
  'selectSecondaryModalContent',
  'selectSecondaryModalProps',
  ({
    doSecondaryModalClose,
    secondaryModalContent: ModalContent,
    secondaryModalProps,
    closeWithEscape = false,
  }) => {
    const closeModalWithEscape = useCallback((e) => {
      if (e.keyCode === 27) doSecondaryModalClose();
    }, [doSecondaryModalClose]);

    useEffect(() => {
      if (closeWithEscape) {
        document.addEventListener('keydown', closeModalWithEscape);

        if (!ModalContent) {
          document.removeEventListener('keydown', closeModalWithEscape);
        }
      }
    }, [ModalContent, closeWithEscape, closeModalWithEscape]);

    return (
      !!ModalContent && (
        <FocusTrap focusTrapOptions={{ initialFocus: false }}>
          <div>
            <div
              onClick={doSecondaryModalClose}
              className='modal fade show'
              style={{ display: 'block', backgroundColor: '#ccc', opacity: 0.5 }}
            />
            <div className='modal fade show' style={{ display: 'block' }} tabIndex={-1}>
              <ModalContent {...secondaryModalProps} />
            </div>
          </div>
        </FocusTrap>
      )
    );
  }
);

export default SecondaryModal;
