import React from 'react';
import Tooltip from '../tooltip/tooltip';

import { useFormContext } from 'react-hook-form';
import '../../styles/index.scss';

const CheckboxInput = ({
  className,
  name,
  label,
  id,
  required,
  onChange,
  onBlur,
  validations,
  tooltip,
  tooltipClickable,
  value,
  disabled,
}) => {

  const { register, formState: { errors } } = useFormContext();

  const inputError = errors[name];

  return (
    <div className='mb-1'>
      <input
        type='checkbox'
        className={`${className} ${inputError ? ' is-invalid' : ''}`}
        id={id ? id : value}
        name={name}
        value={value}
        disabled={disabled}
        required={required}
        {...register(name, { onChange, onBlur, ...validations })}
        aria-invalid={inputError ? 'true' : 'false'}
        aria-required={required}
      />
      <label
        className={'pl-2 mb-0'}
        htmlFor={id ? id : value}>
        {label}{required ? <span className='asterisk-color'>*</span> : ''}
        {tooltip && <>
          <Tooltip name={name} header={label} content={tooltip} clickable={tooltipClickable} iconStyle={{ marginLeft: '5px' }} />
        </>}

      </label>
    </div>
  );
};

export default CheckboxInput;