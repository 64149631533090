import React from 'react';
import { connect } from 'redux-bundler-react';

import ExternalLinkConfirmationModal from '../../app-pages/Resources/components/_shared/ExternalLinkConfirmationModal';
import Icon from '@mdi/react';
import { mdiOpenInNew } from '@mdi/js';

import './linkButton.scss';

/**
 * Reusable link component with many options to style and transform.
 * 
 * @param {string} onClick - function to handle user interaction. ignored if an `href` is provided
 * @param {string} content - text do be display on the link
 * @param {string} title - text to be read be screen readers and onHover. if not provided, text prop will be used
 * @param {string} href - a uri for the user to be taken to onClick. overrides and removes handleClick prop if provided.
 * @param {string} type - the type of resource, one of `[external, internal]`. Default `external`.
 */

const ExternalLink = connect(
  'doSecondaryModalOpen',
  ({
    content,
    doSecondaryModalOpen,
    href,
    onClick,
    suppressWarning,
    title,
    ...customProps
  }) => {

    const handleClick = (e) => {
      if (suppressWarning) {
        window.open(href, '_blank', 'noopener,noreferrer');
        return;
      }
      else {
        doSecondaryModalOpen(ExternalLinkConfirmationModal, { href: href });
        return;
      }
    };

    const handleKeyDown = (e) => {
      e.key === 'Enter' && handleClick();
    };

    return (
      <span
        tabIndex={0}
        className='link-button-style'
        title={title ?? href}
        onClick={handleClick}
        onKeyDown={(e) => handleKeyDown(e)}
        {...customProps}
      >
        <span className='external-link-container'>{content ?? href}<Icon path={mdiOpenInNew} size={'16px'} /></span>
      </span>
    );
  });

export default ExternalLink;
