import React, { useEffect, useState, useRef, useCallback } from 'react';
import { connect } from 'redux-bundler-react';
import { useFormContext } from 'react-hook-form';
import { Alert, Label, FileInput, Button, Radio } from '@trussworks/react-uswds';
import { mdiMapMarker, mdiKeyboard, mdiMapSearchOutline, mdiDatabaseMarker, mdiFolderUploadOutline, mdiMagnify, mdiPlus, mdiMinus, mdiTrashCanOutline, mdiLayersTriple } from '@mdi/js';
import Icon from '@mdi/react';
import L from 'leaflet';
import * as turf from '@turf/turf';

import InputField from '../../../../../app-components/new-inputs/inputField.jsx';
import TextAreaInput from '../../../../../app-components/new-inputs/textAreaInput.jsx';
import SelectInput from '../../../../../app-components/new-inputs/selectInput.jsx';
import CreatableSelectInput from '../../../../../app-components/new-inputs/creatableSelectInput.jsx';
import CheckboxInput from '../../../../../app-components/new-inputs/checkboxInput.jsx';
import ModuleButton from '../../../../Resources/components/_shared/ModuleButton.jsx';
import LinkButton from '../../../../../app-components/link/linkButton.jsx';
import Map from '../../../../../app-components/map/map.jsx';
import ReadOnlyMap from '../../../../../app-components/map/readOnlyMap.jsx';
import Accordion from '../../../../../app-components/accordion/index.js';
import SplitLayout from '../../../../../app-components/layout/splitLayout.jsx';
import { scrollToElement } from '../../../../../utils/helpers.jsx';
import { latRegex, lngRegex } from '../../../../../utils/regex.jsx';
import { FileTypes, TemplateFiles } from '../../../../../utils/enums.tsx';

import { formatCoordFlt, formatCoordStr, isValidORMNumber, mapCountryAlpha3toAlpha2 } from '../../../../../utils/helpers.jsx';
import { ormErrorMsgAlert } from '../../../../Forms/input-forms/_helper.jsx';
import { states, countries } from '../../../../Forms/input-forms/_helper.jsx';
import AddressSuggestInput from '../../../../../app-components/new-inputs/addressSuggestInput.jsx';
import Tooltip from '../../../../../app-components/tooltip/tooltip.jsx';

import { fullscreenSVG, polyLineSVG, polygonSVG } from '../../../../../utils/svg.jsx';
import ErrorSummary from '../../../../../app-components/error-summary/ErrorSummary.jsx';

const ProjectLocationTab = connect(
  'doGetGeometryData',
  'doResetAddressCandidatesResults',
  'doResetGeocodingResults',
  'doUpdateRequestLocation',
  'doUpdateComplexStateField',
  'doGetDistrictFromGeometry',
  'doResetDistrictAPIResults',
  'doGetAddressFromLatLng',
  'doGetPLSSFromLatLng',
  'doResetPLSSAPIResults',
  'doResetComplexState',
  'doUpdateRequestObject',
  'doDownloadFile',
  'doFindAddressCandidates',
  'selectAddressCandidateResults',
  'selectReverseGeocodeResults',
  'selectGDBProjectResults',
  ({
    doGetGeometryData,
    doResetAddressCandidatesResults,
    doResetGeocodingResults,
    doUpdateRequestLocation,
    doUpdateComplexStateField,
    doGetDistrictFromGeometry,
    doResetDistrictAPIResults,
    doGetAddressFromLatLng,
    doGetPLSSFromLatLng,
    doResetPLSSAPIResults,
    doResetComplexState,
    doUpdateRequestObject,
    doDownloadFile,
    doFindAddressCandidates,
    addressCandidateResults,
    reverseGeocodeResults,
    gDBProjectResults,
    areaRequired,
    activeTab,
    locationType = 'Project',
    isReadOnly,
    setProjectOutsideUS,
  }) => {
    const { formState: { errors }, watch, setValue, resetField, clearErrors } = useFormContext();
    const inputTypeError = Object.keys(errors).some(error => error === 'inputType');
    const addressOptionError = Object.keys(errors).some(error => error === 'addressInputType');
    const GDBUploadRef = useRef();
    const inputType = ['address', 'coordinates'];
    const [moduleCollapse, setModuleCollapse] = useState(0);
    const [geometry, setGeometry] = useState();
    const [extent, setExtent] = useState();
    const [selectedFile, setSelectedFile] = useState();

    const projectName = watch('projectName');
    const selectedCountry = watch('country');
    const latitude = watch('latitude');
    const longitude = watch('longitude');
    const address = watch('address');
    const city = watch('city');
    const state = watch('state');
    const zipcode = watch('zipcode');
    const country = watch('country');
    const showMapInput = watch('showMapInput');
    const showGDBInput = watch('showGDBInput');
    const showAddressInput = watch('showAddressInput');
    const addressInputType = watch('addressInputType');

    const resetFields = () => {
      setExtent();
      setGeometry();
      setSelectedFile();
      doResetGeocodingResults();
      doResetDistrictAPIResults();
      doResetPLSSAPIResults();
      doUpdateRequestLocation(null);
      doResetComplexState();
      resetField('address');
      resetField('addressTwo');
      resetField('city');
      resetField('state');
      resetField('zipcode');
      resetField('county');
      resetField('projectArea');
      resetField('projectOutside');
      resetField('unitOfMeasurement');
      resetField('latitude');
      resetField('longitude');
      resetField('addressInputType');
      clearErrors('inputType');
    };

    const toggleInputType = (type) => {
      switch (type) {
        case 'Address':
          showAddressInput !== true && resetFields(); scrollToElement('first_scroll');
          setValue('showAddressInput', true);
          setValue('showMapInput', false);
          setValue('showGDBInput', false);
          moduleCollapse !== 1 ? setModuleCollapse(1) : setModuleCollapse(0);
          break;
        case 'Map':
          showMapInput !== true && resetFields(); scrollToElement('first_scroll');
          setValue('showAddressInput', false);
          setValue('showMapInput', true);
          setValue('showGDBInput', false);
          setValue('addressInputType', null);
          moduleCollapse !== 2 ? setModuleCollapse(2) : setModuleCollapse(0);
          break;
        case 'GDB':
          showGDBInput !== true && resetFields(); scrollToElement('first_scroll');
          setValue('showAddressInput', false);
          setValue('showMapInput', false);
          setValue('showGDBInput', true);
          setValue('addressInputType', null);
          moduleCollapse !== 3 ? setModuleCollapse(3) : setModuleCollapse(0);
          break;
        default:
          setModuleCollapse(0);
          resetFields();
          break;
      }

    };

    const refreshMapWithGDBData = useCallback((type, coordinates) => {


      switch (type) {
        case 'Point':
          doGetPLSSFromLatLng(coordinates);
          doGetAddressFromLatLng(formatCoordStr(coordinates[0]), formatCoordStr(coordinates[1]));
          setValue('latitude', formatCoordStr(coordinates[1]), { shouldValidate: true });
          setValue('longitude', formatCoordStr(coordinates[0]), { shouldValidate: true });
          doUpdateRequestLocation({ latitude: formatCoordFlt(coordinates[1]), longitude: formatCoordFlt(coordinates[0]) });
          break;

        case 'MultiPoint':
          if (coordinates.length > 0) {
            const firstPoint = coordinates[0];
            doGetPLSSFromLatLng(firstPoint);
            doGetAddressFromLatLng(formatCoordStr(firstPoint.lat), formatCoordStr(firstPoint.lng));
            setValue('latitude', formatCoordStr(firstPoint[1]), { shouldValidate: true });
            setValue('longitude', formatCoordStr(firstPoint[0]), { shouldValidate: true });
            doUpdateRequestLocation({ latitude: formatCoordFlt(firstPoint[1]), longitude: formatCoordFlt(firstPoint[0]) });
          }
          break;

        case 'Polygon':
        case 'MultiPolygon':
          const firstPolygon = coordinates[0]; // Use the first polygon if MultiPolygon
          const turfPolygon = turf.polygon(firstPolygon);
          const polygonCentroid = turf.centroid(turfPolygon).geometry.coordinates;
          doGetPLSSFromLatLng({ lat: polygonCentroid[1], lng: polygonCentroid[0] });
          doGetAddressFromLatLng(formatCoordStr(polygonCentroid[1]), formatCoordStr(polygonCentroid[0]));
          setValue('latitude', formatCoordStr(polygonCentroid[1]), { shouldValidate: true });
          setValue('longitude', formatCoordStr(polygonCentroid[0]), { shouldValidate: true });
          doUpdateRequestLocation({ latitude: formatCoordFlt(polygonCentroid[1]), longitude: formatCoordFlt(polygonCentroid[0]) });

          // Calculate Project Area (Acres)
          const area = turf.area(turfPolygon);
          const formattedArea = area / 4046.856422;
          const roundedArea = formattedArea.toFixed(6);
          setValue('projectArea', roundedArea, { shouldValidate: true });
          setValue('unitOfMeasurement', 'Acres', { shouldValidate: true });
          doUpdateRequestLocation({ projectArea: Number(roundedArea), unitOfMeasurement: 'Acres' });
          break;

        case 'LineString':
        case 'MultiLineString':
          const firstLine = coordinates[0]; // Use the first line if MultiLineString
          const turfLine = turf.lineString(firstLine);
          const lineCentroid = turf.centroid(turfLine).geometry.coordinates;
          doGetPLSSFromLatLng({ lat: lineCentroid[1], lng: lineCentroid[0] });
          doGetAddressFromLatLng(formatCoordStr(lineCentroid[1]), formatCoordStr(lineCentroid[0]));
          setValue('latitude', formatCoordStr(lineCentroid[1]), { shouldValidate: true });
          setValue('longitude', formatCoordStr(lineCentroid[0]), { shouldValidate: true });
          doUpdateRequestLocation({ latitude: formatCoordFlt(lineCentroid[1]), longitude: formatCoordFlt(lineCentroid[0]) });
          break;

        default:
          break;
      }


    }, [doGetAddressFromLatLng, doGetPLSSFromLatLng, doUpdateRequestLocation, setValue]);

    const calculateGDBCentroid = async (file) => {
      doGetGeometryData(file, true);
    };

    const handleFileInput = (e) => {
      if (e?.target?.files?.[0]) {
        setSelectedFile(e.target.files[0]);
      }
      else {
        resetFields();
      }
    };

    useEffect(() => {

      if (reverseGeocodeResults) {
        setValue('address', reverseGeocodeResults.Address, { shouldValidate: true });
        setValue('addressTwo', reverseGeocodeResults.SubAddr, { shouldValidate: true });
        setValue('city', reverseGeocodeResults.City, { shouldValidate: true });
        setValue('state', reverseGeocodeResults.RegionAbbr, { shouldValidate: true });
        setValue('zipcode', reverseGeocodeResults.Postal, { shouldValidate: true });
        setValue('county', reverseGeocodeResults.Subregion, { shouldValidate: true });
        setValue('country', mapCountryAlpha3toAlpha2(reverseGeocodeResults.CountryCode), { shouldValidate: true });
        // ESRI API RETURNS 'USA' and 'United States', dropdown expects 'US' or 'United States of America'
        doUpdateRequestLocation({ address: reverseGeocodeResults.Address, addressTwo: reverseGeocodeResults.SubAddr, city: reverseGeocodeResults.City, state: reverseGeocodeResults.RegionAbbr, zipcode: reverseGeocodeResults.Postal, county: reverseGeocodeResults.Subregion, country: reverseGeocodeResults.Country !== 'USA' ? reverseGeocodeResults.Country : 'US' });
      }


    }, [reverseGeocodeResults, setValue, doUpdateRequestLocation]);


    useEffect(() => {

      if (gDBProjectResults) {

        setGeometry(gDBProjectResults);
        doUpdateRequestLocation({ geometry: gDBProjectResults });
        doUpdateComplexStateField({ name: 'projectGeometry', value: gDBProjectResults });
        doGetDistrictFromGeometry(gDBProjectResults);

        const coordinates = gDBProjectResults?.coordinates;
        const type = gDBProjectResults?.type;

        refreshMapWithGDBData(type, coordinates);

        const geoJsonLayer = L.geoJson(gDBProjectResults);
        const extent = geoJsonLayer.getBounds();
        const bounds = [[extent._northEast.lat, extent._northEast.lng], [extent._southWest.lat, extent._southWest.lng]];
        bounds && doUpdateComplexStateField({ name: 'projectBounds', value: bounds });
        bounds && setExtent(bounds);

      }

    }, [gDBProjectResults, doUpdateComplexStateField, doUpdateRequestLocation, doGetDistrictFromGeometry, refreshMapWithGDBData]);

    useEffect(() => {
      const location = addressCandidateResults?.candidates?.[0]?.location;
      const extent = addressCandidateResults?.candidates?.[0]?.extent;

      if (location) {

        //IMPORTANT: location coords are returned from API in (x: long, y: lat) format

        doUpdateRequestLocation({ latitude: formatCoordFlt(location?.y), longitude: formatCoordFlt(location?.x) });
        setValue('latitude', formatCoordStr(formatCoordStr(location?.y)), { shouldValidate: true });
        setValue('longitude', formatCoordStr(formatCoordStr(location?.x)), { shouldValidate: true });

        const geometry = { type: 'Point', coordinates: [formatCoordFlt(location?.x), formatCoordFlt(location?.y)] };
        geometry && setGeometry(geometry);
        geometry && doUpdateRequestLocation({ geometry: geometry });
        geometry && doUpdateComplexStateField({ name: 'projectGeometry', value: geometry });
        geometry && doGetDistrictFromGeometry(geometry);
        doGetPLSSFromLatLng(location?.y, location?.x);

        if (Math.sign(location?.y) === -1 || Math.sign(location?.x) === 1) {
          setValue('projectOutside', 'checked');
          setProjectOutsideUS(true);
        }
        else {
          setValue('projectOutside', '');
          setProjectOutsideUS(false);
        }

      }

      if (extent) {
        const boundary = [[extent.ymin, extent.xmin], [extent.ymax, extent.xmax]];
        boundary && doUpdateComplexStateField({ name: 'projectBounds', value: boundary });
        boundary && setExtent(boundary);
      }

      doResetAddressCandidatesResults();

    }, [addressCandidateResults, doResetAddressCandidatesResults, setValue, doUpdateRequestLocation, doUpdateComplexStateField, doGetDistrictFromGeometry, doGetPLSSFromLatLng, setProjectOutsideUS]);

    useEffect(() => {

      if (!countries.some(country => country.value === selectedCountry) && selectedCountry !== '') {
        setValue('country', '');
      }

    }, [selectedCountry, setValue]);

    const watchPreviousFileNumbersExist = watch('previousFileNumbersExist', 'false');
    const watchPreviousFileNumbers = watch('previousFileNumbers', []);

    const handleSelectChange = (e) => {

      const results = e.map(num => isValidORMNumber(num.value));

      if (!results.every(Boolean)) {
        setValue('previousFileNumbers', e.length > 1 ? watchPreviousFileNumbers : []);
        doUpdateRequestObject({ ormDANumber: e.length > 1 ? watchPreviousFileNumbers : null });

        alert(ormErrorMsgAlert);
      }

    };

    const handlePreviousFileNumberChange = (e) => {

      if (e.target.value === 'false') {
        setValue('previousFileNumbers', [], { shouldValidate: true });
      }

    };

    const clearFileInput = () => {
      GDBUploadRef?.current && GDBUploadRef.current.clearFiles();
      doResetGeocodingResults();
      setSelectedFile();
      setExtent();
      setGeometry();
    };

    const handleBlur = (e) => {

      if (e?.target?.name === 'projectName') {
      }

      if (e?.target?.name === 'latitude' || e?.target?.name === 'longitude') {

        if (latRegex.test(latitude) && lngRegex.test(longitude)) {

          const formattedLatFlt = formatCoordFlt(latitude);
          const formattedLngFlt = formatCoordFlt(longitude);
          const pointGeometry = { type: 'Point', coordinates: [formattedLatFlt, formattedLngFlt] };
          const reversePointGeometry = { type: 'Point', coordinates: [formattedLngFlt, formattedLatFlt] };
          reversePointGeometry?.coordinates?.length > 0 && doGetPLSSFromLatLng(formattedLatFlt, formattedLngFlt);
          reversePointGeometry?.coordinates?.length > 0 && doGetDistrictFromGeometry(reversePointGeometry);
          setGeometry(reversePointGeometry);
          const geoJsonLayer = L.geoJson(reversePointGeometry);
          const extent = geoJsonLayer.getBounds();
          const bounds = [[extent._northEast.lat, extent._northEast.lng], [extent._southWest.lat, extent._southWest.lng]];
          geometry && doUpdateComplexStateField({ name: 'projectGeometry', value: pointGeometry });
          bounds && doUpdateComplexStateField({ name: 'projectBounds', value: bounds });
          bounds && setExtent(bounds);

          (address !== '' || !address) && doGetAddressFromLatLng(formatCoordStr(latitude), formatCoordStr(longitude));
        }

        const pointGeom = {
          'type': 'Point',
          'coordinates': [
            formatCoordFlt(longitude),
            formatCoordFlt(latitude)
          ]
        };

        //Add Marker to Map
        if (!geometry || geometry.type === 'Point') {
          doUpdateRequestLocation({ geometry: pointGeom });
          doUpdateComplexStateField({ name: 'projectGeometry', value: pointGeom });
        }


        doUpdateRequestLocation({ [e.target.name]: formatCoordFlt(e.target.value) });
        return;
      }

      if (address && city && state && zipcode && country) {
        const addressString = `${address}, ${city}, ${state} ${zipcode}, ${country}`;
        doFindAddressCandidates(addressString);
      }

      if (e?.target?.name && e?.target?.value) {
        doUpdateRequestLocation({ [e.target.name]: e.target.value });
      }

    };

    const handleChange = (e) => {
      setProjectOutsideUS(e?.target?.checked ? true : false);
    };

    return (
      <>
        {errors && <ErrorSummary errors={errors} modalID='projectLocationTab' type='modal' />}
        <div id='projectLocationTab'>
          {locationType === 'Project' &&
            <>
              <div className='row'>
                <div className='col'>
                  <h4 className='border-bottom w-100 pb-2 mt-2'>Project Information</h4>
                  A red asterisk (<span className='asterisk-color'>*</span>) indicates a required field.
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <InputField name='projectName' label='Project Name' type='text' required readOnly={isReadOnly} onBlur={handleBlur} />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <SelectInput name='previousFileNumbersExist' label='Has the USACE previously issued a file number for any part of the project area?' required className='w-50' readOnly={isReadOnly} onChange={handlePreviousFileNumberChange} handleBlur={handleBlur}>
                    <option key='2' value='true'>Yes</option>
                    <option key='3' value='false'>No</option>
                  </SelectInput>
                </div>
              </div>

              {watchPreviousFileNumbersExist === 'true' && <>
                <div className='row mt-2 mb-3'>
                  <div className='col'>
                    <CreatableSelectInput name='previousFileNumbers' label='Previous File Number(s)' tooltip='Enter the file number and press "ENTER" or click on "Create ..."' placeholder='e.g. MVS-2023-12345, MVM-2020-1234 ...' required multi handleChange={handleSelectChange} disabled={isReadOnly} />
                  </div>
                </div>
              </>}
            </>}
          <div className='row mt-3'>
            <div className='col'>
              <h4 className='border-bottom w-100 pb-2'>{locationType} Location</h4>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              {locationType !== 'Site' ?
                <Alert type='warning' headingLevel='h4' heading='IMPORTANT'>{`It is critical to accurately input the location during the initial creation of the ${locationType.toLowerCase()}. The ${locationType.toLowerCase()}'s location determines the range of available requests within that specific geographical area. If an error is made in entering the ${locationType.toLowerCase()} location, it will be necessary to delete the request and initiate a new one for the ${locationType.toLowerCase()}.`}</ Alert>
                :
                <div>{`Please provide the ${locationType.toLowerCase()} location of the potential violation being reported.`}</div>
              }
            </div>
          </div >
          <div className={`p-2 mt-2 align-content-center ${(showAddressInput || showMapInput || showGDBInput) ? 'border-bottom' : ''} border-top`} id='inputType'>
            <h4 className='text-center mb-2'>How would you like to provide the {locationType} Location? </h4>
            <div className='row justify-content-center'>
              <div className='col-lg-3 col-md-6 col-xs-12 mt-1 mr-3'>
                <ModuleButton
                  title='Type in Address'
                  imageAlt='Type in Address'
                  text='Provide us with a physical address or latitude and longitude coordinates'
                  subtext={`Recommended for smaller ${locationType.toLowerCase()}s.`}
                  icon={mdiKeyboard}
                  onClick={() => toggleInputType('Address')} S
                  isModalButton
                  isError={inputTypeError}
                  selected={showAddressInput}
                  hideDetails={moduleCollapse !== 0}
                />
              </div>
              <div className='col-lg-3 col-md-6 col-xs-12 mt-1 mb-1 mr-3'>
                <ModuleButton
                  title='Use a Map'
                  imageAlt='Use a Map'
                  text='Provide us with an address by placing a point, line or polygon on a map.'
                  subtext={`Recommended for small and medium  ${locationType.toLowerCase()}s.`}
                  icon={mdiMapSearchOutline}
                  onClick={() => toggleInputType('Map')}
                  isModalButton
                  isError={inputTypeError}
                  selected={showMapInput}
                  hideDetails={moduleCollapse !== 0}
                />
              </div>
              <div className='col-lg-3 col-md-6 col-xs-12 mt-1 mb-1 mr-3'>
                <ModuleButton
                  title='Upload a Geodatabase'
                  imageAlt='Upload a Geodatabase'
                  text={`Provide us with ${locationType.toLowerCase()} boundaries by uploading a geodatabase file.`}
                  subtext={`Recommended for larger ${locationType.toLowerCase()}s.`}
                  icon={mdiDatabaseMarker}
                  onClick={() => toggleInputType('GDB')}
                  isModalButton
                  isError={inputTypeError}
                  selected={showGDBInput}
                  hideDetails={moduleCollapse !== 0}
                />
              </div>
            </div>
          </div >
          {showAddressInput &&
            <>
              <div className='row justify-content-center' id='first_scroll'>
                <div className='col-12 col-md-6'>
                  <fieldset className={`radio-button-group mx-auto w-75 mt-3 ${addressOptionError ? 'radio-fieldset-invalid' : ''} `} id='addressInputType'>
                    <legend className='pl-2'>{'I would like to enter ...'}</legend>
                    <Radio
                      className='p-2'
                      id='radio-address'
                      name='address-coordinates'
                      label={<>The <b>physical address</b> for the {locationType.toLocaleLowerCase()} location</>}
                      value='address'
                      onChange={() => {
                        setValue('addressInputType', 'address', { shouldValidate: true });
                        scrollToElement('second_scroll');
                      }}
                    />

                    <Radio
                      className='p-2'
                      id='radio-coordinates'
                      name='address-coordinates'
                      label={<>The <b>coordinates</b> for the {locationType.toLocaleLowerCase()} location</>}
                      value='coordinates'
                      onChange={() => {
                        setValue('addressInputType', 'coordinates', { shouldValidate: true });
                        scrollToElement('second_scroll');
                      }}
                    />
                  </fieldset>

                </div>
              </div>
              {inputType?.includes(addressInputType) &&
                <>
                  <SplitLayout>
                    <>
                      <div className='row'>
                        <div className='col' id='second_scroll'>
                          <h4 className='border-bottom w-100 pb-2 pt-2 mt-3'>{locationType} {addressInputType === 'address' ? 'Address' : 'Coordinates'}</h4>
                        </div>
                      </div>
                      {addressInputType === 'address' && <>
                        <div className='row'>
                          <div className='col'>
                            <AddressSuggestInput label='Address' name='address' required isProjectAddress onBlur={handleBlur} />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col'>
                            <InputField label='Address Line 2' name='addressTwo' type='text' onBlur={handleBlur} />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col'>
                            <InputField label='City' name='city' type='text' maxLength={26} onBlur={handleBlur} required />
                          </div>
                          <div className='col'>
                            <SelectInput name='state' label='State' onBlur={handleBlur} required >
                              {states.map((item, i) => (<option key={i + 2} value={item.value}>{item.text}</option>))}
                            </SelectInput>
                          </div>
                          <div className='col'>
                            <InputField type='tel' label='Zipcode' name='zipcode' maxLength={5} onBlur={handleBlur} required />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-4'>
                            <SelectInput name='country' label='Country' onBlur={handleBlur} required >
                              {countries.map((item, i) => (<option key={i + 2} value={item.value}>{item.text}</option>))}
                            </SelectInput>
                          </div>
                          <div className='col-4'>
                            <InputField name='county' label='County' type='text' maxLength={26} onBlur={handleBlur} />
                          </div>
                        </div>
                      </>}
                      {addressInputType === 'coordinates' && <>
                        <div className='row mt-2'>
                          <div className='col'>
                            <span style={{ fontStyle: 'italic' }}>Coordinates must be in Decimal Degrees</span>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col d-flex align-content-center'>
                            <CheckboxInput name='projectOutside' label={`My ${locationType.toLowerCase()} is outside of the continental U.S.`} onChange={handleChange} />
                            <span className='ml-2'><Tooltip
                              content={`Select this option for ${locationType.toLowerCase()}s in Guam or other U.S. Territories outside of the continental U.S.`}
                            /></span>
                          </div>
                        </div>
                        <div className='row mb-3'>
                          <div className='col'>
                            <InputField maxLength={12} name='latitude' label='Latitude' readOnly={isReadOnly} onBlur={handleBlur} required />
                          </div>
                          <div className='col'>
                            <InputField maxLength={12} name='longitude' label='Longitude' readOnly={isReadOnly} onBlur={handleBlur} required />
                          </div>
                        </div>
                      </>}
                      <div className='row mt-3'>
                        <div className='col'>
                          <h4 className='border-bottom w-100 pb-2'>{`${locationType === 'Project' ? 'Project Area' : 'Area of Violation'}`}</h4>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-6'>
                          <InputField type='tel' maxLength={17} name='projectArea' label={`${locationType === 'Project' ? 'Project Area' : 'Area of Violation'}`} required={areaRequired === false ? false : true} readOnly={isReadOnly} onBlur={handleBlur} />
                        </div>
                        <div className='col-6'>
                          <SelectInput name='unitOfMeasurement' label='Unit of Measurement' required={areaRequired === false ? false : true} readOnly={isReadOnly} onBlur={handleBlur}>
                            <option key={1} value='Square Feet'>Square Feet</option>
                            <option key={2} value='Acres'>Acres</option>
                          </SelectInput>
                        </div>
                      </div>
                    </>

                    <>
                      <h4 className='border-bottom w-100 pb-2 pt-2 mt-3'>{locationType} Map</h4>
                      {activeTab === 0 &&
                        <ReadOnlyMap extent={extent} geometry={geometry} geometryName={projectName} height='50vh' />
                      }
                    </>
                  </SplitLayout >
                </>}
            </>
          }
          {showMapInput &&
            <>
              <div className='row'>
                <div className='col'>
                  <Accordion.List className='mt-3 mb-2 w-100'>
                    <Accordion.Item headingText='Map Use Tips'>
                      <div className='row'><div className='col pb-2'><Icon path={mdiPlus} size={'24px'} /> Zoom In - Click the plus sign to zoom in on the map. You can also click one in the map and use the scroll wheel on your mouse to zoom in and out. With the focus of your cursor on the map, you can pan the map with the hand icon.</div></div>
                      <div className='row'><div className='col pb-2'><Icon path={mdiMinus} size={'24px'} /> Zoom Out - Click the minus sign to zoom out on the map. See above for further map navigation tips.</div></div>
                      <div className='row'><div className='col pb-2'>{fullscreenSVG} Full Screen - Click the full screen icon to zoom out to the original extent of the map.</div></div>
                      <div className='row'><div className='col pb-2'><Icon path={mdiFolderUploadOutline} size={'24px'} /> Upload GDB File - Click this icon to upload the {locationType.toLowerCase()} location information from a geodatabase (GDB) instead of digitizing the location on the map. The geodatabase must be compressed (.zip or .gdb).</div></div>
                      <div className='row'><div className='col pb-2'>{polyLineSVG} Add a Line Feature - Select the line tool to add a {locationType.toLowerCase()} location as a line. Click the starting point location and any interim shape points. Double-click the final shape point of the line or click to set the location of the final shape point and then select “Finish” from the popup menu in the map.</div> </div>
                      <div className='row'><div className='col pb-2'>{polygonSVG} Add a Polygon Feature - Select the polygon tool to add a {locationType.toLowerCase()} location as a polygon. Click the starting point location followed by the remaining shape points. When complete, either double-click the last shape point or click the set the location of the final shape point and then select “Finish” from the popup menu in the map.</div></div>
                      <div className='row'><div className='col pb-2'><Icon path={mdiMapMarker} size={'24px'} /> Add a Point Feature - Select the point tool to add a {locationType.toLowerCase()} location as a single point. Click the location of the point.</div></div>
                      <div className='row'><div className='col pb-2'><Icon path={mdiTrashCanOutline} size={'24px'} /> Delete Features - Select this option to delete the location information from the map. You will then need to re-create the {locationType.toLowerCase()} location. After clicking the trash can icon, select Finish, Cancel, or Remove All to delete the location entered on the map. </div></div>
                      <div className='row'><div className='col pb-2'><Icon path={mdiMagnify} size={'24px'} /> Search - Click the magnifier icon in the upper right of the map to expand a search box which will allow you to search for a location via text (address, city, etc.)</div></div>
                      <div className='row'><div className='col pb-2'><Icon path={mdiLayersTriple} size={'24px'} /> Layers - Mouse over the layers icon to view the available base maps. The base map can either be viewed as a street map, aerial imagery, or topographic map.</div></div>
                    </Accordion.Item>
                  </Accordion.List>
                </div>
              </div>
              {activeTab === 0 &&
                <div className='row'>
                  <div className='col'>
                    <Map disableFileUpload={true} setProjectOutsideUS={setProjectOutsideUS} />
                  </div>
                </div>}
              <div className='row mt-2'>
                <div className='col'>
                  <span style={{ fontStyle: 'italic' }}>Coordinates must be in Decimal Degrees</span>
                </div>
              </div>
              <div className='row'>
                <div className='col d-flex align-content-center'>
                  <CheckboxInput name='projectOutside' label={`My ${locationType.toLowerCase()} is outside of the continental U.S.`} onChange={handleChange} />
                  <span className='ml-2'><Tooltip
                    content={`Select this option for ${locationType.toLowerCase()}s in Guam or other U.S. Territories outside of the continental U.S.`}
                  /></span>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <InputField maxLength={12} name='latitude' label='Latitude' readOnly onBlur={handleBlur} required />
                </div>
                <div className='col'>
                  <InputField maxLength={12} name='longitude' label='Longitude' readOnly onBlur={handleBlur} required />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <h4 className='border-bottom w-100 pb-2 mt-3'>{`${locationType === 'Project' ? 'Project Area' : 'Area of Violation'}`}</h4>
                </div>
              </div>
              <div className='row'>
                <div className='col-6'>
                  <InputField type='tel' maxLength={17} name='projectArea' label={`${locationType === 'Project' ? 'Project Area' : 'Area of Violation'}`} required={areaRequired === false ? false : true} readOnly={isReadOnly} onBlur={handleBlur} />
                </div>
                <div className='col-6'>
                  <SelectInput name='unitOfMeasurement' label='Unit of Measurement' required={areaRequired === false ? false : true} readOnly={isReadOnly} onBlur={handleBlur}>
                    <option key={1} value='Square Feet'>Square Feet</option>
                    <option key={2} value='Acres'>Acres</option>
                  </SelectInput>
                </div>
              </div>
            </>}
          {
            showGDBInput &&
            <>
              <SplitLayout>
                <>
                  <div className='row'>
                    <div className='col'>
                      <h4 className='border-bottom w-100 mb-3 mt-3'>{locationType} Map</h4>
                    </div>
                  </div>
                  {activeTab === 0 &&
                    <div className='row'>
                      <div className='col'>
                        <ReadOnlyMap mapOnly extent={extent} geometry={geometry} geometryName={projectName} />
                      </div>
                    </div>}
                </>
                <>
                  <div className='row'>
                    <div className='col'>
                      <h4 className='border-bottom w-100 mb-3 mt-3'>Geodatabase File Upload</h4>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <Alert type='info'>
                        <ul>
                          <li>{locationType} Location geometries should be dissolved to a single row geometry</li>
                          <li>Acceptable geometry types: Point, Line, Polygon, MultiPoint, MultiLine, MultiPolygon</li>
                          <li>All geometries must use CRS(Coordinate Reference System) WGS84</li>
                          <li>Geodatabases must be compressed into a single .zip file</li>
                          <li>Use the GDB upload template located here: <LinkButton onClick={() => doDownloadFile(TemplateFiles.ARBulkUploadGDBTemplate, FileTypes.Template)} title='GDB Template [ZIP, 71 KB]' content='GDB Template [ZIP, 71 KB]' /></li>
                        </ul>
                      </Alert>
                    </div>
                  </div>

                  <div className='d-flex flex-column align-items-center mt-4 mb-4'>
                    <Label htmlFor='file-input-single'>Input accepts a single, zipped GDB file</Label>
                    <span className='usa-hint' id='file-input-specific-hint'>
                      Select .ZIP file
                    </span>
                    <FileInput ref={GDBUploadRef} id='file-input-single' name='file-input-single' accept='.zip' onChange={handleFileInput} onDrop={handleFileInput} />
                    {selectedFile && <Button className='mt-3' size='large' secondary={geometry} disabled={!selectedFile} onClick={!geometry ? () => calculateGDBCentroid(selectedFile) : clearFileInput}>{geometry ? 'Remove File' : 'Upload File'}</Button>}
                  </div>
                </>
              </SplitLayout >
              <div className='row'>
                <div className='col'>
                  <h4 className='border-bottom w-100 pb-2'>{`${locationType === 'Project' ? 'Project Area' : 'Area of Violation'}`}</h4>
                </div>
              </div>
              <div className='row'>
                <div className='col-6'>
                  <InputField type='tel' maxLength={17} name='projectArea' label={`${locationType === 'Project' ? 'Project Area' : 'Area of Violation'}`} required={areaRequired === false ? false : true} readOnly={isReadOnly} onBlur={handleBlur} />
                </div>
                <div className='col-6'>
                  <SelectInput name='unitOfMeasurement' label='Unit of Measurement' required={areaRequired === false ? false : true} readOnly={isReadOnly} onBlur={handleBlur}>
                    <option key={1} value='Square Feet'>Square Feet</option>
                    <option key={2} value='Acres'>Acres</option>
                  </SelectInput>
                </div>
              </div>
            </>
          }
          {(inputType?.includes(addressInputType) || showMapInput || showGDBInput) &&
            <>
              <div className='row mt-2'>
                <div className='col'>
                  <h4 className='border-bottom w-100 pb-2 pt-2'>Additional {locationType} Information</h4>
                </div>
              </div>
              <div className='row'>
                <div className='col-8'>
                  <InputField name='parcelNumber' label='Parcel Number' type='text' readOnly={isReadOnly} onBlur={handleBlur} />
                </div>
              </div>
              <div className='row'>
                <div className='col-8'>
                  <InputField name='nearestWaterbody' label='Nearest Waterbody' type='text' readOnly={isReadOnly} onBlur={handleBlur} />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <TextAreaInput name='siteDirections' label={`Directions to ${locationType} Location`} readOnly={isReadOnly} onBlur={handleBlur} tooltip={<><p>Provide directions to the site from a known location or landmark. Include highway and street numbers as well as names. Also provide distances from known locations and any other information that would assist in locating the site. You may also provide description of the proposed {locationType.toLowerCase()} location, such as lot numbers, tract numbers, or you may choose to locate the proposed {locationType.toLowerCase()} location from a known point (such as the right descending bank of Smith Creek, one mile downstream from the Highway 14 bridge). If a large river or stream, include the river mile of the proposed {locationType.toLowerCase()} location if known.</p>
                    <p>Example: The {locationType.toLowerCase()}/review area is located on the NSEW side of ROADWAY, XX miles/feet NSEW of the intersection of ROADWAY and ROADWAY, at Latitude XX.XXXXXX and Longitude XX.XXXXXX; in CITY, COUNTY, STATE.</p></>} />
                </div>
              </div>
            </>
          }
        </div>
      </>
    );
  });

export default ProjectLocationTab;