import React from 'react';

import './CurrentStep.scss';

const CurrentStep = (props) => {
  const { step, totalsteps } = props;
  const stepCount = step + 1;
  return (
    <div className='card d-flex justiy-content-right mt-1 mb-1 pt-1' >
      <div className='col step-status-col'>
        <h6 className='step-status'>Current Step: {stepCount} of {totalsteps}</h6>
      </div>


    </div>
  );
};

export default CurrentStep;