import React, { useEffect } from 'react';
import { connect } from 'redux-bundler-react';

import Breadcrumb from '../../app-components/breadcrumb';
import DashboardTable from './components/DashboardTable';
import TabContainer from '../../app-components/tab/tabContainer';
import NotLoggedIn from '../NotLoggedIn';

import { ProfileRoles } from '../../utils/enums';

import './dashboard.scss';

const Dashboard = connect(
  'doFetchUserRequests',
  'selectUserProfileData',
  'selectUserRequestList',
  'selectIsUserRoleValid',
  ({
    doFetchUserRequests,
    userProfileData,
    userRequestList,
    isUserRoleValid
  }) => {
    useEffect(() => {
      isUserRoleValid === true && doFetchUserRequests();
    }, [doFetchUserRequests, isUserRoleValid]);

    return (
      <>
        {(isUserRoleValid === true && userProfileData?.role !== ProfileRoles.USACERegulator) ? (
          <div className='dashboard-container'>
            <Breadcrumb pathname='/dashboard' href='/' hrefText='Request Page' />
            <div className='row dashboard-header'>
              <div className='col p-2'>
                <h4>My Dashboard</h4>
                You can review your request statuses in the table below.
              </div>
            </div>
            <div>
              {(userProfileData?.role === ProfileRoles.Applicant || userProfileData?.role === ProfileRoles.Agent) && (
                <TabContainer
                  tabs={[{
                    title: `Requests (${userRequestList?.length ?? 0})`,
                    content: (
                      <>
                        <p>The requests shown below are ones that have been created by you. Select the Project Name to view and/or edit your request. Note - submitted requests are locked and cannot be edited.</p>
                        <DashboardTable rowData={userRequestList} />
                      </>
                    )
                  }]}
                />)}
              {userProfileData?.role === ProfileRoles.ExternalAgency && (
                <TabContainer
                  tabs={[{
                    title: `Requests (${userRequestList?.length ?? 0})`,
                    content: (
                      <>
                        <p>The requests shown below are ones that have been created by you. Select the Project Name to view and/or edit your request. Note - submitted requests are locked and cannot be edited.</p>
                        <DashboardTable rowData={userRequestList} msg='' />
                      </>
                    )
                  }, {
                    title: 'Assigned Requests (0)',
                    content: (
                      <>
                        <p>The requests shown below have been assigned (from ORM) for you to review. Please note that you only have read-only access to these requests.</p>
                        <DashboardTable rowData={[]} msg='FUTURE ENHANCEMENTS IN PROGRESS' />
                      </>
                    )
                  }]}
                />)}
              {userProfileData?.role === ProfileRoles.USACERegulator && (
                <TabContainer
                  tabs={[{
                    title: 'Requests Under Area of Interest (0)',
                    content: (
                      <>
                        <p>Use the Division and District dropdowns to view the requests under your Area of Interest. Please note that you only have read-only access to these requests.</p>
                        <DashboardTable rowData={[]} msg='FUTURE ENHANCEMENTS IN PROGRESS' />
                      </>
                    )
                  }]}
                />)}
            </div>
          </div>
        ) : <NotLoggedIn />}
      </>
    );
  });

export default Dashboard;