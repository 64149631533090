import React from 'react';
import { connect } from 'redux-bundler-react';

import CuiBanner from '../cui-banner';
import cwbiLogo from '../../styles/images/CWBI_Logo-Small.png';
import LandingModal from '../../app-pages/common/modals/Landing';
import LinkButton from '../../app-components/link/linkButton.jsx';
import InternalLink from '../../app-components/link/internalLink.jsx';
import regulatoryLogo from '../../styles/images/USACE_Regulatory_Logo.png';

import './footer.scss';

const Footer = connect(
  'doModalOpen',
  'selectAuthIsLoggedIn',
  ({
    doModalOpen,
    authIsLoggedIn,
    version
  }) => (
    <div className='fixed-bottom'>
      <footer className='navbar page-footer'>
        <div className='col-xs-8 left-footer'>
          <img
            className='cwbi-logo'
            alt='CWBI Logo'
            src={cwbiLogo}
            height={25}
          />
          <span className='support-tag'>
            Supported by Civil Works Business Intelligence
          </span>
        </div>
        <div className='col-xs-2 center-footer'>
          <ul className='footer-links'>
            <li>
              <LinkButton className='footer-links-button' customClasses title='Privacy & Legal footer link' onClick={() => doModalOpen(LandingModal)} content='Privacy & Legal' />
            </li>
            <li>
              <InternalLink title='Help footer link' href='/help' content='Help' />
            </li>
            {/* {authIsLoggedIn && <li>
            <a title='Last Login footer link'>Last Login</a>
          </li>
          } */}
            <li>
              <span title='Version'>RRS v{version}</span>
            </li>
          </ul>
        </div>
        <div className='col-xs-2 right-footer d-flex'>
          <img
            className='usace-logo'
            alt='Regulatory Logo'
            src={regulatoryLogo}
            height={25}
          />
        </div>
      </footer>
      <CuiBanner />
    </div>
  )
);

export default Footer;