import React from 'react';
import { useFormContext } from 'react-hook-form';

import InputField from './inputField';
import { isDateRangeValid } from '../../utils/helpers';

import '../../styles/index.scss';

const DateRangeInput = ({
  nameBefore,
  nameAfter,
  labelBefore,
  labelAfter,
  dateBefore,
  required,
  onChange = () => { },
  isOnGoing,
  readOnly,
  className
}) => {
  const { watch, setValue } = useFormContext();
  const watchNameBefore = watch(nameBefore);
  const watchNameAfter = watch(nameAfter);

  const handleBlur = (e) => {
    // Before date cannot be after the After date
    if (e?.target?.name === nameBefore) {
      if (isDateRangeValid(new Date(e?.target?.value), new Date(watchNameAfter)) === false) {
        setValue(nameAfter, '');
      }
    }
    if (e?.target?.name === nameAfter) {
      if (isDateRangeValid(new Date(watchNameBefore), new Date(e?.target?.value)) === false) {
        setValue(nameBefore, '');
      }
    }
  };

  const setMin = () => watchNameBefore ? watchNameBefore : dateBefore;

  return (
    <div className='row'>
      <div className={className}>
        <InputField type='date' min={dateBefore} max={watchNameAfter} className='w-100' name={nameBefore} onChange={onChange} onBlur={handleBlur} label={labelBefore} readOnly={readOnly} required={required} />
      </div>
      <div className={className}>
        <InputField type='date' min={isOnGoing ? null : setMin()} className='w-100' name={nameAfter} onChange={onChange} onBlur={handleBlur} label={labelAfter} readOnly={isOnGoing || readOnly} required={required} />
      </div>
    </div>
  );
};

export default DateRangeInput;