import React, { useState } from 'react';
import Select from 'react-select';

import Tooltip from '../../../../app-components/tooltip/tooltip';

import { Row } from '../../../_shared/helper.jsx';
import { WOTUSTooltipContent, dredgeTooltipContent, fillTooltipContent } from '../_shared/helper.jsx';
import InternalLink from '../../../../app-components/link/internalLink.jsx';

const QAMap = {
  'A': { text: 'Then, it appears based on your selection that a permit may not be required; however, if questions remain, please contact your local USACE Regulatory office. You can exit out of this tool. Have a great day!', link: false },
  'B': {
    text: (<>If your activity is located in an area of tidal waters, the best way to avoid the need for a permit is to select a site that is above the High Tide Line  for fill material, above the Mean High Water Line for structures, and avoids wetlands or other water-bodies. In the vicinity of fresh water, stay above Ordinary High Water Mark and avoid wetlands adjacent to the stream or lake.  <p><InternalLink href='/home/jurisdiction' title='Learn more about high tide line, mean high water line, and ordinary high water mark.' content='Learn more about high tide line, mean high water line, and ordinary high water mark.' /></p></>),
    link: false
  },
  'C': { text: (<>Click <b>Next</b> to view the next set of questions.</>), link: true },
};

const QuestionSetOne = ({ setAnswerValue }) => {
  const [answer, setAnswer] = useState('');

  const handleSelect = val => {
    if (val.value === 'C') {
      setAnswerValue(val.value);
    } else {
      setAnswerValue('');
    }
    setAnswer(val.value);
  };

  return (
    <>
      <div className='container-fluid'>
        <div>
          We will ask you a few questions to help guide you through the Permitting Process.
          <div className='header-spacer'>
            <Row>
              <p className='h6 border-bottom w-100 pb-2'>Question</p>
            </Row>
            <p>Are you planning to do any of the following:</p>
            <ol>
              <li>Conduct work<Tooltip name='work' content='e.g., dredging or disposal of dredged material, excavation, filling, etc.' /> or install structures<Tooltip name='structure' content='e.g. piers, wharfs, breakwaters, bulkheads, jetties, weirs, transmission lines' /> in navigable waters of the United States?</li>
              <li>
                Discharge (dump, place, deposit) dredged<Tooltip name='dredged' content={dredgeTooltipContent} /> or fill<Tooltip name='fill' content={fillTooltipContent} /> material into waters of the United States<Tooltip name='us' content={WOTUSTooltipContent} />, including wetlands?
              </li>
              <li>Transport dredged material for the purpose of dumping it in ocean waters?</li>
            </ol>
          </div>
          <div className='header-spacer'>
            <Row>
              <p className='h6 border-bottom w-100 pb-2' aria-labelledby='answer-heading'>Answer</p>
            </Row>
            <Select
              aria-label='Select an answer'
              aria-describedby='answer-description'
              name='answer'
              value={answer.value}
              options={[
                { value: 'A', label: 'No, I\'m not planning any of these activities.' },
                { value: 'B', label: 'Yes, but how can I design my project to eliminate the need for a Corps Permit?' },
                { value: 'C', label: 'Yes. I am planning to do one or more of the listed activities and I am unable to avoid wetlands or water-bodies.' }
              ]}
              onChange={val => handleSelect(val)}
              menuPlacement='top'
            />
            <p className='header-spacer'>{answer !== '' ? QAMap[answer].text : 'Select an option above to view responses to selected answer.'}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionSetOne;
