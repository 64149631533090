import React, { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';
import { useDropzone } from 'react-dropzone';
import Icon from '@mdi/react';
import { mdiUpload } from '@mdi/js';
import Tooltip from '../tooltip/tooltip';
import './tableDragInput.scss';
import '../../styles/index.scss';

const TableDragInput = connect(
  'selectCombinedRequestFiles',
  ({
    combinedRequestFiles,
    text = 'Drag and drop your files here, or click to select files',
    name,
    label,
    accept,
    required,
    onChange,
    tooltip,
    isDisabled = false,
  }) => {
    const [isDraggingOver, setIsDraggingOver] = useState(false);

    const fileValidator = file => {
      if (file?.size === 0) {
        return {
          code: 'empty-file',
          message: 'File is empty. Please select another file.'
        };
      }
      if (file?.size >= 104857600) { // (binary bytes) 100MB
        return {
          code: 'file-size-too-large',
          message: 'File is too large (>= 100Mb). Please compress file, parse into separate files, or select another file.'
        };
      }

      if (combinedRequestFiles?.filter(item => item.fileName === file.name).length > 0) {
        return {
          code: 'file-exists',
          message: 'This file name has already been used. Please rename file or select another.'
        };
      }
      return null;
    };

    const {
      acceptedFiles,
      getRootProps,
      getInputProps
    } = useDropzone({
      disabled: isDisabled,
      accept: accept,
      validator: fileValidator,
      onDragEnter: () => setIsDraggingOver(true),
      onDragLeave: () => setIsDraggingOver(false),
      onDrop: () => setIsDraggingOver(false),
    });

    useEffect(() => {
      onChange(acceptedFiles);
    }, [acceptedFiles, onChange]);

    return (
      <>
        <label
          className={'mr-2 mb-1 w-100'}
          htmlFor={name}>
          {label}
          {tooltip && <>
            <Tooltip name={name} content={tooltip} iconStyle={{ marginLeft: '5px' }} />
          </>}
        </label>
        <div className={`drag-input ${isDraggingOver ? 'active-dragover' : ''}`}>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input
              type='file'
              id={name}
              name={name}
              required={required}
              aria-invalid={(acceptedFiles.length === 0) ? 'true' : 'false'}
              aria-required={required}
              {...getInputProps()}
              tabIndex={0}
            />
            <p style={{ padding: '10px' }}><span><Icon path={mdiUpload} size={'16px'} /></span> {text} {required ? <span className='asterisk-color'>*</span> : ''}</p>
          </div>
        </div>
      </>
    );
  });

export default TableDragInput;
