import React, { useCallback, useState, useEffect, useRef } from 'react';
import { connect } from 'redux-bundler-react';

import { isDateValid, isValidASCII, sanitizeASCII } from '../../../utils/helpers';
import genericSecondaryModal from '../../../app-pages/Forms/components/modals/genericSecondaryModal';
import { decimalNumberRegex } from '../../../utils/regex';
import { ErrorMessages } from '../../../utils/enums.tsx';

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const WatersNameTableCell = connect(
  'doSecondaryModalOpen',
  ({
    doSecondaryModalOpen,
    getValue,
    row,
    column,
    table,
    cell,
  }) => {
    const columnMeta = column.columnDef.meta;
    const type = columnMeta?.type ?? 'text';
    const tableMeta = table.options.meta;
    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);

    // Setting the End Date
    var endDate = new Date();
    endDate.setFullYear(endDate.getFullYear() + 100);
    endDate = new Date(endDate).toISOString().slice(0, 10);

    const debouncedUpdateRef = useRef();

    useEffect(() => {
      debouncedUpdateRef.current = debounce((newValue) => {
        if (tableMeta?.updateData) {
          tableMeta?.updateData(row.index, column.id, type === 'number' ? Number(newValue) : newValue ?? newValue);
        }
      }, 500);
    }, [row.index, column.id, tableMeta?.updateData, type, tableMeta]);

    const updateValue = useCallback((newValue) => {
      debouncedUpdateRef.current(newValue);
    }, []);

    const getRequired = (row) => {

      const mitigationType = row.getValue('mitigationType');

      if (mitigationType !== 'Advanced Permittee Responsible Mitigation') {
        return true;
      }
      else {
        return false;
      }

    };

    const handleBlur = (e) => {
      // Clear field if value is 0 or is a negative number
      if (String(e?.target?.value) === '0' || String(e?.target?.value)[0] === '-') {
        setValue('');
        updateValue('');
      }
      if (columnMeta?.formatCoordStr) {
        if (!isNaN(e?.target?.value)) {
          const formattedCoord = columnMeta.formatCoordStr(e?.target?.value);
          setValue(formattedCoord);
          updateValue(formattedCoord);
        }
      }
      if (type === 'date') {
        if (isDateValid(value, columnMeta?.min, endDate) === false) {
          // @TODO: Workaround to rerender the cell - generates console warning for invalid formatting
          setValue('0000-0-0');
          updateValue('');
        }
      }
      if (columnMeta?.isNumber === true) {
        if (decimalNumberRegex.test(e?.target?.value) !== true) {
          // Parse out any letters and extra periods from int/float value
          const parsedLettersVal = e?.target?.value?.replace(/[^0-9.]/g, '');
          const rx = /^(\d*\.)([\d.]*)$/;
          const parsedPeriodsVal = parsedLettersVal?.replace(rx, (a, b, c) => b + c?.replace(/\./g, ''));
          setValue(parsedPeriodsVal);
          updateValue(parsedPeriodsVal);
        }
      }
      //tableMeta?.updateData(row.index, column.id, columnMeta?.type === 'number' ? Number(e.target.value) : e.target.value ?? e.target.value);
    };

    const handleChange = (e) => {
      setValue(e?.target?.value);
      updateValue(e?.target?.value);
    };

    // Handling special characters
    useEffect(() => {
      if (value && typeof value === 'string') {
        if (isValidASCII(value)) {
          setValue(value);
          updateValue(value);
        } else {
          // Optionally handle invalid input here, such as warning the user
          const msg = ErrorMessages.InvalidCharacters;
          doSecondaryModalOpen(genericSecondaryModal, { title: 'Invalid Characters', msg: msg });
          setValue(sanitizeASCII(value));
          updateValue(sanitizeASCII(value));
        }
      };
    }, [value, setValue, updateValue, doSecondaryModalOpen]);

    const getMaxLength = () => {
      if (type === 'text') {
        return columnMeta?.maxLength || 256;
      }
      return null;
    };

    return type === 'select' ? (
      <select
        id={cell.id}
        style={{ width: '100%' }}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value ?? ''}
        required={getRequired(row)}
        disabled={columnMeta?.readOnly}>
        <option key={0} value='' className='none' style={{ display: 'none' }}>-- Select a value --</option>
        {columnMeta?.options?.map((option) => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    ) : (
      <input
        id={cell.id}
        style={{ width: '100%' }}
        value={value ?? ''}
        onChange={handleChange}
        onBlur={handleBlur}
        type={type}
        required={getRequired(row)}
        min={type === 'date' ? columnMeta?.min : null}
        max={type === 'date' ? endDate : null}
        maxLength={getMaxLength()}
        readOnly={columnMeta?.readOnly}
      />
    );
  });

export default WatersNameTableCell;