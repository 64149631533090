import React from 'react';
import { connect } from 'redux-bundler-react';

import Accordion from '../../../app-components/accordion';
import AccordionListItem from '../../../app-components/accordion/accordionListItem';
import NewSectionCard from './_shared/NewSectionCard';
import Card from '../../../app-components/card/card';
import ExternalLink from '../../../app-components/link/externalLink.jsx';
import Tooltip from '../../../app-components/tooltip/tooltip';
import NewApplicationModal from '../../Home/components/modals/NewApplication';
import { ProfileRoles } from '../../../../src/utils/enums.tsx';

import { Row } from '../../_shared/helper.jsx';
import {
  CWATooltipContent,
  RHATooltipContent,
  SectionHeader,
  adjacentTooltipContent,
  baselineTooltipContent,
  tidalWatersTooltipContent,
  tideLineTooltipContent,
  waterLineTooltipContent,
  watermarkTooltipContent,
  wetlandTooltipContent
} from './_shared/helper.jsx';

import freshWatersImg from '../../../styles/images/RRS Graphic V2_041423.png';
import tidalWatersImg from '../../../styles/images/RRS Graphic V3_042623.png';

import '../resources.scss';

const Jurisdiction = connect(
  'doAuthLogin',
  'doModalOpen',
  'doUpdateRelativeUrl',
  'selectAuthIsLoggedIn',
  'selectUserProfileData',
  ({
    doAuthLogin,
    doModalOpen,
    doUpdateRelativeUrl,
    authIsLoggedIn,
    userProfileData
  }) => {
    const handleLink = () => {
      sessionStorage.setItem('ResourcesModuleSession', true);
      if (authIsLoggedIn) {
        const resourcesModuleSession = sessionStorage.getItem('ResourcesModuleSession');
        doUpdateRelativeUrl('/home');
        resourcesModuleSession && doModalOpen(NewApplicationModal);
        sessionStorage.removeItem('ResourcesModuleSession');
      } else {
        doAuthLogin();
      }
    };

    return (
      <>
        <section id='understanding-corp-jurisdiction-id'>
          <SectionHeader>Understanding U.S. Army Corps of Engineers (USACE) Regulatory Authority and Jurisdiction</SectionHeader>
          <p>The USACE exercises regulatory authority over various waterways, including rivers, lakes, and wetlands, throughout the United States and its territories. Explore the sections below to learn more about the USACE Regulatory authorities and the geographic extent of USACE Jurisdiction.</p>
        </section>
        <section id='waters-id'>
          <SectionHeader>Which waters do the USACE Regulate?</SectionHeader>
          <p>There are two categories of waters that the USACE has jurisdiction over:</p>
          <Accordion.List>
            <AccordionListItem headingText={<>Expand to Learn More About <b>Navigable Waters of the United States</b></>}>
              <ul>
                <li>Are regulated by the USACE pursuant to Section 10 of the Rivers and Harbors Act<Tooltip name='rivers-harbors-act' content={RHATooltipContent} />.</li>
                <li>These waters are subject to the ebb and flow of the tide and/or are presently used, or have been used in the past, or may be susceptible for use to transport interstate or foreign commerce.</li>
                <li>A more detailed definition of these waters is provided at <ExternalLink href='https://www.ecfr.gov/current/title-33/chapter-II/part-329' title='33 CFR 329' content='33 CFR 329' />.</li>
              </ul>
            </AccordionListItem>
            <AccordionListItem headingText={<>Expand to Learn More About <b>Waters of the United States</b></>}>
              <ul>
                <li>Are regulated by the USACE pursuant to Section 404 of the Clean Water Act<Tooltip name='clean-water-act' content={CWATooltipContent} />.</li>
                <li>These waters include navigable waterways, wetlands adjacent/adjoining navigable waters, and various components of the surface water tributary system, extending up to small streams. They also includes lakes, ponds, or other water bodies connected to those navigable waters and tributaries/streams, as well as adjacent wetlands, provided they meet specific criteria.</li>
                <li>A more detailed definition of these waters is provided at <ExternalLink href='https://www.ecfr.gov/current/title-33/chapter-II/part-328' title='33 CFR 328' content='33 CFR 328' />.</li>
              </ul>
            </AccordionListItem>
          </Accordion.List>
          <p className='mt-3'>The geographic extent of these waters are divided into two categories:</p>
          <Accordion.List>
            <AccordionListItem headingText={<>Expand to Learn More About <b>Tidal Waters of the United States & Territorial Seas</b></>}>
              <ul>
                <li>Under Section 10 of the Rivers and Harbors Act, the extent of USACE jurisdiction in <b>tidal waters</b><Tooltip name='tidal-water' content={tidalWatersTooltipContent} /> extends shoreward to the <b>mean high water line</b><Tooltip name='waterline' content={waterLineTooltipContent} />.</li>
                <li>Under Section 404 of the Clean Water Act, the extent of USACE jurisdiction in <b>tidal waters</b><Tooltip name='tidal-water-2' content={tidalWatersTooltipContent} /> extends to the <b>high tide line</b><Tooltip name='tideline' content={tideLineTooltipContent} />. When adjacent wetlands are present, the geographic limits extend to the delineated limits of the <b>wetland</b><Tooltip name='wetland' content={wetlandTooltipContent} />.</li>
                <li>The limit of jurisdiction in the territorial seas is measured from the <b>baseline</b><Tooltip name='baseline' content={baselineTooltipContent} /> in a seaward direction a distance of three nautical miles.</li>
              </ul>
              <Row className='image-row'>
                <Card className='image-container-accordion'>
                  <Card.Body>
                    <img src={tidalWatersImg} alt='Lateral limits of the USACE jurisdiction in tidal waters' className='w-100' />
                    <p>This figure is a graphical depiction of the lateral limits of the USACE jurisdiction in tidal waters.</p>
                  </Card.Body>
                </Card>
              </Row>
            </AccordionListItem>
            <AccordionListItem headingText={<>Expand to Learn More About <b>Non-Tidal Waters of the United States</b></>}>
              <ul>
                <li>The limit of jurisdiction in non-tidal waters extends to the <b>ordinary high water mark</b><Tooltip name='watermark' content={watermarkTooltipContent} />.</li>
                <li>When adjacent wetlands are present, the jurisdiction extends beyond the <b>ordinary high water mark</b><Tooltip name='watermark-2' content={watermarkTooltipContent} /> to the delineated limit of the <b>adjacent</b><Tooltip name='adjacent' content={adjacentTooltipContent} /> wetlands.</li>
              </ul>
              <Row className='image-row'>
                <Card className='image-container-accordion'>
                  <Card.Body>
                    <img src={freshWatersImg} alt='Lateral limits of the USACE jurisdiction in fresh waters' className='w-100' />
                    <p>This figure is a graphical depiction of the lateral limits of the USACE jurisdiction in fresh waters (non-tidal waters).</p>
                  </Card.Body>
                </Card>
              </Row>
            </AccordionListItem>
          </Accordion.List>
        </section>

        <section id='delineations-of-waters-id' className='mt-3'>
          <SectionHeader>How do I determine if regulated waters are on my project site?</SectionHeader>
          <p>Delineation of aquatic resources is the act of identifying and mapping the extent of aquatic resources (such as rivers, streams, and wetlands) within a specified review area using 
            scientific methods developed by the U.S. Army Corps of Engineers (Corps). An aquatic resources delineation report is a document or series of documents that describe the location and 
            extent of aquatic resources in the review area, describes the methodology used to conduct the delineation, and typically includes maps, datasheets, photographs, and/or figures that 
            depict the location and extent of aquatic resources on the site.
          </p>
          <p>A necessary step in the regulatory permitting process is identifying and delineating aquatic resources within the established footprint of regulated activities. Once the aquatic 
            resources have been accurately identified and delineated, Corps project managers determine the jurisdictional status of the aquatic resources and what (if any) permitting requirements 
            would apply.
          </p>
          <p>A delineation of aquatic resources is a required component of a permit application. The most effective way to obtain a timely permit decision is to submit an accurate aquatic resources 
            delineation report with your permit application. Most applicants elect to retain an environmental consultant to prepare the aquatic resource delineation report, to ensure that the aquatic 
            resources delineation methods have been correctly applied and that the report includes the information needed for the Corps to process a permit application. If you have a question 
            regarding the jurisdictional status or geographic boundary of an aquatic resource on your project site, please specify what questions you have in a cover letter that is attached to your 
            permit application and the Corps will evaluate and address those questions as part of our review of the submittal.
          </p>
          <Accordion.List>
            <AccordionListItem headingText='Expand to Learn More About the Scientific Methods Used to Identify Waters of the United States'>
              <h5><ExternalLink href='https://www.usace.army.mil/Missions/Civil-Works/Regulatory-Program-and-Permits/reg_supp/' title='Wetland Delineation Manual and Regional Supplements' content='Wetland Delineation Manual and Regional Supplements' /></h5>
              <p>Wetland delineation consists of standardized procedures that are used to determine if a wetland is present on a site and, if so, to establish its boundaries in the field.</p>
              <h5><ExternalLink href='https://www.usace.army.mil/Missions/Civil-Works/Regulatory-Program-and-Permits/reg_supp/#data-sheets' title='Automated Wetland Datasheets (ADS)' content='Automated Wetland Datasheets (ADS)' /></h5>
              <p>The Microsoft Excel-based ADS increases technical accuracy by reducing errors and increases efficiency by automatically populating many of the field indicators of wetland hydrology, hydrophytic vegetation,
                and hydric soils. These should be used to the extent possible instead of paper datasheets forms.</p>
              <h5><ExternalLink href='https://www.erdc.usace.army.mil/Media/Fact-Sheets/Fact-Sheet-Article-View/Article/486085/ordinary-high-water-mark-ohwm-research-development-and-training/' title='Ordinary High Water Mark Guidance (OHWM) and Manual' content='Ordinary High Water Mark Guidance (OHWM) and Manual' /></h5>
              <p>The OHWM defines the lateral limits of non-tidal open waters including streams, rivers, lakes, ponds, etc.</p>
              <h5><ExternalLink href='https://wetland-plants.sec.usace.army.mil/' title='National Wetland Plant List (NWPL)' content='National Wetland Plant List (NWPL)' /></h5>
              <p>The NWPL, along with its wetland plant species status, plays a crucial role in wetland delineation, wetland restoration and research. Additionally, it serves as a valuable resource for general botanical information about wetland plants. The NWPL encompasses all
                50 U.S. states, the District of Columbia, and the U.S. territories. Furthermore, to enhance understanding of plant distribution, the NWPL includes a complete geographic range for each taxon across North America north of Mexico. The wetland plant data is categorized
                into ten regions that align with the wetland delineation regions established by the U.S. Army Corps of Engineers.</p>
            </AccordionListItem>
          </Accordion.List>
        </section>

        <section id='jd-id' className='mt-3'>
          <SectionHeader>Can the USACE provide written assurance regarding the location of regulated waters on my project site?</SectionHeader>
          <p>A jurisdictional determination (JD) is a written Corps determination that a wetland and/or waterbody is subject to regulatory jurisdiction under Section 404 of the Clean Water 
            Act (33 U.S.C. 1344) or Sections 9  or 10 of the Rivers and Harbors Act of 1899 (33 U.S.C. 401 et seq.).
          </p>
          <p>A JD is not required to apply for or obtain a permit. The Corps evaluates jurisdiction as part of our review of a permit application. The most effective way to obtain a timely 
            permit decision is to submit an accurate aquatic resources delineation report with your permit application. Most applicants elect to retain an environmental consultant to prepare 
            the aquatic resource delineation report, to ensure that the aquatic resources delineation methods have been correctly applied and that the report includes the information needed 
            for the Corps to process a permit application. If you have a question regarding the jurisdictional status or geographic boundary of an aquatic resource on your project site, please 
            specify what questions you have in a cover letter that is attached to your permit application and the Corps will evaluate and address those questions as part of our review of the 
            submittal.
          </p>
          <p>Many projects, such as those that are complex or may involve large impacts to aquatic resources, benefit from a pre-application consultation. During such a consultation, the Corps 
            can provide information on ways to expedite, reduce, or eliminate regulatory requirements, including whether preparing a JD may reduce permitting requirements. The Regulatory Request 
            System (RRS) provides a means to request a JD in those cases.
          </p>
          <p>The Regulatory Request System (RRS) offers two options associated with USACE jurisdiction: 1) "Submit a WOTUS Delineation Report and Aquatic Resource Inventory" and 2) "Request for Jurisdictional Determination". The first option is designed for environmental consultants who have completed a delineation report on behalf of their client. The second option is designed for property owners who require a written determination of jurisdiction and/or property owners who might need a permit from the USACE and require assistance in identifying waters of the United States on their property. To submit a request, click the "Start a New Request" button below.</p>
          <p>The USACE provides two types of JDs:</p>
          <Accordion.List className='mb-3'>
            <AccordionListItem headingText='Approved Jurisdictional Determination (AJD)'>
              <p>An AJD is defined in USACE regulations at 33 CFR 331.2. As explained in further detail in <ExternalLink href='https://www.usace.army.mil/missions/civil-works/regulatory-program-and-permits/guidance-letters/' title='Regulatory Guidance Letter 16-01' content='Regulatory Guidance Letter 16-01' />, an AJD is used to indicate that the USACE
                has identified the presence or absence of wetlands and/or other aquatic resources on a site, including their accurate location(s) and boundaries, as well as their jurisdictional status. AJDs are valid for 5 years.</p>
            </AccordionListItem>
            <AccordionListItem headingText='Preliminary Jurisdictional Determination (PJD)'>
              <p>A PJD is defined in USACE regulations at 33 CFR 331.2. As explained in further detail in <ExternalLink href='https://www.usace.army.mil/missions/civil-works/regulatory-program-and-permits/guidance-letters/' title='Regulatory Guidance Letter 16-01' content='Regulatory Guidance Letter 16-01' />, a PJD is used to indicate that the USACE
                has identified the approximate location(s) and boundaries of wetlands and/or other aquatic resources on a site that are presumed to be subject to regulatory jurisdiction of the U.S. Army Corps of Engineers. Unlike an AJD, a PJD does not represent a definitive, official determination that there are, or that
                there are not, jurisdictional aquatic resources on a site, and does not have an expiration date. </p>
            </AccordionListItem>
          </Accordion.List>
          <p className='mb-3'>You can view AJDs issued by the USACE by visiting the <ExternalLink href='https://permits.ops.usace.army.mil/orm-public#' title='Approved JD Archive' content='Approved JD Archive' />.</p>
        </section>

        <section id='next-steps-id' className='mt-3'>
          <SectionHeader>What are my next steps?</SectionHeader>
          <div className='row mt-3'>
            <div className='col-lg-6 col-md-6 col-xs-12 mt-1'>
              <NewSectionCard
                title='Request a Jurisdictional Determination'
                content={`If you're ready to submit a WOTUS Delineation Report and Aquatic Resource Inventory and/or request a Jurisdictional Determination, click here to ${authIsLoggedIn ? 'start a new request' : 'login'}.`}
                onClick={() => handleLink()}
                buttonText={authIsLoggedIn ? 'Start a New Request' : 'Login'}
                buttonTitle={authIsLoggedIn ? 'Start a New Request' : 'Login'}
                isDisabled={userProfileData?.role?.toLowerCase() === ProfileRoles.USACERegulator.toString().toLowerCase()}
              />
            </div>
            <div className='col-lg-6 col-md-6 col-xs-12 mt-1'>
              <NewSectionCard
                title='Need Further Assistance'
                content='Locate your local USACE Regulatory office for further assistance.'
                url='https://regulatory.ops.usace.army.mil/offices/'
                buttonText='Office Locator'
                buttonTitle='LearnMore'
                external
              />
            </div>
          </div>
        </section>
      </>
    );
  });

export default Jurisdiction;
