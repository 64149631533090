import React, { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';

import JDForm1bInstructions from '../static-forms/JDForm1bInstructions';
import JDFormGeneralProjectInformation from '../input-forms/JDForm/JDFormGeneralProjectInformation';
import JDFormContacts from '../input-forms/JDForm/JDFormContacts';
import SupportingInformation from '../input-forms/common/SupportingInformation';
import JDFormCertifySignSubmit from '../input-forms/JDForm/JDFormCertifySignSubmit';
import JDFormPropertyOwners from '../input-forms/JDForm/JDFormPropertyOwners';

//List of steps for vertical stepper, these can also be used for form header. index = step ID 
export const JDForm1bMetadata = [
  JDForm1bInstructions.metadata,
  JDFormGeneralProjectInformation.metadata,
  JDFormContacts.metadata,
  JDFormPropertyOwners.metadata,
  SupportingInformation.metadata,
  JDFormCertifySignSubmit.metadata];

const JDForm1b = connect(
  'doUpdateJDRequest',
  'selectActiveStep',
  'selectIsReadOnly',
  'selectJDRequestID',
  'selectJDData',
  ({
    doUpdateJDRequest,
    activeStep,
    isReadOnly,
    jDRequestID,
    jDData,
    edit,
  }) => {
    const [showInstructions, setShowInstructions] = useState(false);
    const [showGeneralProjectInfo, setShowGeneralProjectInfo] = useState(false);
    const [showContactInfo, setShowContactInfo] = useState(false);
    const [showPropertyOwners, setShowPropertyOwners] = useState(false);
    const [showSupportingInfo, setShowSupportingInfo] = useState(false);
    const [showCertifySignSubmit, setShowCertifySignSubmit] = useState(false);

    //display only active step, hide all other steps
    useEffect(() => {
      if (activeStep) {
        switch (activeStep.id) {
          case 0: {
            setShowInstructions(true);
            setShowGeneralProjectInfo(false);
            setShowContactInfo(false);
            setShowPropertyOwners(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 1: {
            setShowInstructions(false);
            setShowGeneralProjectInfo(true);
            setShowContactInfo(false);
            setShowPropertyOwners(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 2: {
            setShowInstructions(false);
            setShowGeneralProjectInfo(false);
            setShowContactInfo(true);
            setShowPropertyOwners(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 3: {
            setShowInstructions(false);
            setShowGeneralProjectInfo(false);
            setShowContactInfo(false);
            setShowPropertyOwners(true);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 4: {
            setShowInstructions(false);
            setShowGeneralProjectInfo(false);
            setShowContactInfo(false);
            setShowPropertyOwners(false);
            setShowSupportingInfo(true);
            setShowCertifySignSubmit(false);
            break;
          }
          case 5: {
            setShowInstructions(false);
            setShowGeneralProjectInfo(false);
            setShowContactInfo(false);
            setShowPropertyOwners(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(true);
            break;
          }
          default: {
            setShowInstructions(true);
            setShowGeneralProjectInfo(false);
            setShowContactInfo(false);
            setShowPropertyOwners(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
          }
        }
      }
    }, [activeStep]);

    const instructionStyles = { display: showInstructions ? 'inline' : 'none' };
    const generalProjInfoStyles = { display: showGeneralProjectInfo ? 'inline' : 'none' };
    const contactInfoStyles = { display: showContactInfo ? 'inline' : 'none' };
    const propertyOwnersStyles = { display: showPropertyOwners ? 'inline' : 'none' };
    const supportingInfoStyles = { display: showSupportingInfo ? 'inline' : 'none' };
    const certifySignSubmitStyles = { display: showCertifySignSubmit ? 'inline' : 'none' };

    return (<>
      <section id='JD1bInstructions' name='Instructions' style={instructionStyles}>
        <JDForm1bInstructions stepNo={0} />
      </section>
      <section id='JD1bGeneralProjectInformation' name='General Project Information' style={generalProjInfoStyles}>
        <JDFormGeneralProjectInformation isReadOnly={isReadOnly} stepNo={1} />
      </section>
      <section id='JD1bContactInformation' name='Contact Information' style={contactInfoStyles}>
        <JDFormContacts componentID={jDRequestID} edit={edit} isReadOnly={isReadOnly} stepNo={2} />
      </section>
      <section id='JD1bPropertyOwners' name='Property Owners' style={propertyOwnersStyles}>
        <JDFormPropertyOwners componentID={jDRequestID} edit={edit} isReadOnly={isReadOnly} stepNo={3} />
      </section>
      <section id='JD1bSupportingInformation' name='Supporting Information' style={supportingInfoStyles}>
        <SupportingInformation
          componentID={jDRequestID}
          subtext={<p>Upload supporting documentation including wetland datasheets, OHWM datasheets, and figures supporting the delineation report. This information will help facilitate the review of your request.
            Multiple documents of the same file can be combined and uploaded as one document.</p>}
          isReadOnly={isReadOnly}
          requestData={jDData}
          updateRequestData={doUpdateJDRequest}
          stepNo={4}
        />
      </section>
      <section id='JD1bCertifySignSubmit' name='Certify Sign Submit' style={certifySignSubmitStyles}>
        <JDFormCertifySignSubmit isReadOnly={isReadOnly} stepNo={5} />
      </section>
    </>);

  });

export default JDForm1b;
