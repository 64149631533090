import React, { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';

import Step from './Step';
import CurrentStep from './CurrentStep';
import FormStatus from './FormStatus';

import './VerticalStepper.scss';

const VerticalStepper = connect(
  'doSetActiveStep',
  'doSetTouchedStep',
  'selectSteps',
  'selectActiveStep',
  ({
    doSetActiveStep,
    doSetTouchedStep,
    steps,
    activeStep,
  }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [previousStep, setPreviousStep] = useState();

    const onStepClickHandler = (event) => {
      const clickedID = parseInt(event.target.id);

      if (activeStep?.id !== clickedID) {
        doSetActiveStep(clickedID);
        document.getElementById('scroll').scrollTop = 0;
      }
    };

    useEffect(() => {
      if (activeStep?.id) {
        setPreviousStep(currentStep);
        setCurrentStep(activeStep?.id ?? 0);
      }
    }, [activeStep?.id, setPreviousStep, setCurrentStep, currentStep]);

    useEffect(() => {
      previousStep !== currentStep && doSetTouchedStep(previousStep);
    }, [previousStep, currentStep, doSetTouchedStep]);

    return (
      <div className='vertical-container'>
        <FormStatus />
        <CurrentStep step={currentStep} totalsteps={steps.length} />
        {steps.map((step) => (
          <Step
            id={step.id}
            key={step.id}
            status={step.status}
            last={step.last}
            touched={step.touched}
            onStepClick={onStepClickHandler}
          >
            {step.label}
          </Step>
        ))}
      </div>
    );
  }
);

export default VerticalStepper;
