import React from 'react';

const FileDetails = ({ file, isValue }) => {
  const name = file.fileName || file.name;
  const lastModified = isValue ? file.lastModified : file.file?.[0]?.lastModified || file.lastModified;

  return (
    <div className='d-inline'>
      <p className='text-primary d-inline mr-3'><b> {name || '--'}</b></p>
      <p className='text-primary d-inline mr-3'>Last Modified: <b>{lastModified ? new Date(lastModified).toDateString() : '--'}</b></p>
    </div>
  );
};

export default FileDetails;
