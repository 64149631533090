import React from 'react';

import './dynamictable.scss';

const DynamicTable = (props) => {
  const { columns, data, childKey } = props;

  return (
    <div className='dynamictable'>
      <table>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, i) => (
            <React.Fragment key={i}>
              <tr>
                {columns.map((column) => (
                  <td style={row[column] === 'PCN not required' ? { color: 'red', fontWeight: 'bold' } : column === 'Practicability Category' ? { fontWeight: 'bold' } : {}} key={column}>{row[column]}</td>
                ))}
              </tr>
              {childKey &&
                row[childKey] &&
                row[childKey].map((childRow, j) => (
                  <tr key={`${i}-${j}`}>
                    {columns.map((column) => (
                      <td style={childRow[column] === 'PCN not required' ? { color: 'red', fontWeight: 'bold' } : {}} key={column}>{childRow[column]}</td>
                    ))}
                  </tr>
                ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div >
  );

};

export default DynamicTable;
