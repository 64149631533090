import { ContactTypes } from '../../../../../utils/enums.tsx';

//Checks if there is a user profile agent
export const userProfileAgentUsed = (agents, userFirstName, userLastName, userEmail) =>
  agents.some(agent => agent.firstName?.toLowerCase() === userFirstName?.toLowerCase()
    && agent.lastName?.toLowerCase() === userLastName?.toLowerCase()
    && agent.emailAddress?.toLowerCase() === userEmail?.toLowerCase());

//Checks if row is user profile agent
export const isUserProfileAgent = (agent, userFirstName, userLastName, userEmail) =>
  agent.firstName?.toLowerCase() === userFirstName?.toLowerCase()
  && agent.lastName?.toLowerCase() === userLastName?.toLowerCase()
  && agent.emailAddress?.toLowerCase() === userEmail?.toLowerCase();

//Checks if duplicate agent would be created
export const hasDuplicateAgent = (agents, newAgent) =>
  agents.some(agent => agent.firstName?.toLowerCase() === newAgent.firstName?.toLowerCase()
    && agent.lastName?.toLowerCase() === newAgent.lastName?.toLowerCase()
    && agent.emailAddress?.toLowerCase() === newAgent.emailAddress?.toLowerCase());

export const isCurrentUser = (userProfileData, contactFields) =>
  userProfileData?.firstName === contactFields?.firstName &&
  userProfileData?.lastName === contactFields?.lastName &&
  userProfileData?.emailAddress === contactFields?.emailAddress;

export const updateAgentsValue = (userRole, rowData, setValue) => {
  switch (userRole) {
    case ContactTypes.Agent:
      setValue('agents', 'true');
      break;
    case ContactTypes.Applicant:
      if (rowData.length > 0)
        setValue('agents', 'true');
      else
        setValue('agents', 'false');
      break;
    default:
      break;
  }
};

//Updates the Agents dropdown depending on the updated user role and agents data
export const updateGPAgentsValue = (userRole, hasHiredAgent, setValue, doUpdatePermitRequest = null) => {
  switch (userRole) {
    case ContactTypes.Agent:
      setValue('agents', 'true');
      doUpdatePermitRequest && doUpdatePermitRequest({ hasHiredAgent: 'true' });
      break;
    case ContactTypes.Applicant:
      if (hasHiredAgent === 'true') {
        setValue('agents', 'true');
      }
      else {
        setValue('agents', 'false');
      }
      break;
    default:
      break;
  }
};



//Adds user profile data as an agent record. Does not allow duplicate entries.
export const addUserProfileAgent = (userProfileData, rowData, setRowData, isUserProfileAgentUsed, setIsUserProfileAgentUsed, doUpdatePermitRequest = null) => {
  if (!isUserProfileAgentUsed) {
    let newRowData = [...rowData];

    let newAgent = {};
    newAgent.contactType = userProfileData.role;
    newAgent.salutation = userProfileData.salutation;
    newAgent.firstName = userProfileData.firstName;
    newAgent.middleName = userProfileData.middleName;
    newAgent.lastName = userProfileData.lastName;
    newAgent.address = userProfileData.address;
    newAgent.addressTwo = userProfileData.addressTwo;
    newAgent.city = userProfileData.city;
    newAgent.country = userProfileData?.country ?? 'US';
    newAgent.state = userProfileData.state;
    newAgent.zipcode = userProfileData.zipcode;
    newAgent.phoneOne = userProfileData.phone;
    newAgent.phoneOneCountryCode = userProfileData?.phoneCountryCode ?? '1';
    newAgent.phoneOneType = 'Primary';
    newAgent.emailAddress = userProfileData.emailAddress;
    newAgent.company = userProfileData.companyName;

    newRowData.push(newAgent);

    setRowData(newRowData);
    setIsUserProfileAgentUsed(true);
    doUpdatePermitRequest && doUpdatePermitRequest({ hasHiredAgent: 'true' });
  }
};

//Updates the user profile agent; assuming it exists
export const updateUserProfileAgent = (agents, userProfileData) => {
  //Find user profile agent
  let userProfileAgent = agents.find(agent => agent.firstName.toLowerCase() === userProfileData.firstName.toLowerCase()
    && agent.lastName.toLowerCase() === userProfileData.lastName.toLowerCase()
    && agent.emailAddress.toLowerCase() === userProfileData.emailAddress.toLowerCase());
  const index = agents.indexOf(userProfileAgent);

  let newAgent = { ...userProfileAgent };
  newAgent.contactType = userProfileData.role;
  newAgent.salutation = userProfileData.salutation;
  newAgent.firstName = userProfileData.firstName;
  newAgent.middleName = userProfileData.middleName;
  newAgent.lastName = userProfileData.lastName;
  newAgent.address = userProfileData.address;
  newAgent.addressTwo = userProfileData.addressTwo;
  newAgent.city = userProfileData.city;
  newAgent.state = userProfileData.state;
  newAgent.zipcode = userProfileData.zipcode;
  newAgent.phoneOne = userProfileData.phone;
  newAgent.phoneOneCountryCode = userProfileData?.phoneCountryCode ?? '1';
  newAgent.phoneOneType = 'Primary';
  newAgent.emailAddress = userProfileData.emailAddress;
  newAgent.company = userProfileData.companyName;

  agents[index] = newAgent;
  return agents;
};