import React, { useState } from 'react';

import Card from '../../../app-components/card/card';
import { ModalTabContainer } from '../../../app-components/modal';
import QuestionSetOne from './getting-started/questionSetOne';
import QuestionSetTwo from './getting-started/questionSetTwo';

import { SectionHeader } from './_shared/helper.jsx';

import '../resources.scss';

const GettingStarted = () => {
  const [answer, setAnswer] = useState('');

  return (
    <div>
      <section id='guide-tool-id'>
        <SectionHeader>Permit Process Guide</SectionHeader>
        <p>The Regulatory Permit Process Guide is meant to provide guidance in navigating our website. After selecting one of the available answers you will be taken to a page that provides more detailed information on that topic.</p>
        <Card>
          <Card.Body>
            <ModalTabContainer
              tabs={[{
                content: (<QuestionSetOne setAnswerValue={setAnswer} />),
                setDisabled: !(!!answer),
              }, {
                content: (<QuestionSetTwo />)
              }]}
              hideFooter={answer !== 'C'}
              hideLastBtn
            />
          </Card.Body>
        </Card>
      </section>
    </div>
  );
};

export default GettingStarted;