import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { connect } from 'redux-bundler-react';
import { Alert } from '@trussworks/react-uswds';
import Icon from '@mdi/react';
import { AgGridReact } from 'ag-grid-react';
import { mdiAccountPlus, mdiCloseOctagon } from '@mdi/js';

import FieldHeader from '../../components/Form/FieldHeader';
import ContactFields from '../../../../app-components/new-inputs/contactFieldsnew';
import Card from '../../../../app-components/card/card';
import Button from '../../../../app-components/button/button';
import EditCellRenderer from '../../components/gridCellRenderers/editCellRender';
import AddAgentModal from '../../components/modals/AddAgentModal';
import SelectInput from '../../../../app-components/new-inputs/selectInput';
import TextAreaInput from '../../../../app-components/new-inputs/textAreaInput';
import ValidationCard from '../../components/Form/ValidationCard';
import DragInput from '../../../../app-components/drag-input/dragInput';
import LinkButton from '../../../../app-components/link/linkButton.jsx';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import useErrorFocus from '../../../../customHooks/useErrorFocus.jsx';
import { ContactTypes, ContactsFormNames, FileTypes, ProfileRoles, TemplateFiles } from '../../../../utils/enums.tsx';
import { addressRegex, addressTwoRegex, emailRegex, phoneRegex, countryCodeRegex, usPhoneRegex, phoneExtRegex, cityRegex, zipCodeRegex } from '../../../../utils/regex.jsx';
import { addUserProfileAgent, updateUserProfileAgent, userProfileAgentUsed, updateAgentsValue } from '../../components/modals/_shared/UserProfileAgent.jsx';
import { formatUSPhoneNumber, stripUncompletedContacts } from '../../../../utils/helpers.jsx';

import '../../../../styles/index.scss';
import ErrorSummary from '../../../../app-components/error-summary/ErrorSummary.jsx';

export const JDFormContactsMetadata = {
  sectionName: 'Contact Information',
  isSection: true,
  lastSection: false,
  firstSection: false,
};

const JDFormContacts = connect(
  'doDownloadFile',
  'doDownloadPopulatedFile',
  'doDeleteFile',
  'doResetFileSection',
  'doClearContactType',
  'doUpdateSectionValidity',
  'doModalOpen',
  'doUpdateSelectedFiles',
  'doUpdateRequestObject',
  'doUpdateRequestContacts',
  'doSaveTable',
  'selectTableData',
  'selectRequestAPIData',
  'selectAgentAuthorizationFile',
  'selectUserProfileData',
  'selectSteps',
  'selectActiveStep',
  'selectTotalFileSize',
  'selectMaxTotalFileSize',
  'selectMaxFileCount',
  'selectSelectedFiles',
  ({
    doDownloadFile,
    doDownloadPopulatedFile,
    doDeleteFile,
    doResetFileSection,
    doClearContactType,
    doUpdateSectionValidity,
    doModalOpen,
    doUpdateSelectedFiles,
    doUpdateRequestObject,
    doUpdateRequestContacts,
    doSaveTable,
    tableData,
    requestAPIData,
    agentAuthorizationFile,
    userProfileData,
    steps,
    activeStep,
    totalFileSize,
    maxTotalFileSize,
    maxFileCount,
    selectedFiles,
    edit,
    isReadOnly,
    stepNo,
    componentID
  }) => {
    const thisSectionStepStatus = useMemo(() => steps.find(step => step.id === stepNo)?.touched, [steps, stepNo]);
    const [rowData, setRowData] = useState([]);
    const [isUserProfileAgentUsed, setIsUserProfileAgentUsed] = useState(false);

    const [agentAuthFile, setAgentAuthFile] = useState([]);
    const [isMultiple, setIsMultiple] = useState(false);
    const [isOverTotalFileLimit, setIsOverTotalFileLimit] = useState(false);
    const [isOverFileCountLimit, setIsOverFileCountLimit] = useState(false);

    const applicantContact = useMemo(() => {
      const contacts = requestAPIData?.request?.contacts || [];
      return contacts.find((contact) => contact.contactType === ContactTypes.Applicant);
    }, [requestAPIData?.request?.contacts]);
    const contacts = requestAPIData?.request?.contacts ?? [];

    const agentsTableLength = rowData?.length;

    const schema = (agentsTableLength) => yup.object().shape({
      firstName: yup.string().required('First Name is required'),
      lastName: yup.string().required('Last Name is required'),
      address: yup.string().required('Address is required').matches(addressRegex, 'Please enter a valid address. Only letters, numbers, spaces, commas, periods, hyphens, apostrophes, forward slashes, and hash symbols are allowed.'),
      addressTwo: yup.string().nullable().matches(addressTwoRegex, 'Please enter a valid address. Only letters, numbers, spaces, commas, periods, hyphens, apostrophes, forward slashes, and hash symbols are allowed.'),
      city: yup.string().matches(cityRegex, 'City is invalid').required('City is required'),
      state: yup.string().when('country', { is: 'US', then: () => yup.string().required('Please select a state'), otherwise: () => yup.string().required('Please select a Region') }),
      zipcode: yup.string().when('country', { is: 'US', then: () => yup.string().matches(zipCodeRegex, 'Zipcode is invalid'), otherwise: () => yup.string().nullable() }),
      country: yup.string().required('Country is required'),
      phoneOneType: yup.string().required('Phone One Type is required'),
      phoneOneCountryCode: yup.string().required('Country Code is required').matches(countryCodeRegex, 'Country Code is invalid'),
      phoneOne: yup.string().when('phoneOneCountryCode', {
        is: '1', then: () => yup.string().required('Phone Number One is required').matches(usPhoneRegex, 'Phone Number One is invalid'),
        otherwise: () => yup.string().when('phoneOneCountryCode', { is: val => val !== '1', then: () => yup.string().required('Phone Number One is required').matches(phoneRegex, 'Phone Number One is invalid') })
      }).required('Phone Number One is required'),
      phoneOneExtension: yup.string().nullable().when('phoneOneType', { is: 'Business', then: () => yup.string().matches(phoneExtRegex, 'Phone Ext is invalid') }),
      showPhoneTwo: yup.boolean().nullable(),
      phoneTwoType: yup.string().nullable().when('showPhoneTwo', { is: true, then: () => yup.string().required('Phone Two Type is required') }),
      phoneTwoCountryCode: yup.string().nullable().when('showPhoneTwo', { is: true, then: () => yup.string().required('Country Code is required').matches(countryCodeRegex, 'Country Code is invalid') }),
      phoneTwo: yup.string().nullable().when(['phoneTwoCountryCode', 'showPhoneTwo'], {
        is: (countryCode, showPhoneTwo) => countryCode === '1' && showPhoneTwo === true, then: () => yup.string().required('Phone Number Two is required').matches(usPhoneRegex, 'Phone Number Two is invalid'),
        otherwise: () => yup.string().nullable().when(['phoneTwoCountryCode', 'showPhoneTwo'], { is: (countryCode, showPhoneTwo) => countryCode !== '1' && showPhoneTwo === true, then: () => yup.string().required('Phone Number Two is required').matches(phoneRegex, 'Phone Number Two is invalid') })
      }),
      phoneTwoExtension: yup.string().nullable().when(['showPhoneTwo', 'phoneTwoType'], { is: (show, type) => show && type === 'Business', then: () => yup.string().nullable().matches(phoneExtRegex, { message: 'Phone Extension is invalid', excludeEmptyString: true }) }),
      faxPhone: yup.string().matches(phoneRegex, { message: 'Fax Phone is invalid', excludeEmptyString: true }).nullable(),
      emailAddress: yup.string().required('Email Address is required').matches(emailRegex, 'Email Address is invalid'),
      agents: yup.string().required('Please select a value'),
      agentsTable: yup.boolean().when('agents', { is: (val) => val === 'true' && agentsTableLength < 1, then: () => yup.boolean().required('At least one agent is required') }),
      agentAuth: yup.string().when('agents', { is: 'true', then: () => yup.string().required('Please provide an Agent Authorization Form') }),
      agentAuthComments: yup.string().when('agents', { is: 'true', then: () => yup.string().nullable() }),
    });

    const defaultValues = {
      salutation: applicantContact?.salutation ?? '',
      firstName: applicantContact?.firstName ?? '',
      middleName: applicantContact?.middleName ?? '',
      lastName: applicantContact?.lastName ?? '',
      address: applicantContact?.address ?? '',
      addressTwo: applicantContact?.addressTwo ?? '',
      city: applicantContact?.city ?? '',
      state: applicantContact?.state ?? '',
      zipcode: applicantContact?.zipcode ?? '',
      country: applicantContact?.country ?? 'US',
      phoneOneType: applicantContact?.phoneOneType ?? '',
      phoneOneCountryCode: applicantContact?.phoneOneCountryCode ?? '1',
      phoneOne: applicantContact?.phoneOne ? (applicantContact?.phoneOneCountryCode === '1' ? formatUSPhoneNumber(applicantContact.phoneOne) : applicantContact?.phoneOne) : '',
      phoneOneExtension: applicantContact?.phoneOneExtension ?? '',
      phoneTwoType: applicantContact?.phoneTwoType ?? '',
      phoneTwoCountryCode: applicantContact?.phoneTwoCountryCode ?? '',
      phoneTwo: applicantContact?.phoneTwo ? (applicantContact?.phoneTwoCountryCode === '1' ? formatUSPhoneNumber(applicantContact.phoneTwo) : applicantContact?.phoneTwo) : '',
      phoneTwoExtension: applicantContact?.phoneTwoExtension ?? '',
      faxPhone: applicantContact?.faxPhone ?? '',
      emailAddress: applicantContact?.emailAddress ?? '',
      company: applicantContact?.company ?? '',
      agents: ((contacts.some((contact) => contact.contactType === ContactTypes.Agent) || (userProfileData?.role === ContactTypes.Agent && !isReadOnly))) ? 'true' : 'false',
      agentAuthComments: requestAPIData?.request?.agentAuthComments ?? '',
    };

    const methods = useForm({ resolver: yupResolver(schema(agentsTableLength)), mode: 'onBlur', defaultValues: defaultValues });
    const { formState: { isValid, errors }, watch, setError, clearErrors, trigger, getValues, setValue } = methods;

    const agents = watch('agents');
    const agentAuthComments = watch('agentAuthComments');
    const latestvalues = watch();

    const columnDefs = [
      { field: 'actions', headerName: 'Actions', width: 65, cellRenderer: 'editRowRenderer', cellRendererParams: { doModalOpen: doModalOpen, modalComponent: AddAgentModal, edit: true, setRowData: setRowData, rowData: rowData, isReadOnly: isReadOnly, userProfileKey: { firstName: userProfileData.firstName, lastName: userProfileData.lastName, email: userProfileData.emailAddress } } },
      { field: 'firstName', headerName: 'First Name', flex: 1, resizable: true },
      { field: 'middleName', headerName: 'Middle Name', flex: 1, resizable: true },
      { field: 'lastName', headerName: 'Last Name', flex: 1, resizable: true },
      { field: 'address', headerName: 'Address 1', flex: 1, resizable: true },
      { field: 'city', flex: 1, resizable: true },
      { field: 'state', flex: 1, maxWidth: 70 },
      { field: 'country', flex: 1, maxWidth: 70 },
    ];

    const clearRowData = useCallback(() => {
      setRowData([]);
      doClearContactType(ContactTypes.Agent);
      setIsUserProfileAgentUsed(false);
    }, [doClearContactType]);

    useEffect(() => {
      setIsUserProfileAgentUsed(userProfileAgentUsed(rowData, userProfileData?.firstName, userProfileData?.lastName, userProfileData?.emailAddress));
    }, [rowData, userProfileData]);

    useEffect(() => {
      // Load tables from database
      let initialRowData = requestAPIData?.request?.contacts?.filter((contact) => contact.contactType === ContactTypes.Agent);
      // If country is null, default to US
      initialRowData = initialRowData?.map(contact => contact.country === null ? { ...contact, country: 'US' } : contact);
      if (initialRowData) {
        //Update agents array if user profile agent exists
        if (userProfileAgentUsed(initialRowData, userProfileData?.firstName, userProfileData?.lastName, userProfileData?.emailAddress)) {
          initialRowData = updateUserProfileAgent(initialRowData, userProfileData);
        }
        updateAgentsValue(userProfileData?.role, initialRowData, setValue);
        setRowData(initialRowData);
      }
    }, [requestAPIData, userProfileData, setValue]);

    useEffect(() => {
      setValue('agentAuth', agentAuthorizationFile?.[0]?.fileName, { shouldValidate: true });
    }, [agentAuthorizationFile, setValue]);

    useEffect(() => {
      userProfileData?.role === ProfileRoles.Agent && setValue('agents', 'true');
    }, [userProfileData?.role, agents, setValue]);

    useEffect(() => {
      // File validations
      setIsMultiple(agentAuthFile?.length > 1 ? true : false);
      setIsOverTotalFileLimit(((isNaN(totalFileSize) ? 0 : totalFileSize) + agentAuthFile?.[0]?.size > maxTotalFileSize) ? true : false);
      setIsOverFileCountLimit((selectedFiles?.length + 1 > maxFileCount) ? true : false);
    }, [agentAuthFile, setIsMultiple, setIsOverTotalFileLimit, setIsOverFileCountLimit, maxFileCount, maxTotalFileSize, selectedFiles?.length, totalFileSize]);

    useEffect(() => {
      const doSelectFile = () => {
        const agentFile = {
          file: agentAuthFile?.[0],
          section: ContactsFormNames.AgentAuthorization,
          fileName: agentAuthFile?.[0]?.name,
          componentID: componentID
        };
        doUpdateSelectedFiles(agentFile);
      };
      // Set selected file if valid
      doResetFileSection(ContactsFormNames.AgentAuthorization);
      (agentAuthFile?.length > 0 && !isMultiple && !isOverTotalFileLimit && !isOverFileCountLimit) && doSelectFile();
    }, [agentAuthFile, isMultiple, isOverTotalFileLimit, isOverFileCountLimit, doResetFileSection, componentID, doUpdateSelectedFiles]);

    useEffect(() => {
      const valid = ((agents === 'false' || agents === false) ? true : ((agents === 'true' || agents === true) && (rowData?.length > 0)));
      const validity = isValid && valid;
      doUpdateSectionValidity(JDFormContactsMetadata.sectionName, validity, stepNo, isReadOnly);
    }, [isValid, rowData, agents, doUpdateSectionValidity, stepNo, isReadOnly]);

    useEffect(() => {
      const { agents, agentAuth, agentAuthComments, propertyowners, showPhoneTwo, ...applicantFields } = latestvalues;
      const applicant = {
        ...applicantFields,
        contactID: applicantContact?.contactID ?? undefined,
        requestID: applicantContact?.requestID ?? undefined,
        version: applicantContact?.version ?? undefined,
        contactType: ContactTypes.Applicant,
        createdBy: applicantContact?.createdBy ?? undefined,
        phoneOne: applicantFields?.phoneOne?.replace(/\D/g, '') ?? undefined,
        phoneTwo: applicantFields?.phoneTwo?.replace(/\D/g, '') ?? undefined,
      };
      const contacts = [applicant, ...rowData, ...tableData?.propOwners];
      doUpdateRequestContacts(stripUncompletedContacts(contacts));
    }, [rowData, getValues, latestvalues, doUpdateRequestContacts, applicantContact, tableData?.propOwners]);

    useEffect(() => {
      doUpdateRequestObject({ agentAuthComments: agentAuthComments });
    }, [agentAuthComments, doUpdateRequestObject]);

    // Reset Files if section is hidden
    useEffect(() => {
      if (agents === 'false') {
        clearRowData();
        doResetFileSection(ContactsFormNames.AgentAuthorization, true);
      }
    }, [agents, doResetFileSection, clearRowData]);

    useEffect(() => {
      rowData && doSaveTable('agents', rowData);
    }, [rowData, doSaveTable]);

    useEffect(() => {
      if (agents === 'true' && rowData?.length < 1) {
        setError('agentsTable', { type: 'custom', message: 'At least one agent is required' });
      }
      else {
        clearErrors('agentsTable');
      }

    }, [agents, rowData, setError, clearErrors]);

    useErrorFocus({ steps, stepNo, activeStep, trigger, isReadOnly });

    return (
      <FormProvider {...methods}>
        {errors && thisSectionStepStatus === 'true' && !isReadOnly &&
          <ErrorSummary errors={errors} sectionNo={stepNo} />
        }
        <>
          <FieldHeader
            text='Contact Information'
            subtext='Provide contact information for the applicant and the agent if applicable. The applicant is the individual
            or entity submitting the delineation report and/or requesting the
            jurisdictional determination. The agent is a third-party that has
            been retained by the applicant to act on their behalf in submitting
            this request to the U.S. Army Corps of Engineers.'
          >
          </FieldHeader>

          {/* Contact Section comes first when role is not agent  */}
          {userProfileData?.role !== ProfileRoles.Agent && (
            <ContactFields type='applicant' label='Applicant' edit={edit} isReadOnly={isReadOnly} showButton />
          )}

          <FieldHeader text='Agents' />
          <div className='ml-2 mb-2' id='agentsTable'>
            <SelectInput name='agents' label='Has the applicant hired an agent to complete the application process?' required className='w-50' readOnly={isReadOnly || userProfileData?.role === ContactTypes.Agent}>
              <option key='2' value='true'>Yes</option>
              <option key='3' value='false'>No</option>
            </SelectInput>
          </div>
        </>

        {agents === 'true' && (
          <>

            <Card>
              <div className='row d-flex w-100 mt-3 pb-3 justify-content-center'>
                <div className='d-flex justify-content-center col-3'>
                  <Button
                    icon={<Icon path={mdiAccountPlus} size={'16px'} />}
                    text='Add an Agent'
                    size='small'
                    onClick={() => doModalOpen(AddAgentModal, { setRowData: setRowData, rowData: rowData, isReadOnly: isReadOnly })}
                    isDisabled={isReadOnly}
                  />
                </div>
                {userProfileData?.role === ProfileRoles.Agent &&
                  <div className='d-flex justify-content-center col-3'>
                    <Button
                      className={`table-btn profile ${(isUserProfileAgentUsed || isReadOnly) ? 'disabled' : 'hover'}`}
                      text='Use Profile Data'
                      size='small'
                      onClick={() => addUserProfileAgent(userProfileData, rowData, setRowData, isUserProfileAgentUsed, setIsUserProfileAgentUsed)}
                      isDisabled={isReadOnly || isUserProfileAgentUsed}
                    />
                  </div>}
                <div className='d-flex justify-content-center col-3'>
                  <Button
                    icon={<Icon path={mdiCloseOctagon} size={'16px'} />}
                    className={`table-btn clear ${(rowData.length === 0 || isReadOnly) ? 'disabled' : 'hover'}`}
                    text='Clear Agents'
                    size='small'
                    onClick={() => clearRowData()}
                    isDisabled={rowData.length > 0 ? isReadOnly : true}
                  />
                </div>
              </div>
              <div className='ag-theme-balham' style={{ height: 400 }}>
                <AgGridReact
                  rowData={rowData}
                  columnDefs={columnDefs}
                  pagination={true}
                  paginationAutoPageSize={true}
                  rowHeight={35}
                  gridOptions={{
                    alwaysShowVerticalScroll: true
                  }}
                  components={{
                    'editRowRenderer': EditCellRenderer,
                  }}
                  suppressClickEdit
                />
              </div>
            </Card>
            <h6 className='border-bottom w-100 pb-2 mt-3'>Agent Authorization<span className='asterisk-color'>*</span></h6>
            <Alert type='info'>
              The agent must provide an Agent Authorization form from the applicant. If a site visit is needed, you, as the agent must also provide a <LinkButton onClick={() => doDownloadFile(TemplateFiles.RightOfEntry, FileTypes.Template)} title='Right of Entry form' content='Right of Entry form' />.
              <p><LinkButton onClick={() => doDownloadFile(TemplateFiles.AgentAuthorization, FileTypes.Template)} title='Download a blank Agent Authorization form' content='Download a blank Agent Authorization form' /> to complete or <LinkButton onClick={() => doDownloadPopulatedFile(ContactsFormNames.AgentAuthorization, TemplateFiles.AgentAuthorization)} title='download an Agent Authorization form populated' content='download an Agent Authorization form populated' /> with the information you entered. Note that both documents will need to be signed by the applicant prior to submittal.</p><b>Individual files cannot exceed 100MB, and in total cannot exceed 500MB per save.</b>
            </Alert>
            <div className='row mt-1 mb-3'>
              <div className='col'>
                <DragInput name='agentAuth' text='Click to browse or drag and drop your Agent Authorization Form here.' accept={{ 'application/pdf': ['.pdf'], 'application/docx': ['.docx'] }} onChange={setAgentAuthFile} isDisabled={agentAuthorizationFile?.[0]?.key || isReadOnly} value={agentAuthorizationFile} doDownloadFile={() => doDownloadFile(agentAuthorizationFile?.[0]?.fileName, FileTypes.Request)} doDeleteFile={() => doDeleteFile(agentAuthorizationFile?.[0]?.fileName, componentID)} isReadOnly={isReadOnly} required />
                {isMultiple && <ValidationCard message='Multiple files were selected. Please select only 1 file' />}
                {isOverTotalFileLimit && <ValidationCard message='Only 500MB of files can be uploaded per save. Please hit "Save Progress" to upload your files and try again.' id='file_size_error' />}
                {isOverFileCountLimit && <ValidationCard message='Only 50 files can be uploaded per save. Please hit "Save Progress" to upload your files and try again.' id='file_count_error' />}
              </div>
            </div>

            <div className='row'>
              <div className='col'>
                <TextAreaInput name='agentAuthComments' label='Agent Authorization Comment' readOnly={isReadOnly} />
              </div>
            </div>

            {/* Contact Section comes after Agent Section when role is agent  */}
            {userProfileData?.role === ProfileRoles.Agent && (
              <div className='ml-2 mb-2'>
                <ContactFields type='applicant' label='Applicant' edit={edit} isReadOnly={isReadOnly} showButton />
              </div>
            )}
          </>
        )
        }
      </FormProvider >
    );
  }
);
JDFormContacts.metadata = JDFormContactsMetadata;

export default JDFormContacts;
