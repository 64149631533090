import '../../resources.scss';

// Reusable custom components

export const SectionHeader = ({ children }) => (
  <div className='mt-2'>
    <h5>{children}</h5>
    <hr className='header-border' />
  </div>
);

// Static Content Tooltip Content

export const dredgeTooltipContent = <>The term <i>dredged material</i> means material that is excavated or dredged from waters of the United States.</>;

export const fillTooltipContent = <>The term <i>discharge of fill material</i> means the addition of fill material into waters of the United States. The term generally includes, without limitation, the following activities: Placement of fill that is necessary for the construction of any structure or infrastructure in a water of the United States; the building of any structure, infrastructure, or impoundment requiring rock, sand, dirt, or other material for its construction;
  site-development fills for recreational, industrial, commercial, residential, or other uses; causeways or road fills; dams and dikes; artificial islands; property protection and/or reclamation devices such as riprap, groins,
  seawalls, breakwaters, and revetments; beach nourishment; levees; fill for structures such as sewage treatment facilities, intake and outfall pipes associated with power plants and subaqueous utility lines; placement of fill
  material for construction or maintenance of any liner, berm, or other infrastructure associated with solid waste landfills; placement of overburden, slurry, or tailings or similar mining-related materials; and artificial reefs.
  The term does not include plowing, cultivating, seeding and harvesting for the production of food, fiber, and forest products.</>;

export const WOTUSTooltipContent = <>"Waters of the U.S." (WOTUS) are waters such as oceans, rivers, streams, lakes, ponds, and wetlands subject to federal jurisdiction under Section 404 of the CWA.</>;

export const RGPTooltipContent = <>A regional or programmatic general permit is issued by the division or district engineer that has regulatory jurisdiction over the geographic area in which the general permit will be used.</>;

export const NWPTooltipContent = <>Nationwide permits are general permits issued nationwide to authorize categories of similar activities with minor impacts. Individual Districts have developed regional conditions and can exclude specific NWPs in their geographic region in order to provide additional protection for the aquatic environment. Nationwide permits authorize specific activities in areas under Corps' Regulatory jurisdiction. These activities are minor in scope and must result in no more than minimal adverse impacts, both individually and cumulatively. Individuals wishing to perform work under a nationwide permit must ensure their project meets all applicable terms and conditions. Processing time usually takes 30 to 45 days.</>;

export const RHATooltipContent = <>Section 10 of the Rivers and Harbors Act approved March 3, 1899, (33 U.S.C. 403),
  prohibits the unauthorized obstruction or alteration of any navigable water of the United States. The construction of any structure in or over any navigable water of the United States, the excavating
  from or depositing of material in such waters, or the accomplishment of any other work affecting the course, location, condition, or capacity of such waters is unlawful unless the work has been recommended
  by the Chief of Engineers and authorized by the Secretary of the Army. The instrument of authorization is designated a permit. The authority of the Secretary of the Army to prevent obstructions to navigation
  in navigable waters of the United States was extended to artificial islands, installations, and other devices located on the seabed, to the seaward limit of the outer continental shelf, by section 4(f) of
  the Outer Continental Shelf Lands Act of 1953 as amended (43 U.S.C. 1333(e)).</>;

export const CWATooltipContent = <>Section 404 of the Clean Water Act (33 U.S.C. 1344) authorizes the Secretary of the Army,
  acting through the Chief of Engineers, to issue permits, after notice and opportunity for public hearing, for the discharge of dredged or fill material into the waters of the United States at specified disposal
  sites. (See 33 CFR part 323.) The selection and use of disposal sites will be in accordance with guidelines developed by the Administrator of EPA in conjunction with the Secretary of the Army and published in
  40 CFR part 230. If these guidelines prohibit the selection or use of a disposal site, the Chief of Engineers shall consider the economic impact on navigation and anchorage of such a prohibition in reaching his
  decision. Furthermore, the Administrator can deny, prohibit, restrict or withdraw the use of any defined area as a disposal site whenever he determines, after notice and opportunity for public hearing and after
  consultation with the Secretary of the Army, that the discharge of such materials into such areas will have an unacceptable adverse effect on municipal water supplies, shellfish beds and fishery areas, wildlife,
  or recreational areas. (See 40 CFR part 230).</>;

export const tidalWatersTooltipContent = <>Tidal waters are those waters that rise and fall in a predictable and measurable
  rhythm or cycle due to the gravitational pulls of the moon and sun. Tidal waters end where the rise and fall of the water surface can no longer be practically measured in a predictable rhythm due to masking by hydrologic,
  wind, or other effects.</>;

export const waterLineTooltipContent = <>The mean high water (MHW) line, also known as the mean high water mark, refers to the average height of the
  highest tide recorded over a specific period of time, typically based on a 19-year tidal cycle. It represents the average shoreline level reached by high tides under normal conditions, excluding exceptional tidal events
  or storm surges.</>;

export const tideLineTooltipContent = <>High tide line means the line of intersection of the land with the water's surface at the maximum height reached by a rising tide. The high tide line may be determined, in the absence of
  actual data, by a line of oil or scum along shore objects, a more or less continuous deposit of fine shell or debris on the foreshore or berm, other physical markings or characteristics, vegetation lines, tidal gages, or
  other suitable means that delineate the general height reached by a rising tide. The line encompasses spring high tides and other high tides that occur with periodic frequency but does not include storm surges in which
  there is a departure from the normal or predicted reach of the tide due to the piling up of water against a coast by strong winds such as those accompanying a hurricane or other intense storm.</>;

export const wetlandTooltipContent = <>Wetlands means those areas that are inundated or saturated by surface or ground water at a frequency and duration sufficient to support, and that under normal circumstances do support, a prevalence
  of vegetation typically adapted for life in saturated soil conditions. Wetlands generally include swamps, marshes, bogs, and similar areas. The USACE has developed wetland delineations manuals that aid the USACE in identifying wetlands
  (see delineation section below).</>;

export const baselineTooltipContent = <>Generally, where the shore directly contacts the open sea, the line on the shore reached by the ordinary low tides comprises the baseline from which the distance of three geographic miles is measured.</>;

export const watermarkTooltipContent = <>Ordinary high water mark (OHWM) means that line on the shore established by the fluctuations of water and
  indicated by physical characteristics such as clear, natural line impressed on the bank, shelving, changes in the character of soil, destruction of terrestrial vegetation, the presence of litter and debris, or other appropriate
  means that consider the characteristics of the surrounding areas. The USACE has developed OHWM delineation manuals that aid the USACE in identifying the OHWM (see delineation section below).</>;

export const adjacentTooltipContent = <>Adjacent means having a continuous surface connection.</>;

export const standardPermitTooltipContent = <>There are two types of IPs- Letters of Permission and Standard Permits (SP).  SPs are the only type that requires a public notice to advertise the proposed work to the regulated public.</>;

export const generalPermitTooltipContent = <>A more streamlined review when the project meets certain criteria – consists of Nationwide Permits, Regional General Permits, and Programmatic General Permits.</>;

export const projectDescriptionTooltipContent = <>The written descriptions and illustrations are an important part of the application. Please describe, in detail, what you wish to do. Describe the permitted activity and its intended use with references to any attached plans or drawings that are considered to be a part of the project description. Include a description of the types and quantities of dredged or fill materials to be discharged in jurisdictional waters.</>;

export const projectPurposeTooltipContent = <>Describe the purpose and need for the proposed project. What will it be used for and why? Also include a brief description of any related activities to be developed as the result of the proposed project. Give the approximate dates you plan to both begin and complete all work.</>;

export const natureOfActivityTooltipContent = <>Describe the overall activity or project. Give appropriate dimensions of structures such as wing walls, dikes (identify the materials to be used in construction, as well as the methods by which the work is to be done), or excavations (length, width, and height). Indicate whether discharge of dredged or fill material is involved. Also, identify any structure to be constructed on a fill, piles, or float-supported platforms.</>;

export const wotusDelineationReportTooltipContent = <>A document or series of documents, stored in the USACE administrative record for the project, that describes the methodology used to conduct the delineation and typically includes<br></br>a collection of one or more maps, datasheets, photographs, and/or figures that depict and support a legally defensible delineation of waters of the United States on a property.</>;

export const aquaticResourceInventoryTooltipContent = <>An accounting of all aquatic resources, including all waters (streams, lakes, ponds, etc.) and wetlands, within the project site. Information provided will include the type, geometry, and measurements of these resources in the project area.</>;

export const jurisdictionalDeterminationTooltipContent = <>A written determination by the USACE identifying which delineated areas are or may be (depending on the type of JD requested) regulated by the USACE under Section 404 of the Clean Water Act and/or Section 10 of the Rivers and Harbors Act.</>;