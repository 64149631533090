import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { ImpactsActivity } from '../../../utils/enums';

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const PermanentLossTableCell = ({
  getValue,
  row,
  cell,
  column,
  table
}) => {
  const columnMeta = column.columnDef.meta;
  const tableMeta = table.options.meta;
  const initialValue = getValue();
  const type = columnMeta?.type ?? 'text';
  const initialOptions = columnMeta?.options;
  const [value, setValue] = useState(initialValue);
  const isActivity = useMemo(() => row.getValue('activity'), [row]);
  const debouncedUpdateRef = useRef();
  const [permanentLossOptions, setPermanentLossOptions] = useState(initialOptions);

  const updateValue = useCallback((newValue) => {
    debouncedUpdateRef?.current?.(newValue);
  }, []);

  useEffect(() => {
    let options = initialOptions;
    if (isActivity) {
      if (isActivity !== ImpactsActivity.DischargeDredgedMaterial && isActivity !== ImpactsActivity.DischargeFillMaterial) {
        options = [{ value: 'false', label: 'No' }];
        const secondOptionValue = initialOptions?.[1]?.value;
        if (secondOptionValue && secondOptionValue !== value) {
          setValue(secondOptionValue);
          updateValue(secondOptionValue);
        }
      }
    }
    setPermanentLossOptions(options);
  }, [isActivity, initialOptions, value, setValue, updateValue]);

  useEffect(() => {
    debouncedUpdateRef.current = debounce((newValue) => {
      if (tableMeta?.updateData) {
        tableMeta.updateData(row.index, column.id, newValue);
      }
    }, 500);
  }, [row.index, column.id, tableMeta?.updateData, columnMeta?.type, tableMeta]);

  const handleChange = (e) => {
    setValue(e?.target?.value);
    updateValue(e?.target?.value);
  };

  return type === 'select' && (
    <select
      id={cell.id}
      style={{ width: '100%' }}
      onChange={handleChange}
      value={value ?? ''}
      required={columnMeta?.required}
      disabled={columnMeta?.readOnly}
    >
      <option key={0} value=''>-- Select a value --</option>
      {(permanentLossOptions).map((option) => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </select>
  );
};