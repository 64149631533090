import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { decimalNumberRegex } from '../../../utils/regex';
import { getProposedAmount, calculateAcres } from '../../../utils/helpers';

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const AreaAmountTableCell = ({
  getValue,
  row,
  column,
  table,
  cell
}) => {
  const columnMeta = column.columnDef.meta;
  const tableMeta = table.options.meta;
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);
  const [userInput, setUserInput] = useState(false);

  const rowMitigationLength = useMemo(() => row.getValue('proposedLength'), [row]);
  const rowMitigationWidth = useMemo(() => row.getValue('proposedWidth'), [row]);
  const rowMitigationAmount = useMemo(() => row.getValue('proposedAmount'), [row]);
  const rowMitigationAmountUnit = useMemo(() => row.getValue('amountUnit'), [row]);
  const debouncedUpdateRef = useRef();

  useEffect(() => {
    debouncedUpdateRef.current = debounce((newValue) => {
      if (tableMeta?.updateData) {
        tableMeta?.updateData(row.index, column.id, columnMeta?.type === 'number' ? Number(newValue) : newValue ?? newValue);
      }
    }, 500);
  }, [row.index, column.id, tableMeta?.updateData, columnMeta?.type, tableMeta]);

  const updateValue = useCallback((newValue) => {
    debouncedUpdateRef.current(newValue);
  }, []);

  const handleBlur = (e) => {
    // Clear field if value is 0 or is a negative number
    if (String(e?.target?.value) === '0' || String(e?.target?.value)[0] === '-') {
      setValue(null);
      updateValue(null);
    }
    if (columnMeta?.isNumber === true) {
      if (decimalNumberRegex.test(e?.target?.value) !== true) {
        // Parse out any letters and extra periods from int/float value
        const parsedLettersVal = e?.target?.value?.replace(/[^0-9.]/g, '');
        const rx = /^(\d*\.)([\d.]*)$/;
        const parsedPeriodsVal = parsedLettersVal?.replace(rx, (a, b, c) => b + c?.replace(/\./g, ''));
        setValue(parsedPeriodsVal);
        updateValue(parsedPeriodsVal);
      }
    }
    if (column.id === 'proposedLength' || column.id === 'proposedWidth') {
      setUserInput(true);
    }
    // tableMeta?.updateData(row.index, column.id, columnMeta?.type === 'number' ? Number(e.target.value) : e.target.value ?? e.target.value);
  };

  const handleChange = (e) => {
    if (e?.target?.value === '') {
      setValue(null);
      updateValue(null);
    } else {
      setValue(e?.target?.value);
      updateValue(e?.target?.value);
    }
  };

  const disableProposedAmount = ((typeof rowMitigationLength === 'string' && rowMitigationLength.trim() !== '') || (rowMitigationLength !== null && rowMitigationLength !== undefined)) || ((typeof rowMitigationWidth === 'string' && rowMitigationWidth.trim() !== '') || (rowMitigationWidth !== null && rowMitigationWidth !== undefined));
  const disableLengthWidthCells = (rowMitigationAmount !== null && rowMitigationAmount !== undefined) && (rowMitigationLength === null || rowMitigationLength === undefined) && (rowMitigationWidth === null || rowMitigationWidth === undefined);
  const isDisabled =
    ((column.id === 'proposedLength' || column.id === 'proposedWidth') && disableLengthWidthCells) ||
    ((column.id === 'proposedAmount' && disableProposedAmount));

  const cursorStyle = columnMeta?.readOnly || isDisabled ? { cursor: 'not-allowed' } : {};

  useEffect(() => {
    if (!isNaN(rowMitigationLength) && (!isNaN(rowMitigationWidth)) && (rowMitigationAmountUnit)) {
      let calculatedAmount;
      if (rowMitigationAmountUnit === 'Acres') {
        calculatedAmount = calculateAcres(rowMitigationLength, rowMitigationWidth);
      } else if (rowMitigationAmountUnit === 'Square Feet') {
        calculatedAmount = rowMitigationLength * rowMitigationWidth;
      } else {
        calculatedAmount = null;
      }
      if (rowMitigationAmount !== calculatedAmount) {
        if (rowMitigationLength !== null && rowMitigationWidth !== null) {
          tableMeta?.updateData(row.index, 'proposedAmount', calculatedAmount);
        }
      }
    }
  }, [rowMitigationLength, rowMitigationWidth, rowMitigationAmountUnit, rowMitigationAmount, row.index, tableMeta.updateData, tableMeta]);
  
  useEffect(() => {
    if (!userInput && (rowMitigationLength === null || rowMitigationLength === undefined) && (rowMitigationWidth === null || rowMitigationWidth === undefined)) {
      updateValue(null);
    }
  }, [userInput, rowMitigationLength, rowMitigationWidth, updateValue]);

  useEffect(() => {
    if (column.id === 'proposedAmount') {
      let newAmount;
      if (rowMitigationAmountUnit === 'Acres' ) {
        newAmount = calculateAcres(rowMitigationLength, rowMitigationWidth);
      } else if (rowMitigationAmountUnit === 'Square Feet' ) {
        newAmount =  rowMitigationLength * rowMitigationWidth;
      } else {
        newAmount = rowMitigationAmount !== undefined ? rowMitigationAmount : null; 
      }
      setValue(newAmount === 0 ? rowMitigationAmount : newAmount);
      updateValue(newAmount === 0 ? rowMitigationAmount : newAmount);
    }
  }, [rowMitigationLength, rowMitigationWidth, rowMitigationAmountUnit, column.id, updateValue, rowMitigationAmount]);

  return columnMeta?.type === 'select' ? (
    <select
      id={cell.id}
      onChange={handleChange}
      onBlur={handleBlur} value={value ?? ''}
      required={columnMeta?.required}
      disabled={isDisabled}
      style={{ width: '100%', ...cursorStyle }}>
      <option key={0} value='' className='none' style={{ display: 'none' }}>-- Select a value --</option>
      {columnMeta?.options?.map((option) => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </select>
  ) : (
    <input
      id={cell.id}
      style={{ width: '100%', ...cursorStyle }}
      value={value ?? ''}
      onChange={handleChange}
      onBlur={handleBlur}
      type={columnMeta?.type || 'text'}
      required={columnMeta?.required}
      readOnly={columnMeta?.readOnly}
      disabled={isDisabled}
      maxLength={columnMeta?.maxLength}
      placeholder={getProposedAmount(isDisabled, rowMitigationAmountUnit, rowMitigationLength, rowMitigationWidth)}
    />
  );
};
