import { toast } from 'react-toastify';
import { tSuccess, tError } from '../utils/toast-helpers';

const projectBundle = {
  name: 'project',
  getReducer: () => {
    const initialData = {
      projects: [],
      projectDetails: null
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case 'PROJECT': return Object.assign({}, state);
        case 'UPDATE_PROJECT_LIST': return { ...state, projects: [...payload] };
        case 'UPDATE_PROJECT_DETAILS': return { ...state, projectDetails: payload };
        default: return state;
      };
    };
  },
  doAddProject: (projectData) => ({ dispatch, apiPost, store }) => {
    const toastId = toast.loading('Saving Project...');

    const url = '/api/Project/addProject';

    apiPost(url, projectData, (err, body) => {
      if (!err && body.status === 'Success') {
        tSuccess(toastId, body.msg);
        store.doGetAllProjects();
      } else {
        tError(toastId, err);
      }
    });
  },
  doGetAllProjects: () => ({ dispatch, apiGet, store }) => {
    const url = '/api/Project/getALLProjects';

    apiGet(url, (err, body) => {
      if (!err && body.status === 'Success') {
        dispatch({ type: 'UPDATE_PROJECT_LIST', payload: body.data });
      } else {
        toast.error(err);
      }
    });
  },
  doGetProjectByID: (projectID, requestID, version) => ({ dispatch, apiGet, store }) => {

    const url = '/api/Project/getProject?' + new URLSearchParams({
      projectID,
      requestID,
      version
    });

    apiGet(url, (err, body) => {
      if (!err && body.status === 'Success') {
        dispatch({ type: 'UPDATE_PROJECT_DETAILS', payload: body.data });
      } else {
        toast.error(err);
      }
    });
  },
  doUpdateProject: (projectData) => ({ dispatch, apiPut, store }) => {
    const toastId = toast.loading('Saving Project...');

    const url = '/api/Project/updateProject';

    apiPut(url, projectData, (err, body) => {
      if (!err && body.status === 'Success') {
        tSuccess(toastId, body.msg);
      } else {
        tError(toastId, err);
      }
    });
  },
  doResetProjectDetails: () => ({ dispatch }) => {
    dispatch({ type: 'UPDATE_PROJECT_DETAILS', payload: null });
  },

  selectProjects: (state) => state.project.projects,
  selectProjectDetails: (state) => state.project.projectDetails,
};

export default projectBundle;
