import React from 'react';
import InternalLink from '../../app-components/link/internalLink.jsx';

const NotFound = () => (
  <>
    <h4>Page Not Found</h4>
    <p>
      The page you are trying to reach has moved or does not exist.
      <br />
      <InternalLink href='/' title='Click here' content='Click here' /> to navigate back to the home page.
    </p>
  </>
);

export default NotFound;
