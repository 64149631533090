import React, { useEffect } from 'react';
import { connect } from 'redux-bundler-react';

import FieldHeader from '../components/Form/FieldHeader';

import ImageFigure from '../components/Form/ImageFigure';
import LateralLimitsTidal from '../../../styles/images/RRS Graphic V3_042623.png';
import LateralLimitsFresh from '../../../styles/images/RRS Graphic V2_041423.png';

import ExternalLink from '../../../app-components/link/externalLink.jsx';

export const JDForm1bInstructionsMetadata = {
  header: 'Request Jurisdictional Determination (Applicants/Landowners)',
  sectionName: 'Instructions',
  isSection: true,
  lastSection: false,
  firstSection: true,
};

const JDForm1bInstructions = connect(
  'doUpdateSectionValidity',
  ({
    doUpdateSectionValidity,
    stepNo,
  }) => {

    useEffect(() => {
      doUpdateSectionValidity(JDForm1bInstructionsMetadata.sectionName, true, stepNo);
    }, [doUpdateSectionValidity, stepNo]);

    return (
      <>
        <FieldHeader text='INSTRUCTIONS' />
        <p>
          This request provides a standardized way for applicants to submit a request for a United States Army Corps of Engineers (USACE) jurisdictional determination within a specified area located in the United States or its territories. This electronic application will guide you through providing the information necessary to efficiently process your request.
        </p>
        <p>
          <b style={{ color: 'red' }}>IMPORTANT:</b> By selecting this option you’re asking the USACE to issue a jurisdictional determination.
          This may include the need for the USACE to conduct a delineation of waters of the United States on the specified property.
          <b> There may be a delay if the USACE need to conduct the delineation</b>, especially if the project site is large or contains many wetlands, other special aquatic sites, and other waters.
          <b> Please contact your local <ExternalLink href='https://regulatory.ops.usace.army.mil/offices/' title='USACE Regulatory office' content='USACE Regulatory office' /> to determine current processing times. </b>
          If you cannot wait for the USACE to complete the delineation, you can retain a qualified environmental consultant to complete the delineation report on your behalf and submit it to the USACE for review.
        </p>
        <p>
          <u>
            <b>Delineation of waters of the United States:</b>
          </u>
          <br />A <b>delineation of waters of the United States</b> is the act of
          identifying and locating aquatic resources (including wetlands) within a
          specified area using scientific methods developed by the U.S. Army Corps of
          Engineers. A<b> delineation report </b>is a term often used to
          describe a document or series of documents, stored in the USACE
          administrative record for the project, that describes the methodology
          used to conduct the delineation and typically includes a collection of
          one or more maps, datasheets, photographs, and/or figures that depict
          and support a legally defensible delineation of waters of the United
          States on a property. If you’re applying for a permit to impact waters of the United States, a delineation is necessary for the USACE to issue a permit decision.
          <br />
          <br />
          <b>
            <u>Jurisdictional Determination:</u>
          </b>
          <br />
          Obtaining a Jurisdictional Determination (JD) typically involves a two-step process of
          1) identifying and delineating the geographic extent of all aquatic resources (including wetlands) on a
          property through the creation of a delineation report and 2) the USACE
          issuing a written determination identifying which delineated areas are or may be (depending on the type of JD request) regulated
          by the U.S. Army Corps of Engineers under section 404 of the Clean Water
          Act and/or Section 10 of the Rivers and Harbors Act. Jurisdictional determinations are not required for the USACE to issue a permit but can provide a level of written assurance regarding the accuracy of the delineated waters during the planning phase of a project.
        </p>
        <div className='d-flex justify-content-center'>
          <ImageFigure
            src={LateralLimitsTidal}
            alt='USACE jurisdiction in tidal waters'
            caption='This figure is a graphical depiction of the lateral limits of the USACE jurisdiction in tidal waters'
            style={{ 'width': '700px' }}
          />
        </div>
        <br />
        <br />
        <div className='d-flex justify-content-center'>
          <ImageFigure
            src={LateralLimitsFresh}
            alt='USACE jurisdiction in fresh waters'
            caption='This figure is a graphical depiction of the lateral limits of the USACE jurisdiction in fresh waters (non-tidal waters).'
            style={{ 'width': '700px' }}
          />
        </div>

        <br />
        <p>
          The USACE provides two types of JDs:
        </p>
        <p>
          <u>Approved Jurisdictional Determination (AJD)</u> - An AJD is defined
          in USACE regulations at 33 CFR 331.2. As explained in further detail in <ExternalLink href='https://usace.contentdm.oclc.org/utils/getfile/collection/p16021coll9/id/1256' title='RGL 16-01' content='RGL 16-01' />,
          an AJD is used to indicate that the USACE has identified
          the presence or absence of wetlands and/or other aquatic resources on a
          site, including their accurate location(s) and boundaries, as well as
          their jurisdictional status. AJDs are valid for 5 years.
          <br />
          <br /> <u>Preliminary Jurisdictional Determination (PJD)</u> - A PJD is
          defined in USACE regulations at 33 CFR 331.2. As explained in further
          detail in <ExternalLink href='https://usace.contentdm.oclc.org/utils/getfile/collection/p16021coll9/id/1256' title='RGL 16-01' content='RGL 16-01' />, a PJD is used to indicate that the USACE has
          identified the approximate location(s) and boundaries of wetlands and/or
          other aquatic resources on a site that are presumed to be subject to
          regulatory jurisdiction of the U.S. Army Corps of Engineers. Unlike an AJD, a PJD
          does not represent a definitive, official determination that there are,
          or that there are not, jurisdictional aquatic resources on a site, and
          does not have an expiration date.
        </p>
      </>
    );
  });

JDForm1bInstructions.metadata = JDForm1bInstructionsMetadata;

export default JDForm1bInstructions;
