import React from 'react';

/**
 * Reusable link component with many options to style and transform.
 * 
 * @param {string} content - text do be display on the link
 * @param {string} title - text to be read be screen readers and onHover. if not provided, text prop will be used
 * @param {string} href - a uri for the user to be taken to onClick. overrides and removes handleClick prop if provided.
 * @param {boolean} openInNewTab - whether the link should open in a new tab
 */

const InternalLink =
  ({
    target,
    rel,
    title,
    content,
    href,
    openInNewTab,
    ...customProps
  }) => {
    
    const currentEnv = import.meta.env.VITE_ENVIRONMENT;
    const base = () => {
      if (currentEnv === 'local' || currentEnv === 'development') {
        return '';
      } else {
        return !openInNewTab ? '' : '/rrs';
      }
    };

    const handleClick = (event) => {
      if (!openInNewTab && customProps.onClick) {
        customProps.onClick(event);
      }
    };

    const handleKeyDown = (e) => {
      e.key === 'Enter' && handleClick();
    };

    return (
      <a
        tabIndex={0}
        title={title ?? content ?? href}
        href={base() + href}
        rel={openInNewTab ? 'noopener noreferrer' : rel}
        target={openInNewTab ? '_blank' : target}
        onClick={handleClick}
        onKeyDown={(e) => handleKeyDown(e)}
        {...customProps}
      >
        {content ?? href}
      </a>
    );
  };

export default InternalLink;
