import React from 'react';
import { connect } from 'redux-bundler-react';

import InputField from '../../../../../app-components/new-inputs/inputField.jsx';
import SelectInput from '../../../../../app-components/new-inputs/selectInput.jsx';
import TextAreaInput from '../../../../../app-components/new-inputs/textAreaInput.jsx';

import { handleIntegerChange } from '../../../../../utils/regex.jsx';

import { useFormContext } from 'react-hook-form';

const PermitsDredgeTransportation = connect(
  'selectUserProfileData',
  ({
    isReadOnly,
    handleChange = () => { }
  }) => {
    const { watch } = useFormContext();

    const isSiteDesignatedForDISP = watch('isSiteDesignatedForDISP');

    return (
      <div className='container-fluid mt-2'>
        <>
          <div className='ml-2'>
            <SelectInput name='isSiteDesignatedForDISP' label='Has the proposed disposal site been designated for use by the Administrator, EPA, pursuant to Section 102(c) of the Marine Protection, Research, and Sanctuaries Act of 1972, as amended (33 U.S.C. 1413)?' className='w-50' onChange={handleChange} readOnly={isReadOnly} required>
              <option key='2' value='true'>Yes</option>
              <option key='3' value='false'>No</option>
            </SelectInput>
          </div>
          {isSiteDesignatedForDISP === 'false' && <>
            <div className='ml-2'><TextAreaInput name='disposalSiteCharacteristics' label='Describe the characteristics of the proposed disposal site and an explanation as to why no previously designated disposal site is feasible.' onChange={handleChange} readOnly={isReadOnly} required /></div>
          </>}
          <div className='ml-2'><TextAreaInput name='knownDredgeMaterialDischarges' label='Describe known dredged material discharges at the proposed disposal site' onChange={handleChange} readOnly={isReadOnly} required /></div>
          <div className='ml-2'><TextAreaInput name='previousDisposals' label='Provide the existence and documented effects of other authorized disposals that have been made in the disposal area (e.g., heavy metal background reading and organic carbon content)' onChange={handleChange} readOnly={isReadOnly} required /></div>
          <div className='ml-2'>
            <InputField className='w-50' type='number' valueAsNumber name='estNumberOfMonthsOnSite' label='Provide the estimated length of time during which the disposal would continue at the proposed site (months)' readOnly={isReadOnly} onChange={(event) => { handleChange(); handleIntegerChange(event); }} required />
          </div>
          <div className='ml-2'><TextAreaInput name='materialSource' label='Provide the source of the material' onChange={handleChange} readOnly={isReadOnly} required /></div>
          <div className='ml-2'><TextAreaInput name='materialTypeAndComp' label='Describe the type and composition of the material' onChange={handleChange} readOnly={isReadOnly} required /></div>
          <div className='ml-2'><TextAreaInput name='transportationAndDisposalMethod' label='Describe the method of transportation and disposal of the material' onChange={handleChange} readOnly={isReadOnly} required /></div>

        </>
      </div >

    );
  }
);

export default PermitsDredgeTransportation;
