const PLSSAPIBundle = {
  name: 'plssapi',

  getReducer: () => {
    const initialData = { FRSTDIVNO: null, TWNSHPNO: null, RANGENO: null };

    return (state = initialData, { type, payload }) => {

      if (type === 'CALCULATING_PLSS') {
        if (payload.features.length > 0) {
          return {
            ...state, FRSTDIVNO: payload.features[0].attributes.FRSTDIVNO, TWNSHPNO: payload.features[0].attributes.TWNSHPNO, RANGENO: payload.features[0].attributes.RANGENO
          };
        }
        else return { ...state, FRSTDIVNO: null, TWNSHPNO: null, RANGENO: null };
      };

      if (type === 'RESET_PLSS_API_RESULTS') {
        return initialData;
      };
      return state;
    };
  },
  doGetPLSSFromLatLng:
    (lat, lng) =>
      ({ dispatch }) => {
        const LATLNG = String(lng).concat(',', String(lat));

        fetch(
          'https://gis.blm.gov/arcgis/rest/services/Cadastral/BLM_Natl_PLSS_CadNSDI/MapServer/3/query?' +
          new URLSearchParams({
            where: '1=1',
            f: 'json',
            geometry: LATLNG,
            geometryType: 'esriGeometryPoint',
            spatialRel: 'esriSpatialRelIntersects',
            distance: 0,
            inSR: 4326,
            outFields: '*',
          })
        ).then(data => data.json()).then((json) => {
          dispatch({
            type: 'CALCULATING_PLSS',
            payload: json,
          });
        })
          .catch((err) => console.log(err));
      },
  doResetPLSSAPIResults: () =>
    ({ dispatch }) => {
      dispatch({
        type: 'RESET_PLSS_API_RESULTS'
      });
    },
  selectPlssResults: (state) => state.plssapi,
};

export default PLSSAPIBundle;
