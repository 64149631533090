import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css';
import * as ELG from 'esri-leaflet-geocoder';

const Geocoder = () => {
  const map = useMap();

  useEffect(() => {
    ELG.geosearch({
      position: 'topright',
      allowMultipleResults: true,
      useMapBounds: false,
      providers: [
        ELG.arcgisOnlineProvider({
          label: 'Search Results',
          countries: 'USA,ASM,PRI,UMI,VIR,GUM,FSM',
          apikey: import.meta.env.VITE_ESRI_GEOLOCATION_API_KEY
        })

      ]
    }).addTo(map);

  }, [map]);

  return null;
};

export default Geocoder;