const selectedRequestBundle = {
  name: 'selectedrequest',

  getReducer: () => {
    const initialState = {
      selectedRequest: null
    };

    return (state = initialState, { type, payload }) => {
      switch (type) {
        case 'UPDATE_SELECTED_REQUEST':
          return { selectedRequest: payload };
        case 'RESET_SELECTED_REQUEST':
          return initialState;
        default:
          return state;
      }
    };
  },

  doUpdateSelectedRequest:
    (payload) =>
      ({ dispatch }) => {
        dispatch({ type: 'UPDATE_SELECTED_REQUEST', payload });
      },
  doResetSelectedRequest:
    () =>
      ({ dispatch }) => {
        dispatch({ type: 'RESET_SELECTED_REQUEST' });
      },

  selectSelectedRequest: (state) => state.selectedrequest.selectedRequest,

};
export default selectedRequestBundle;
