import { useState, useCallback, useEffect } from 'react';
import './TanStackTable.scss';
import HeaderCellNew from './tableCellComponents/HeaderCellNew';
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Icon from '@mdi/react';
import { mdiArrowUp, mdiArrowDown, mdiArrowLeft, mdiArrowRight } from '@mdi/js';
import Tooltip from '../tooltip/tooltip';

const TanStackTableNew = ({
  data,
  columns,
  validationSchema,
  initialTableState,
  isReadOnly,
  updateSourceData,
  addRow,
  removeMultipleRows,
  rowErrorCallback,
  hideDrag,
  tableVersion,
  isFullWidth = false,
}) => {
  const [rowErrors, setRowErrors] = useState();

  useEffect(() => {
    const hasErrors = (rowErrors && Object?.keys(rowErrors)?.length > 0) ?? false;
    rowErrorCallback(() => hasErrors);
  }, [rowErrorCallback, rowErrors]);

  const debounce = useCallback((func, wait) => {
    let timeout;

    const executedFunction = function (...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };

    executedFunction.cancel = function () {
      clearTimeout(timeout);
    };

    return executedFunction;
  }, []);

  const debouncedValidation = useCallback(() => debounce((rows) => {
    rows.forEach(({ rowIndex, updatedRowData }) => {
      validationSchema
        .validate(updatedRowData, { abortEarly: false })
        .then(() => {
          setRowErrors((currentErrors) => {
            const newErrors = { ...currentErrors };
            delete newErrors[rowIndex];
            return newErrors;
          });
        })
        .catch((err) => {
          if (err.inner && Array.isArray(err.inner)) {
            const newRowErrors = err.inner.reduce((acc, currError) => {
              acc[currError.path] = currError.message;
              return acc;
            }, {});

            setRowErrors((currentErrors) => ({
              ...currentErrors,
              [rowIndex]: newRowErrors,
            }));
          } else {
            console.error('Validation error:', err.message);
          }
        });
    });
  }, 500), [validationSchema, debounce]);

  useEffect(() => {
    if (data) {
      const rowsToValidate = data.map((feature, index) => ({
        rowIndex: index,
        updatedRowData: feature
      }));
      const validateRows = debouncedValidation();
      validateRows(rowsToValidate);
    }
  }, [data, debouncedValidation]);

  const [sorting, setSorting] = useState([]);
  const [editedRows, setEditedRows] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: true,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    autoResetPageIndex: false,
    state: {
      ...initialTableState,
      sorting,
    },
    meta: {
      editedRows,
      setEditedRows,
      updateData: (rowIndex, columnId, value) => {
        updateSourceData(rowIndex, columnId, value);
        const updatedRowData = { ...data[rowIndex], [columnId]: value };
        debouncedValidation([{ rowIndex, updatedRowData }]);
      },
      addRow: () => {
        addRow();
      },
      removeSelectedRows: (selectedRows) => {
        // eslint-disable-next-line no-restricted-globals
        const confirmed = confirm(`Are you sure you want to delete ${selectedRows?.length} rows? This action is permanent and cannot be undone!`);
        confirmed && removeMultipleRows(selectedRows);
        confirmed && table.resetRowSelection();
      },
    },
  }
  );
  return (
    <div className='w3-container' style={{ overflowX: 'auto' }}>
      <table className='w3-table-all' width={isFullWidth ? '100%' : table.getCenterTotalSize()}>
        <thead>
          {!isReadOnly && <tr>
            <th style={{ backgroundColor: 'transparent' }} colSpan={table.getCenterLeafColumns()?.length} align='right'>
              <HeaderCellNew table={table} isReadOnly={isReadOnly} hideDrag={hideDrag} />
            </th>
          </tr>}
          {table.getHeaderGroups().map((headerGroup) => (
            <>
              {(tableVersion === 'ImpactsTable' || tableVersion === 'PRMTable') && (
                <tr>
                  <th colSpan={headerGroup.headers.length} style={{ textAlign: 'right', paddingRight: '8em', maxWidth: '100%' }}>
                    For proposed amounts, enter length and width, OR area amount.
                  </th>
                </tr>
              )}
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    onClick={isReadOnly ? header.column.getToggleSortingHandler() : null}
                    colSpan={header.colSpan}
                    style={{
                      position: 'relative',
                      width: header.getSize() + 'px',
                      cursor: 'col-resize',
                      userSelect: header.column.getIsResizing() ? 'none' : 'auto',
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <div style={{ cursor: header.column.getIsResizing() ? 'col-resize' : isReadOnly ? 'pointer' : 'auto', position: 'relative' }}>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {header.column.columnDef.meta?.required && (
                            <span className='red-asterisk'>*</span>
                          )}
                          {header.column.columnDef.meta?.tooltip &&
                            <Tooltip
                              header={header.column.columnDef.header}
                              content={header.column.columnDef.meta?.tooltip}
                            />
                          }
                          {{
                            asc: <Icon path={mdiArrowUp} size={'16px'} />,
                            desc: <Icon path={mdiArrowDown} size={'16px'} />,
                          }[header.column.getIsSorted() ?? null]}
                        </div>
                        {/* Add resizer element */}
                        <div
                          className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                          onMouseDown={header.getResizeHandler()}
                          onTouchStart={header.getResizeHandler()}
                          style={{ padding: '0 5px', cursor: 'col-resize' }}
                        />
                      </>
                    )}
                  </th>
                ))}
              </tr>
            </>
          ))}
        </thead>
        <tbody>
          {table.getRowModel()?.rows?.length === 0 && (
            <tr>
              <td colSpan='100%'>
                <div>NO ROWS TO DISPLAY</div>
              </td>
            </tr>
          )}
          {table.getRowModel()?.rows?.map(row => (
            <tr key={row.id} className={`${row.getIsSelected() ? 'selected-row' : ''} ${rowErrors && rowErrors?.[row.id] && Object.keys(rowErrors[row.id])?.length !== 0 ? 'row-error' : ''}`}>
              {row.getVisibleCells().map(cell => {
                const cellError = rowErrors?.[row.id]?.[cell.column.id];
                const isCellError = cellError !== undefined;
                const cellClasses = isCellError ? 'cell-error' : '';
                return (<td className={cellClasses} key={cell.id} style={{ width: cell.column.getSize() + 'px' }}>
                  <div className='d-flex align-items-center'>
                    {cellError && (
                      <Tooltip
                        iconSize='large'
                        place='bottom'
                        header={cell.column.columnDef.header}
                        name={cell.column.id}
                        content={cellError}
                        iconStyle={{ color: 'red', marginRight: '5px' }}
                        isError
                        noDelay
                        border
                      />
                    )}
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                </td>
                );
              })
              }
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th className={'bottom-btns'} colSpan={table.getCenterLeafColumns()?.length} align='right'>
              <button
                disabled={!table.getCanPreviousPage()}
                onClick={() => {
                  table.previousPage();
                  setCurrentPage((prevPage) => Math.max(0, prevPage - 1));
                }}
              >
                <Icon path={mdiArrowLeft} size={'16px'} /> Previous
              </button>
              <button
                disabled={!table.getCanNextPage()}
                onClick={() => {
                  table.nextPage();
                  setCurrentPage((prevPage) => prevPage + 1);
                }}
              >
                Next <Icon path={mdiArrowRight} size={'16px'} />
              </button>
              <span>
                rows per page:{' '}
                <select
                  value={rowsPerPage}
                  onChange={(e) => {
                    setRowsPerPage(parseInt(e.target.value));
                    table.setPageSize(parseInt(e.target.value));
                    setCurrentPage(0);
                  }}
                >
                  {[10, 20, 30, 50].map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </span>
              {` ${currentPage * rowsPerPage + 1}-${Math.min(
                (currentPage + 1) * rowsPerPage,
                data?.length
              )} of ${data?.length}`}
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default TanStackTableNew;