import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';

import FieldHeader from '../../components/Form/FieldHeader';
import Button from '../../../../app-components/button';
import InputField from '../../../../app-components/new-inputs/inputField';

import { Row } from '../../../_shared/helper';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { Alert } from '@trussworks/react-uswds';
import { filterNullEmptyObjects } from '../../../../utils/helpers';
import ErrorSummary from '../../../../app-components/error-summary/ErrorSummary.jsx';

export const PermitsFormCertifySignSubmitMetadata = {
  sectionName: 'Certify, Sign, and Submit',
  isSection: true,
  lastSection: true,
  firstSection: false,
};

const PermitsFormCertifySignSubmit = connect(
  'doUpdateSectionValidity',
  'doUpdateRequestObject',
  'doUploadAndUpdatePermits',
  'selectRequestAPIData',
  'selectRequestFormData',
  'selectFormValidation',
  'selectUserProfileData',
  ({
    doUpdateSectionValidity,
    doUpdateRequestObject,
    doUploadAndUpdatePermits,
    requestAPIData,
    requestFormData,
    formValidation,
    userProfileData,
    stepNo,
    isReadOnly,
  }) => {

    const defaultValues = {
      signature: requestAPIData?.request?.signature ?? null,
      signatureDate: requestAPIData?.request?.signatureDate !== null ? requestAPIData?.request?.signatureDate?.split('T')[0] : '' ?? '',
    };

    const [isSigned, setIsSigned] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const getName = useCallback(() => userProfileData.middleName
      ? `${userProfileData.firstName} ${userProfileData.middleName} ${userProfileData.lastName}`
      : `${userProfileData?.firstName} ${userProfileData.lastName}`, [userProfileData]);

    const schema = yup.object().shape({
      signature: yup.string().test('isSigned', `Signature must be ${getName()}`, val => val.trim() === getName()),
      signatureDate: yup.string().required(),
    });

    const methods = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema), mode: 'onBlur' });
    const { formState: { errors }, setValue, watch, handleSubmit, trigger } = methods;
    const errorCount = Object.values(errors)?.length;
    const signature = watch('signature');
    const signatureDate = watch('signatureDate');
    const dateNow = new Date().toISOString().slice(0, 10);

    useEffect(() => {
      if (signature?.trim() === getName()) {
        !signatureDate && setValue('signatureDate', new Date().toISOString().slice(0, 10));
        setIsSigned(true);
        trigger();
      } else {
        setIsSigned(false);
      }
    }, [signature, setValue, signatureDate, trigger, getName]);

    const submitForm = (e) => {
      const requestFormDataSubmit = { ...requestFormData, request: { ...requestFormData.request, isSubmit: true } };
      const filteredRequest = filterNullEmptyObjects(requestFormDataSubmit);
      doUploadAndUpdatePermits(filteredRequest, 'Submit');
    };

    useEffect(() => {
      doUpdateSectionValidity(PermitsFormCertifySignSubmitMetadata.sectionName, (isFormValid && isSigned), stepNo, isReadOnly);
      isFormValid && isSigned && signature && signatureDate && doUpdateRequestObject({ signature: signature, signatureDate: signatureDate });
    }, [isFormValid, isSigned, signature, signatureDate, doUpdateRequestObject, doUpdateSectionValidity, stepNo, isReadOnly]);

    useEffect(() => {
      setIsFormValid(Object.values(formValidation).find(section => section.validity === false && section.section !== 'Certify, Sign, and Submit') ? false : true);
    }, [formValidation]);


    return (
      <FormProvider {...methods}>
        {(!isFormValid || errorCount > 0) && !isReadOnly &&
          <ErrorSummary errors={errors} type='form' />
        }
        <FieldHeader text='Certify, Sign, and Submit'>
          <b>The Application must be signed by the person who desires to undertake the proposed activity (applicant) or it may be signed by a duly authorized agent if a completed agent authorization is provided. A Department of the Army Permit (DA) permit does not convey any property rights, either in real estate or material, or any exclusive privileges. Furthermore, a DA permit does not authorize any injury to property or invasion of rights or any infringement of Federal, state or local laws or regulations. The applicant's signature on an application is an affirmation that the applicant possesses or will possess the requisite property interest to undertake the activity proposed in the application. The district engineer will not enter into disputes but will remind the applicant of the above. The dispute over property ownership will not be a factor in the Corps public interest decision (33 FR 320.4(g)(6))</b>
        </FieldHeader>
        <Row>
          <p className='h6 w-100 pb-2 b'>BY SUBMITTING THIS REQUEST, I AM AGREEING TO ALL OF THE FOLLOWING:</p>
        </Row>
        <ul>
          <li><b>I consent to conduct business electronically and understand this consent can be withdrawn at any time in writing to the local USACE Regulatory office.</b></li>
          <li><b>Application is hereby made for permit or permits to authorize the work described in this application and all supporting documents submitted with the application. </b></li>
          <li><b>I certify that the information in this application is complete and accurate. </b></li>
          <li><b>I understand that the signature of the applicant or the agent will be an affirmation that the applicant possesses or will possess the requisite property interest to undertake the activity proposed in the application.</b></li>
          <li><b>I hereby certify that I have included all activities that require a USACE permit that I plan to undertake and that are reasonably related to the project proposed in this permit application.</b></li>
          <li><b>I understand that, whoever, in any matter within the jurisdiction of any department of agency of the United States, knowingly and willfully (1) falsifies, conceals, or covers up by any trick, scheme, or device a material fact; (2) makes any materially false, fictitious, or fraudulent statement or representation; or (3) makes or uses any false writing or document knowing the same to contain any materially false, fictitious, or fraudulent statement or entry; shall be fined not more than $10,000 or imprisoned not more than 5 years or both. (18 USC Section 1001)</b></li>
          <li><b>I understand that by typing my name in the text field below that I am e-signing this application and I understand that this e-signature has the same legal status as handwritten signatures.</b></li>
        </ul>
        {isFormValid || isReadOnly ?
          <>
            <p className='h6 border-bottom w-100 pb-2 b'>Signature</p>
            <div className='row'>
              <div className='col-6'>
                <InputField name='signature' label='Signature' signature={getName()} required readOnly={isReadOnly} />
              </div>
              <div className='col-6'>
                <InputField type='date' min={null} max={dateNow} name='signatureDate' label='Signature Date' readOnly={isReadOnly} />
              </div>
            </div>
            <p className='h6 border-bottom w-100 pb-2 b'></p>
            <div className='row'>
              <div className='col-4 mb-3'>
                <Button text='Submit Request' onClick={handleSubmit(submitForm)} isDisabled={!isSigned || isReadOnly} />
              </div>
            </div></> :
          <Alert slim className='mb-3' type='error' >In order to submit this form, please revisit the section(s) marked with errors, and correct any missing or invalid fields.</Alert>
        }
      </FormProvider>
    );
  }
);

PermitsFormCertifySignSubmit.metadata = PermitsFormCertifySignSubmitMetadata;

export default PermitsFormCertifySignSubmit;
