import { mdiHelpCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import ReactTooltip from 'react-tooltip';

export const divisions = [
  { value: 1, label: 'Mississippi Valley Division' },
  { value: 3, label: 'North Atlantic Division' },
  { value: 4, label: 'Northwestern Division ' },
  { value: 5, label: 'Great Lakes and Ohio River Division ' },
  { value: 7, label: 'South Atlantic Division' },
  { value: 8, label: 'South Pacific Division' },
  { value: 9, label: 'Southwestern Division' },
  { value: 6, label: 'Pacific Ocean Division' },
];

// Cascading select options for areas of interest
export const districts = {
  1: [
    { value: 14, label: 'Vicksburg District' },
    { value: 13, label: 'St. Louis District' },
    { value: 12, label: 'New Orleans District' },
    { value: 11, label: 'Memphis District' },
    { value: 16, label: 'St. Paul District' },
    { value: 15, label: 'Rock Island District' },
  ],
  3: [
    { value: 34, label: 'Norfolk District' },
    { value: 35, label: 'Philadelphia District' },
    { value: 31, label: 'Baltimore District' },
    { value: 33, label: 'New York District' },
    { value: 36, label: 'New England District' },
  ],
  4: [
    { value: 47, label: 'Seattle District' },
    { value: 44, label: 'Walla Walla District' },
    { value: 46, label: 'Omaha District' },
    { value: 45, label: 'Kansas City District' },
    { value: 42, label: 'Portland District' },
  ],
  5: [
    { value: 55, label: 'Buffalo District' },
    { value: 54, label: 'Pittsburgh District' },
    { value: 57, label: 'Detroit District' },
    { value: 56, label: 'Chicago District' },
    { value: 51, label: 'Huntington District' },
    { value: 53, label: 'Nashville District' },
    { value: 52, label: 'Louisville District' },
  ],
  6: [
    { value: 63, label: 'Honolulu District' },
    { value: 64, label: 'Alaska District' },
  ],
  7: [
    { value: 76, label: 'Savannah District' },
    { value: 75, label: 'Mobile District' },
    { value: 71, label: 'Jacksonville District' },
    { value: 77, label: 'Wilmington District' },
    { value: 72, label: 'Charleston District' },
  ],
  8: [
    { value: 84, label: 'Albuquerque District' },
    { value: 81, label: 'Los Angeles District' },
    { value: 82, label: 'Sacramento District' },
    { value: 83, label: 'San Francisco District' },
  ],
  9: [
    { value: 95, label: 'Tulsa District' },
    { value: 92, label: 'Fort Worth District' },
    { value: 93, label: 'Galveston District' },
    { value: 94, label: 'Little Rock District' },
  ],
};

export const districtsArr = [
  { value: 14, label: 'Vicksburg District' },
  { value: 13, label: 'St. Louis District' },
  { value: 12, label: 'New Orleans District' },
  { value: 11, label: 'Memphis District' },
  { value: 16, label: 'St. Paul District' },
  { value: 15, label: 'Rock Island District' },
  { value: 34, label: 'Norfolk District' },
  { value: 35, label: 'Philadelphia District' },
  { value: 31, label: 'Baltimore District' },
  { value: 33, label: 'New York District' },
  { value: 36, label: 'New England District' },
  { value: 47, label: 'Seattle District' },
  { value: 44, label: 'Walla Walla District' },
  { value: 46, label: 'Omaha District' },
  { value: 45, label: 'Kansas City District' },
  { value: 42, label: 'Portland District' },
  { value: 55, label: 'Buffalo District' },
  { value: 54, label: 'Pittsburgh District' },
  { value: 57, label: 'Detroit District' },
  { value: 56, label: 'Chicago District' },
  { value: 51, label: 'Huntington District' },
  { value: 53, label: 'Nashville District' },
  { value: 52, label: 'Louisville District' },
  { value: 63, label: 'Honolulu District' },
  { value: 64, label: 'Alaska District' },
  { value: 76, label: 'Savannah District' },
  { value: 75, label: 'Mobile District' },
  { value: 71, label: 'Jacksonville District' },
  { value: 77, label: 'Wilmington District' },
  { value: 72, label: 'Charleston District' },
  { value: 84, label: 'Albuquerque District' },
  { value: 81, label: 'Los Angeles District' },
  { value: 82, label: 'Sacramento District' },
  { value: 83, label: 'San Francisco District' },
  { value: 95, label: 'Tulsa District' },
  { value: 92, label: 'Fort Worth District' },
  { value: 93, label: 'Galveston District' },
  { value: 94, label: 'Little Rock District' },
];

export const materialTypeCustomLabel = (
  <>Type of Materials Being Discharged:
    <Icon
      path={mdiHelpCircleOutline}
      data-tip
      data-for='dischargeMaterial'
      style={{ marginBottom: '8px' }}
      size={'12px'}
    />
    <ReactTooltip
      id='dischargeMaterial'
      effect='solid'
      place='right'
      className='col-4'
    >
      Describe the material to be discharged within Corps jurisdiction. Please be sure this description will agree with your illustrations. Discharge material includes: rock, sand, clay, concrete, etc.
    </ReactTooltip>
  </>
);

export const impactResourceTypes = [
  { value: 'Harbor/Ocean', text: 'Harbor/Ocean' },
  { value: 'Lake', text: 'Lake' },
  { value: 'Non-Tidal Wetland', text: 'Non-Tidal Wetland' },
  { value: 'Pond', text: 'Pond' },
  { value: 'River/Stream', text: 'River/Stream' },
  { value: 'Tidal Wetland', text: 'Tidal Wetland' },
  { value: 'Other', text: 'Other' },
];

export const permitteeResponsibleTypes = [
  { value: 'Establishment', text: 'Establishment' },
  { value: 'Re-Establishment', text: 'Re-Establishment' },
  { value: 'Enhancement', text: 'Enhancement' },
  { value: 'Rehabilitation', text: 'Rehabilitation' },
  { value: 'Preservation', text: 'Preservation' }
];

export const mitigationTypes = [
  { value: 'Mitigation Bank', text: 'Mitigation Bank' },
  { value: 'In-Lieu Fee', text: 'In-Lieu Fee' },
  { value: 'Permittee Responsible (on-site)', text: 'Permittee Responsible (on-site)' },
  { value: 'Permittee Responsible (off-site)', text: 'Permittee Responsible (off-site)' },
  { value: 'Advanced Permittee Responsible', text: 'Advanced Permittee Responsible' }
];

export const feedbackTypes = [
  { value: 'Beta Testing' },
  { value: 'Error' },
  { value: 'Additional Feature or Enhancement' },
  { value: 'Design Issue' },
];

export const organizationOptions = [
  { value: 'HQ' },
  { value: 'LRB' },
  { value: 'LRC' },
  { value: 'LRD' },
  { value: 'LRE' },
  { value: 'LRH' },
  { value: 'LRL' },
  { value: 'LRN' },
  { value: 'LRP' },
  { value: 'MVD' },
  { value: 'MVK' },
  { value: 'MVM' },
  { value: 'MVN' },
  { value: 'MVP' },
  { value: 'MVR' },
  { value: 'MVS' },
  { value: 'NAB' },
  { value: 'NAD' },
  { value: 'NAE' },
  { value: 'NAN' },
  { value: 'NAO' },
  { value: 'NAP' },
  { value: 'NWD' },
  { value: 'NWK' },
  { value: 'NWO' },
  { value: 'NWP' },
  { value: 'NWS' },
  { value: 'NWW' },
  { value: 'POA' },
  { value: 'POD' },
  { value: 'POH' },
  { value: 'SAC' },
  { value: 'SAD' },
  { value: 'SAJ' },
  { value: 'SAM' },
  { value: 'SAS' },
  { value: 'SAW' },
  { value: 'SPA' },
  { value: 'SPD' },
  { value: 'SPK' },
  { value: 'SPL' },
  { value: 'SPN' },
  { value: 'SWD' },
  { value: 'SWF' },
  { value: 'SWG' },
  { value: 'SWL' },
  { value: 'SWT' },
  { value: 'Other' },
];
