import React from 'react';
import { ModalContent, ModalHeader, ModalFooter, } from '../../../../app-components/modal';
import { connect } from 'redux-bundler-react';
import { RequestTypeStrings } from '../../../../utils/enums.tsx';

const submissionModal = connect('selectSelectedRequest', 'selectRequestFormData', 'selectDistrictResults', ({
  selectedRequest,
  requestFormData,
  districtResults,
  title = 'Request Submitted',
  onSave,
  saveText = 'OK',
  cancelText
}) => {
  const todayPlusTen = new Date();
  todayPlusTen.setDate(todayPlusTen.getDate() + 10);

  // const dateOptions = {
  //   year: 'numeric',
  //   month: 'short',
  //   day: '2-digit'
  // };

  // const formattedDate = todayPlusTen.toLocaleDateString('en-US', dateOptions).replace(/,/g, '');

  const district = requestFormData?.location?.district ?? '';

  //@TODO Query for district info

  return (
    <ModalContent>
      <ModalHeader title={title} hasCloseButton />
      <section className='modal-body'>
        <div className='container-fluid'>
          {/* <p>
            Your request for a <b>{RequestTypeStrings[selectedRequest]}</b> has been submitted to the <b>{district}</b>.
            An email will be sent to you shortly, which will include a file number and the assigned project manager.</p>

          <p>If you do not receive this email by <b>{formattedDate}</b> [10 business days after submission],
            please contact your local Regulatory office at <b>{districtResults?.district_properties?.DISTRICT_N}</b>.
          </p> */}
          <p>
            Your request for a <b>{RequestTypeStrings[selectedRequest]}</b> has been submitted to the <b>{district}</b>.
          </p>
          <p>
            Please contact your local Regulatory office at <b>{districtResults?.district_properties?.DISTRICT_N}</b> with any questions/concerns.
          </p>
        </div>
      </section>
      <ModalFooter
        saveText={saveText}
        showCancelButton={cancelText}
        onSave={onSave}
        cancelText={cancelText}
      />
    </ModalContent>);
});

export default submissionModal;
