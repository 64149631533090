import React from 'react';
import { connect } from 'redux-bundler-react';

import Accordion from '../../../app-components/accordion';
import AccordionListItem from '../../../app-components/accordion/accordionListItem';
import NewSectionCard from './_shared/NewSectionCard';
import NewApplicationModal from '../../Home/components/modals/NewApplication';
import InternalLink from '../../../app-components/link/internalLink.jsx';
import ExternalLink from '../../../app-components/link/externalLink.jsx';
import LinkButton from '../../../app-components/link/linkButton.jsx';

import { FileTypes, ProfileRoles, ResourceFiles } from '../../../../src/utils/enums.tsx';
import { SectionHeader } from './_shared/helper.jsx';

import '../resources.scss';

const Permitting = connect(
  'doAuthLogin',
  'doDownloadFile',
  'doModalOpen',
  'doUpdateRelativeUrl',
  'selectAuthIsLoggedIn',
  'selectUserProfileData',
  ({
    doAuthLogin,
    doDownloadFile,
    doModalOpen,
    doUpdateRelativeUrl,
    authIsLoggedIn,
    userProfileData
  }) => {
    const handleLink = () => {
      sessionStorage.setItem('PermitButtonSession', true);
      if (authIsLoggedIn) {
        const PermitButtonSession = sessionStorage.getItem('PermitButtonSession');
        doUpdateRelativeUrl('/home');
        PermitButtonSession && doModalOpen(NewApplicationModal);
        sessionStorage.removeItem('PermitButtonSession');
      } else {
        doAuthLogin();
      }
    };

    return (
      <>
        <section id='why-id'>
          <SectionHeader>Do I need a permit?</SectionHeader>
          <p>The U.S. Army Corps of Engineers (USACE) Regulatory Program administers and enforces Section 10 of the Rivers and Harbors Act of 1899; Section 404 of the Clean Water Act of 1972, as amended; and Section 103 of the Marine Protection, Research, and Sanctuaries Act of 1972.</p>
          <Accordion.List>
            <AccordionListItem headingText={<>Expand to Learn More About <b>Section 10 of the Rivers and Harbors Act</b></>}>
              Under Section 10 of the Rivers and Harbors Act, a USACE permit is required for <b>work or structures in, over, or under</b> navigable waters of the United States.  Navigable waters of the United States typically include larger rivers, larger freshwater lakes, tidal waters, and the ocean.
            </AccordionListItem>
            <AccordionListItem headingText={<>Expand to Learn More About <b>Section 404 of the Clean Water Act</b></>}>
              Under Section 404 of the Clean Water Act, a USACE permit is required for the <b>discharge of dredged or fill material</b> into waters of the United States. Waters of the United States including the larger navigable waters of the United States and also include smaller streams and adjacent wetlands.
            </AccordionListItem>
            <AccordionListItem headingText={<>Expand to Learn More About <b>Section 103 of the Marine Protection, Research, and Sanctuaries Act</b></>}>
              Under Section 103 of the Marine Protection, Research, and Sanctuaries Act, USACE regulates the <b>transport and disposal of dredged material</b> at designated ocean sites.
            </AccordionListItem>
          </Accordion.List>
          <p className='mt-3'>If your project area contains waters of the United States and your project would impact these waters, you may need a permit. The size/extent of proposed project impacts to waters of the United States and the type of work you’re conducting determines whether you need permit and the type of permit (general or standard) that is required.</p>
          <p>Read more about how the USACE determines the location of waters of the United States by visiting the <InternalLink href='/home/jurisdiction' title='Jurisdiction page' content='Jurisdiction page' />.</p>
          <p>All USACE permit decisions are subject to various other federal laws applicable to federal actions, such as the Endangered Species Act, the National Historic Preservation Act, tribal treaties, and the Magnuson-Stevens Fisheries Conservation and Management. Additional permits, licenses, variances, or similar authorization may be required by other federal, state, and local laws for the proposed activity.</p>
        </section >

        <section id='permit-types-id'>
          <SectionHeader>What types of permits are available?</SectionHeader>
          <p>The USACE has two types of permits, <b>general</b> and <b>standard</b> permits:</p>
          <Accordion.List>
            <Accordion.Item headingText={<>Expand to Learn More About <b>General Permits</b></>}>
              <p>There are three types of general permits: Nationwide Permits (NWPs), Regional General Permits (RGPs), and Programmatic General Permits (PGPs). General permits are typically not tailored for individual applicants/projects but instead authorize activities that the USACE has determined to be substantially similar in nature (such as utility lines, residential developments, bank stabilization, etc.) and which cause
                minimal individual and cumulative environmental impacts. General permits can authorize activities within a limited geographic area (such as a county or state), a specific region (such as a group of contiguous states), or the entire nation. In some cases, activities that qualify for general permit authorization can proceed without written confirmation from the USACE, if they adhere to the terms and conditions
                outlined in the general permit. However, for many general permits, the USACE must review the proposed work and provide written verification of compliance with the general permit before construction on the project can commence. <b>Written notification to the USACE is almost always required when the project has the potential to impact federally listed threatened or endangered species or properties that are included in,
                  or eligible for inclusion in, the National Register of Historic Places.</b></p>

              <h5>Nationwide Permits</h5>
              <p>Nationwide permits are general permits issued nationwide to authorize categories of similar activities with minor impacts. Regional conditions may be added by individual Districts to provide additional protection for aquatic environments. Nationwide permits authorize specific activities within <InternalLink href='/home/jurisdiction' title='waters of the United States' content='waters of the United States' />. These activities are normally minor
                in scope and result in no more than minimal adverse impacts, both individually and cumulatively to waters of the United States. Individuals seeking to undertake work under a nationwide permit must ensure that their project adheres to all relevant terms and conditions. Typically, the processing time for nationwide permits ranges from 30 to 45 days.</p>
              <p><u>Links:</u></p>
              <ul>
                <li><ExternalLink href='https://www.usace.army.mil/Missions/Civil-Works/Regulatory-Program-and-Permits/Nationwide-Permits/' title='2021 Nationwide Permits Information' content='2021 Nationwide Permits Information' /></li>
                <li><ExternalLink href='https://www.usace.army.mil/Missions/Civil-Works/Regulatory-Program-and-Permits/Nationwide-Permits/#twentyseventeenOriginal' title='Expired Nationwide Permits' content='Expired Nationwide Permits' /></li>
              </ul>

              <h5>Regional General Permits</h5>
              <p>A regional general permit is issued by the USACE District that has regulatory jurisdiction over the geographic area in which the general permit will be used. Regional general permits authorize specific activities within <InternalLink href='/home/jurisdiction' title='waters of the United States' content='waters of the United States' /> that are typically common in that geographic area. These activities are normally minor in scope and result in no
                more than minimal adverse impacts, both individually and cumulatively to waters of the United States.  The processing times for regional general permit are dependent on the terms and conditions of the permit.</p>
              <p><u>Links:</u></p>
              <ul>
                <li><ExternalLink href='https://www.usace.army.mil/Missions/Civil-Works/Regulatory-Program-and-Permits/Obtain-a-Permit/#RegProgPermits' title='Current Regional General Permits' content='Current Regional General Permits' /></li>
              </ul>

              <h5>Programmatic General Permits</h5>
              <p>Programmatic general permits are developed using established state, local, or federal programs to prevent redundant processes. State Programmatic General Permits are permits issued by the USACE to minimize duplication between USACE districts and state regulatory programs that offer comparable protection for aquatic resources. In certain states, the SPGP replaces some or all of the USACE nationwide permits, aiming to enhance
                overall efficiency in the permitting process.</p>
            </Accordion.Item>
            <Accordion.Item headingText={<>Expand to Learn More About <b>Individual Permits</b></>}>
              <p>Individual permits (IPs) are authorized for individual applicants/projects and typically authorize activities that may cause more than minimal individual and cumulative environmental impacts. Activities that require an individual permit authorization cannot proceed without written authorization from the USACE. There are two types of individual permits: Standard Permits (SPs) and Letters of Permissions (LOPs).</p>
              <h5>Standard Permits</h5>
              <p>A Standard Permit is an authorization granted by the Department of the Army following a comprehensive review of the public interest. This process involves a public notice period of 15 to 30 days, during which the permit drawings and detailed project description are provided to interested parties, neighboring property owners, and State and Federal agencies for review and feedback. The typical processing time for such permits
                ranges from 60 to 120 days after receiving a complete application for non-controversial projects. However, controversial or complex projects, including those requiring a public hearing or an Environmental Impact Statement (EIS), may take longer to process.</p>
              <p><u>Links:</u></p>
              <ul>
                <li><ExternalLink href='https://permits.ops.usace.army.mil/orm-public#' title='View the complete list of Final Individual Permits' content='View the complete list of Final Individual Permits' /></li>
                <i>Please select district from "Filter By District" option to narrow the results.</i>
              </ul>

              <h5>Letters of Permission</h5>
              <p>The USACE District has the option to issue Letters of Permission (LOP), as specified in 33 CFR Part 325, particularly 33 CFR 325.2(e)(1). This expedited process for individual permits typically results in a decision within 60 days. An LOP can be utilized when the USACE District determines that the proposed work is minor, would not have significant individual or cumulative impacts on the environment, and is unlikely to face
                substantial opposition.</p>
              <p>All applications qualifying for LOP are categorically excluded under the USACE implementing regulations for the National Environmental Policy Act, outlined in 33 CFR Part 325 Appendix B. Therefore, letters of permission do not require an environmental assessment or environmental impact statement as a legal requirement. However, it's important to note that the USACE must still comply with other laws when issuing an LOP. In these cases, the proposal is coordinated with Federal and state fish and wildlife agencies, as required by the Fish and Wildlife Coordination Act, and includes a public interest evaluation, but without the publishing of an individual public notice.</p>
              <p>Letters of permission are issued in the form of a letter, providing details such as the permittee's information, project location, authorized work, time limits, and any specific limitations or conditions applicable to the project. Additionally, relevant general conditions are attached to and incorporated into the letter of permission.</p>
            </Accordion.Item>
          </Accordion.List>
          <NewSectionCard
            className='mt-2'
            title='Unsure of what type of permit you need?'
            content={`If you're unsure of what type of permit you need, you can request a pre-application meeting with the USACE${authIsLoggedIn ? '.' : ' by signing up/logging in.'}`}
            onClick={() => handleLink()}
            buttonText={authIsLoggedIn ? 'Request a Pre-application Meeting' : 'Login Here'}
            buttonTitle={authIsLoggedIn ? 'Request a Pre-application Meeting' : 'Login Here'}
            isDisabled={userProfileData?.role?.toLowerCase() === ProfileRoles.USACERegulator.toString().toLowerCase()}
          />
        </section>

        <section id='clean-water-act-id' className='mt-3'>
          <SectionHeader>State Assumption of Clean Water Act, Section 404 Permitting Program (Michigan, and New Jersey)</SectionHeader>
          <p>Under Section 404(g) of the Clean Water Act 33 U.S.C. 1344(g), any State or Tribe can administer its own individual and general permit program for the 
            discharge of dredged or fill material into certain waters subject to Section 404 of the Clean Water Act in place of the Corps after the State or Tribal 
            program is approved by the U.S. Environmental Protection Agency. The States of Michigan and New Jersey currently administer the Clean Water Act Section 
            404 program in the assumed waters within their respective states, however the Corps retains Section 404 permitting authority over some of the waters within 
            these states. The State of Florida had been approved to administer its own Section 404 program but that approval was vacated by a Federal court on 15 Feb 2024. 
            The Corps is currently the only entity authorized to issue Section 404 permits within Florida, although this is subject to change as the litigation challenging 
            Florida’s assumption is subject to change as the litigation challenging Florida’s assumption is ongoing.
          </p>
        </section>

        <section id='drawings-id'>
          <SectionHeader>Permit Application Drawings - Examples by Project Type</SectionHeader>
          <p>Please review the sample drawing for typical projects requiring a permit from the U.S. Army Corps of Engineers.</p>
          <Accordion.List>
            <AccordionListItem headingText='Expand to View Resources'>
              <p>These sample drawings will aid you in developing a plan for your project that is sufficiently clear for the USACE District to make a permit decision.</p>
              <p>While plans do not need to be professionally produced, they should be clear, accurate, and contain all necessary information. The example plans included in these instructions could be used as a guide when developing project plans. Following these examples will help facilitate an efficient review of the application and prevent the need for the USACE to request additional information.</p>
              <p><b>Example Plans:</b></p>
              <p className='mb-0'><LinkButton onClick={() => doDownloadFile(ResourceFiles.SampleDrawingBankStabilizationBioEng, FileTypes.Resource)} title='Sample Drawing Bank Stabilization - Bioengineered [PDF, 2 pages]' content='Sample Drawing Bank Stabilization - Bioengineered [PDF, 2 pages]' /></p>
              <p className='mb-0'><LinkButton onClick={() => doDownloadFile(ResourceFiles.SampleDrawingBankStabilizationRipRap, FileTypes.Resource)} title='Sample Drawing Bank Stabilization – Riprap [PDF, 2 pages]' content='Sample Drawing Bank Stabilization – Riprap [PDF, 2 pages]' /></p>
              <p className='mb-0'><LinkButton onClick={() => doDownloadFile(ResourceFiles.SampleDrawingCulvert, FileTypes.Resource)} title='Sample Drawing Culvert [PDF, 3 pages]' content='Sample Drawing Culvert [PDF, 3 pages]' /></p>
              <p className='mb-0'><LinkButton onClick={() => doDownloadFile(ResourceFiles.SampleDrawingDock, FileTypes.Resource)} title='Sample Drawing Dock [PDF, 2 pages]' content='Sample Drawing Dock [PDF, 2 pages]' /></p>
              <p className='mb-0'><LinkButton onClick={() => doDownloadFile(ResourceFiles.SampleDrawingStream, FileTypes.Resource)} title='Sample Drawing Stream Crossing [PDF, 2 pages]' content='Sample Drawing Stream Crossing [PDF, 2 pages]' /></p>
              <p className='mb-0'><LinkButton onClick={() => doDownloadFile(ResourceFiles.SampleDrawingWetlandHouse, FileTypes.Resource)} title='Sample Drawing Wetland Fill for House [PDF, 1 page]' content='Sample Drawing Wetland Fill for House [PDF, 1 page]' /></p>
              <p className='mb-0'><LinkButton onClick={() => doDownloadFile(ResourceFiles.SampleDrawingWetlandRoad, FileTypes.Resource)} title='Sample Drawing Wetland Fill for Road [PDF, 2 pages]' content='Sample Drawing Wetland Fill for Road [PDF, 2 pages]' /></p>
            </AccordionListItem>
          </Accordion.List>
        </section>

        <section id='next-steps-id' className='mt-3'>
          <SectionHeader>What are my next steps?</SectionHeader>
          <div className='row mt-3'>
            <div className='col-lg-6 col-md-6 col-xs-12 mt-1'>
              <NewSectionCard
                title='Apply for a Permit'
                content={`If you are ready to apply for a permit, click here to ${authIsLoggedIn ? 'start a new request' : 'login'}.`}
                onClick={() => handleLink()}
                buttonText={authIsLoggedIn ? 'Start a New Request' : 'Login'}
                buttonTitle={authIsLoggedIn ? 'Start a New Request' : 'Login'}
                isDisabled={userProfileData?.role?.toLowerCase() === ProfileRoles.USACERegulator.toString().toLowerCase()}
              />
            </div>
            <div className='col-lg-6 col-md-6 col-xs-12 mt-1'>
              <NewSectionCard
                title='Need Further Assistance'
                content='If you are unsure, review the information below or try our Permit Process Guide.'
                onClick={() => doUpdateRelativeUrl('/home/permit-process-guide')}
                buttonText='Permit Process Guide'
                buttonTitle='PermitProcessGuide'
              />
            </div>
          </div>
        </section>
      </>
    );
  });

export default Permitting;
