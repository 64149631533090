import { toast } from 'react-toastify';

const feedbackBundle = {
  name: 'feedback',

  getReducer: () => {
    const initialData = {
      feedbackFiles: [],
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case 'UPDATE_USER_FEEDBACK_FILES':
          return {
            ...state,
            feedbackFiles: payload,
          };
        default:
          return state;
      }
    };
  },

  selectFeedback: state => state.feedback,
  selectFeedbackFiles: state => state.feedback.feedbackFiles,

  doSaveUserFeedback: (formData) => ({ dispatch, apiPost, store }) => {
    store.doSetLoadingState(true);
    store.doSetLoadingMessage('Saving Feedback...');

    const url = '/api/UserFeedback/save';

    apiPost(url, formData, (err, body) => {
      if (!err && body.status === 'Success') {
        if (store.selectFeedbackFiles()?.length > 0) {
          // Format to FormData
          const formdata = new FormData();
          formdata.append('feedbackRecordId', body?.data);
          store.selectFeedbackFiles().forEach(item => {
            formdata.append('files', item);
          });

          // Upload Files and Save Feedback
          Promise.all([store.doUploadFilesFeedback(formdata)])
            .then(results => {
              // if all files upload successfully
              if (results?.[0]?.status === 'Success') {
                store.doSetLoadingState(false);
                store.doSetLoadingMessage('Loading...');
                store.doModalClose();
                toast.success('Feedback has been successfully submitted!');
              } else {
                // if any of the files failed to upload
                store.doSetLoadingState(false);
                store.doSetLoadingMessage('Loading...');
                console.error(results);
                store.doModalClose();
              }
            })
            .catch(e => {
              console.error(e);
            });
        } else {
          store.doSetLoadingState(false);
          store.doSetLoadingMessage('Loading...');
          store.doModalClose();
          toast.success('Feedback has been successfully submitted!');
        }
      } else {
        store.doSetLoadingState(false);
        store.doSetLoadingMessage('Loading...');
        dispatch({ type: 'SAVE_USER_FEEDBACK_ERROR', payload: err });
        toast.error('There was an error submitting the Feedback! Please try again in a few minutes.');
      }
    });
  },
  doSaveUserFeedbackFiles: (files) => ({ dispatch }) => {
    dispatch({ type: 'UPDATE_USER_FEEDBACK_FILES', payload: files });
  },
};

export default feedbackBundle;