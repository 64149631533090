import React, { useEffect } from 'react';
import { connect } from 'redux-bundler-react';
import { ToastContainer } from 'react-toastify';

import Footer from './app-components/footer';
import Modal from './app-components/modal';
import SecondaryModal from './app-components/secondary-modal';
import NavBar from './app-components/navigation/navBar';
import PageContent from './app-components/page-content/pageContent';
import LandingModal from './app-pages/common/modals/Landing';
import LoadingModal from './app-pages/common/modals/Loading';
import { GovBanner } from '@trussworks/react-uswds';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/css/bootstrap/css/bootstrap.water.min.css';
import './styles/bootstrap_overrides.css';
import './styles/index.scss';

export default connect(
  'doModalOpen',
  'doFetchBannerInfo',
  'doFetchUserProfile',
  'doSetLoadingState',
  'doSetLoadingMessage',
  'selectRoute',
  'selectAuthIsLoggedIn',
  'selectUserProfileData',
  'selectLoadingState',
  'selectLoadingMessage',
  ({
    doModalOpen,
    doFetchBannerInfo,
    doFetchUserProfile,
    doSetLoadingState,
    doSetLoadingMessage,
    route: Route,
    authIsLoggedIn,
    userProfileData,
    loadingState,
    loadingMessage,
  }) => {
    try {
      setInterval(async () => {
        doFetchBannerInfo();
      }, 300000);
    } catch(e) {
      console.error(e);
    }

    useEffect(() => {
      doFetchBannerInfo();
    }, [doFetchBannerInfo]);

    useEffect(() => {
      if (!authIsLoggedIn) {
        const landingModalSeen = sessionStorage.getItem('landingModalSeen');
        if(!landingModalSeen || landingModalSeen === 'false'){
          doModalOpen(LandingModal);
        }
      } else {
        if (userProfileData.length === 0) {
          doFetchUserProfile();
        }
      }
    }, [authIsLoggedIn, userProfileData, doModalOpen, doFetchUserProfile ]);

    useEffect(() => {
      window.addEventListener('pageshow', (event) => {
        // Check if page was restored from the bfcache
        if (event.persisted) {
          // Clear loading overlay
          doSetLoadingState(false);
          doSetLoadingMessage('Loading...');
        }
      });
    }, [doSetLoadingMessage, doSetLoadingState]);

    return (
      <>
        {loadingState && <LoadingModal text={loadingMessage} />}
        <ToastContainer autoClose={3500} hideProgressBar={false} />
        <div className={'gov-banner-container'}>
          <GovBanner
            language='english'
            tld='.mil'
            aria-label='Official government website'
          />
        </div>
        <NavBar />
        <div style={{ display: 'flex' }}>
          <PageContent>
            <Route />
          </PageContent>
        </div>
        <Modal closeWithEscape />
        <SecondaryModal closeWithEscape />
        <Footer version={import.meta.env.VITE_VERSION} />
      </>
    );
  }
);
