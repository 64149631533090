
import React from 'react';
import Tooltip from '../tooltip/tooltip';

import { useFormContext } from 'react-hook-form';
import '../../styles/index.scss';

const USWDSCheckboxInput = ({
  className,
  name,
  label,
  id,
  required,
  onChange,
  onBlur,
  validations,
  tooltip,
  tooltipClickable,
  value,
  disabled,
  subtext
}) => {

  const { register, formState: { errors } } = useFormContext();

  const inputError = errors[name];

  return (
    <div className='usa-checkbox' title={`${inputError?.message ?? label}`}>
      <input
        type='checkbox'
        className={`usa-checkbox__input usa-checkbox__input--tile ${className}`}
        id={id ? id : value}
        name={name}
        value={value}
        disabled={disabled}
        required={required}
        {...register(name, { onChange, onBlur, ...validations })}
        aria-invalid={inputError ? 'true' : 'false'}
        aria-required={required}
      />
      <label
        className={`usa-checkbox__label ${inputError && 'checkbox-is-invalid'}`}
        htmlFor={id ? id : value}>
        {label}{required ? <span className='asterisk-color'>*</span> : ''}
        {tooltip && <>
          <Tooltip name={name} header={label} content={tooltip} clickable={tooltipClickable} iconStyle={{ marginLeft: '5px' }} />
        </>}
        {subtext && <span className='usa-checkbox__label-description'>{subtext}</span>}
      </label>
    </div>
  );
};

export default USWDSCheckboxInput;