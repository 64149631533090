import React, { useRef, useEffect } from 'react';
import { connect } from 'redux-bundler-react';

import Icon from '@mdi/react';
import { mdiFileDocumentEdit } from '@mdi/js';

import './cellRenderers.scss';
import { isUserProfileAgent } from '../modals/_shared/UserProfileAgent';

const EditCellRenderer = connect(
  'doModalOpen',
  'selectUserProfileData',
  ({
    data,
    doModalOpen,
    modalComponent,
    setRowData,
    rowData,
    isReadOnly,
    userProfileData,
    api,
    column,
    rowIndex,
  }) => {
    const iconRef1 = useRef(null);
    // Identify id for row
    const id = data.contactID ? data.contactID : rowData.indexOf(data);

    const handleTab = (e) => {
      if (e.key === 'Tab') {
        e.preventDefault();
        e.stopPropagation();
        document.activeElement === iconRef1.current && iconRef1.current.focus();
      }
    };

    useEffect(() => {
      const onCellFocused = (params) => {
        (params?.column?.colId === column?.colId && params?.rowIndex === rowIndex) && iconRef1?.current?.focus();
      };

      api.addEventListener('cellFocused', onCellFocused);
      return () => {
        api.removeEventListener('cellFocused', onCellFocused);
      };
    }, [api, column.colId, rowIndex]);

    const handleKeyUp = (e, iconIndex) => {
      switch (e.key) {
        case 'Enter' && iconIndex === 1:
          doModalOpen(modalComponent, { edit: true, setRowData: setRowData, rowData: rowData, data: data, id: id, isReadOnly: isReadOnly });
          break;
        case 'ArrowRight' && iconIndex === 1:
          iconRef1.current.focus();
          break;
        default:
          break;
      }
    };

    return (
      <div className='renderer-div'>
        <div title='Edit' hidden={isUserProfileAgent(data, userProfileData?.firstName, userProfileData?.lastName, userProfileData?.emailAddress)} onClick={() => doModalOpen(modalComponent, { edit: true, setRowData: setRowData, rowData: rowData, data: data, id: id, isReadOnly: isReadOnly })} onKeyUp={e => handleKeyUp(e, 1)} onKeyDown={handleTab} tabIndex={0} ref={iconRef1}>
          <Icon className='cell-btn-icon info' path={mdiFileDocumentEdit} size={'28px'} />
        </div>
      </div>
    );
  });

export default EditCellRenderer;