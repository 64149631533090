import React, { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';

import { Row } from '../../../_shared/helper';
import Tooltip from '../../../../app-components/tooltip/tooltip';
import USWDSCheckboxInput from '../../../../app-components/uswds/uswdsCheckboxInput.jsx';
import { ContactTypes } from '../../../../utils/enums.tsx';
import { Alert } from '@trussworks/react-uswds';


import { useFormContext } from 'react-hook-form';
import { aquaticResourceInventoryTooltipContent, jurisdictionalDeterminationTooltipContent, wotusDelineationReportTooltipContent } from '../../../Resources/components/_shared/helper.jsx';

const RequestTypes = connect(
  'selectUserProfileData',
  ({ userProfileData }) => {

    const [isJDDisabled, setIsJDDisabled] = useState(false);
    const [isPreAppDisabled, setIsPreAppDisabled] = useState(false);
    const [isGPDisabled, setIsGPDisabled] = useState(false);
    const [isIPDisabled, setIsIPDisabled] = useState(false);
    const { watch } = useFormContext();

    const requestType = watch('requestType');

    useEffect(() => {
      if (Array.isArray(requestType)) {
        setIsPreAppDisabled(requestType?.filter(type => type === '1' || type === '2' || type === '3' || type === '4')?.length > 0 ? true : false); // 5
        setIsGPDisabled(requestType?.filter(type => type === '1' || type === '2' || type === '3' || type === '5')?.length > 0 ? true : false); // 4
        setIsJDDisabled(requestType?.filter(type => type === '3' || type === '4' || type === '5')?.length > 0 ? true : false); // 1 and 2
        setIsIPDisabled(requestType?.filter(type => type === '1' || type === '2' || type === '4' || type === '5')?.length > 0 ? true : false); // 3
      }
    }, [requestType]);

    //@TODO WHEN MULTIPLE SELECTIONS ALLOWS, MAKE EACH RADIO BUTTON NAME UNIQUE
    return (
      <>
        <div className='container-fluid'>
          <Row>
            <h4 className='border-bottom w-100 pb-2 pt-2'>Select a Request Type</h4>
          </Row>
          {userProfileData.role === ContactTypes.Agent &&
            <Alert className='mt-1' type='info'>Agents - If you are selecting the “Jurisdictional Determination” option, below, and you have completed a delineation and Aquatic Resource Inventory, you must also select the “Submit a WOTUS Delineation Report and Aquatic Resource Inventory”.  Note that you will be prompted to upload the Aquatic Resource Inventory at the end of this section.</Alert>
          }
          <fieldset className='mb-3 ml-2'>
            <p className='h5 border-bottom w-100 mt-4 mb-4'>Delineation/Jurisdictional Determination</p>
            <USWDSCheckboxInput
              id='1a'
              name='requestType'
              label={<>Submit a WOTUS Delineation Report<Tooltip name={'delineation-report'} content={wotusDelineationReportTooltipContent} /> and Aquatic Resource Inventory<Tooltip name={'ar-inventory'} content={aquaticResourceInventoryTooltipContent} /></>}
              value='1'
              disabled={isJDDisabled}
              hideError
            />
            <USWDSCheckboxInput
              id='1b'
              name='requestType'
              label={<>Request a Jurisdictional Determination<Tooltip name={'jurisdictional-determination'} content={jurisdictionalDeterminationTooltipContent} /></>}
              value='2'
              disabled={isJDDisabled}
              hideError
            />
            <p className='h5 border-bottom w-100 mt-4 mb-4'>Permits</p>
            <USWDSCheckboxInput
              id='2b'
              name='requestType'
              label='Nationwide Permit (ENG 6082)'
              value='4'
              disabled={isGPDisabled}
              hideError
            />
            <USWDSCheckboxInput
              id='2a'
              name='requestType'
              label='Individual Permit (ENG 4345)'
              value='3'
              disabled={isIPDisabled}
              hideError
            />
            <p className='h5 border-bottom w-100 mt-4 mb-4'>Pre-application / Project Scoping</p>
            <USWDSCheckboxInput
              id='5'
              name='requestType'
              label='Pre-application/Project Scoping'
              value='5'
              disabled={isPreAppDisabled}
              hideError
            />
          </fieldset>
        </div>
      </>);
  });

export default RequestTypes;