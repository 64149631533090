import React from 'react';
import Tooltip from '../tooltip/tooltip';

import { useFormContext } from 'react-hook-form';
import '../../styles/index.scss';

const SelectInput = ({
  className,
  name,
  label,
  required,
  onChange,
  onBlur,
  validations,
  tooltip,
  tooltipClickable,
  options = [],
  children,
  readOnly,
  group,
  defaultOption = '--- Please select an option ---',
}) => {

  const { register, formState: { errors } } = useFormContext();

  const inputError = errors[name];

  return (
    <>
      <label
        className={'mr-2 mb-0 mt-2 w-100'}
        htmlFor={name}>
        {label}{required ? <span className='asterisk-color'>*</span> : <span style={{ fontStyle: 'italic' }}> (optional)</span>}
        {tooltip && <>
          <Tooltip name={name} header={label} content={tooltip} clickable={tooltipClickable} iconStyle={{ marginLeft: '5px' }} />
        </>}
      </label>
      <select
        className={`custom-select mt-1 ${className} ${inputError ? ' is-invalid' : ''}`}
        id={name}
        name={name}
        required={required}
        disabled={readOnly}
        aria-invalid={inputError ? 'true' : 'false'}
        aria-required={required}
        {...register(name, { onChange, onBlur, ...validations })}>
        <option key='1' value='' style={{ display: required ? 'none' : 'inline' }}>{`${defaultOption}`}</option>
        {!group && options.map((option, i) =>
          <option key={i + 2} value={option.value ? option.value : option}>{option.label ? option.label : option}</option>
        )}
        {group &&
          <optgroup label={group}>
            {options.map((option, i) =>
              <option key={i + 2} value={option.value ? option.value : option}>{option.label ? option.label : option}</option>
            )}
          </optgroup>
        }
        {children}
      </select >
    </>
  );
};

export default SelectInput;