import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const AmountUnitTableCell =
  ({
    getValue,
    row,
    column,
    table,
    cell,
  }) => {
    const columnMeta = column.columnDef.meta;
    const tableMeta = table.options.meta;
    const initialValue = getValue();
    const initialOptions = columnMeta?.options[0];
    const [value, setValue] = useState(initialValue);
    const [amountOptions, setAmountOptions] = useState(initialOptions);
    const [amountType, setAmountType] = useState();
    const rowAmountType = useMemo(() => row.getValue('amountType'), [row]);
    const debouncedUpdateRef = useRef();

    useEffect(() => {
      debouncedUpdateRef.current = debounce((newValue) => {
        if (tableMeta?.updateData) {
          tableMeta.updateData(row.index, column.id, newValue);
        }
      }, 500);
    }, [row.index, column.id, tableMeta?.updateData, columnMeta?.type, tableMeta]);

    const updateValue = useCallback((newValue) => {
      debouncedUpdateRef.current(newValue);
    }, []);

    useEffect(() => {
      rowAmountType && setAmountType(rowAmountType);
    }, [rowAmountType]);

    useEffect(() => {
      if (amountType?.includes('Volume') && initialValue !== 'Cubic Yards') {
        value !== 'Cubic Yards' && setValue('Cubic Yards');
        value !== 'Cubic Yards' && tableMeta?.updateData(row.index, column.id, 'Cubic Yards');
      }
    }, [amountOptions, amountType, column.id, row.index, tableMeta, initialValue, value]);


    useEffect(() => {
      const options = amountType?.includes('Area') ? columnMeta?.options[0] : columnMeta?.options[1];
      amountOptions?.[0]?.value !== options?.[0]?.value && setAmountOptions(options);
    }, [amountType, columnMeta?.options, amountOptions]);

    const handleBlur = (e) => {
      // tableMeta?.updateData(row.index, column.id, columnMeta?.type === 'number' ? Number(e.target.value) : e.target.value ?? e.target.value);
    };

    useEffect(() => {
      if (!amountType?.includes('Volume') && value === 'Cubic Yards') {
        setValue('');
        tableMeta?.updateData(row.index, column.id, '');
      }
    }, [amountType, value, column.id, row.index, tableMeta]);

    const handleChange = (e) => {
      setValue(e.target.value);
      updateValue(e.target.value);
    };

    return (
      <select
        id={cell.id}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value ?? ''}
        required={columnMeta?.required}
        disabled={columnMeta?.readOnly}
        style={{ width: '100%' }}>
        <option key={0} value='' className='none' style={{ display: 'none' }}>-- Select a value --</option>
        {amountOptions?.map((option) => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    );
  };

export default AmountUnitTableCell;