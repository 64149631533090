import React from 'react';

import Card from '../../../../app-components/card';
import Icon from '@mdi/react';

import '../../resources.scss';

const ModuleButton = ({
  title,
  text,
  icon,
  popupText,
  selected = false,
  hideDetails,
  image,
  subtext,
  imageAlt = '',
  onClick = () => { },
  isDisabled,
  isError,
  isModalButton,
  iconOnly
}) => {

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onClick();
    }

  };

  return (
    <>
      <Card className={`spacer w-100 ${isDisabled && 'homepage-disabled'} ${isModalButton && subtext ? 'modal-card-container' : 'card-container'} ${selected && 'module-selected'} ${hideDetails && 'module-icon-only'} ${isError && 'modal-card-invalid'}`} onClick={onClick} onKeyUp={handleKeyPress} tabIndex={0}>
        <Card.Body>
          <div className='center'>
            <div>
              {image && (<img src={image} alt={imageAlt} className='img-icon' />)}
              {icon && (<Icon title={text} className='img-icon' path={icon} size={3} />)}
              <div className={`${hideDetails && 'd-none'}`}>
                {title && <div className='button-header'>{title}</div>}
                {popupText && <div className='button-text popup'>{popupText}</div>}
                <div>
                  {text && <div className='button-text'>{text}</div>}
                  {subtext && <div className='button-subtext'>{subtext}</div>}
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ModuleButton;
;
