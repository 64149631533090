import React from 'react';
import Tooltip from '../tooltip/tooltip';

import { Controller } from 'react-hook-form';
import Select from 'react-select';

import { customSelectStyles, customSelectStylesNonRequired } from '../../app-pages/Forms/input-forms/_helper.jsx';
import '../../styles/index.scss';
import { useFormContext } from 'react-hook-form';

const MultiSelectInput = ({
  name,
  label,
  required,
  handleChange,
  handleBlur,
  tooltip,
  tooltipClickable,
  options,
  isLoading,
  multi,
  readOnly,
  closeMenuOnSelect = false,
  menuPlacement
}) => {

  const { control, formState: { errors } } = useFormContext();

  const inputError = errors[name];

  const errorStyle = required ? customSelectStyles : customSelectStylesNonRequired;

  return (
    <>
      <label
        className={'mr-2 mb-0 mt-2 w-100'}
        htmlFor={name}>
        {label}{required ? <span className='asterisk-color'>*</span> : <span style={{ fontStyle: 'italic' }}> (optional)</span>}
        {tooltip && <>
          <Tooltip name={name} header={label} content={tooltip} clickable={tooltipClickable} iconStyle={{ marginLeft: '5px' }} />
        </>}
      </label>
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, onBlur, value, name, ref },
        }) => (
          <Select
            inputId={name}
            styles={inputError ? errorStyle : {}}
            required={required}
            readOnly={readOnly}
            isDisabled={readOnly}
            options={options}
            isLoading={isLoading}
            closeMenuOnSelect={closeMenuOnSelect}
            hideSelectedOptions={false}
            onChange={(e) => {
              onChange(e);
              handleChange && handleChange(e);
            }}
            isMulti={multi}
            onBlur={(e) => {
              onBlur(e);
              handleBlur && handleBlur(e);
            }}
            value={value}
            name={name}
            ref={ref}
            menuPlacement={menuPlacement}
          />
        )}
      />
    </>
  );
};

export default MultiSelectInput;