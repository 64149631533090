import React from 'react';
import { ModalContent, ModalHeader, ModalFooter, } from '../../../../app-components/modal';
import ReadOnlyMap from '../../../../app-components/map/readOnlyMap';
import { calculateGeometryExtent } from '../../../../utils/helpers';
import { formatCoordStr } from '../../../../utils/helpers';

import { connect } from 'redux-bundler-react';


const ProjectLocationModal = connect(
  'selectRequestFormData',
  'selectSelectedRequest',
  ({ requestFormData,
    selectedRequest,
    saveText = 'Close',
  }) => {

    const location = requestFormData?.location;



    return (
      < ModalContent size='lg'>
        <ModalHeader title={`${selectedRequest === '6' ? 'Site Location' : 'Project Location'}`} hasCloseButton />
        <section className='modal-body'>
          <ReadOnlyMap mapOnly geometry={location?.geometry} extent={calculateGeometryExtent(location?.geometry)} />
          <div className='border mt-2 pb-2'>
            <div className='ml-2'>
              <div className='row mt-1'>
                <div className='col-auto'>
                  <b>Latitude:</b> {location?.latitude ? formatCoordStr(location.latitude) : '---'}
                </div>
                <div className='col-auto'>
                  <b>Longitude:</b> {location?.longitude ? formatCoordStr(location.longitude) : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col'>
                  <b>Address:</b> {location.address ? location.address : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col'>
                  <b>Address Line 2:</b> {location.addressTwo ? location.addressTwo : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col'>
                  <b>City:</b> {location.city ? location.city : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col'>
                  <b>State:</b> {location.state ? location.state : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col'>
                  <b>Zipcode:</b> {location.zipcode ? location.zipcode : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col'>
                  <b>County:</b> {location.county ? location.county : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col'>
                  <b>District:</b> {location.district ? location.district : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col'>
                  <b>Field Office Code:</b> {location.fieldOfficeCode ? location.fieldOfficeCode : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col'>
                  <b>{`${selectedRequest === '6' ? 'Area of Violation:' : 'Project Area:'}`}</b> {location.projectArea ? location.projectArea : '---'} {location.unitOfMeasurement ? location.unitOfMeasurement : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col'>
                  <b>Parcel Number:</b> {location.parcelnumber ? location.parcelnumber : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col-auto'>
                  <b>PLSS Section:</b> {location.plsssection ? location.plsssection : '---'}
                </div>
                <div className='col-auto'>
                  <b>PLSS Township:</b> {location.plsstownship ? location.plsstownship : '---'}
                </div>
                <div className='col-auto'>
                  <b>PLSS Range:</b> {location.plssrange ? location.plssrange : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col'>
                  <b>Nearest Waterbody:</b> {location.nearestWaterbody ? location.nearestWaterbody : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col'>
                  <b>{`${selectedRequest === '6' ? 'Directions to Site Location:' : 'Directions to Project Location:'}`}</b> {location.siteDirections ? location.siteDirections : '---'}
                </div>
              </div>
            </div>
          </div>
        </section>
        <ModalFooter
          saveText={saveText}
          showCancelButton={false}
        />
      </ModalContent >);
  }
);
export default ProjectLocationModal;
