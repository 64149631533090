import React from 'react';
import { connect } from 'redux-bundler-react';
import Icon from '@mdi/react';
import { mdiNoteEdit, mdiFileCheckOutline } from '@mdi/js';

import './FormStatus.scss';

const FormStatus = connect(
  'selectRequestStatus',
  ({
    requestStatus
  }) => {
    const icon = requestStatus === 'Draft' ? mdiNoteEdit : mdiFileCheckOutline;
    let statusClass;

    switch (requestStatus) {
      case 'Draft':
        statusClass = 'draft';
        break;
      case 'Submitted':
        statusClass = 'submitted';
        break;
      case 'Acknowledged':
        statusClass = 'acknowledged';
        break;
      case 'Not Approved':
        statusClass = 'notapproved';
        break;
      case 'Lead Agency Changed':
        statusClass = 'lead-agency-changed';
        break;
      case 'Re-Routed':
        statusClass = 're-routed';
        break;
      case 'Assigned':
        statusClass = 'assigned';
        break;
      default:
        statusClass = 'draft';
        break;
    }

    const renderedIcon = <Icon className='form-status icon' path={icon} size={3} />;

    return (
      <div className='form-status-container'>
        <div className={`form-status-icon-container form-status ${statusClass}`}>
          {renderedIcon}
        </div>
        <div className='form-status-text-container'>
          <div className='form-status text'>
            Request Status
          </div>
          <div className='form-status text'><b>{requestStatus}</b></div>
        </div>
      </div >
    );

  }
);

export default FormStatus;