import React from 'react';
import { connect } from 'redux-bundler-react';

import { classArray } from '../../utils/helpers.jsx';

import './sideBar.scss';

/**
 * @param {Array} href - An array of strings, or objects structured as { text: '', uri: '' }.
 * @param {Array} includedLinks - An array of uris to force the display of the active class when user is on one of the provided uris.
 */

const SideBarItem = connect(
  'selectRelativePathname',
  ({
    relativePathname: pathname,
    href,
    children,
    className,
    includedLinks = [],
    key,
  }) => {
    const cls = classArray([
      'nav-item',
      href &&
      (href.includes(pathname) || includedLinks.includes(pathname)) &&
      'active',
      className,
    ]);

    return (
      <>
        <li key={key} className={cls}>
          <a className='nav-link' href={href}>
            {children}
          </a>
        </li>
      </>
    );
  }
);

export default SideBarItem;
