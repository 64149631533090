const fieldLookupTableBundle = {
  name: 'fieldlookuptable',

  getReducer: () => {
    const initialData = {
      ARTypes: [],
      Cowardins: [],
      HGMCodes: [],
      StateRegulations: [],
      DivisionAndDistricts: [],
      CreditUnits: [],
    };

    return (state = initialData, { type, payload }) => {

      switch (type) {
        case 'UPDATE_FIELD_LOOKUP_TABLE':
          return {
            ...state, ...payload
          };
        case 'UPDATE_CREDIT_UNITS_LOOKUP_TABLE':
          return {
            ...state, 
            CreditUnits: payload
          };
        default:
          return state;
      }
    };
  },
  doGetLookupTableData: () => ({ dispatch, apiGet }) => {
    const uri = '/api/Lookup/getALLLKPTables';

    apiGet(uri, (err, body) => {
      if (!err && body.status === 'Success') {
        dispatch({
          type: 'UPDATE_FIELD_LOOKUP_TABLE',
          payload: body.data
        });
      }
      else {
        console.error('FAILED TO GET LATEST LOOKUP TABLE DATA');
      }

    });
  },
  doFetchCreditUnitsByDistrict: (district) => ({ dispatch, apiGet }) => {
    const uri = '/api/Lookup/getCreditUnitsForDistrict?'  + new URLSearchParams({
      district
    });

    apiGet(uri, (err, body) => {
      if (!err && body?.status === 'Success') {
        dispatch({
          type: 'UPDATE_CREDIT_UNITS_LOOKUP_TABLE',
          payload: body?.data
        });
      }
      else {
        console.error('FAILED TO GET LATEST CREDIT UNITS DATA');
      }

    });
  },
  selectLookupTableData: (state) => state.fieldlookuptable,
  selectLookupTableARTypes: (state) => state.fieldlookuptable.ARTypes,
  selectLookupTableCowardins: (state) => state.fieldlookuptable.Cowardins,
  selectLookupTableHGMCodes: (state) => state.fieldlookuptable.HGMCodes,
  selectLookupTableStateRegulations: (state) => state.fieldlookuptable.StateRegulations,
  selectLookupTableDivisionsAndDistricts: (state) => state.fieldlookuptable.DivisionAndDistricts,
  selectLookupTableCreditUnits: (state) => state.fieldlookuptable.CreditUnits,
};

export default fieldLookupTableBundle;