import React, { useEffect } from 'react';
import { connect } from 'redux-bundler-react';

import Tooltip from '../tooltip/tooltip';

import { useFormContext } from 'react-hook-form';
import '../../styles/index.scss';

import { isValidASCII, sanitizeASCII } from '../../utils/helpers';
import { ErrorMessages } from '../../utils/enums.tsx';

import genericSecondaryModal from '../../app-pages/Forms/components/modals/genericSecondaryModal';

const TextAreaInput = connect('doSecondaryModalOpen', ({
  doSecondaryModalOpen,
  className = 'w-100',
  name,
  label,
  required,
  onChange,
  onBlur,
  pattern,
  validations,
  tooltip,
  tooltipClickable,
  rowCount = 3,
  maxLength = 4000,
  readOnly, }) => {

  const { register, watch, setValue, formState: { errors } } = useFormContext();

  const input = watch(name);

  useEffect(() => {

    if (input && typeof input === 'string') {

      if (isValidASCII(input)) {
        setValue(name, input);
      } else {
        // Optionally handle invalid input here, such as warning the user
        const msg = ErrorMessages.InvalidCharacters;
        doSecondaryModalOpen(genericSecondaryModal, { title: 'Invalid Characters', msg: msg });
        setValue(name, sanitizeASCII(input));
      }

    };

  }, [input, name, setValue, doSecondaryModalOpen]);

  const inputError = errors[name];

  return (
    <>
      {label && <label
        className={'mr-2 mb-0 mt-2 w-100'}
        htmlFor={name}>
        {label}{required ? <span className='asterisk-color'>*</span> : <span style={{ fontStyle: 'italic' }}> (optional)</span>}
        {tooltip && <>
          <Tooltip name={name} header={label} content={tooltip} clickable={tooltipClickable} iconStyle={{ marginLeft: '5px' }} />
        </>}
      </label>}
      <textarea
        rows={rowCount}
        maxLength={maxLength}
        pattern={pattern}
        className={`form-control mt-1 ${className} ${inputError ? ' is-invalid' : ''}`}
        id={name}
        disabled={readOnly}
        required={required}
        {...register(name, { onChange, onBlur, pattern, ...validations })}
        aria-invalid={inputError ? 'true' : 'false'}
        aria-required={required}
      />
      <div className='mb-0'>
        <small>{`${watch(name) ? watch(name).length : 0}/${maxLength}`}</small>
      </div>
    </>
  );
}
);

export default TextAreaInput;