import React, { useEffect, useState } from 'react';

import { connect } from 'redux-bundler-react';

import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import Button from '../button';

import { classArray } from '../../utils/helpers.jsx';

/**
 * 
 * @param {Array} tabs - List of tabs to display, objects structured as `{ content: <Element>, setButton = logic }`. Default `[]`.
 * @param {number} defaultTab - Default tab index to be set as active. Default `0`.
 * @param {string} contentClassName - Class(es) to be applied to the contents container element `<section />`.
 * @param {Function} onSave - Function triggered after last tab is reached.
 */
const ModalTabContainerNewRequest = connect('selectDistrictResults', ({
  districtResults,
  tabs = [],
  defaultTab = 0,
  contentClassName = '',
  setActiveTab,
  onSave = () => { },
  disableOnSave,
  hideLastBtn,
  hideFooter
}) => {
  const [currentTab, setCurrentTab] = useState(defaultTab);
  const [prevButton, setPrevButton] = useState('none');
  const [nextButton, setNextButton] = useState('Next');

  const { formState: { isValid }, trigger } = useFormContext();

  const district = districtResults?.[0];

  useEffect(() => {

    setActiveTab(currentTab);

  }, [currentTab, setActiveTab]);

  const sectionClasses = classArray([
    'modal-body',
    contentClassName,
  ]);

  // Display the specified tab of the form
  const showTab = n => {
    const tabs = document.getElementsByClassName('tab');

    tabs[n].style.display = 'block';

    if (n === 0) {
      setPrevButton('none');
    } else {
      setPrevButton('inline');
    }
    if (n === (tabs.length - 1)) {
      setNextButton('Continue');
    } else {
      setNextButton('Next');
    }
  };

  const nextPrev = n => {

    if (isValid && district) {
      const tabs = document.getElementsByClassName('tab');
      const buttons = document.getElementsByClassName('tab-button');

      tabs[currentTab].style.display = 'none';
      buttons[currentTab].style.display = 'none';

      setCurrentTab(currentTab + n);

      // if you have reached the end of the form
      if (currentTab >= tabs.length) return false;
    }
    else {
      if (isValid && !district) {
        alert('Location is not in a valid USACE district or district has not been determined yet. Please verify the project location and try again.');
      }
      else {
        trigger();
        toast.error('Please fill out all required fields!');
      }

    };
  };

  const updateIndicator = n => {
    // removes the "active" class of all steps
    const steps = document.getElementsByClassName('step');
    if (steps.length > 0) {
      const stepsArr = Array.from(steps);
      stepsArr.map((step) => step.className = step.className.replace(' active', ''));
      // adds the "active" class on the current step:
      steps[n].className += ' active';
    }
  };

  // Display the current tab
  useEffect(() => {
    const showButtons = n => {
      const buttons = document.getElementsByClassName('tab-button');
      if (buttons.length > 0) {
        buttons[n].style.display = (((n === buttons.length - 1) && hideLastBtn) || hideFooter) ? 'none' : 'inline';
      }
    };
    showTab(currentTab);
    showButtons(currentTab);
    updateIndicator(currentTab);
  }, [currentTab, hideFooter, hideLastBtn]);

  return (
    <>
      <section className={sectionClasses}>
        {tabs.map((item, index) =>
          <div key={index} className='tab'>
            {item.content}
          </div>
        )}
      </section>
      {/* Modal footer */}
      {!hideFooter && <div className='modal-footer'>
        {/* Circles which indicates the steps of the form */}
        <div style={{ textAlign: 'center' }}>
          {tabs.map((item, index) =>
            <span key={index} className='step'></span>
          )}
        </div>
        <div style={{ float: 'right' }}>
          <div style={{ display: `${prevButton}`, paddingRight: '3px' }}>
            <Button
              size='small'
              variant='secondary'
              id='prevBtn'
              text='Previous'
              handleClick={() => nextPrev(-1)}
              isOutline
            />
          </div>
          {tabs.map((item, index) =>
            <Button
              key={index}
              className='tab-button'
              style={{ display: 'none' }}
              size='small'
              variant='primary'
              text={nextButton}
              handleClick={nextButton === 'Continue' ? onSave : () => nextPrev(1)}
              isDisabled={item.setDisabled || (nextButton === 'Continue' && disableOnSave)}
              isLoading={item.isLoading}
            />
          )}
        </div>
      </div>}
    </>
  );
}
);

export default ModalTabContainerNewRequest;