import React from 'react';
import { connect } from 'redux-bundler-react';

import NewSectionCard from './_shared/NewSectionCard';
import Tooltip from '../../../app-components/tooltip/tooltip';
import InternalLink from '../../../app-components/link/internalLink.jsx';
import ExternalLink from '../../../app-components/link/externalLink.jsx';
import LinkButton from '../../../app-components/link/linkButton.jsx';
import Accordion from '../../..//app-components/accordion';
import AccordionListItem from '../../../app-components/accordion/accordionListItem';

import { generalPermitTooltipContent, SectionHeader, standardPermitTooltipContent } from './_shared/helper.jsx';

import { FileTypes, ResourceFiles } from '../../../utils/enums.tsx';

import '../resources.scss';

const corpsRegulationsLinks = [
  { id: 320, title: 'General Regulatory Policies' },
  { id: 321, title: 'Permits for Dams & Dikes in Navigable Waters of the U.S.' },
  { id: 322, title: 'Permits for Structures in or Affecting Navigable Waters of the U.S.' },
  { id: 323, title: 'Permits for Discharges of Dredged or Fill Material Into Waters of the U.S.' },
  { id: 324, title: 'Permits for Ocean Dumping of Dredged Material' },
  { id: 325, title: 'Processing of Department of the Army Permits' },
  { id: 326, title: 'Enforcement' },
  { id: 327, title: 'Public Hearing' },
  { id: 328, title: 'Definition of Waters of the United States (1986 Rule)' },
  { id: 329, title: 'Definition of Navigable Waters' },
  { id: 330, title: 'Nationwide Permit Program' },
  { id: 331, title: 'Administrative Appeal Process' },
  { id: 332, title: 'Compensatory Mitigation for Losses of Aquatic Resources' },
  { id: 334, title: 'Danger Zone and Restricted Areas (Procedures)' },
];

const OverviewResources = connect(
  'doAuthLogin',
  'doDownloadFile',
  'doUpdateRelativeUrl',
  'selectAuthIsLoggedIn',
  ({
    doAuthLogin,
    doDownloadFile,
    doUpdateRelativeUrl,
    authIsLoggedIn,
  }) => (
    <>
      <section id='usace-regulatory-program-id'>
        <SectionHeader>What is the U.S. Army Corps of Engineers (USACE) Regulatory Program?</SectionHeader>
        <div>
          <p>The U.S. Army Corps of Engineers (USACE) Regulatory Program is committed to protecting the nation's aquatic resources and navigation capacity, while allowing reasonable development through fair and balanced decisions. The USACE Regulatory Program
            has been involved in regulating certain activities in the nation's waters since 1890.</p>
          <p>Upon the passage of the <b>Rivers and Harbors Act of 1899 (RHA)</b>, USACE was provided the authority and responsibility to regulate activities, such as structures or work, in or affecting "navigable waters of the United States." The 1972 amendments to the
            <b> Clean Water Act (CWA)</b> established federal jurisdiction over "waters of the United States," and Section 404 of the CWA provides USACE with the authority and responsibility to regulate discharges of dredged or fill material into the "waters of the United
            States."</p>
          <p>"Navigable waters of the United States" that are regulated pursuant to the RHA and "waters of the United States" that are regulated pursuant to the CWA may include aquatic resources such as ocean and tidal waters, rivers, streams, and certain wetlands.
            Individuals uncertain about the status of waters on their property may {authIsLoggedIn ? <InternalLink href='/' content='request a jurisdictional determination'/> : <LinkButton onClick={() => doAuthLogin()}>request a jurisdictional determination</LinkButton>} from the USACE.</p>
          <p>A general depiction of the USACE Regulatory jurisdiction under the RHA and the CWA can be found in the <InternalLink href='/home/jurisdiction' title='Jurisdiction section' content='Jurisdiction section'/>. More information about wetlands and
            wetland delineations can be found in this <ExternalLink href='https://www.dvidshub.net/video/148986/wetland-delineation' content='video' title='video'/> as well as many of the resources included below. Additional videos can be found in
            the <ExternalLink href='https://www.dvidshub.net/search?filter%255Bunit%255D=USACE-JV&q=Regulatory' title='USACE digital library' content='USACE digital library' />
          </p>
        </div>
      </section>

      <section id='usace-regulatory-mission-id'>
        <SectionHeader>Understanding the Mission</SectionHeader>
        <div>
          <p>This <ExternalLink href='https://www.dvidshub.net/video/102251/regulatory-process-101' title='video' content='video' /> describes the US Army Corps of Engineers Regulatory Program mission, background on:</p>
          <ul>
            <li>The Regulatory authorities which grants USACE the responsibility to require permits when work is proposed in navigable waters or other jurisdictional waters including wetlands.</li>
            <li>The permit process for two types of permits: <b>Individual Permits</b><Tooltip name='individual-permit' content={standardPermitTooltipContent} /> and <b>General Permits</b><Tooltip name='general-permit' content={generalPermitTooltipContent} />.
              For projects that propose impacts to regulated waters, the type and area of impacts will determine what permit type is required. State and local certifications or permits may also be required, as
              well as compliance with other federal laws such as the Endangered Species Act, and the National Historic Preservation Act. More information about these types of permits can be found in the <InternalLink href='/home/permitting' title='Permitting section' content='Permitting section' /> of this site.
            </li>
          </ul>
          <p>A <ExternalLink href='https://www.dvidshub.net/video/149226/regulatory-101-childrens-video' title={'children\'s version'} content={'children\'s version'} /> of the video is available. </p>
        </div>
      </section>

      <section id='additional-resources-id'>
        <SectionHeader>Additional Resources</SectionHeader>

        <div className='mb-3'>
          <Accordion.List>
            <AccordionListItem headingText={<span className='font-weight-bold'>Corps Regulations</span>}>
              33 CFR Parts 320-332 and 334 are the implementing regulations that USACE uses to administer Section 404 of the Clean Water Act. These regulations specify, among other things, requirements for applications for permits, compliance with other Federal statutes including the Endangered Species Act and the National Historic Preservation Act, and how the Corps will comply with the requirements of the National Environmental Policy Act.

              <ul className='mt-3'>
                {corpsRegulationsLinks.map((link) => (
                  <li><ExternalLink href={`https://www.ecfr.gov/current/title-33/chapter-II/part-${link.id}`} content={`33 CFR Part ${link.id} - ${link.title}`} title={`33 CFR Part ${link.id} - ${link.title}`}/></li>
                ))}
              </ul>
            </AccordionListItem>
          </Accordion.List>
        </div>

        <LinkButton onClick={() => doDownloadFile(ResourceFiles.Reg101FreqUsedTerms, FileTypes.Resource)} title='RRS Commonly Used Regulatory Terms [PDF, 9 pages]' content='RRS Commonly Used Regulatory Terms [PDF, 9 pages]' />
        <p>Check out the following resources from the Headquarters homepage:</p>
        <div className='row mt-3'>
          <div className='col-lg-6 col-md-6 col-xs-12 mt-1'>
            <NewSectionCard
              title='Recent Regulatory Announcements'
              content='View the recent announcements related to the Regulatory Program posted on the USACE Headquarters website.'
              url='https://www.usace.army.mil/Missions/Civil-Works/Regulatory-Program-and-Permits/'
              buttonText='Announcements'
              buttonTitle='Regulatory Announcements'
              external
            />
          </div>
          <div className='col-lg-6 col-md-6 col-xs-12 mt-1'>
            <NewSectionCard
              title='Headquarters Resources'
              content='Relevant content and links for resources found on the USACE Headquarters homepage.'
              url='https://www.usace.army.mil/Missions/Civil-Works/Regulatory-Program-and-Permits/Related-Resources/'
              buttonText='Resources'
              buttonTitle='Resources'
              external
            />
          </div>
        </div>
      </section>
    </>
  )
);

export default OverviewResources;