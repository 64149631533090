import React from 'react';
import { connect } from 'redux-bundler-react';
import Countdown from 'react-countdown';
import { ModalContent, ModalHeader, ModalFooter } from '../../../app-components/secondary-modal';

const SessionExpirationModal = connect(
  'doSecondaryModalClose',
  ({
    doSecondaryModalClose,
    handleLogout = () => { },
    handleExtend = () => { },
    idle = false,
  }) => {
    // Renderer callback with condition
    const countdownRenderer = ({ minutes, seconds, completed }) => {
      if (completed) {
        // Render a completed state
        handleLogout();
        doSecondaryModalClose();
        return;
      } else {
        // Render a countdown
        return <span>{minutes}:{seconds}</span>;
      }
    };

    return (
      <ModalContent>
        <ModalHeader title='Session Expiration Warning' hasCloseButton />
        <section className='modal-body'>
          <div className='container-fluid'>
            <p>{idle === true && 'You\'re being timed out due to inactivity.'} Your session will expire in <span><Countdown zeroPadTime={2} date={Date.now() + 300000} renderer={countdownRenderer} /></span> seconds. Please choose to stay signed-in or to logout. Otherwise, you will be logged off automatically.</p>
          </div>
        </section>
        <ModalFooter
          doSecondaryModalClose
          saveText='Continue session'
          onSave={() => handleExtend()}
          deleteText='Logout'
          onDelete={() => handleLogout()}
        />
      </ModalContent >
    );
  });

export default SessionExpirationModal;
