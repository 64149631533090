import React from 'react';

import { ModalContent, ModalHeader } from '../../../app-components/modal';
import LinkButton from '../../../app-components/link/linkButton.jsx';
/* eslint-disable */

const HelpModal = () => {

  return (
    <ModalContent size='md'>
      <ModalHeader title='Submit a Help Ticket' hasCloseButton />
      <div className='mt-2 mb-2 text-center'>
        <p className='text-center'>To submit a ticket, please either:</p>
        <div className='text-center mb-2'>
          <LinkButton onClick={(e) => { window.location.href = 'mailto:rrs@usace.army.mil?subject=RRS%20Support%20Ticket'; e?.preventDefault(); }} title='Click This Link' content='Click This Link' />
        </div>
        <p><b> OR </b></p>
        <p className='text-center'>Send an email directly to:</p>
        <p><b>rrs@usace.army.mil</b></p>
      </div>
    </ModalContent >
  );
};

export default HelpModal;