import React, { useState } from 'react';
import { connect } from 'redux-bundler-react';
import { Button } from '@trussworks/react-uswds';

import Dropdown from '../dropdown';
import Icon from '@mdi/react';
import { mdiMenuDown, mdiChevronDown, mdiChevronUp } from '@mdi/js';
import NavItem from './navItem';
import ProfileModal from '../../app-pages/Forms/components/modals/profile';
import LinkButton from '../link/linkButton.jsx';
import { FileTypes, ResourceFiles } from '../../utils/enums.tsx';
import { mdiInformation } from '@mdi/js';

import usaceLogo from '../../styles/images/usace-logo-color.svg';
import rrsLogo from '../../styles/images/RRS-Black-Red.png';

import { classArray } from '../../utils/helpers.jsx';
import { ProfileRoles } from '../../utils/enums.tsx';

import './navigation.scss';
import MaintenanceBanner from '../maintenance-banner/MaintenanceBanner.jsx';

const NavBar = connect(
  'doAuthLogin',
  'doAuthLogout',
  'doModalOpen',
  'doDownloadFile',
  'doFetchBannerInfo',
  'selectAuthIsLoggedIn',
  'selectUserProfileParams',
  'selectUserProfileData',
  'selectUserIsDeveloper',
  ({
    doAuthLogin,
    doAuthLogout,
    doModalOpen,
    doDownloadFile,
    doFetchBannerInfo,
    authIsLoggedIn,
    userProfileParams,
    userProfileData,
    userIsDeveloper,
  }) => {
    const [show, setShow] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const navClasses = classArray([
      'navbar',
      'navbar-expand-xl',
      'navbar-light',
      'navbar-bg-color',
      'seperator',
    ]);

    const navCollapseClasses = classArray([
      'collapse',
      'navbar-collapse',
      show && 'show',
    ]);

    const toggleShow = () => setShow(!show);
    const toggleExpand = () => setExpanded(!expanded);

    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        doAuthLogin();
      }
    };

    return (
      <div className='sticky-top'>
        <nav className={navClasses}>
          <div className={`container-fluid ${authIsLoggedIn ? 'container-fluid-loggedin' : ''}`}>
            <div className='d-flex flex-grow-1'>
              <div className='navbar-brand ml-4'>
                <a href={authIsLoggedIn ? '/home' : '/'} title='Regulatory Request System - Home'>
                  <img
                    className='usace-logo'
                    alt='USACE Regulatory Logo'
                    src={usaceLogo}
                    height={65}
                  />
                  <img
                    className='rrs-logo'
                    alt='RRS Logo'
                    src={rrsLogo}
                    height={39}
                  />
                </a>
              </div>
            </div>
            {/* <div className='d-flex justify-content-center align-items-center'>
            <span style={{ color: 'red', textAlign: 'center' }}>
              <b>******
                THIS SYSTEM IS FOR INFORMATIONAL PURPOSES ONLY! <br /> REQUESTS SUBMITTED USING THIS SYSTEM WILL NOT BE ACTED UPON BY USACE! <br />
                SUBMIT BETA FEEDBACK:
              </b>
              <a href='https://play.apps.appsplatform.us/play/e/default-fc4d76ba-f17c-4c50-b9a7-8f3163d27582/a/a37afa95-4250-4d76-a45d-aac47b78d130?tenantId=fc4d76ba-f17c-4c50-b9a7-8f3163d27582&source=portal' target='_blank'> USACE INTERNAL FEEDBACK </a>
              {authIsLoggedIn && (
                <>
                  <b> or </b>
                  <a href='' onClick={() => doModalOpen(ExternalFeedbackModal)}>EXTERNAL FEEDBACK</a>
                </>)}
              <b>******</b>
            </span>
          </div> */}

            {!authIsLoggedIn ? (
              <>
                <div className='d-flex flex-grow-1 justify-content-end'>
                  <button
                    className='navbar-toggler'
                    type='button'
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                    onClick={() => toggleShow()}
                  >
                    <span className='navbar-toggler-icon' />
                  </button>
                </div>
                <div className={navCollapseClasses}>
                  <ul className='navbar-nav ml-auto'>
                    <NavItem className='usa-nav-link' href={['/help']}>Help</NavItem>
                  </ul>
                  <Button className='ml-3 mr-5' onClick={() => doAuthLogin()} onKeyUp={handleKeyPress} tabIndex={0}>Sign In</Button>
                </div>
              </>
            ) : (<>
              <div className='d-flex flex-grow-1 justify-content-end'>
                <button
                  className='navbar-toggler'
                  type='button'
                  aria-expanded='false'
                  aria-label='Toggle navigation'
                  onClick={() => toggleShow()}
                >
                  <span className='navbar-toggler-icon' />
                </button>
              </div>
              <div className={navCollapseClasses}>
                <ul className='navbar-nav ml-auto'>
                  <>
                    <NavItem href={['/home']}>Home</NavItem>
                    <NavItem href={['/']}>Request Page</NavItem>
                    {(userProfileData?.role !== ProfileRoles.USACERegulator && userProfileData?.role !== ProfileRoles.ExternalAgency) && (
                      <NavItem href={['/dashboard']}>My Dashboard</NavItem>
                    )}
                    <NavItem href={['/help']}>Help</NavItem>
                    {import.meta.env.VITE_ENVIRONMENT !== 'prod' && <NavItem href={['/updates']}>RRS Updates</NavItem>}
                    {userIsDeveloper === true && <NavItem href={['/developer']} handler={() => doFetchBannerInfo()}>Developer</NavItem>}
                    {/* <li className='nav-item'>
                      <Dropdown.Menu
                        withToggleArrow={false}
                        menuClass='dropdown-menu-left'
                        buttonClass='btn-small p-0 nav-dropdown-button'
                        buttonContent={(<span className='nav-link'>{<>Developer<Icon path={mdiMenuDown} size={'24px'} /></>}</span>)}>
                        <Dropdown.Item href='/developer/generatePDFs'>Generate PDFs</Dropdown.Item>
                      </Dropdown.Menu>
                    </li> */}
                    <li className='nav-item'>
                      <Dropdown.Menu
                        withToggleArrow={false}
                        menuClass='dropdown-menu-left'
                        buttonClass='btn-small p-0 nav-dropdown-button'
                        buttonContent={(
                          <span className='nav-link'>
                            {userProfileData?.role && (
                              <>Logged in as: <i>{(userProfileParams?.firstName && userProfileParams?.lastName) ? `${userProfileParams.firstName} ${userProfileParams.lastName}` : `${userProfileParams.email}`}</i> </>
                            )}
                            ({userProfileData?.role && <>{userProfileData.role}) <Icon path={mdiMenuDown} size={'24px'} /></>}
                          </span>
                        )}>
                        <Dropdown.Item onClick={() => doModalOpen(ProfileModal, { edit: true })}>Profile</Dropdown.Item>
                        {/* <Dropdown.Item onClick={() => doModalOpen(ProjectModal)}>Projects</Dropdown.Item> */}
                        <Dropdown.Item onClick={() => {
                          doAuthLogout();
                        }}>Logout</Dropdown.Item>
                      </Dropdown.Menu>
                    </li>
                  </>
                </ul>
              </div></>)}
          </div >
        </nav >
        <div className={`secondary-bar w-100 ${expanded ? 'expanded' : ''}`}>
          <span className='announcement-banner align-middle'>
            <Icon className='mb-1 mr-1' path={mdiInformation} color='#FFF' size={'14px'} />
            <span>
              The Regulatory Request System (RRS) is currently operating as a beta version &nbsp;
              <span style={{ color: '#58A6FF', textDecoration: 'underline', cursor: 'pointer' }} onClick={toggleExpand} tabIndex={0}>Learn More</span>
              {expanded ? <Icon path={mdiChevronUp} size={'14px'} /> : <Icon path={mdiChevronDown} size={'14px'} />}
            </span>
          </span>
          {expanded && (
            <div className='additional-text'>
              <p className='mb-1'>through this calendar year as joint permit application forms are being incorporated into the system. <br /> For <LinkButton style={{ color: '#58A6FF' }} onClick={() => doDownloadFile(ResourceFiles.DistrictList, FileTypes.Resource)} title={'districts [PDF, 1 page]'} content={'districts [PDF, 1 page]'} /> with joint applications processes, please refer to <LinkButton style={{ color: '#58A6FF' }} onClick={() => doDownloadFile(ResourceFiles.DistrictSpecificInfo, FileTypes.Resource)} title={'district specific information [PDF, 4 pages]'} content={'district specific information [PDF, 4 pages]'} />.</p>

              <p className='mb-1'>The RRS can provide an avenue for the public to submit requests for both pre-application meetings, jurisdictional determinations, and individual and nationwide permits. <br />This beta version will continue to be enhanced based on user feedback throughout this year. </p>
            </div>
          )}
        </div>
        <MaintenanceBanner />
      </div>
    );
  }
);
export default NavBar;
