import React, { useCallback, useEffect } from 'react';
import { connect } from 'redux-bundler-react';

import FocusTrap from 'focus-trap-react';

const Modal = connect(
  'doModalClose',
  'selectModalContent',
  'selectModalProps',
  ({
    doModalClose,
    modalContent: ModalContent,
    modalProps,
    closeWithEscape = false,
  }) => {
    const closeModalWithEscape = useCallback((e) => {
      if (e.keyCode === 27) doModalClose();
    }, [doModalClose]);

    useEffect(() => {
      if (closeWithEscape) {
        document.addEventListener('keydown', closeModalWithEscape);

        if (!ModalContent) {
          document.removeEventListener('keydown', closeModalWithEscape);
        }
      }
    }, [ModalContent, closeWithEscape, closeModalWithEscape]);

    return (
      !!ModalContent && (
        <FocusTrap focusTrapOptions={{ initialFocus: false }}>
          <div>
            <div
              onClick={doModalClose}
              className='modal fade show'
              aria-modal={true}
              role='dialog'
              style={{ display: 'block', backgroundColor: '#ccc', opacity: 0.5 }}
            />
            <div className='modal fade show' style={{ display: 'block' }} tabIndex={0}>
              <ModalContent {...modalProps} />
            </div>
          </div>
        </FocusTrap>
      )
    );
  }
);

export default Modal;
