import { createRouteBundle } from 'redux-bundler';
import Dashboard from '../app-pages/Dashboard';
import Home from '../app-pages/Home';
import Forms from '../app-pages/Forms';
import NotFound from '../app-pages/NotFound';
import Resources from '../app-pages/Resources';
import Updates from '../app-pages/Updates';
import ContentLayout from '../app-pages/Resources/components/ContentLayout';
import Help from '../app-pages/Help';
import DeveloperDashboard from '../app-pages/Developer/DeveloperDashboard';

// const base = import.meta.env.VITE_URL_BASE_PATH ?? '';
const base = '';

export default createRouteBundle(
  {
    [`${base}`]: Home,
    [`${base}/`]: Home,
    [`${base}/dashboard`]: Dashboard,
    [`${base}/forms`]: Forms,
    [`${base}/forms/:id`]: Forms,
    [`${base}/home`]: Resources,
    [`${base}/home/:resource`]: ContentLayout,
    [`${base}/updates`]: Updates,
    [`${base}/help`]: Help,
    [`${base}/developer`]: DeveloperDashboard,
    '*': NotFound,
  },
  {
    routeInfoSelector: 'selectRelativePathname',
  }
);
