import React from 'react';

import SideBarItem from './sideBarItem';

import './sideBar.scss';

/**
 * Sidebar with content customization.
 * 
 * @param {object} content - [{ header: '', links: [{ href: [''], text: '' }], content: htmlElement }]
 */

const renderSection = (item, key) => (
  <div key={key}>
    <div style={{ paddingTop: '5%', margin: '5%' }}>
      <h6>{item.header}</h6>
      <hr></hr>
    </div>
    <ul className='sidebar-nav ml-auto'>
      {item.links ? item.links.map((link, index) => (
        <SideBarItem key={index} href={link.href}>{link.text}</SideBarItem>
      )) : item.content}
    </ul>
  </div>
);

const SideBar = ({
  content,
}) => (
  <>
    <div className='sidebar-container'>
      {content.map((item, index) => (
        renderSection(item, index)
      ))}
    </div>
  </>
);

export default SideBar;
