import React from 'react';
import { connect } from 'redux-bundler-react';

import Button from '../../../../app-components/button/button';
import Card from '../../../../app-components/card';
import ExternalLinkConfirmationModal from './ExternalLinkConfirmationModal';
import Icon from '@mdi/react';
import { mdiOpenInNew } from '@mdi/js';

import '../../resources.scss';

const NewSectionCard = connect(
  'doSecondaryModalOpen',
  ({
    doSecondaryModalOpen,
    url,
    title,
    content,
    buttonText,
    buttonTitle,
    onClick = () => { },
    isDisabled,
    className,
    popupText,
    external
  }) => {

    const openPage = () => {
      window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleClick = e => {
      if (url && external) {
        doSecondaryModalOpen(ExternalLinkConfirmationModal, { href: url });
      } else if (url && !external) {
        openPage();
      } else {
        onClick();
      }
    };

    const getButtonText = (text) => (
      <>
        {text}{external && <Icon path={mdiOpenInNew} size={'16px'} />}
      </>
    );

    return (
      <>
        <Card className={`card-style ${className} ${isDisabled ? 'form-btn-disabled' : ''}`}>
          <div className='static-container' style={{ height: '200px' }}>
            <h6 className='mt-3'>{title}</h6>
            <hr className='header-border' />
            {popupText && <div className='button-text popup center'>{popupText}</div>}
            <p style={{ fontSize: 'small' }} className='card-text'>{content}</p>
            {buttonText && (
              <div style={{ float: 'right' }}>
                <Button
                  variant='primary'
                  text={getButtonText(buttonText)}
                  title={buttonTitle}
                  handleClick={handleClick}
                  isDisabled={isDisabled}
                  isOutline
                />
              </div>
            )}
          </div>
        </Card>
      </>
    );
  });

export default NewSectionCard;
