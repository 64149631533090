import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'redux-bundler-react';
import LinkButton from '../link/linkButton';
import './errorSummary.scss';

import Icon from '@mdi/react';
import { mdiChevronUp, mdiChevronDown } from '@mdi/js';

const ErrorSummary = connect(
  'doSetActiveStep',
  'selectFormValidation',
  ({
    doSetActiveStep,
    formValidation,
    errors,
    type = 'base',
    modalID,
    sectionNo = 0,
  }) => {
    const [isExpanded, setIsExpanded] = useState(true);

    const genericErrorArray = [];

    for (const [key, value] of Object.entries(errors)) {
      if (value.message) {
        genericErrorArray.push({ id: key, message: value.message, ref: value.ref });
      }
    }

    const steps = formValidation && Object?.values(formValidation);
    const sectionErrorArray = steps?.map((step, index) => ({ ...step, originalIndex: index }))?.filter(step => step.validity === false && step.section !== 'Certify, Sign, and Submit');

    const genericErrorCount = genericErrorArray?.length;
    const sectionErrorCount = sectionErrorArray?.length;

    const calculateSuffix = (type) => {
      switch (type) {
        case 'modal':
          return '_modal';
        case 'form':
          return 'form';
        default:
          return `${sectionNo}`;
      }
    };

    const typeSuffix = calculateSuffix(type);


    const scrollById = (errorID, modalID) => {
      if (modalID) {
        const modal = document.getElementById(modalID);
        modal.querySelector(`#${errorID}`).scrollIntoView({ behavior: 'smooth', block: 'center' });
        modal.querySelector(`#${errorID}`).focus();
      }
      else {
        document.getElementById(errorID).scrollIntoView({ behavior: 'smooth', block: 'center' });
        document.getElementById(errorID).focus();
      }
    };

    const boldError = useCallback((e) => {
      const targetID = `${e?.target?.id}_li${typeSuffix}`;

      const target = document?.getElementById(`error_list_summary${typeSuffix}`)?.children;
      const elements = target && Array?.from(target);

      elements?.forEach((element) => {
        if (element?.id === targetID) {
          document.getElementById(element.id).style.fontWeight = 'bold';
        }
        else if (document.getElementById(element?.id)?.getAttribute('style')) {
          document.getElementById(element.id).style.fontWeight = 'normal';
        }
      }
      );
    }, [typeSuffix]);

    const generateErrorHeader = (genericErrorCount, sectionErrorCount, isExpanded, type) => {

      switch (type) {
        case 'modal': {
          if (genericErrorCount > 0 && isExpanded) {
            return `To complete this modal, correct the errors below (${genericErrorCount})`;
          }
          else if (genericErrorCount > 0 && !isExpanded) {
            return `This modal contains errors (${genericErrorCount}), expand to view error list`;
          }
          else {
            return 'This modal is complete';
          }
        }
        case 'form': {
          if (sectionErrorCount > 0 && isExpanded) {
            return `To complete this request, complete the section(s) below (${sectionErrorCount}):`;
          }
          else if (sectionErrorCount > 0 && !isExpanded) {
            return `This request is incomplete, expand to view incomplete section list (${sectionErrorCount})`;
          }
          else if (genericErrorCount > 0 && isExpanded) {
            return `To complete this section, correct the errors below (${genericErrorCount})`;
          }
          else if (genericErrorCount > 0 && !isExpanded) {
            return `This section contains errors (${genericErrorCount}), expand to view error list`;
          }
          else {
            return 'This section is complete';
          }
        }
        default: {
          if (genericErrorCount > 0 && isExpanded) {
            return `To complete this section, correct the errors below (${genericErrorCount})`;
          }
          else if (genericErrorCount > 0 && !isExpanded) {
            return `This section contains errors (${genericErrorCount}), expand to view error list`;
          }
          else {
            return 'This section is complete';
          }
        }
      }
    };

    useEffect(() => {
      const handleBlur = (e) => {
        boldError(e);
      };

      // Attach the event listener
      document.addEventListener('focus', handleBlur, true);

      // Clean up the event listener on component unmount
      return () => {
        document.removeEventListener('focus', handleBlur, true);
      };
    }, [boldError]);

    const genericErrors = genericErrorArray?.map((error, id) => (<li key={id} id={`${error?.id}_li${typeSuffix}`}>
      <LinkButton onClick={() => {
        error?.id && scrollById(error?.id, modalID);
      }} content={error?.message} />
    </li>));

    const sectionErrors = sectionErrorArray?.map((section) => (<li key={section?.originalIndex} id={`${section?.originalIndex}_li${typeSuffix}`}>
      <LinkButton onClick={() => {
        doSetActiveStep(section?.originalIndex);
      }} content={section?.section} />
    </li>));


    return (
      (type !== 'modal' || (type === 'modal' && (genericErrorCount > 0 || (type === 'form' && sectionErrorCount > 0)))) && <div className={`${type !== 'modal' && 'mt-3'} errorSummary`}>
        <div className={`usa-alert usa-alert${(genericErrorCount > 0 || (type === 'form' && sectionErrorCount > 0)) ? '--error' : '--success'}`} id='error_list' role='alert'>
          {(genericErrorCount >= 1 || (type === 'form' && sectionErrorCount >= 1)) &&
            <div tabIndex={0} className='errorSummaryChevron' onClick={() => setIsExpanded(prev => !prev)} onKeyUp={e => { e.key === 'Enter' && setIsExpanded(prev => !prev); }}>
              <Icon size={'36px'} path={isExpanded ? mdiChevronUp : mdiChevronDown} />
            </div>}
          <div className='usa-alert__body' >
            <h4 className='usa-alert__heading'>{generateErrorHeader(genericErrorCount, sectionErrorCount, isExpanded, type)}</h4>
            {(genericErrorCount > 0 || (type === 'form' && sectionErrorCount > 0)) && isExpanded && <div className='usa-alert__text error-list-container' >
              <ol id={`error_list_summary${typeSuffix}`}>
                {(type === 'form' && sectionErrorCount > 0) ? sectionErrors : genericErrors}
              </ol>
            </div>}
          </div >
        </div >
      </div >
    );
  });

export default ErrorSummary;

