import React from 'react';
import { SummaryBox, SummaryBoxContent, SummaryBoxHeading } from '@trussworks/react-uswds';

import '../../home.scss';

const HeaderCard = ({ isHero }) => (
  <div className={`p-2 ${isHero ? 'center' : ''}`}>
    <SummaryBox>
      <SummaryBoxHeading headingLevel='h1'>Regulatory Request System (RRS)</SummaryBoxHeading>
      <SummaryBoxContent>The purpose of this system to accept electronic submittals of information, Department of the Army permit applications, requests for jurisdictional determinations, and comments on projects
        from the regulated public and resource agencies in one portal. Applicants or agents can also check on the status of their requests. Currently you can only submit requests for preapplication
        meetings, Jurisdictional determinations, Nationwide Permits and view general information about the Regulatory Program.</SummaryBoxContent>
    </SummaryBox>
  </div>
);

export default HeaderCard;
