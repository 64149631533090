import React from 'react';
import { ModalContent, ModalHeader, ModalFooter, } from '../../../../app-components/secondary-modal';

const statusModal = ({
  msg,
  status,
  onSave,
  saveText = 'OK',
  cancelText
}) => (
  <ModalContent>
    <ModalHeader title={status === 'Success' ? 'SUCCESS!' : status === 'Failed' ? 'ERROR!' : status} hasCloseButton />
    <section className='modal-body' style={{ maxHeight: '600px', overflowY: 'auto' }}>
      <div className='container-fluid'>
        {msg}
      </div>
    </section>
    <ModalFooter
      saveText={saveText}
      showCancelButton={cancelText}
      onSave={onSave}
      cancelText={cancelText}
    />
  </ModalContent>);
export default statusModal;
