import React from 'react';
import { connect } from 'redux-bundler-react';
import Icon from '@mdi/react';
import { mdiHelpCircle, mdiAlertCircle } from '@mdi/js';

/**
 * @param {string} title - The title of the Modal 
 * @param {boolean} hasCloseButton - Whether or not there should be a button to clear the input field, default `false`
 */
const ModalHeader = connect(
  'doSecondaryModalClose',
  ({
    doSecondaryModalClose,
    title = '',
    hasCloseButton = false,
    hasIcon = false,
    isError = false,
  }) => (
    <header className='modal-header'>
      <h5 className='modal-title'>{title}</h5>
      {hasIcon && <Icon path={isError ? mdiAlertCircle : mdiHelpCircle} color={isError ? '#D43929' : '#005ea2'} size={'24px'} className='help-icon' />}
      {hasCloseButton && <span className='close pointer text-primary' onClick={doSecondaryModalClose}>&times;</span>}
    </header>
  )
);

export default ModalHeader;
