import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'redux-bundler-react';

import { isValidASCII, sanitizeASCII } from '../../../utils/helpers';
import { ErrorMessages } from '../../../utils/enums.tsx';
import genericSecondaryModal from '../../../app-pages/Forms/components/modals/genericSecondaryModal';

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const ExplanationTableCell = connect(
  'doSecondaryModalOpen',
  ({
    doSecondaryModalOpen,
    getValue,
    row,
    column,
    table,
    cell,
  }) => {
    const columnMeta = column.columnDef.meta;
    const tableMeta = table.options.meta;
    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);
    const [approvalType, setApprovalType] = useState();
    const rowApprovalType = useMemo(() => row.getValue('approvalType'), [row]);
    const debouncedUpdateRef = useRef();

    useEffect(() => {
      debouncedUpdateRef.current = debounce((newValue) => {
        if (tableMeta?.updateData) {
          tableMeta?.updateData(row.index, column.id, columnMeta?.type === 'number' ? Number(newValue) : newValue ?? newValue);
        }
      }, 500);
    }, [row.index, column.id, tableMeta?.updateData, columnMeta?.type, tableMeta]);

    const updateValue = useCallback((newValue) => {
      debouncedUpdateRef.current(newValue);
    }, []);

    useEffect(() => {
      rowApprovalType && setApprovalType(rowApprovalType);
    }, [rowApprovalType]);

    // Clear explanation field if approval type is not 'Other'
    useEffect(() => {
      if (approvalType !== 'Other' && approvalType !== undefined) {
        setValue('');
        updateValue('');
      }
    }, [approvalType, setValue, updateValue]);

    const handleChange = (e) => {
      if (approvalType === 'Other') {
        setValue(e?.target?.value);
        updateValue(e?.target?.value);
      }
    };

    // Handling special characters
    useEffect(() => {
      if (value && typeof value === 'string') {
        if (isValidASCII(value)) {
          setValue(value);
          updateValue(value);
        } else {
          // Optionally handle invalid input here, such as warning the user
          const msg = ErrorMessages.InvalidCharacters;
          doSecondaryModalOpen(genericSecondaryModal, { title: 'Invalid Characters', msg: msg });
          setValue(sanitizeASCII(value));
          updateValue(sanitizeASCII(value));
        }
      };
    }, [value, setValue, updateValue, doSecondaryModalOpen]);

    return (
      <input
        id={cell.id}
        style={{ width: '100%', cursor: (columnMeta?.readOnly || approvalType !== 'Other') ? 'not-allowed' : 'auto' }}
        value={value ?? ''}
        placeholder={approvalType !== 'Other' ? 'N/A' : ''}
        onChange={handleChange}
        type='text'
        required={columnMeta?.required}
        disabled={approvalType !== 'Other' || columnMeta?.readOnly}
        maxLength={4000}
      />
    );
  });

export default ExplanationTableCell;