import React from 'react';
import { connect } from 'redux-bundler-react';

import { ModalContent, ModalFooter, ModalHeader } from '../../../../app-components/modal';

const NavbarNavigateWarningModal = connect('doModalClose', 'doUpdateRelativeUrl', ({ doModalClose, doUpdateRelativeUrl, url, openInNewTab }) => {

  const isInternal = openInNewTab;

  const onSave = () => {
    doModalClose();
    if (isInternal) {
      window.open(url, '_blank');
    } else {
      doUpdateRelativeUrl(url);
    }
  };

  return (<ModalContent size='md'>
    <ModalHeader title={isInternal ? 'Internal Resource' : 'Warning!'} hasCloseButton />
    <section className='modal-body'>
      {isInternal ? (
        <h6>You are now being redirected to an internal page of the application in a new tab.</h6>
      ) : (
        <h6>You are about to navigate away from the forms application, any unsaved work will be lost! <br /> <br /> Press Cancel to go back and save progress, or OK to proceed.</h6>
      )}
    </section>
    <ModalFooter
      onSave={onSave}
      onCancel={() => doModalClose()}
      saveText='OK'
      customSaveLogic
    />
  </ModalContent>
  );
});

export default NavbarNavigateWarningModal;