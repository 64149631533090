import React from 'react';
import { ModalContent, ModalHeader, ModalFooter } from '../../../../app-components/secondary-modal';
import Icon from '@mdi/react';
import { mdiOpenInNew } from '@mdi/js';

const ExternalLinkConfirmationModal = ({ href }) => {

  const openPage = () => window.open(href, '_blank', 'noopener,noreferrer');

  return (
    <ModalContent>
      <ModalHeader title={<>External Resource<Icon path={mdiOpenInNew} size={'16px'} /></>} hasCloseButton />
      <section className='modal-body'>
        <div className='container-fluid'>
          <p>You are now being redirected to an external website in a new tab.</p>
        </div>
      </section>
      <ModalFooter
        onSave={() => openPage()}
        saveText='Continue'
      />
    </ModalContent>
  );
};

export default ExternalLinkConfirmationModal;
