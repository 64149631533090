import React, { useEffect } from 'react';

import { ModalContent, ModalFooter, ModalHeader } from '../../../../app-components/modal';
import ContactFields from '../../../../app-components/new-inputs/contactFieldsnew';

import { connect } from 'redux-bundler-react';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import { ContactTypes } from '../../../../utils/enums.tsx';
import { addressRegex, addressTwoRegex, emailRegex, phoneRegex, countryCodeRegex, usPhoneRegex, phoneExtRegex, cityRegex, zipCodeRegex } from '../../../../utils/regex.jsx';
import ErrorSummary from '../../../../app-components/error-summary/ErrorSummary.jsx';

const schema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  address: yup.string().required('Address is required').matches(addressRegex, 'Please enter a valid address. Only letters, numbers, spaces, commas, periods, hyphens, apostrophes, forward slashes, and hash symbols are allowed.'),
  addressTwo: yup.string().nullable().matches(addressTwoRegex, 'Please enter a valid address. Only letters, numbers, spaces, commas, periods, hyphens, apostrophes, forward slashes, and hash symbols are allowed.'),
  city: yup.string().matches(cityRegex, 'City is invalid').required('City is required'),
  state: yup.string().when('country', { is: 'US', then: () => yup.string().required('Please select a state'), otherwise: () => yup.string().required('Please select a Region') }),
  zipcode: yup.string().nullable().when('country', { is: 'US', then: () => yup.string().matches(zipCodeRegex, 'Zipcode is invalid') }),
  country: yup.string().required('Please select a country'),
  phoneOneType: yup.string().required('Phone One Type is required'),
  phoneOneCountryCode: yup.string().required('Country Code is required').matches(countryCodeRegex, 'Country Code is invalid'),
  phoneOne: yup.string().when('phoneOneCountryCode', {
    is: '1',
    then: () => yup.string().matches(usPhoneRegex, 'Phone Number One is invalid'),
    otherwise: () => yup.string().matches(phoneRegex, 'Phone Number One is invalid')
  }).required('Phone Number One is required'),
  phoneOneExtension: yup.string().nullable().when('phoneOneType', { is: 'Business', then: () => yup.string().matches(phoneExtRegex, 'Phone Ext is invalid') }),
  showPhoneTwo: yup.boolean().nullable(),
  phoneTwoType: yup.string().nullable().when('showPhoneTwo', { is: true, then: () => yup.string().required('Phone Two Type is required') }),
  phoneTwoCountryCode: yup.string().nullable().when('showPhoneTwo', { is: true, then: () => yup.string().required('Country Code is required').matches(countryCodeRegex, 'Country Code is invalid') }),
  phoneTwo: yup.string().nullable().when(['phoneTwoCountryCode', 'showPhoneTwo'], {
    is: (countryCode, showPhoneTwo) => countryCode === '1' && showPhoneTwo === true,
    then: () => yup.string().required('Phone Number Two is required').matches(usPhoneRegex, 'Phone Number Two is invalid'),
    otherwise: () => yup.string().nullable().when(['phoneTwoCountryCode', 'showPhoneTwo'], {
      is: (countryCode, showPhoneTwo) => countryCode !== '1' && showPhoneTwo === true,
      then: () => yup.string().required('Phone Number Two is required').matches(phoneRegex, 'Phone Number Two is invalid')
    })
  }),
  phoneTwoExtension: yup.string().nullable().when(['showPhoneTwo', 'phoneTwoType'], { is: (show, type) => show && type === 'Business', then: () => yup.string().nullable().matches(phoneExtRegex, { message: 'Phone Extension is invalid', excludeEmptyString: true }) }),
  faxPhone: yup.string().matches(phoneRegex, { message: 'Fax Phone is invalid', excludeEmptyString: true }).nullable(),
  emailAddress: yup.string().matches(emailRegex, { message: 'Email Address is invalid', excludeEmptyString: true }).nullable()
});

const AddPropertyOwnerModal = connect(
  'doModalClose',
  ({
    doModalClose,
    edit,
    data,
    setRowData,
    rowData,
    id,
    isReadOnly
  }) => {

    const formatParcelNumbersOutput = data => data?.map(obj => ({ label: obj.parcelNumber, value: obj.parcelNumber }));
    const defaultValues = edit ? {
      contactType: ContactTypes.PropertyOwner,
      parcels: formatParcelNumbersOutput(data?.parcels) ? formatParcelNumbersOutput(data.parcels) : [],
      address: data?.address ?? null,
      addressTwo: data?.addressTwo ?? null,
      city: data?.city ?? null,
      company: data?.company ?? null,
      emailAddress: data?.emailAddress ?? null,
      faxPhone: data?.faxPhone ?? null,
      firstName: data?.firstName ?? null,
      lastName: data?.lastName ?? null,
      middleName: data?.middleName ?? null,
      phoneOne: data?.phoneOne ?? null,
      phoneOneType: data?.phoneOneType ?? null,
      phoneOneCountryCode: data?.phoneOneCountryCode ?? '1',
      phoneOneExtension: data?.phoneOneExtension ?? null,
      phoneTwo: data?.phoneTwo ?? null,
      phoneTwoType: data?.phoneTwoType ?? null,
      phoneTwoCountryCode: data?.phoneTwoCountryCode ?? null,
      phoneTwoExtension: data?.phoneTwoExtension ?? null,
      salutation: data?.salutation ?? null,
      state: data?.state ?? null,
      zipcode: data?.zipcode ?? null,
      country: data?.country ?? 'US',
    } : {
      contactType: ContactTypes.PropertyOwner,
      country: 'US'
    };

    const methods = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema), mode: 'onBlur' });
    const { formState: { errors, isValid }, setFocus, watch, getValues, trigger } = methods;

    const watchParcels = watch('parcels');

    const onSave = () => {
      if (isValid) {
        const formatParcelNumbers = () => watchParcels.map(obj => ({ parcelNumber: obj.value }));
        const contactFields = getValues();
        const contactFieldsWithIDs = {
          ...contactFields,
          contactID: data?.contactID ?? undefined,
          requestID: data?.requestID ?? undefined,
          version: data?.version ?? undefined,
          contactType: ContactTypes.PropertyOwner,
          createdBy: data?.createdBy ?? undefined,
          phoneOne: contactFields?.phoneOne?.replace(/\D/g, '') ?? undefined,
          phoneTwo: contactFields?.phoneTwo?.replace(/\D/g, '') ?? undefined,
        };
        if (edit) {
          let dataArr = [...rowData];
          const index = data.contactID ? dataArr.findIndex(el => el.contactID === id) : id;
          contactFieldsWithIDs.parcels = watchParcels ? formatParcelNumbers() : [];
          dataArr[index] = contactFieldsWithIDs;
          setRowData(dataArr);
        }
        else {
          contactFieldsWithIDs.parcels = watchParcels ? formatParcelNumbers() : [];
          setRowData((rowData) => [...rowData, contactFieldsWithIDs]);
        }
        doModalClose();
      }
      else {
        trigger();
        toast.error('Please fill out all required fields!');
      }
    };

    useEffect(() => {
      if (errors?.[Object.keys(errors)[0]]?.['ref']?.focus) {
        errors?.[Object.keys(errors)[0]]?.['ref']?.focus();
      }

      setFocus(errors?.[Object.keys(errors)[0]]?.['ref']?.['id']);
    }, [errors, setFocus]);

    return (
      <FormProvider {...methods}>
        <ModalContent size='lg'>
          <ModalHeader title={`${edit ? 'Edit' : 'Add'} Property Owner`} hasCloseButton />
          {errors && <ErrorSummary errors={errors} modalID='addPropertyOwnerModal' type='modal' />}
          <section className='modal-body' id='addPropertyOwnerModal'>
            <div className='container-fluid'>
              <ContactFields type='Property Owner' isReadOnly={isReadOnly} requireEmail={false} />
            </div>
          </section>
          <ModalFooter
            showCancelButton={!isReadOnly}
            showSaveButton={!isReadOnly}
            saveText={edit ? 'Apply Changes' : 'Add'}
            customClosingLogic
            onSave={onSave}
          />
        </ModalContent>
      </FormProvider >
    );
  }
);

export default AddPropertyOwnerModal;
