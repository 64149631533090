import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useErrorFocus from '../../../../customHooks/useErrorFocus.jsx';
import { connect } from 'redux-bundler-react';
import { createColumnHelper } from '@tanstack/react-table';
import { Alert } from '@trussworks/react-uswds';

import { EditCell } from '../../../../app-components/table/tableCellComponents/EditCell.jsx';
import { TableCell } from '../../../../app-components/table/tableCellComponents/TableCell.jsx';
import { PermitDateTableCell } from '../../../../app-components/table/tableCellComponents/PermitDateTableCell.jsx';

import FieldHeader from '../../components/Form/FieldHeader.jsx';
import statusModal from '../../components/modals/statusModal.jsx';
import InputField from '../../../../app-components/new-inputs/inputField.jsx';
import TextAreaInput from '../../../../app-components/new-inputs/textAreaInput.jsx';
import SelectInput from '../../../../app-components/new-inputs/selectInput.jsx';
import CreatableSelectInput from '../../../../app-components/new-inputs/creatableSelectInput.jsx';
import USWDSCheckboxInput from '../../../../app-components/uswds/uswdsCheckboxInput.jsx';
import TanStackTableNew from '../../../../app-components/table/TanStackTableNew.jsx';
import ExternalLink from '../../../../app-components/link/externalLink.jsx';
import Tooltip from '../../../../app-components/tooltip/tooltip.jsx';
import Accordion from '../../../../app-components/accordion/index.js';
import MultiSelectInput from '../../../../app-components/new-inputs/multiSelectInput.jsx';
import ExplanationTableCell from '../../../../app-components/table/tableCellComponents/ExplanationTableCell.jsx';
import ErrorSummary from '../../../../app-components/error-summary/ErrorSummary.jsx';

// import PermitsPileDriving from './permitsGeneralProjectInfoComponents/permitsPileDriving.jsx';
import PermitsDredgeProject from './permitsGeneralProjectInfoComponents/permitsDredgeProject.jsx';
// import PermitsShorelineStabilization from './permitsGeneralProjectInfoComponents/permitsShorelineStabilization.jsx';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import { ormErrorMsg } from '../_helper.jsx';
import { decimalNumberRegex, integerRegex, latRegex, lngRegex, setNumberValue } from '../../../../utils/regex.jsx';
import { natureOfActivityTooltipContent } from '../../../Resources/components/_shared/helper.jsx';

import { ApplicableStatutoryAuthorities } from '../../../../utils/enums.tsx';
import { dateBeforeB, filterNullEmptyObjects, formatCoordFlt, isDateValid, isValidORMNumber } from '../../../../utils/helpers.jsx';

import '../../../../styles/index.scss';

export const PermitsFormGeneralProjectInformationMetadata = {
  sectionName: 'General Project Information',
  isSection: true,
  lastSection: false,
  firstSection: false,
};

const currentEnv = import.meta.env.VITE_ENVIRONMENT;



const PermitsFormGeneralProjectInformation = connect(
  'doUpdateSectionValidity',
  'doUpdatePermitRequest',
  'doUpdatePermitRequestSub',
  'doClearPileDataByUse',
  'doUpdateRequestData',
  'doSecondaryModalOpen',
  'selectRequestFormData',
  'selectGPPermitTypes',
  'selectPermitData',
  'selectSteps',
  'selectActiveStep',
  'selectRequestAPIData',
  ({
    doUpdateSectionValidity,
    doUpdatePermitRequest,
    doUpdatePermitRequestSub,
    doClearPileDataByUse,
    doUpdateRequestData,
    doSecondaryModalOpen,
    requestFormData,
    gPPermitTypes,
    permitData,
    steps,
    activeStep,
    requestAPIData,
    isReadOnly,
    stepNo
  }) => {
    const [otherNWPData, setOtherNWPData] = useState([]);
    const [otherNWPKey, setOtherNWPKey] = useState(0);
    const [tableInvalid, setTableInvalid] = useState(true);
    const [rowErrors, setRowErrors] = useState(false);
    const columnHelper = createColumnHelper();
    const [dredgeProjectData, setDredgeProjectData] = useState({});
    const thisSectionStepStatus = useMemo(() => steps.find(step => step.id === stepNo)?.touched, [steps, stepNo]);
    // const [esaConsultantInfoData, setESAConsultantInfoData] = useState({});
    //const [pileTypeState, setPileTypeState] = useState([]);
    // const [pileDetailsData, setPileDetailsData] = useState([]);
    //const [pileInstallMethods, setPileInstallMethods] = useState([]);

    const convertFileNumbersToSelectFormat = (data) => data && data.map(obj => ({ label: obj.ormFileNumber, value: obj.ormFileNumber }));

    const populateDredgeTypes = () => {
      const dredgeTypes = [];
      if (permitData?.dredgeProject?.isDredgeTypeMaintenance) {
        dredgeTypes.push('Maintenance');
      }
      if (permitData?.dredgeProject?.isDredgeTypeNew) {
        dredgeTypes.push('New');
      }
      return dredgeTypes;
    };

    const otherNWPValidationSchema = yup.object().shape({
      approvalType: yup.string().required('Please select an option'),
      explanation: yup.string().when('approvalType', { is: 'Other', then: () => yup.string().required('Value is required') }).nullable(),
      fileNumber: yup.string().required('Value is required'),
      appliedDate: yup.string().required('Please select a date'),
      approvedDate: yup.string().nullable(),
      deniedDate: yup.string().nullable(),
    });


    const schema = () => yup.object().shape({
      projectName: yup.string().required('Project Name is required'),
      previousFileNumbersExist: yup.string().required('Please select an option'),
      previousFileNumbers: yup.array().when('previousFileNumbersExist', { is: 'true', then: () => yup.array().min(1, 'At least one Previous File Number is required').required('At least one Previous File Number is required') }),
      gpPermitTypes: yup.array(),
      applicableStatutoryAuthority: yup.array().min(1, 'Please select at least one value'),
      projectDescription: yup.string().test('isDefault', 'DEFAULT_VALUE is a reserved word, please enter a valid string', val => val.toUpperCase() !== 'DEFAULT_VALUE').required('Project Description is required'),
      natureOfActivity: yup.string().test('isDefault', 'DEFAULT_VALUE is a reserved word, please enter a valid string', val => val.toUpperCase() !== 'DEFAULT_VALUE').required('Nature of Activity is required'),
      projectPurpose: yup.string().test('isDefault', 'DEFAULT_VALUE is a reserved word, please enter a valid string', val => val.toUpperCase() !== 'DEFAULT_VALUE').required('Project Purpose is required'),
      navigableWatersAdditionalInfo: yup.string().nullable(),
      isAnyWorkComplete: yup.string().required('Please select an option'),
      completedWorkDescription: yup.string().when('isAnyWorkComplete', { is: 'true', then: () => yup.string().required('Please enter a description') }).nullable(),
      //DREDGING
      isDredgingNavWaters: yup.string().when('applicableStatutoryAuthority', { is: val => val?.includes(ApplicableStatutoryAuthorities.Section10), then: () => yup.string().required('Please select an option') }).nullable(),
      materialTypeComposition: yup.string().when('isDredgingNavWaters', { is: 'true', then: () => yup.string().required('Value is required') }).nullable(),
      dredgeMethod: yup.string().when('isDredgingNavWaters', { is: 'true', then: () => yup.string().required('Value is required') }).nullable(),
      constructionMethods: yup.string().when('isDredgingNavWaters', { is: 'true', then: () => yup.string().required('Value is required') }).nullable(),
      estimatedNumberOfEvents: yup.string().when(['estimatedNumberOfEvents', 'isDredgingNavWaters'], (estimatedNumberOfEvents, isDredgingNavWaters) => (estimatedNumberOfEvents && isDredgingNavWaters === 'true') ? yup.string().matches(integerRegex, { message: 'Value is invalid. Must be an integer.', excludeEmptyString: true }) : yup.string().nullable()),
      workStartDate: yup.string().when('isDredgingNavWaters', { is: 'true', then: () => yup.string().required('Please select a date') }).nullable(),
      workEndDate: yup.string().when('isDredgingNavWaters', { is: 'true', then: () => yup.string().required('Please select a date') }).nullable().test({ test: (value, { parent: { isDredgingNavWaters } }) => isDredgingNavWaters === 'true' ? value !== '' : true, message: 'Please select a date' }).test({ test: (value, { parent: { workStartDate } }) => !value || new Date(value) >= new Date(workStartDate), message: 'The end date must be after the start date' }),
      durationOfWork: yup.string().when('isDredgingNavWaters', { is: 'true', then: () => yup.number().required('Please enter a value').typeError('Value is invalid. Must be an integer.').positive('Value must be > 0') }).nullable(),
      unitOfWork: yup.string().when('isDredgingNavWaters', { is: 'true', then: () => yup.string().required('Please select an option') }).nullable(),
      currentDepth: yup.string().when('isDredgingNavWaters', { is: 'true', then: () => yup.string().required('Please enter value').matches(decimalNumberRegex, 'Value is invalid.') }).nullable(),
      dredgeType: yup.array().when('isDredgingNavWaters', { is: 'true', then: () => yup.array().min(1, 'Please select at least one value') }).nullable(),
      lastDredgeCycle: yup.string().when('dredgeType', { is: val => val?.includes('Maintenance'), then: () => yup.string().required('Please select a date') }).nullable(),
      authorizedLength: yup.string().when('dredgeType', { is: val => val?.length > 0, then: () => yup.string().required('Please enter a value').matches(decimalNumberRegex, 'Value is invalid.') }).nullable(),
      authorizedWidth: yup.string().when('dredgeType', { is: val => val?.length > 0, then: () => yup.string().required('Please enter a value').matches(decimalNumberRegex, 'Value is invalid.') }).nullable(),
      authorizedDepth: yup.string().when('dredgeType', { is: val => val?.length > 0, then: () => yup.string().required('Please enter a value').matches(decimalNumberRegex, 'Value is invalid.') }).nullable(),
      disposalPlan: yup.string().when('dredgeType', { is: val => val?.length > 0, then: () => yup.string().required('Value is required') }).nullable(),
      estNumDisposalTrips: yup.string().when(['estNumDisposalTrips', 'dredgeType'], (estNumDisposalTrips, dredgeType) => (estNumDisposalTrips && dredgeType?.length > 0) ? yup.string().matches(integerRegex, { message: 'Value is invalid. Must be an integer.', excludeEmptyString: true }) : yup.string().nullable()),
      dredgeDisposalLatitude: yup.mixed().when('dredgeType', { is: val => val?.length > 0, then: () => yup.string().required('Latitude is required').matches(latRegex, 'Latitude format is incorrect. Must be +-XX.XXXXXX and include at least 6 decimal places.') }).nullable(),
      dredgeDisposalLongitude: yup.mixed().when('dredgeType', { is: val => val?.length > 0, then: () => yup.string().required('Longitude is required').matches(lngRegex, 'Longitude format is incorrect. Must be +-XXX.XXXXXX and include at least 6 decimal places.') }).nullable(),
      //PILE DRIVING
      // includesInstallPiling: yup.string().when('applicableStatutoryAuthority', { is: val => val?.length > 0, then: () => yup.string().required('Please select an option') }).nullable(),
      // doesPilingDischargeFillMaterial: yup.string().when('includesInstallPiling', { is: 'true', then: () => yup.string().required('Please select an option') }).nullable(),
      // pileType: yup.array().when(['applicableStatutoryAuthority', 'includesInstallPiling', 'doesPilingDischargeFillMaterial'], { is: (applicableStatutoryAuthority, includesInstallPiling, doesPilingDischargeFillMaterial) => ((applicableStatutoryAuthority?.includes(ApplicableStatutoryAuthorities.Section10) && includesInstallPiling === 'true') || doesPilingDischargeFillMaterial === 'true'), then: () => yup.array().min(1, 'Please select at least one value') }).nullable(),
      // pileTypeOther: yup.string().when('pileType', { is: val => val?.includes('Other'), then: () => yup.string().required('Please enter a description') }).nullable(),
      // pileMaterial: yup.string().when(['applicableStatutoryAuthority', 'includesInstallPiling', 'doesPilingDischargeFillMaterial'], { is: (applicableStatutoryAuthority, includesInstallPiling, doesPilingDischargeFillMaterial) => ((applicableStatutoryAuthority?.includes(ApplicableStatutoryAuthorities.Section10) && includesInstallPiling === 'true') || doesPilingDischargeFillMaterial === 'true'), then: () => yup.string().required('Value is required') }).nullable(),
      // pileDiameter: yup.string().when(['applicableStatutoryAuthority', 'includesInstallPiling', 'doesPilingDischargeFillMaterial'], { is: (applicableStatutoryAuthority, includesInstallPiling, doesPilingDischargeFillMaterial) => ((applicableStatutoryAuthority?.includes(ApplicableStatutoryAuthorities.Section10) && includesInstallPiling === 'true') || doesPilingDischargeFillMaterial === 'true'), then: () => yup.string().required('Please enter a value').matches(decimalNumberRegex, 'Value is invalid.') }).nullable(),
      // totalPiles: yup.string().when(['applicableStatutoryAuthority', 'includesInstallPiling', 'doesPilingDischargeFillMaterial'], { is: (applicableStatutoryAuthority, includesInstallPiling, doesPilingDischargeFillMaterial) => ((applicableStatutoryAuthority?.includes(ApplicableStatutoryAuthorities.Section10) && includesInstallPiling === 'true') || doesPilingDischargeFillMaterial === 'true'), then: () => yup.string().matches(decimalNumberRegex, 'Value is invalid.').required('Value is required') }).nullable(),
      // installationMethod: yup.array().when(['applicableStatutoryAuthority', 'includesInstallPiling', 'doesPilingDischargeFillMaterial'], { is: (applicableStatutoryAuthority, includesInstallPiling, doesPilingDischargeFillMaterial) => ((applicableStatutoryAuthority?.includes(ApplicableStatutoryAuthorities.Section10) && includesInstallPiling === 'true') || doesPilingDischargeFillMaterial === 'true'), then: () => yup.array().min(1, 'Please select at least one value') }).nullable(),
      // impactHammerStrikesPerPile: yup.string().when('installationMethod', { is: val => val?.includes('Impact Hammer'), then: () => yup.string().matches(integerRegex, 'Value is invalid. Must be an integer.').required('Value is required') }).nullable(),
      // vibrationHammerSecondsPerPile: yup.string().when('installationMethod', { is: val => val?.includes('Vibratory Hammer'), then: () => yup.string().matches(integerRegex, 'Value is invalid. Must be an integer.').required('Value is required') }).nullable(),
      // installationMethodOther: yup.string().when('installationMethod', { is: val => val?.includes('Other'), then: () => yup.string().required('Please enter a description') }).nullable(),
      // pileConstructionMethods: yup.string().when(['applicableStatutoryAuthority', 'includesInstallPiling', 'doesPilingDischargeFillMaterial'], { is: (applicableStatutoryAuthority, includesInstallPiling, doesPilingDischargeFillMaterial) => ((applicableStatutoryAuthority?.includes(ApplicableStatutoryAuthorities.Section10) && includesInstallPiling === 'true') || doesPilingDischargeFillMaterial === 'true'), then: () => yup.string().required('Value is required') }).nullable(),
      // earliestStartDate: yup.string().when(['applicableStatutoryAuthority', 'includesInstallPiling', 'doesPilingDischargeFillMaterial'], { is: (applicableStatutoryAuthority, includesInstallPiling, doesPilingDischargeFillMaterial) => ((applicableStatutoryAuthority?.includes(ApplicableStatutoryAuthorities.Section10) && includesInstallPiling === 'true') || doesPilingDischargeFillMaterial === 'true'), then: () => yup.string().required('Please select a date') }).nullable(),
      // latestStopDate: yup.string().when(['applicableStatutoryAuthority', 'includesInstallPiling', 'doesPilingDischargeFillMaterial'], { is: (applicableStatutoryAuthority, includesInstallPiling, doesPilingDischargeFillMaterial) => ((applicableStatutoryAuthority?.includes(ApplicableStatutoryAuthorities.Section10) && includesInstallPiling === 'true') || doesPilingDischargeFillMaterial === 'true'), then: () => yup.string().required('Please select a date') }).nullable(),
      // pilesInstalledPerDay: yup.string().when(['applicableStatutoryAuthority', 'includesInstallPiling', 'doesPilingDischargeFillMaterial'], { is: (applicableStatutoryAuthority, includesInstallPiling, doesPilingDischargeFillMaterial) => ((applicableStatutoryAuthority?.includes(ApplicableStatutoryAuthorities.Section10) && includesInstallPiling === 'true') || doesPilingDischargeFillMaterial === 'true'), then: () => yup.string().matches(integerRegex, 'Value is invalid. Must be an integer.').required('Value is required') }).nullable(),
      // pileDrivingDurationInDays: yup.string().when(['applicableStatutoryAuthority', 'includesInstallPiling', 'doesPilingDischargeFillMaterial'], { is: (applicableStatutoryAuthority, includesInstallPiling, doesPilingDischargeFillMaterial) => ((applicableStatutoryAuthority?.includes(ApplicableStatutoryAuthorities.Section10) && includesInstallPiling === 'true') || doesPilingDischargeFillMaterial === 'true'), then: () => yup.string().matches(integerRegex, 'Value is invalid. Must be an integer.').required('Value is required') }).nullable(),
      // substrateType: yup.string().when(['applicableStatutoryAuthority', 'includesInstallPiling', 'doesPilingDischargeFillMaterial'], { is: (applicableStatutoryAuthority, includesInstallPiling, doesPilingDischargeFillMaterial) => ((applicableStatutoryAuthority?.includes(ApplicableStatutoryAuthorities.Section10) && includesInstallPiling === 'true') || doesPilingDischargeFillMaterial === 'true'), then: () => yup.string().required('Value is required') }).nullable(),
      // pileInstallWaterDepth: yup.string().when(['applicableStatutoryAuthority', 'includesInstallPiling', 'doesPilingDischargeFillMaterial'], { is: (applicableStatutoryAuthority, includesInstallPiling, doesPilingDischargeFillMaterial) => ((applicableStatutoryAuthority?.includes(ApplicableStatutoryAuthorities.Section10) && includesInstallPiling === 'true') || doesPilingDischargeFillMaterial === 'true'), then: () => yup.string().required('Please enter a value').matches(decimalNumberRegex, 'Value is invalid.') }).nullable(),
      // isWatercraftStructurePiling: yup.string().when(['applicableStatutoryAuthority', 'includesInstallPiling', 'doesPilingDischargeFillMaterial'], { is: (applicableStatutoryAuthority, includesInstallPiling, doesPilingDischargeFillMaterial) => ((applicableStatutoryAuthority?.includes(ApplicableStatutoryAuthorities.Section10) && includesInstallPiling === 'true') || doesPilingDischargeFillMaterial === 'true'), then: () => yup.string().required('Please select an option') }).nullable(),
      // existingVesselSlips: yup.string().when(['existingVesselSlips', 'isWatercraftStructurePiling'], (existingVesselSlips, isWatercraftStructurePiling) => (existingVesselSlips && isWatercraftStructurePiling === 'true') ? yup.string().matches(integerRegex, { message: 'Value is invalid. Must be an integer.', excludeEmptyString: true }) : yup.string().nullable()),
      // proposedNewVesselSlips: yup.string().when('isWatercraftStructurePiling', { is: 'true', then: () => yup.string().matches(integerRegex, 'Value is invalid. Must be an integer.').required('Value is required') }).nullable(),
      // //SHORELINE STABILIZATION
      // includesShorelineStabilization: yup.string().when('applicableStatutoryAuthority', { is: val => val?.length > 0, then: () => yup.string().required('Please select an option') }).nullable(),
      // isPileInUnitedStates: yup.string().when('includesShorelineStabilization', { is: 'true', then: () => yup.string().required('Please select an option') }).nullable(),
      // sheetPileType: yup.array().when('isPileInUnitedStates', { is: 'true', then: () => yup.array().min(1, 'Please select at least one value') }).nullable(),
      // sheetPileMaterial: yup.string().when('isPileInUnitedStates', { is: 'true', then: () => yup.string().required('Value is required') }).nullable(),
      // precastSheetPileWidth: yup.string().when('sheetPileType', { is: val => val?.includes('Pre-cast panels/sheet piles'), then: () => yup.number().required('Please enter a value').typeError('Value is invalid. Must be an integer.').positive('value must be > 0') }).nullable(),
      // sheetPileDiameter: yup.string().when('sheetPileType', { is: val => val?.filter(item => item === 'Square' || item === 'Round' || item === 'Other').length > 0, then: () => yup.number().required('Please enter a value').typeError('Value is invalid. Must be an integer.').positive('value must be > 0') }).nullable(),
      // sheetPileTypeOther: yup.string().when('sheetPileType', { is: val => val?.includes('Other'), then: () => yup.string().required('Please enter a description') }).nullable(),
      // sheetPileTotalPiles: yup.string().when('isPileInUnitedStates', { is: 'true', then: () => yup.string().matches(decimalNumberRegex, 'Value is invalid.').required('Value is required') }).nullable(),
      // totalSheetPiles: yup.string().when('isPileInUnitedStates', { is: 'true', then: () => yup.string().matches(decimalNumberRegex, 'Value is invalid.').required('Value is required') }).nullable(),
      // sheetPileInstallationMethod: yup.array().when('isPileInUnitedStates', { is: 'true', then: () => yup.array().min(1, 'Please select at least one value') }).nullable(),
      // sheetPileImpactHammerStrikesPerPile: yup.string().when('sheetPileInstallationMethod', { is: val => val?.includes('Impact Hammer'), then: () => yup.string().matches(integerRegex, 'Value is invalid. Must be an integer.').required('Value is required') }).nullable(),
      // sheetPileVibrationHammerSecondsPerPile: yup.string().when('sheetPileInstallationMethod', { is: val => val?.includes('Vibratory Hammer'), then: () => yup.string().matches(integerRegex, 'Value is invalid. Must be an integer.').required('Value is required') }).nullable(),
      // sheetPileInstallationMethodOther: yup.string().when('sheetPileInstallationMethod', { is: val => val?.includes('Other'), then: () => yup.string().required('Please enter a description') }).nullable(),
      // sheetPileConstructionMethods: yup.string().when('isPileInUnitedStates', { is: val => val?.includes('true'), then: () => yup.string().required('Value is required') }).nullable(),
      // sheetPileEarliestStartDate: yup.string().when('isPileInUnitedStates', { is: 'true', then: () => yup.string().required('Please select a date') }).nullable(),
      // sheetPileLatestStopDate: yup.string().when('isPileInUnitedStates', { is: 'true', then: () => yup.string().required('Please select a date') }).nullable(),
      // sheetpilesInstalledPerDay: yup.string().when('isPileInUnitedStates', { is: 'true', then: () => yup.number().required('Please enter a value').typeError('Value is invalid. Must be an integer.').positive('value must be > 0') }).nullable(),
      // sheetPilePilesInstalledPerDay: yup.string().when('isPileInUnitedStates', { is: 'true', then: () => yup.string().matches(integerRegex, 'Value is invalid. Must be an integer.').required('Value is required') }).nullable(),
      // sheetPileDrivingDurationInDays: yup.string().when('isPileInUnitedStates', { is: 'true', then: () => yup.string().matches(integerRegex, 'Value is invalid. Must be an integer.').required('Value is required') }).nullable(),
      // sheetPileSubstrateType: yup.string().when('isPileInUnitedStates', { is: 'true', then: () => yup.string().required('Value is required') }).nullable(),
      // sheetPileInstallWaterDepth: yup.string().when('isPileInUnitedStates', { is: 'true', then: () => yup.string().required('Please enter a value').matches(decimalNumberRegex, 'Value is invalid.') }).nullable(),
      // proposedStabilizationWidth: yup.string().when('isPileInUnitedStates', { is: 'true', then: () => yup.string().required('Please enter a value').matches(decimalNumberRegex, 'Value is invalid.') }).nullable(),
      // isDischargeInUnitedStates: yup.string().when('includesShorelineStabilization', { is: 'true', then: () => yup.string().required('Please select an option') }).nullable(),
      // proposedStabilizationDistance: yup.string().when('isDischargeInUnitedStates', { is: 'true', then: () => yup.string().required('Please enter a value').matches(decimalNumberRegex, 'Value is invalid.') }).nullable(),
    }, [
      ['estimatedNumberOfEvents', 'estimatedNumberOfEvents'],
      ['estNumDisposalTrips', 'estNumDisposalTrips'],
      ['existingVesselSlips', 'existingVesselSlips']
    ]);

    const defaultValues = {
      projectName: requestFormData?.projectName,
      previousFileNumbersExist: requestFormData?.previousFileNumbers?.length > 0 ? 'true' : 'false',
      previousFileNumbers: requestFormData?.previousFileNumbers?.length > 0 ? convertFileNumbersToSelectFormat(requestFormData.previousFileNumbers) : [],
      projectDescription: requestFormData?.projectDescription ?? '',
      projectPurpose: requestFormData?.projectPurpose ?? '',
      natureOfActivity: requestFormData?.natureOfActivity ?? '',
      applicableStatutoryAuthority: permitData?.applicableStatutoryAuthorities ?? [],
      gpPermitTypes: permitData?.gpPermitTypes?.map(item => ({ value: item, label: item })) ?? [],
      supportingPermits: permitData?.supportingPermits ? permitData?.supportingPermits?.map(item => ({
        ...item,
        appliedDate: item?.appliedDate?.split('T')?.[0],
        approvedDate: item?.approvedDate?.split('T')?.[0],
        deniedDate: item?.deniedDate?.split('T')?.[0],
      })) : [],
      activityDescription: permitData?.activityDescription ?? '',
      activityPurposeAndNeed: permitData?.activityPurposeAndNeed ?? '',
      navigableWatersAdditionalInfo: permitData?.navigableWatersAdditionalInfo ?? '',
      isAnyWorkComplete: (permitData?.isAnyWorkComplete !== undefined && permitData?.isAnyWorkComplete !== null) ? (permitData.isAnyWorkComplete ? 'true' : 'false') : '',
      completedWorkDescription: permitData?.completedWorkDescription ?? '',
      // Dredge Project
      isDredgingNavWaters: (permitData?.isDredgingNavWaters !== undefined && permitData?.isDredgingNavWaters !== null) ? (permitData.isDredgingNavWaters ? 'true' : 'false') : '',
      materialTypeComposition: permitData?.dredgeProject?.materialTypeComposition ?? '',
      dredgeMethod: permitData?.dredgeProject?.dredgeMethod ?? '',
      constructionMethods: permitData?.dredgeProject?.constructionMethods ?? '',
      estimatedNumberOfEvents: permitData?.dredgeProject?.estimatedNumberOfEvents ?? '',
      workStartDate: permitData?.dredgeProject?.workStartDate?.split('T')?.[0] ?? '',
      workEndDate: permitData?.dredgeProject?.workEndDate?.split('T')?.[0] ?? '',
      durationOfWork: permitData?.dredgeProject?.durationOfWork ?? null,
      unitOfWork: permitData?.dredgeProject?.unitOfWork ?? '',
      currentDepth: permitData?.dredgeProject?.currentDepth ?? '',
      dredgeType: populateDredgeTypes(),
      lastDredgeCycle: permitData?.dredgeProject?.lastDredgeCycle?.split('T')?.[0] ?? '',
      authorizedLength: permitData?.dredgeProject?.authorizedLength ?? '',
      authorizedWidth: permitData?.dredgeProject?.authorizedWidth ?? '',
      authorizedDepth: permitData?.dredgeProject?.authorizedDepth ?? '',
      disposalPlan: permitData?.dredgeProject?.disposalPlan ?? '',
      estNumDisposalTrips: permitData?.dredgeProject?.estNumDisposalTrips ?? '',
      dredgeDisposalLatitude: typeof permitData?.dredgeProject?.dredgeDisposalLatitude === 'number' ? permitData?.dredgeProject?.dredgeDisposalLatitude?.toFixed(7) : permitData?.dredgeProject?.dredgeDisposalLatitude ?? '',
      dredgeDisposalLongitude: typeof permitData?.dredgeProject?.dredgeDisposalLongitude === 'number' ? permitData?.dredgeProject?.dredgeDisposalLongitude?.toFixed(7) : permitData?.dredgeProject?.dredgeDisposalLongitude ?? '',

      // Pile Driving and Coastal Shoreline Stabilization Manual Overwrite
      includesInstallPiling: (permitData?.includesInstallPiling !== undefined && permitData?.includesInstallPiling !== '') ? (permitData.includesInstallPiling ? 'true' : 'false') : 'false',
      includesShorelineStabilization: (permitData?.includesShorelineStabilization !== undefined && permitData?.includesShorelineStabilization !== '') ? (permitData.includesShorelineStabilization ? 'true' : 'false') : 'false',

      /* // Pile Driving
      doesPilingDischargeFillMaterial: (permitData?.esaConsultantInformation?.doesPilingDischargeFillMaterial !== undefined && permitData?.esaConsultantInformation?.doesPilingDischargeFillMaterial !== '') ? (permitData.esaConsultantInformation.doesPilingDischargeFillMaterial ? 'true' : 'false') : '',
      includesInstallPiling: (permitData?.includesInstallPiling !== undefined && permitData?.includesInstallPiling !== '') ? (permitData.includesInstallPiling ? 'true' : 'false') : '',
      pileType: permitData?.esaConsultantInformation?.pileTypes?.filter(item => item.pileUse === 'Piling for Structure').length > 0 ? permitData?.esaConsultantInformation?.pileTypes?.filter(item => item.pileUse === 'Piling for Structure')?.map(item => item.pileType) : [],
      pileTypeOther: permitData?.esaConsultantInformation?.pileTypes?.filter(item => item.pileUse === 'Piling for Structure' && item.pileType === 'Other').length > 0 ? permitData?.esaConsultantInformation?.pileTypes?.filter(item => item.pileUse === 'Piling for Structure' && item.pileType === 'Other')[0].pileTypeOther : '',
      pileMaterial: permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Piling for Structure' && item.pileMaterial).length > 0 ? permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Piling for Structure')[0].pileMaterial : '',
      pileDiameter: permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Piling for Structure' && item.pileDiameter).length > 0 ? permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Piling for Structure')[0].pileDiameter : '',
      totalPiles: permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Piling for Structure' && item.totalPiles).length > 0 ? permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Piling for Structure')[0].totalPiles : '',
      impactHammerStrikesPerPile: permitData?.esaConsultantInformation?.pileInstallMethods?.filter(item => item.pileUse === 'Piling for Structure' && item.installationMethod === 'Impact Hammer').length > 0 ? permitData?.esaConsultantInformation?.pileInstallMethods?.filter(item => item.pileUse === 'Piling for Structure' && item.installationMethod === 'Impact Hammer')[0].impactHammerStrikesPerPile : '',
      vibrationHammerSecondsPerPile: permitData?.esaConsultantInformation?.pileInstallMethods?.filter(item => item.pileUse === 'Piling for Structure' && item.installationMethod === 'Vibratory Hammer').length > 0 ? permitData?.esaConsultantInformation?.pileInstallMethods?.filter(item => item.pileUse === 'Piling for Structure' && item.installationMethod === 'Vibratory Hammer')[0].vibrationHammerSecondsPerPile : '',
      installationMethod: permitData?.esaConsultantInformation?.pileInstallMethods?.filter(item => item.pileUse === 'Piling for Structure').length > 0 ? permitData?.esaConsultantInformation?.pileInstallMethods?.filter(item => item.pileUse === 'Piling for Structure').map(item => item.installationMethod) : [],
      installationMethodOther: permitData?.esaConsultantInformation?.pileInstallMethods?.filter(item => item.pileUse === 'Piling for Structure' && item.installationMethod === 'Other').length > 0 ? permitData?.esaConsultantInformation?.pileInstallMethods?.filter(item => item.pileUse === 'Piling for Structure' && item.installationMethod === 'Other')[0].installationMethodOther : '',
      earliestStartDate: permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Piling for Structure' && item.earliestStartDate).length > 0 ? permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Piling for Structure')[0].earliestStartDate?.split('T')[0] : '',
      latestStopDate: permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Piling for Structure' && item.latestStopDate).length > 0 ? permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Piling for Structure')[0].latestStopDate?.split('T')[0] : '',
      pilesInstalledPerDay: permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Piling for Structure' && item.pilesInstalledPerDay).length > 0 ? permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Piling for Structure')[0].pilesInstalledPerDay : '',
      pileConstructionMethods: permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Piling for Structure' && item.constructionMethods).length > 0 ? permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Piling for Structure')[0].constructionMethods : '',
      pileDrivingDurationInDays: permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Piling for Structure' && item.pileDrivingDurationInDays).length > 0 ? permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Piling for Structure')[0].pileDrivingDurationInDays : '',
      substrateType: permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Piling for Structure' && item.substrateType).length > 0 ? permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Piling for Structure')[0].substrateType : '',
      pileInstallWaterDepth: permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Piling for Structure' && item.pileInstallWaterDepth).length > 0 ? permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Piling for Structure')[0].pileInstallWaterDepth : '',
      isWatercraftStructurePiling: (permitData?.esaConsultantInformation?.isWatercraftStructurePiling !== undefined && permitData?.esaConsultantInformation?.isWatercraftStructurePiling !== '') ? (permitData.esaConsultantInformation.isWatercraftStructurePiling ? 'true' : 'false') : '',
      existingVesselSlips: permitData?.esaConsultantInformation?.existingVesselSlips ?? '',
      proposedNewVesselSlips: permitData?.esaConsultantInformation?.proposedNewVesselSlips ?? '',
      includesShorelineStabilization: (permitData?.includesShorelineStabilization !== undefined && permitData?.includesShorelineStabilization !== '') ? (permitData.includesShorelineStabilization ? 'true' : 'false') : '',
      isPileInUnitedStates: (permitData?.esaConsultantInformation?.isPileInUnitedStates !== undefined && permitData?.esaConsultantInformation?.isPileInUnitedStates !== '') ? (permitData.esaConsultantInformation.isPileInUnitedStates ? 'true' : 'false') : '',
      // Shoreline Stabilization
      sheetPileType: permitData?.esaConsultantInformation?.pileTypes?.filter(item => item.pileUse === 'Shoreline Stabilization').length > 0 ? permitData?.esaConsultantInformation?.pileTypes?.filter(item => item.pileUse === 'Shoreline Stabilization')?.map(item => item.pileType) : [],
      sheetPileMaterial: permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization' && item.pileMaterial).length > 0 ? permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization')[0].pileMaterial : '',
      precastSheetPileWidth: permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization' && item.precastSheetPileWidth).length > 0 ? permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization')[0].precastSheetPileWidth : '',
      sheetPileDiameter: permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization' && item.pileDiameter).length > 0 ? permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization')[0].pileDiameter : '',
      sheetPileTypeOther: permitData?.esaConsultantInformation?.pileTypes?.filter(item => item.pileUse === 'Shoreline Stabilization' && item.pileType === 'Other').length > 0 ? permitData?.esaConsultantInformation?.pileTypes?.filter(item => item.pileUse === 'Shoreline Stabilization' && item.pileType === 'Other')[0].pileTypeOther : '',
      sheetPileTotalPiles: permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization' && item.totalPiles).length > 0 ? permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization')[0].totalPiles : '',
      totalSheetPiles: permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization' && item.totalSheetpiles).length > 0 ? permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization')[0].totalSheetpiles : '',
      sheetPileImpactHammerStrikesPerPile: permitData?.esaConsultantInformation?.pileInstallMethods?.filter(item => item.pileUse === 'Shoreline Stabilization' && item.installationMethod === 'Impact Hammer').length > 0 ? permitData?.esaConsultantInformation?.pileInstallMethods?.filter(item => item.pileUse === 'Shoreline Stabilization' && item.installationMethod === 'Impact Hammer')[0].impactHammerStrikesPerPile : '',
      sheetPileVibrationHammerSecondsPerPile: permitData?.esaConsultantInformation?.pileInstallMethods?.filter(item => item.pileUse === 'Shoreline Stabilization' && item.installationMethod === 'Vibratory Hammer').length > 0 ? permitData?.esaConsultantInformation?.pileInstallMethods?.filter(item => item.pileUse === 'Shoreline Stabilization' && item.installationMethod === 'Vibratory Hammer')[0].vibrationHammerSecondsPerPile : '',
      sheetPileInstallationMethod: permitData?.esaConsultantInformation?.pileInstallMethods?.filter(item => item.pileUse === 'Shoreline Stabilization').length > 0 ? permitData?.esaConsultantInformation?.pileInstallMethods?.filter(item => item.pileUse === 'Shoreline Stabilization').map(item => item.installationMethod) : [],
      sheetPileInstallationMethodOther: permitData?.esaConsultantInformation?.pileInstallMethods?.filter(item => item.pileUse === 'Shoreline Stabilization' && item.installationMethod === 'Other').length > 0 ? permitData?.esaConsultantInformation?.pileInstallMethods?.filter(item => item.pileUse === 'Shoreline Stabilization' && item.installationMethod === 'Other')[0].installationMethodOther : '',
      sheetPileEarliestStartDate: permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization' && item.earliestStartDate).length > 0 ? permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization')[0].earliestStartDate?.split('T')[0] : '',
      sheetPileLatestStopDate: permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization' && item.latestStopDate).length > 0 ? permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization')[0].latestStopDate?.split('T')[0] : '',
      sheetPilePilesInstalledPerDay: permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization' && item.pilesInstalledPerDay).length > 0 ? permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization')[0].pilesInstalledPerDay : '',
      sheetPileConstructionMethods: permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization' && item.constructionMethods).length > 0 ? permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization')[0].constructionMethods : '',
      sheetpilesInstalledPerDay: permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization' && item.sheetpilesInstalledPerDay).length > 0 ? permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization')[0].sheetpilesInstalledPerDay : '',
      sheetPileDrivingDurationInDays: permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization' && item.pileDrivingDurationInDays).length > 0 ? permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization')[0].pileDrivingDurationInDays : '',
      sheetPileSubstrateType: permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization' && item.substrateType).length > 0 ? permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization')[0].substrateType : '',
      sheetPileInstallWaterDepth: permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization' && item.pileInstallWaterDepth).length > 0 ? permitData?.esaConsultantInformation?.pileDetails?.filter(item => item.pileUse === 'Shoreline Stabilization')[0].pileInstallWaterDepth : '',
      proposedStabilizationWidth: permitData?.esaConsultantInformation?.proposedStabilizationWidth ?? '',
      isDischargeInUnitedStates: (permitData?.esaConsultantInformation?.isDischargeInUnitedStates !== undefined && permitData?.esaConsultantInformation?.isDischargeInUnitedStates !== '') ? (permitData.esaConsultantInformation.isDischargeInUnitedStates ? 'true' : 'false') : '',
      proposedStabilizationDistance: permitData?.esaConsultantInformation?.proposedStabilizationDistance ?? '',
       */
    };

    const methods = useForm({ resolver: yupResolver(schema()), mode: 'onBlur', defaultValues: defaultValues });
    const { formState: { isValid, errors }, setValue, setError, clearErrors, watch, getValues, trigger } = methods;
    const previousFileNumbersExist = watch('previousFileNumbersExist', 'false');
    const previousFileNumbers = watch('previousFileNumbers', []);

    const isAnyWorkComplete = watch('isAnyWorkComplete');
    const applicableStatutoryAuthority = watch('applicableStatutoryAuthority');
    const isDredgingNavWaters = watch('isDredgingNavWaters');
    const dredgeType = watch('dredgeType');
    // const doesPilingDischargeFillMaterial = watch('doesPilingDischargeFillMaterial');
    // const includesInstallPiling = watch('includesInstallPiling');
    //const installationMethod = watch('installationMethod');
    //const sheetPileInstallationMethod = watch('sheetPileInstallationMethod');
    // const isWatercraftStructurePiling = watch('isWatercraftStructurePiling');
    //const includesShorelineStabilization = watch('includesShorelineStabilization');
    //const isPileInUnitedStates = watch('isPileInUnitedStates');
    //const isDischargeInUnitedStates = watch('isDischargeInUnitedStates');
    //const pileType = watch('pileType');
    //const sheetPileType = watch('sheetPileType');
    const gpPermitTypes = watch('gpPermitTypes');

    const NWPColumns = useMemo(() => [
      columnHelper.display({
        id: 'edit',
        cell: isReadOnly ? <></> : EditCell,
        size: 20,
        enableResizing: false,
        enableSorting: false,
      }),
      columnHelper.accessor('approvalType', {
        header: 'Type of Approval',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
        meta: {
          type: 'select',
          required: true,
          options: [
            { value: 'Nationwide Permit', label: 'Nationwide Permit' },
            { value: 'Regional General Permit', label: 'Regional General Permit' },
            { value: 'Programmatic General Permit', label: 'Programmatic General Permit' },
            { value: 'Individual Permit', label: 'Individual Permit' },
            { value: 'Other', label: 'Other' },
          ]
        },
      }),
      columnHelper.accessor('explanation', {
        header: 'Explanation',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : ExplanationTableCell,
        meta: {
          required: true,
          type: 'text',
        },
      }),
      columnHelper.accessor('fileNumber', {
        header: 'File Number',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
        meta: {
          required: true,
          type: 'text',
        },
      }),
      columnHelper.accessor('appliedDate', {
        header: 'Date Applied',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : PermitDateTableCell,
        meta: {
          type: 'date',
          min: dateBeforeB,
          required: true,
        },
      }),
      columnHelper.accessor('approvedDate', {
        header: 'Date Approved',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : PermitDateTableCell,
        meta: {
          type: 'date',
          min: dateBeforeB,
          required: false,
        },
      }),
      columnHelper.accessor('deniedDate', {
        header: 'Date Denied',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : PermitDateTableCell,
        meta: {
          type: 'date',
          min: dateBeforeB,
          required: false,
        },
      }),
    ], [columnHelper, isReadOnly]);

    const updateOtherNWPData = useCallback((rowIndex, columnId, updatedValue) => {
      setOtherNWPData((oldData) => {
        const newData = oldData ? [...oldData] : null;
        if (newData && newData[rowIndex]) {
          // Update properties
          newData[rowIndex] = {
            ...newData[rowIndex],
            [columnId]: updatedValue,
          };
          return newData;
        }
      });
    }, [setOtherNWPData]);

    const removeMultipleRows = useCallback((indicesToRemove) => {
      setOtherNWPData((oldData) => {
        const newRows = oldData.filter((_, index) => !indicesToRemove.includes(index));
        return newRows;
      });
      setOtherNWPKey(old => old + 1);
    }, [setOtherNWPData, setOtherNWPKey]);

    // Update Supporting Permits data in Permit Request Body
    useEffect(() => {
      otherNWPData && doUpdatePermitRequest({
        supportingPermits: otherNWPData.map(item => ({
          ...item,
          appliedDate: isDateValid(item?.appliedDate?.split('T')?.[0]) === true ? item?.appliedDate?.split('T')?.[0] : '',
          approvedDate: isDateValid(item?.approvedDate?.split('T')?.[0]) === true ? item?.approvedDate?.split('T')?.[0] : '',
          deniedDate: isDateValid(item?.deniedDate?.split('T')?.[0]) === true ? item?.deniedDate?.split('T')?.[0] : '',
        }))
      });
    }, [otherNWPData, doUpdatePermitRequest]);

    // Update Dredge Project data in Permit Request Body
    useEffect(() => {
      dredgeProjectData && doUpdatePermitRequest({ dredgeProject: dredgeProjectData });
    }, [dredgeProjectData, doUpdatePermitRequest]);

    // Update ESA Consultant Info data in Permit Request Body
    // useEffect(() => {
    //   esaConsultantInfoData && doUpdatePermitRequest({ esaConsultantInformation: esaConsultantInfoData });
    // }, [esaConsultantInfoData, doUpdatePermitRequest]);

    // Load data from API
    useEffect(() => {
      if (requestAPIData?.request?.permits?.[0]?.supportingPermits) {
        setOtherNWPData(requestAPIData?.request?.permits?.[0]?.supportingPermits.map(item => ({
          ...item,
          appliedDate: isDateValid(item?.appliedDate?.split('T')?.[0]) === true ? item?.appliedDate?.split('T')?.[0] : '',
          approvedDate: isDateValid(item?.approvedDate?.split('T')?.[0]) === true ? item?.approvedDate?.split('T')?.[0] : '',
          deniedDate: isDateValid(item?.deniedDate?.split('T')?.[0]) === true ? item?.deniedDate?.split('T')?.[0] : '',
        })));
      }

      if (requestAPIData?.request?.permits?.[0]?.dredgeProject) {
        setDredgeProjectData(requestAPIData?.request?.permits?.[0]?.dredgeProject);
      }

      // if (requestAPIData?.request?.permits?.[0]?.esaConsultantInformation) {
      //   setESAConsultantInfoData(requestAPIData?.request?.permits?.[0]?.esaConsultantInformation);
      // }

      // if (requestAPIData?.request?.permits?.[0]?.esaConsultantInformation?.pileTypes) {
      //   setPileTypeState(requestAPIData?.request?.permits?.[0]?.esaConsultantInformation?.pileTypes);
      // }

      // if (requestAPIData?.request?.permits?.[0]?.esaConsultantInformation?.pileDetails) {
      //   setPileDetailsData(requestAPIData?.request?.permits?.[0]?.esaConsultantInformation?.pileDetails);
      // }

      // if (requestAPIData?.request?.permits?.[0]?.esaConsultantInformation?.pileInstallMethods) {
      //   setPileInstallMethods(requestAPIData?.request?.permits?.[0]?.esaConsultantInformation?.pileInstallMethods);
      // }
    }, [requestAPIData]);

    const handleChange = (e) => {
      if (e?.target?.name === 'previousFileNumbersExist' && e?.target?.value === 'false') {
        setValue('previousFileNumbers', [], { shouldValidate: true });
        doUpdateRequestData({ previousFileNumbers: [] });
        return;
      }

      // Map & insert values into GP data modal
      if (!isReadOnly) {
        const values = getValues();
        const permitTypes = values?.gpPermitTypes?.map(val => val.value);
        const gpValues = {
          activityDescription: values?.activityDescription ?? '',
          activityPurposeAndNeed: values?.activityPurposeAndNeed ?? '',
          navigableWatersAdditionalInfo: values?.navigableWatersAdditionalInfo ?? '',
          isAnyWorkComplete: values?.isAnyWorkComplete === 'true' ? true : values?.isAnyWorkComplete === 'false' ? false : null,
          completedWorkDescription: values?.completedWorkDescription ?? '',
          isDredgingNavWaters: values?.isDredgingNavWaters === 'true' ? true : values?.isDredgingNavWaters === 'false' ? false : null,
          includesInstallPiling: false,
          includesShorelineStabilization: false,
          //includesInstallPiling: values?.includesInstallPiling === 'true' ? true : values?.includesInstallPiling === 'false' ? false : null,
          //includesShorelineStabilization: values?.includesShorelineStabilization === 'true' ? true : values?.includesShorelineStabilization === 'false' ? false : null,
          applicableStatutoryAuthorities: values?.applicableStatutoryAuthority ?? [],
          gpPermitTypes: permitTypes,
          dredgeProject: populateDredgeProject() ?? null,
          // esaConsultantInformation: populateESAConsultantInfo() ?? null,
        };
        const prevFileNumbers = values?.previousFileNumbersExist === 'false' ? [] : values?.previousFileNumbers?.length > 0 ? values?.previousFileNumbers?.map(val => ({ ormFileNumber: val?.value?.toUpperCase() })) : [];
        doUpdateRequestData({ projectName: values?.projectName, previousFileNumbers: prevFileNumbers, projectDescription: values?.projectDescription, projectPurpose: values?.projectPurpose, natureOfActivity: values?.natureOfActivity });
        doUpdatePermitRequest(gpValues);
      }
    };

    // Populate General Project Info nested sections

    const populateDredgeProject = () => {
      const values = getValues();
      // Dredge Project
      const dredgeProjectObj = {
        materialTypeComposition: values?.materialTypeComposition ?? '',
        dredgeMethod: values?.dredgeMethod ?? '',
        constructionMethods: values?.constructionMethods ?? '',
        estimatedNumberOfEvents: setNumberValue(values?.estimatedNumberOfEvents),
        workStartDate: values?.workStartDate ?? '',
        workEndDate: values?.workEndDate ?? '',
        durationOfWork: setNumberValue(values?.durationOfWork),
        unitOfWork: values?.unitOfWork ?? '',
        currentDepth: setNumberValue(values?.currentDepth, false),
        isDredgeTypeMaintenance: values?.dredgeType.length > 0 ? (values?.dredgeType?.includes('Maintenance') ? true : false) : null,
        isDredgeTypeNew: values?.dredgeType.length > 0 ? (values?.dredgeType?.includes('New') ? true : false) : null,
        lastDredgeCycle: values?.lastDredgeCycle ?? '',
        authorizedLength: setNumberValue(values?.authorizedLength, false),
        authorizedWidth: setNumberValue(values?.authorizedWidth, false),
        authorizedDepth: setNumberValue(values?.authorizedDepth, false),
        disposalPlan: values?.disposalPlan ?? '',
        estNumDisposalTrips: setNumberValue(values?.estNumDisposalTrips),
        dredgeDisposalLatitude: formatCoordFlt(values?.dredgeDisposalLatitude) ?? null,
        dredgeDisposalLongitude: formatCoordFlt(values?.dredgeDisposalLongitude) ?? null,
      };
      // Check if values in obj are all null/empty string
      return (filterNullEmptyObjects(dredgeProjectObj) !== null) ? { ...dredgeProjectData, ...dredgeProjectObj } : null;
    };

    // const populateESAConsultantInfo = () => {
    //   const values = getValues();
    //   // ESA Consultant Information
    //   const esaConsultantInfoObj = {
    //     doesPilingDischargeFillMaterial: values?.doesPilingDischargeFillMaterial === 'true' ? true : values?.doesPilingDischargeFillMaterial === 'false' ? false : null,
    //     isWatercraftStructurePiling: values?.isWatercraftStructurePiling === 'true' ? true : values?.isWatercraftStructurePiling === 'false' ? false : null,
    //     existingVesselSlips: setNumberValue(values?.existingVesselSlips),
    //     proposedNewVesselSlips: setNumberValue(values?.proposedNewVesselSlips),
    //     isPileInUnitedStates: values?.isPileInUnitedStates === 'true' ? true : values?.isPileInUnitedStates === 'false' ? false : null,
    //     proposedStabilizationWidth: values?.proposedStabilizationWidth ?? null,
    //     isDischargeInUnitedStates: values?.isDischargeInUnitedStates === 'true' ? true : values?.isDischargeInUnitedStates === 'false' ? false : null,
    //     proposedStabilizationDistance: values?.proposedStabilizationDistance ?? null,
    //     pileTypes: populatePileType() ?? null,
    //     pileDetails: populatePileDetails() ?? null,
    //     pileInstallMethods: populatePileInstallMethods() ?? null,
    //   };
    //   // Check if values in obj are all null/empty string
    //   return (filterNullEmptyObjects(esaConsultantInfoObj) !== null) ? { ...esaConsultantInfoData, ...esaConsultantInfoObj } : null;
    // };

    // const populatePileType = () => {
    //   // const values = getValues();
    //   // // ESA Consultant Information > Pile Type Data
    //   // const pileTypeData = values?.pileType.map(type => ({
    //   //   ...pileTypeState?.filter(item => item.pileUse === 'Piling for Structure' && item.pileType === type)?.[0],
    //   //   pileType: type,
    //   //   pileUse: 'Piling for Structure',
    //   //   pileTypeOther: type === 'Other' ? values?.pileTypeOther : null
    //   // }));
    //   // const sheetPileTypeData = values?.sheetPileType.map(type => ({
    //   //   ...pileTypeState?.filter(item => item.pileUse === 'Shoreline Stabilization' && item.pileType === type)?.[0],
    //   //   pileType: type,
    //   //   pileUse: 'Shoreline Stabilization',
    //   //   pileTypeOther: type === 'Other' ? values?.sheetPileTypeOther : null
    //   // }));

    //   // const isPileDriving = ((values?.applicableStatutoryAuthority?.includes(ApplicableStatutoryAuthorities.Section10) && values?.includesInstallPiling === 'true') || (values?.doesPilingDischargeFillMaterial === 'true')) && filterNullEmptyObjects(pileTypeData) !== null;
    //   // const isShorelineStabilization = (values?.isPileInUnitedStates === 'true') && filterNullEmptyObjects(sheetPileTypeData) !== null;
    //   // // Build Pile Type arr obj
    //   // if (isPileDriving === false && isShorelineStabilization === false) {
    //   //   return;
    //   // } else if (isPileDriving === true && isShorelineStabilization === false) {
    //   //   return pileTypeData;
    //   // } else if (isPileDriving === false && isShorelineStabilization === true) {
    //   //   return sheetPileTypeData;
    //   // } else if (isPileDriving === true && isShorelineStabilization === true) {
    //   //   return [...pileTypeData, ...sheetPileTypeData];
    //   // }
    // };

    // const populatePileDetails = () => {
    //   const values = getValues();
    //   // ESA Consultant Information > Pile Details Data
    //   var pilingDetailsObj = {
    //     pileMaterial: values?.pileMaterial ?? '',
    //     pileDiameter: values?.pileDiameter ?? null,
    //     totalPiles: values?.totalPiles ?? null,
    //     constructionMethods: values?.pileConstructionMethods ?? '',
    //     earliestStartDate: values?.earliestStartDate ?? '',
    //     latestStopDate: values?.latestStopDate ?? '',
    //     pilesInstalledPerDay: setNumberValue(values?.pilesInstalledPerDay) ?? null,
    //     pileDrivingDurationInDays: setNumberValue(values?.pileDrivingDurationInDays) ?? null,
    //     substrateType: values?.substrateType ?? '',
    //     pileInstallWaterDepth: values?.pileInstallWaterDepth ?? null
    //   };
    //   var sheetPilingDetailsObj = {
    //     pileMaterial: values?.sheetPileMaterial ?? '',
    //     pileDiameter: values?.sheetPileDiameter ?? null,
    //     totalPiles: values?.sheetPileTotalPiles ?? null,
    //     totalSheetPiles: values?.totalSheetPiles ?? null,
    //     precastSheetPileWidth: values?.precastSheetPileWidth ?? null,
    //     constructionMethods: values?.sheetPileConstructionMethods ?? '',
    //     earliestStartDate: values?.sheetPileEarliestStartDate ?? '',
    //     latestStopDate: values?.sheetPileLatestStopDate ?? '',
    //     pilesInstalledPerDay: setNumberValue(values?.sheetPilePilesInstalledPerDay),
    //     sheetpilesInstalledPerDay: setNumberValue(values?.sheetpilesInstalledPerDay),
    //     pileDrivingDurationInDays: setNumberValue(values?.sheetPileDrivingDurationInDays),
    //     substrateType: values?.sheetPileSubstrateType ?? '',
    //     pileInstallWaterDepth: values?.sheetPileInstallWaterDepth ?? null,
    //   };

    //   const isPileDriving = ((values?.applicableStatutoryAuthority?.includes(ApplicableStatutoryAuthorities.Section10) && values?.includesInstallPiling === 'true') || (values?.doesPilingDischargeFillMaterial === 'true')) && filterNullEmptyObjects(pilingDetailsObj) !== null;
    //   const isShorelineStabilization = (values?.isPileInUnitedStates === 'true') && filterNullEmptyObjects(sheetPilingDetailsObj) !== null;
    //   // Check if values in obj are all null/empty string
    //   if (filterNullEmptyObjects(pilingDetailsObj) !== null) {
    //     pilingDetailsObj = { ...pileDetailsData?.filter(item => item.pileUse === 'Piling for Structure')?.[0], ...pilingDetailsObj };
    //     pilingDetailsObj['pileUse'] = 'Piling for Structure';
    //   }
    //   if (filterNullEmptyObjects(sheetPilingDetailsObj) !== null) {
    //     sheetPilingDetailsObj = { ...pileDetailsData?.filter(item => item.pileUse === 'Shoreline Stabilization')?.[0], ...sheetPilingDetailsObj };
    //     sheetPilingDetailsObj['pileUse'] = 'Shoreline Stabilization';
    //   }

    //   // Build Pile Details arr obj
    //   if (isPileDriving === false && isShorelineStabilization === false) {
    //     return;
    //   } else if (isPileDriving === true && isShorelineStabilization === false) {
    //     return [pilingDetailsObj];
    //   } else if (isPileDriving === false && isShorelineStabilization === true) {
    //     return [sheetPilingDetailsObj];
    //   } else if (isPileDriving === true && isShorelineStabilization === true) {
    //     return [pilingDetailsObj, sheetPilingDetailsObj];
    //   }
    // };

    // const populatePileInstallMethods = () => {
    //   // const values = getValues();
    //   // // ESA Consultant Information > Pile Type Data
    //   // const pileInstallMethodsData = values?.installationMethod.map(method => ({
    //   //   ...pileInstallMethods?.filter(item => item.pileUse === 'Piling for Structure' && item.installationMethod === method)?.[0],
    //   //   pileUse: 'Piling for Structure',
    //   //   installationMethod: method,
    //   //   impactHammerStrikesPerPile: method === 'Impact Hammer' ? setNumberValue(values?.impactHammerStrikesPerPile) : null,
    //   //   vibrationHammerSecondsPerPile: method === 'Vibratory Hammer' ? setNumberValue(values?.vibrationHammerSecondsPerPile) : null,
    //   //   installationMethodOther: method === 'Other' ? values?.installationMethodOther : '',
    //   // }));
    //   // const sheetPileInstallMethodsData = values?.sheetPileInstallationMethod.map(method => ({
    //   //   ...pileInstallMethods?.filter(item => item.pileUse === 'Shoreline Stabilization' && item.installationMethod === method)?.[0],
    //   //   pileUse: 'Shoreline Stabilization',
    //   //   installationMethod: method,
    //   //   impactHammerStrikesPerPile: method === 'Impact Hammer' ? setNumberValue(values?.sheetPileImpactHammerStrikesPerPile) : null,
    //   //   vibrationHammerSecondsPerPile: method === 'Vibratory Hammer' ? setNumberValue(values?.sheetPileVibrationHammerSecondsPerPile) : null,
    //   //   installationMethodOther: method === 'Other' ? values?.sheetPileInstallationMethodOther : '',
    //   // }));

    //   // const isPileDriving = ((values?.applicableStatutoryAuthority?.includes(ApplicableStatutoryAuthorities.Section10) && values?.includesInstallPiling === 'true') || (values?.doesPilingDischargeFillMaterial === 'true')) && filterNullEmptyObjects(pileInstallMethodsData) !== null;
    //   // const isShorelineStabilization = (values?.isPileInUnitedStates === 'true') && filterNullEmptyObjects(sheetPileInstallMethodsData) !== null;
    //   // // Build Pile Details arr obj
    //   // if (isPileDriving === false && isShorelineStabilization === false) {
    //   //   return [];
    //   // } else if (isPileDriving === true && isShorelineStabilization === false) {
    //   //   return pileInstallMethodsData;
    //   // } else if (isPileDriving === false && isShorelineStabilization === true) {
    //   //   return sheetPileInstallMethodsData;
    //   // } else if (isPileDriving === true && isShorelineStabilization === true) {
    //   //   return [...pileInstallMethodsData, ...sheetPileInstallMethodsData];
    //   // }
    // };

    const populateGPPermitTypeOptions = () => {
      if (gpPermitTypes.find(item => item.value === 'I\'m not sure')) {
        return [{ value: 'I\'m not sure', label: 'I\'m not sure' }];
      } else if (gpPermitTypes.length > 0 && gpPermitTypes.find(item => item.value !== 'I\'m not sure')) {
        return gPPermitTypes?.map(item => ({ value: item, label: item }));
      } else {
        return [{ value: 'I\'m not sure', label: 'I\'m not sure' }, ...gPPermitTypes?.map(item => ({ value: item, label: item }))];
      }
    };

    const handleSelectChange = (e) => {


      const results = e.map(num => isValidORMNumber(num.value));

      if (!results.every(Boolean)) {
        setValue('previousFileNumbers', e.length > 1 ? previousFileNumbers : []);
        doSecondaryModalOpen(statusModal, { status: 'Failed', msg: ormErrorMsg });
      }
    };

    /* clear out sections when dropdowns are false */
    useEffect(() => {
      if (isAnyWorkComplete === 'false' || isAnyWorkComplete === '') {
        setValue('completedWorkDescription', '', { shouldValidate: true });
        doUpdatePermitRequest({ completedWorkDescription: '' });
      }
    }, [isAnyWorkComplete, setValue, doUpdatePermitRequest]);

    // useEffect(() => {
    //   if (includesShorelineStabilization === 'false' || includesShorelineStabilization === '') {
    //     setValue('isDischargeInUnitedStates', '', { shouldValidate: true });
    //     doUpdatePermitRequest({ isDischargeInUnitedStates: '' });
    //   }
    // }, [includesShorelineStabilization, setValue, doUpdatePermitRequest]);

    // Pile Driving
    // useEffect(() => {
    //   if (includesInstallPiling === 'false' || includesInstallPiling === '') {
    //     setValue('doesPilingDischargeFillMaterial', '', { shouldValidate: true });
    //     doUpdatePermitRequestSub('esaConsultantInformation', { doesPilingDischargeFillMaterial: '' });
    //   }
    // }, [includesInstallPiling, setValue, doUpdatePermitRequestSub]);

    // useEffect(() => {
    //   if (((applicableStatutoryAuthority?.includes(ApplicableStatutoryAuthorities.Section10) && includesInstallPiling === 'true') || doesPilingDischargeFillMaterial === 'true') === false) {
    //     setValue('pileType', [], { shouldValidate: true });
    //     setValue('pileMaterial', '', { shouldValidate: true });
    //     setValue('pileDiameter', '', { shouldValidate: true });
    //     setValue('totalPiles', '', { shouldValidate: true });
    //     setValue('pileTypeOther', '', { shouldValidate: true });
    //     setValue('installationMethod', [], { shouldValidate: true });
    //     setValue('impactHammerStrikesPerPile', '', { shouldValidate: true });
    //     setValue('vibrationHammerSecondsPerPile', '', { shouldValidate: true });
    //     setValue('installationMethodOther', '', { shouldValidate: true });
    //     setValue('pileConstructionMethods', '', { shouldValidate: true });
    //     setValue('earliestStartDate', '', { shouldValidate: true });
    //     setValue('latestStopDate', '', { shouldValidate: true });
    //     setValue('pilesInstalledPerDay', '', { shouldValidate: true });
    //     setValue('pileDrivingDurationInDays', '', { shouldValidate: true });
    //     setValue('substrateType', '', { shouldValidate: true });
    //     setValue('pileInstallWaterDepth', '', { shouldValidate: true });
    //     setValue('isWatercraftStructurePiling', '', { shouldValidate: true });
    //     setValue('existingVesselSlips', '', { shouldValidate: true });
    //     setValue('proposedNewVesselSlips', '', { shouldValidate: true });
    //     doClearPileDataByUse('Piling for Structure', ['pileTypes', 'pileDetails', 'pileInstallMethods']);
    //     doUpdatePermitRequestSub('esaConsultantInformation', { isWatercraftStructurePiling: null });
    //     doUpdatePermitRequestSub('esaConsultantInformation', { existingVesselSlips: null });
    //     doUpdatePermitRequestSub('esaConsultantInformation', { proposedNewVesselSlips: null });
    //   }
    // }, [doesPilingDischargeFillMaterial, applicableStatutoryAuthority, setValue, doUpdatePermitRequestSub, doClearPileDataByUse, includesInstallPiling]);

    // useEffect(() => {
    //   if (!pileType.includes('Other')) {
    //     setValue('pileTypeOther', '');
    //   }
    // }, [pileType, setValue]);

    // useEffect(() => {
    //   if (!installationMethod.includes('Impact Hammer')) {
    //     setValue('impactHammerStrikesPerPile', '', { shouldValidate: true });
    //   }
    // }, [installationMethod, setValue]);

    // useEffect(() => {
    //   if (!installationMethod.includes('Vibratory Hammer')) {
    //     setValue('vibrationHammerSecondsPerPile', '', { shouldValidate: true });
    //   }
    // }, [installationMethod, setValue]);

    // useEffect(() => {
    //   if (!installationMethod.includes('Other')) {
    //     setValue('installationMethodOther', '');
    //   }
    // }, [installationMethod, setValue]);


    useEffect(() => {
      if (!applicableStatutoryAuthority.includes(ApplicableStatutoryAuthorities.Section10)) {
        setValue('isDredgingNavWaters', '', { shouldValidate: true });
        doUpdatePermitRequest({ isDredgingNavWaters: null });
      }
    }, [applicableStatutoryAuthority, doUpdatePermitRequest, setValue]);

    useEffect(() => {
      if (applicableStatutoryAuthority.length === 0) {
        // setValue('includesInstallPiling', '', { shouldValidate: true });
        // setValue('includesShorelineStabilization', '', { shouldValidate: true });
        setValue('isDredgingNavWaters', '', { shouldValidate: true });
        // doUpdatePermitRequest({ includesInstallPiling: null });
        // doUpdatePermitRequest({ includesShorelineStabilization: null });
        doUpdatePermitRequest({ isDredgingNavWaters: null });
      }
    }, [applicableStatutoryAuthority, doUpdatePermitRequest, setValue]);

    // Dredging
    useEffect(() => {
      if (dredgeType.length === 0) {
        setValue('lastDredgeCycle', '', { shouldValidate: true });
        setValue('authorizedLength', '', { shouldValidate: true });
        setValue('authorizedWidth', '', { shouldValidate: true });
        setValue('authorizedDepth', '', { shouldValidate: true });
        setValue('disposalPlan', '', { shouldValidate: true });
        setValue('estNumDisposalTrips', '', { shouldValidate: true });
        setValue('dredgeDisposalLatitude', '', { shouldValidate: true });
        setValue('dredgeDisposalLongitude', '', { shouldValidate: true });
        doUpdatePermitRequestSub('dredgeProject', { lastDredgeCycle: null });
        doUpdatePermitRequestSub('dredgeProject', { authorizedLength: null });
        doUpdatePermitRequestSub('dredgeProject', { authorizedWidth: null });
        doUpdatePermitRequestSub('dredgeProject', { authorizedDepth: null });
        doUpdatePermitRequestSub('dredgeProject', { disposalPlan: '' });
        doUpdatePermitRequestSub('dredgeProject', { estNumDisposalTrips: null });
        doUpdatePermitRequestSub('dredgeProject', { dredgeDisposalLatitude: null });
        doUpdatePermitRequestSub('dredgeProject', { dredgeDisposalLongitude: null });
      }

    }, [dredgeType, setValue, doUpdatePermitRequestSub]);

    useEffect(() => {
      if (isDredgingNavWaters === 'false' || isDredgingNavWaters === '') {
        setValue('materialTypeComposition', '', { shouldValidate: true });
        setValue('dredgeMethod', '', { shouldValidate: true });
        setValue('constructionMethods', '', { shouldValidate: true });
        setValue('estimatedNumberOfEvents', '', { shouldValidate: true });
        setValue('workStartDate', '', { shouldValidate: true });
        setValue('workEndDate', '', { shouldValidate: true });
        setValue('durationOfWork', '', { shouldValidate: true });
        setValue('unitOfWork', '', { shouldValidate: true });
        setValue('currentDepth', '', { shouldValidate: true });
        setValue('dredgeType', [], { shouldValidate: true });
        doUpdatePermitRequestSub('dredgeProject', { materialTypeComposition: '' });
        doUpdatePermitRequestSub('dredgeProject', { dredgeMethod: '' });
        doUpdatePermitRequestSub('dredgeProject', { constructionMethods: '' });
        doUpdatePermitRequestSub('dredgeProject', { estimatedNumberOfEvents: null });
        doUpdatePermitRequestSub('dredgeProject', { workStartDate: '' });
        doUpdatePermitRequestSub('dredgeProject', { workEndDate: '' });
        doUpdatePermitRequestSub('dredgeProject', { durationOfWork: null });
        doUpdatePermitRequestSub('dredgeProject', { unitOfWork: '' });
        doUpdatePermitRequestSub('dredgeProject', { currentDepth: null });
        doUpdatePermitRequestSub('dredgeProject', { isDredgeTypeMaintenance: null });
        doUpdatePermitRequestSub('dredgeProject', { isDredgeTypeNew: null });
        // Reset all dredge project data
        setDredgeProjectData({});
      }
    }, [isDredgingNavWaters, setValue, doUpdatePermitRequestSub]);

    // useEffect(() => {
    //   if (isWatercraftStructurePiling === 'false' || isWatercraftStructurePiling === '') {
    //     setValue('existingVesselSlips', '', { shouldValidate: true });
    //     setValue('proposedNewVesselSlips', '', { shouldValidate: true });
    //     doUpdatePermitRequestSub('esaConsultantInformation', { existingVesselSlips: null });
    //     doUpdatePermitRequestSub('esaConsultantInformation', { proposedNewVesselSlips: null });
    //   }
    // }, [isWatercraftStructurePiling, setValue, doUpdatePermitRequestSub]);


    // Shoreline Stabilization
    // useEffect(() => {
    //   if (includesShorelineStabilization === 'false' || includesShorelineStabilization === '') {
    //     setValue('isPileInUnitedStates', '', { shouldValidate: true });
    //     doUpdatePermitRequest({ isPileInUnitedStates: null });
    //   }
    // }, [includesShorelineStabilization, setValue, doUpdatePermitRequest]);

    // useEffect(() => {
    //   if (!sheetPileType.includes('Pre-cast panels/sheet piles')) {
    //     setValue('precastSheetPileWidth', '', { shouldValidate: true });
    //   }
    // }, [sheetPileType, setValue]);

    // useEffect(() => {
    //   if (!sheetPileType.filter(item => item === 'Square' || item === 'Round' || item === 'Other').length > 0) {
    //     setValue('sheetPileDiameter', '', { shouldValidate: true });
    //   }
    // }, [sheetPileType, setValue]);

    // useEffect(() => {
    //   if (!sheetPileType.includes('Other')) {
    //     setValue('sheetPileTypeOther', '');
    //   }
    // }, [sheetPileType, setValue]);

    // useEffect(() => {
    //   if (!sheetPileInstallationMethod.includes('Impact Hammer')) {
    //     setValue('sheetPileImpactHammerStrikesPerPile', '', { shouldValidate: true });
    //   }
    // }, [sheetPileInstallationMethod, setValue]);

    // useEffect(() => {
    //   if (!sheetPileInstallationMethod.includes('Vibratory Hammer')) {
    //     setValue('sheetPileVibrationHammerSecondsPerPile', '', { shouldValidate: true });
    //   }
    // }, [sheetPileInstallationMethod, setValue]);

    // useEffect(() => {
    //   if (!sheetPileInstallationMethod.includes('Other')) {
    //     setValue('sheetPileInstallationMethodOther', '', { shouldValidate: true });
    //   }
    // }, [sheetPileInstallationMethod, setValue]);


    // selection isPileInUnitedStates and includesShorelineStabilization
    // useEffect(() => {
    //   if (isPileInUnitedStates === 'false' || includesShorelineStabilization === 'false' || isPileInUnitedStates === '' || includesShorelineStabilization === '') {
    //     doUpdatePermitRequest({ isDischargeInUnitedStates: '' });
    //     setValue('sheetPileType', [], { shouldValidate: true }); // checkboxes
    //     setValue('sheetPileInstallationMethod', [], { shouldValidate: true }); // checkboxes

    //     setValue('sheetPileMaterial', '', { shouldValidate: true });
    //     setValue('precastSheetPileWidth', '', { shouldValidate: true });
    //     setValue('sheetPileTypeOther', '', { shouldValidate: true });
    //     setValue('sheetPileTotalPiles', '', { shouldValidate: true });
    //     setValue('totalSheetPiles', '', { shouldValidate: true });
    //     setValue('sheetPileImpactHammerStrikesPerPile', '', { shouldValidate: true });
    //     setValue('sheetPileVibrationHammerSecondsPerPile', '', { shouldValidate: true });
    //     setValue('sheetPileInstallationMethodOther', '', { shouldValidate: true });
    //     setValue('sheetPileConstructionMethods', '', { shouldValidate: true });
    //     setValue('proposedStabilizationWidth', '', { shouldValidate: true });
    //     setValue('sheetPileInstallWaterDepth', '', { shouldValidate: true });
    //     setValue('sheetPileSubstrateType', '', { shouldValidate: true });
    //     setValue('sheetPileDrivingDurationInDays', '', { shouldValidate: true });
    //     setValue('sheetpilesInstalledPerDay', '', { shouldValidate: true });
    //     setValue('sheetPilePilesInstalledPerDay', '', { shouldValidate: true });
    //     setValue('sheetPileLatestStopDate', '', { shouldValidate: true });
    //     setValue('sheetPileEarliestStartDate', '', { shouldValidate: true });

    //     doClearPileDataByUse('Shoreline Stabilization', ['pileTypes', 'pileDetails', 'pileInstallMethods']);

    //     doUpdatePermitRequestSub('esaConsultantInformation', { proposedStabilizationWidth: null });

    //   }
    // }, [isPileInUnitedStates, includesShorelineStabilization, setValue, doUpdatePermitRequest, doUpdatePermitRequestSub, doClearPileDataByUse]);

    // last field
    // useEffect(() => {
    //   if (isDischargeInUnitedStates === 'false' || includesShorelineStabilization === 'false' || isDischargeInUnitedStates === '' || includesShorelineStabilization === '') {
    //     setValue('proposedStabilizationDistance', '', { shouldValidate: true });
    //     doUpdatePermitRequestSub('esaConsultantInformation', { proposedStabilizationDistance: null });
    //   }
    // }, [includesShorelineStabilization, isDischargeInUnitedStates, setValue, doUpdatePermitRequestSub]);

    useEffect(() => {
      if (rowErrors && tableInvalid !== true) {
        setError('otherNWP', { type: 'custom', message: 'Table contains invalid rows' });
        setTableInvalid(true);
      }
      else if (!rowErrors && tableInvalid !== false) {
        clearErrors('otherNWP');
        setTableInvalid(false);
      }

    }, [rowErrors, otherNWPData.length, setTableInvalid, setError, clearErrors, tableInvalid]);

    useEffect(() => {
      const sectionValid = isValid && !tableInvalid;
      doUpdateSectionValidity(PermitsFormGeneralProjectInformationMetadata.sectionName, sectionValid, stepNo, isReadOnly);
    }, [isValid, doUpdateSectionValidity, stepNo, tableInvalid, isReadOnly]);

    useEffect(() => {

      if (rowErrors) {
        setError('otherNWP', { type: 'custom', message: 'Table contains invalid rows' });
      }
      else if (!rowErrors) {
        clearErrors('otherNWP');
      }
      else {
        clearErrors('otherNWP');
      }

    }, [rowErrors, otherNWPData, clearErrors, setError]);


    useErrorFocus({ steps, stepNo, activeStep, trigger, isReadOnly });


    return (
      <FormProvider {...methods}>
        {errors && thisSectionStepStatus === 'true' && !isReadOnly &&
          <ErrorSummary errors={errors} sectionNo={stepNo} />
        }
        <FieldHeader text='General Project Information' />
        <h6 className='border-bottom w-100 pb-2 mt-4'>Project</h6>
        <div className='ml-2'>
          <InputField label='Project Name' name='projectName' type='text' tooltip='Please provide name identifying the proposed project, e.g., Landmark Plaza, Burned Hills Subdivision, or Edsall Commercial Center' onChange={handleChange} readOnly={isReadOnly} required />
        </div>
        <div className='ml-2'>
          <SelectInput className='w-50' name='previousFileNumbersExist' label='Has the USACE previously issued a file number for any part of the project area?' onChange={handleChange} readOnly={isReadOnly} required>
            <option key='2' value='true'>Yes</option>
            <option key='3' value='false'>No</option>
          </SelectInput>
        </div>
        {previousFileNumbersExist === 'true' && (
          <div className='mt-2 ml-2'>
            <CreatableSelectInput className='mt-2' name='previousFileNumbers' label='Previous File Number(s)' tooltip='Enter the file number and press "ENTER" or click on "Create ..."' placeholder='e.g. MVS-2023-12345, MVM-2020-1234 ...' required multi disabled={isReadOnly} handleChange={handleSelectChange} handleBlur={handleChange} />
          </div>
        )}

        <h6 className='border-bottom w-100 pb-2 mt-4'>Permits Info</h6>
        <div className='ml-2'>
          <MultiSelectInput
            multi
            name='gpPermitTypes'
            label='Identify the specific Nationwide Permit(s) or Regional or Programmatic General Permit you propose to use'
            tooltip='List the number(s) of the Nationwide Permit(s) you want to use to authorize the proposed activity (e.g., NWP 29).'
            options={populateGPPermitTypeOptions()}
            handleChange={handleChange}
            readOnly={isReadOnly} />
        </div>
        <Alert className='ml-2' type='info' heading='NOTE' headingLevel='h4'>You can access additional information about the Nationwide Permit(s) or Regional or Programmatic General Permit you select above, at the following link: <ExternalLink href='https://usace.contentdm.oclc.org/utils/getfile/collection/p16021coll7/id/19757' title='read more here' content='https://usace.contentdm.oclc.org/utils/getfile/collection/p16021coll7/id/19757' /></Alert>
        <Alert className='ml-2' type='info'>The PCN must include a delineation of wetlands, other special aquatic sites, and other waters, such as lakes and ponds, and perennial and intermittent streams, on the project site. Wetland delineations must be prepared in accordance with the current method required by USACE. The permittee may ask USACE to delineate the special aquatic sites and other waters on the project site, but there may be a delay if USACE does the delineation, especially if the project site is large or contains many wetlands, other special aquatic sites, and other waters. Furthermore, the 45-day period will not start until the delineation has been submitted to or completed by USACE, as appropriate.</Alert>
        <label className='mt-3 mb-0 ml-2'>List any other Nationwide Permit(s), Regional General Permit(s), Individual Permit(s) used to authorize any part of the proposed project or any related activity. Also, if any other Certificates or Approvals/Denials have been received from other Federal, State or Local Agencies for Work Described in This Application, please include here and select “Other” in the Type of Approval column <Tooltip name='NWP-tooltip' content='List any other Nationwide Permit(s), Regional General Permit(s), or individual permit(s) used or intended to be used to authorize any part of the proposed project or any related activity. For linear projects, list other separate and distant crossings of waters and wetlands authorized by Nationwide Permits 12 or 14 that do not require PCNs.' clickable={false} iconStyle={{ marginLeft: '5px' }} /></label>
        <div id='otherNWP'>
          <TanStackTableNew
            data={otherNWPData}
            key={otherNWPKey}
            columns={NWPColumns}
            validationSchema={otherNWPValidationSchema}
            rowErrorCallback={setRowErrors}
            addRow={() => setOtherNWPData(prev => prev ? [...prev, {}] : [{}])}
            updateSourceData={updateOtherNWPData}
            removeMultipleRows={removeMultipleRows}
            isReadOnly={isReadOnly}
            initialTableState={{}}
            hideDrag={true}
          />
        </div>
        <fieldset className='mt-3' id='applicableStatutoryAuthority'>
          <legend className='mb-0'>Select Applicable Statutory Authority<span className='asterisk-color'>*</span></legend>
          <Alert className='mt-1' type='info'>If you're unsure of which authorities apply to your project, you can <ExternalLink href={(currentEnv === 'local' || currentEnv === 'development') ? '/home/permitting' : '/rrs/home/permitting'} title='read about permitting requirements' content='read about permitting requirements' aria-label='read about permitting requirements' suppressWarning /> or contact your <ExternalLink href='https://regulatory.ops.usace.army.mil/offices ' title='local regulatory office' content='local regulatory office' aria-label='local regulatory office' />.</Alert>
          <USWDSCheckboxInput
            id='check-section-404'
            name='applicableStatutoryAuthority'
            label={ApplicableStatutoryAuthorities.Section404}
            value={ApplicableStatutoryAuthorities.Section404}
            subtext='Requires authorization from the U.S. Army Corps of Engineers, for the discharge of dredged or fill material into all waters of the United States, including wetlands. Discharges of fill material generally include, without limitation: placement of fill that is necessary for the construction of any structure, or impoundment requiring rock, sand, dirt, or other material for its construction; site-development fills for recreational, industrial, commercial, residential, and other uses; causeways or road fills; dams and dikes; artificial islands; property protection or reclamation devices such as riprap, groins, seawalls, breakwaters, and revetments; beach nourishment; levees; fill for intake and outfall pipes and subaqueous utility lines; fill associated with the creation of ponds; and any other work involving the discharge of fill or dredged material. A USACE permit is required whether the work is permanent or temporary. Examples of temporary discharges include dewatering of dredged material prior to final disposal, and temporary fills for access roadways, cofferdams, storage and work areas.'
            disabled={isReadOnly}
            onChange={handleChange}
          />
          <USWDSCheckboxInput
            id='check-section-10'
            name='applicableStatutoryAuthority'
            label={ApplicableStatutoryAuthorities.Section10}
            value={ApplicableStatutoryAuthorities.Section10}
            subtext='Requires authorization from the U.S. Army Corps of Engineers for the construction of any structure in or over any navigable water of the United States. Structures or work outside the limits defined for navigable waters of the United States require a Section 10 permit if the structure or work affects the course, location, or condition of the water body. The law applies to any dredging or disposal of dredged materials, excavation, filling, re-channelization, or any other modification of a navigable water of the United States, and applies to all structures, from the smallest floating dock to the largest commercial undertaking. It further includes, without limitation, any wharf, dolphin, weir, boom breakwater, jetty, groin, bank protection (e.g. riprap, revetment, bulkhead), mooring structures such as pilings, aerial or subaqueous power transmission lines, intake or outfall pipes, permanently moored floating vessel, tunnel, artificial canal, boat ramp, aids to navigation, and any other permanent, or semi-permanent obstacle or obstruction.'
            disabled={isReadOnly}
            onChange={handleChange}
          />
        </fieldset>
        <div className='ml-2'>
          <TextAreaInput name='projectDescription' label='Project Description (Describe the proposed Nationwide Permit activity)' tooltip='Describe the proposed Nationwide Permit activity, including the direct and indirect adverse environmental effects the activity would cause. The description of the proposed activity should be sufficiently detailed to allow the U.S. Army Corps of Engineers to determine that the adverse environmental effects of the activity will be no more than minimal. Identify the materials to be used in constructions, as well as the methods by which the work is to be done.' onChange={handleChange} readOnly={isReadOnly} required />
        </div>
        <div className='ml-2'>
          <TextAreaInput name='natureOfActivity' label='Nature of Activity' tooltip={natureOfActivityTooltipContent} readOnly={isReadOnly} onChange={handleChange} required />
        </div>
        <div className='ml-2'>
          <TextAreaInput name='projectPurpose' label='Project Purpose (Describe the purpose of the Nationwide Permit activity)' required tooltip='Describe the purpose and need for the proposed Nationwide Permit activity. What will it be used for and why?' onChange={handleChange} readOnly={isReadOnly} />
        </div>
        <div className='ml-2'>
          <label className='mt-3 mb-0'>If the terms of the Nationwide Permit(s) you want to use require additional information to be included in the PCN, please provide here</label>
          <Accordion.List className='w-100 mb-1'>
            <Accordion.Item headingText='The terms of some of the Nationwide Permits include additional information requirements for preconstruction notifications'>
              <div className='row'><div className='col pb-2'><b>NWP 3, Maintenance</b> – information regarding the original design capacities and configurations of the outfalls, intakes, small impoundments, and canals.</div></div>
              <div className='row'><div className='col pb-2'><b>NWP 31, Maintenance of Existing Flood Control Facilities</b> – a description of the maintenance baseline and the dredged material disposal site.</div></div>
              <div className='row'><div className='col pb-2'><b>NWP 33, Temporary Construction, Access, and Dewatering</b> – a restoration plan showing how all temporary fills and structures will be removed and the area restored to pre-project conditions.</div></div>
              <div className='row'><div className='col pb-2'><b>NWP 44, Mining Activities</b> – if reclamation is required by other statutes, then a copy of the final reclamation plan must be submitted with the preconstruction notification.</div></div>
              <div className='row'><div className='col pb-2'><b>NWP 45, Repair of Uplands Damaged by Discrete Events</b> – documentation, such as a recent topographic survey or photographs, to justify the extent of the proposed restoration.</div></div>
              <div className='row'><div className='col pb-2'><b>NWP 48, Commercial Shellfish Aquaculture Activities</b> – (1) a map showing the boundaries of the project area, with latitude and longitude coordinates for each corner of the project area; (2) the name(s) of the species that will be cultivated during the period this NWP is in effect; (3) whether canopy predator nets will be used; (4) whether suspended cultivation techniques will be used; and (5) general water depths in the project area (a detailed survey is not required).</div></div>
              <div className='row'><div className='col pb-2'><b>NWP 49, Coal Remining Activities</b> – a document describing how the overall mining plan will result in a net increase in aquatic resource functions must be submitted to the district engineer and receive written authorization prior to commencing the activity.</div></div>
              <div className='row'><div className='col pb-2'><b>NWP 50, Underground Coal Mining Activities</b> – if reclamation is required by other statutes, then a copy of the reclamation plan must be submitted with the preconstruction notification.</div></div>
            </Accordion.Item>
          </Accordion.List>
          <TextAreaInput className='mt-0' name='navigableWatersAdditionalInfo' onChange={handleChange} readOnly={isReadOnly} />
        </div>
        <div className='ml-2'>
          <SelectInput className='w-50' name='isAnyWorkComplete' label='Is any portion of the Nationwide Permit activity complete?' tooltip='Describe any work that has already been completed for the NWP activity.' onChange={handleChange} readOnly={isReadOnly} required>
            <option key='2' value='true'>Yes</option>
            <option key='3' value='false'>No</option>
          </SelectInput>
        </div>
        {(isAnyWorkComplete === 'true' || isAnyWorkComplete === true) && <div className='ml-2'><TextAreaInput name='completedWorkDescription' label='Describe the completed work' onChange={handleChange} readOnly={isReadOnly} required /></div>}

        {/* Dredge Project Section */}
        {(applicableStatutoryAuthority.length > 0 && applicableStatutoryAuthority?.includes(ApplicableStatutoryAuthorities.Section10)) && (
          <>
            <FieldHeader text='Dredging' subtext='Projects Involving Impacts to Section 10 Navigable Waters' />
            <div className='ml-2'>
              <SelectInput name='isDredgingNavWaters' label='Does the project involve dredging in navigable waters of the United States?' className='w-50' onChange={handleChange} readOnly={isReadOnly} required>
                <option key='2' value='true'>Yes</option>
                <option key='3' value='false'>No</option>
              </SelectInput>
            </div>
            {(isDredgingNavWaters === 'true' || isDredgingNavWaters === true) && (<PermitsDredgeProject handleChange={handleChange} isReadOnly={isReadOnly} />)}
          </>
        )}
        {/* Hiding Pile Driving And Coastal Shoreline Stabilization */}
        {/* <div hidden>
          {(applicableStatutoryAuthority.length > 0) && (
            <>
              <FieldHeader text='Pile Driving' />
              <div className='ml-2'>
                <SelectInput className='w-50' label='Does the project involve the installation of piling within waters of the United States?' name='includesInstallPiling' readOnly={isReadOnly} required onChange={handleChange}>
                  <option key='2' value='true'>Yes</option>
                  <option key='3' value='false'>No</option>
                </SelectInput>
              </div>
              {includesInstallPiling === 'true' && (
                <>
                  <div className='ml-2'>
                    <SelectInput name='doesPilingDischargeFillMaterial' label='Would the project involve the placement of pilings in a manner that would have the effect of a discharge of fill material?' tooltip='Examples of such activities that have the effect of a discharge of fill material include, but are not limited to, the following: Projects where the pilings are so closely spaced that sedimentation rates would be increased; projects in which the pilings themselves effectively would replace the bottom of a waterbody; projects involving the placement of pilings that would reduce the reach or impair the flow or circulation of waters of the United States; and projects involving the placement of pilings which would result in the adverse alteration or elimination of aquatic functions. 33 CFR 323.3(c)' required className='w-50' readOnly={isReadOnly} onChange={handleChange}>
                      <option key='2' value='true'>Yes</option>
                      <option key='3' value='false'>No</option>
                    </SelectInput>
                  </div>
                </>
              )}
              {doesPilingDischargeFillMaterial === 'true' && <Alert className='ml-2' type='info'>This is considered a discharge in waters of the United State pursuant to Section 404 of the Clean Water Act that must be permitted. Accordingly, please quantify this impact in the Impacts section of this form.</Alert>}
              {((applicableStatutoryAuthority?.includes(ApplicableStatutoryAuthorities.Section10) && includesInstallPiling === 'true') || doesPilingDischargeFillMaterial === 'true') && <PermitsPileDriving handleChange={handleChange} isReadOnly={isReadOnly} />}
           <FieldHeader text='Coastal Shoreline Stabilization' />
              <div className='ml-2'>
                <SelectInput name='includesShorelineStabilization' label='Is the purpose of any component of the project shoreline stabilization?' required className='w-50' readOnly={isReadOnly} onChange={handleChange}>
                  <option key='2' value='true'>Yes</option>
                  <option key='3' value='false'>No</option>
                </SelectInput>
              </div>
              {includesShorelineStabilization === 'true' && <PermitsShorelineStabilization handleChange={handleChange} isReadOnly={isReadOnly} />}
            </>
          )
          }
        </div> */}
        <div className='mb-4' />
      </FormProvider >
    );
  });

PermitsFormGeneralProjectInformation.metadata =
  PermitsFormGeneralProjectInformationMetadata;

export default PermitsFormGeneralProjectInformation;
