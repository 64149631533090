import React, { useState } from 'react';

import Icon from '@mdi/react';
import { mdiChevronRight, mdiChevronDown } from '@mdi/js';

import { classArray } from '../../utils/helpers.jsx';
import { Row } from '../../app-pages/_shared/helper.jsx';

import './accordion.scss';

const AccordionListItem = ({
  isDefaultOpen = false,
  onToggle = () => { },
  headingText = '',
  children = null,
  className = '',
  contentClassname = ''
}) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  const itemClasses = classArray([
    'accordion-item',
    className,
  ]);

  const contentClasses = classArray([
    'accordion-collapse',
    'collapse',
    isOpen && 'show',
    contentClassname,
  ]);

  const headingClasses = classArray([
    'accordion-heading',
    isOpen && 'is-open',
    'flex'
  ]);

  const toggleAccordion = () => {
    onToggle(!isOpen);
    setIsOpen(!isOpen);
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      toggleAccordion();
    }
  };

  return (
    <div className={itemClasses}>
      <div className={headingClasses} onClick={() => toggleAccordion()} onKeyUp={handleKeyUp} tabIndex={0}>
        <Row>
          <Icon
            className='ml-3 mb-3 mr-2 accordion-icon'
            size={'24px'}
            path={isOpen ? mdiChevronDown : mdiChevronRight}
          />
          <p>{headingText}</p>
        </Row>
      </div>
      <div className={contentClasses}>
        <div className='accordion-body'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AccordionListItem;
