import React, { useEffect } from 'react';
import { connect } from 'redux-bundler-react';
import FieldHeader from '../components/Form/FieldHeader.jsx';
import ExternalLink from '../../../app-components/link/externalLink.jsx';
import { Alert } from '@trussworks/react-uswds';


export const ViolationsFormInstructionsMetadata = {
  header: 'Report Potential Violation',
  sectionName: 'Instructions',
  isSection: true,
  lastSection: false,
  firstSection: true,
};

const ViolationsFormInstructions = connect(
  'doUpdateSectionValidity',
  ({
    doUpdateSectionValidity,
    stepNo,
  }) => {
    useEffect(() => {
      doUpdateSectionValidity(ViolationsFormInstructionsMetadata.sectionName, true, stepNo);
    }, [doUpdateSectionValidity, stepNo]);

    return (
      <>
        <FieldHeader text='INSTRUCTIONS' />
        <p className='mb-3 mt-3'>
          The <b>report of Unauthorized Activity or Non-compliance request</b> provides the public with an electronic means to report a potential unauthorized activity or permit noncompliance to the U.S. Army Corps of Engineers (USACE).
        </p>
        <p className='mb-3'>
          Enforcement, as part of the overall regulatory program of the USACE, is based on a policy of regulating the waters of the United States by discouraging activities that have not been properly authorized and by requiring corrective measures, where
          appropriate, to ensure those waters are not misused and to maintain the integrity of the Regulatory Program.
        </p>
        <p className='mb-3'>There are two general categories of USACE enforcement: </p>
        <ol>
          <li><b>Unauthorized Activities</b> – These are activities that require USACE authorization but have been performed without required USACE permits.</li>
          <li><b>Permit Non-Compliance</b> – These involve circumstances where a permittee has violated the terms or conditions of an issued permit and where the violation is sufficiently serious to require an enforcement action.</li>
        </ol>
        <p className='mb-3'>
          Please complete all forms fields in the subsequent sections of this form and provide any information that you believe would assist with our investigation. Information such as maps, photographs, alleged violator contact information, if known, and dates/times that the alleged activities occurred
          may be particularly useful to support our investigation. While it is strongly recommended to make the report electronically, you can also make this report to the local Corps district office by traditional mail or email. To locate your local USACE
          Regulatory office, please visit: <ExternalLink href='https://regulatory.ops.usace.army.mil/offices/' />
        </p>
        <p className='mb-3'><b>If you started this request and initially chose "No" when asked "Do you wish to remain anonymous?", but now prefer to select “Yes” to remain anonymous, you may exit this form and start the request again. Then select “Yes” to the anonymous question.</b></p>
        <Alert className='mb-2' type='warning' heading='NOTICE:' headingLevel='h4'>You will not receive update notifications on an anonymous submissions. </Alert>
        <p className='mb-3'>
          Thank you for providing the information in this report. The information provided in the report may be used to initiate an investigation and enforcement proceeding. <b>However, enforcement is a discretionary duty of the USACE and deviation from the
            procedures in the USACE enforcement regulations (33 CFR 326) shall not give rise to a private right of action against a USACE District. Additionally, unless you are party to the enforcement, it is unlikely that the USACE District will provide you
            with regular updates regarding the status of any enforcement action.</b>
        </p>
      </>
    );
  });

ViolationsFormInstructions.metadata = ViolationsFormInstructionsMetadata;

export default ViolationsFormInstructions;
