import React from 'react';
import { ModalContent, ModalHeader, ModalFooter, } from '../../app-components/secondary-modal';

const tooltipModal = ({
  msg,
  title,
  onSave,
  saveText = 'Close',
  cancelText,
  hasCloseButton = false,
  isError,
  size = 'lg',
}) => (
  <ModalContent size={size}>
    <ModalHeader title={title} hasCloseButton={hasCloseButton} hasIcon isError={isError} />
    <section className='modal-body'>
      <div className='container-fluid' style={{ wordWrap: 'break-word' }}>
        {msg}
      </div>
    </section>
    <ModalFooter
      saveText={saveText}
      showCancelButton={cancelText}
      onSave={onSave}
      cancelText={cancelText}
    />
  </ModalContent>);
export default tooltipModal;
