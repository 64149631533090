import React from 'react';
import { connect } from 'redux-bundler-react';
import Select from 'react-select';

import Breadcrumb from '../../../app-components/breadcrumb';
import PageContent from '../../../app-components/page-content';
import SideBar from '../../../app-components/side-bar';
import PermitProcessGuide from './PermitProcessGuide';
import Jurisdiction from './Jurisdiction';
import Permitting from './Permitting';
import PublicNotices from './PublicNotices';
import Mitigation from './Mitigation';
import OverviewResources from './OverviewResources';
import Announcements from './Announcements';

import '../resources.scss';

const headerText = {
  'jurisdiction': 'Jurisdiction',
  'mitigation': 'Mitigation',
  'permit-process-guide': 'Permit Process Guide',
  'permitting': 'Permitting',
  'public-notices': 'Public Notices',
  'regulatory-announcements': 'Regulatory Announcements',
  'regulatory-program': 'Regulatory Program Information',
};

const content = {
  'jurisdiction': <Jurisdiction />,
  'mitigation': <Mitigation />,
  'permit-process-guide': <PermitProcessGuide />,
  'permitting': <Permitting />,
  'public-notices': <PublicNotices />,
  'regulatory-announcements': <Announcements />,
  'regulatory-program': <OverviewResources />,
};

const ContentLayout = connect(
  'doUpdateRelativeUrl',
  'selectRouteParams',
  ({
    doUpdateRelativeUrl,
    routeParams,
  }) => {
    const resource = routeParams.resource;

    return (
      <div className='content-container'>
        <Breadcrumb pathname={`home/${resource}`} />
        <div style={{ paddingTop: '1%' }}>
          <h1>{headerText[resource] || ''}</h1>
        </div>
        <div className='nav-dropdown'>
          <Select
            name='jumpTo'
            placeholder='Jump To...'
            options={[
              { value: '/home/regulatory-program', label: 'Regulatory Program Information' },
              { value: '/home/permit-process-guide', label: 'Permit Process Guide' },
              { value: '/home/jurisdiction', label: 'Jurisdiction' },
              { value: '/home/permitting', label: 'Permitting' },
              { value: '/home/regulatory-announcements', label: 'Regulatory Announcements' },
              { value: '/home/mitigation', label: 'Mitigation' },
            ]}
            onChange={val => doUpdateRelativeUrl(val.value)}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <div>
            <div className='sidebar'>
              <SideBar
                content={[{
                  header: 'Jump To',
                  links: [{
                    href: ['/home/regulatory-program'],
                    text: 'Regulatory Program Information',
                  }, {
                    href: ['/home/permit-process-guide'],
                    text: 'Permit Process Guide',
                  }, {
                    href: ['/home/jurisdiction'],
                    text: 'Jurisdiction',
                  }, {
                    href: ['/home/permitting'],
                    text: 'Permitting',
                  }, {
                    href: ['/home/regulatory-announcements'],
                    text: 'Regulatory Announcements',
                  }, {
                    href: ['/home/mitigation'],
                    text: 'Mitigation',
                  }],
                  content: null,
                  style: {},
                }]}
              />
            </div>
          </div>
          <PageContent>
            <div className='page-content-resources'>
              {content[resource] || null}
            </div>
          </PageContent>
        </div>
      </div>
    );
  }
);

export default ContentLayout;
