import React from 'react';
import { connect } from 'redux-bundler-react';

/**
 * @param {string} title - The title of the Modal 
 * @param {boolean} hasCloseButton - Whether or not there should be a button to clear the input field, default `false`
 */
const ModalHeader = connect(
  'doModalClose',
  'doAuthLogout',
  ({
    doModalClose,
    doAuthLogout,
    title = '',
    hasCloseButton = false,
    isLogout = false
  }) => {
    const handleClick = e => {
      doModalClose();
      isLogout && doAuthLogout();
    };

    return (
      <header className='modal-header'>
        <h5 className='modal-title'>{title}</h5>
        {hasCloseButton && <span title='Close Modal' className='close pointer text-primary' onClick={handleClick} tabIndex={0}>&times;</span>}
      </header>
    );
  });

export default ModalHeader;
