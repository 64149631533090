import React, { useState, useEffect } from 'react';
import { connect } from 'redux-bundler-react';

import Icon from '@mdi/react';
import { mdiArrowLeftThick, mdiCloudUploadOutline, mdiMapMarkerRadius, mdiFilePdfBox } from '@mdi/js';
import { Alert, Button, ButtonGroup } from '@trussworks/react-uswds';

import { filterNullEmptyObjects } from '../../../../utils/helpers.jsx';
import ProjectLocationModal from '../modals/ProjectLocationModal.jsx';
import NavbarNavigateWarningModal from '../modals/NavbarNavigateWarningModal.jsx';

import TimeAgo from 'react-timeago';

import './FormHeader.scss';

const FormHeader = connect(
  'doUploadAndUpdateViolationComplaint',
  'doUploadAndUpdatePreApp',
  'doUploadAndUpdateJD',
  'doUploadAndUpdatePermits',
  'doGenerateDraftDocuments',
  'doModalOpen',
  'doUpdateRelativeUrl',
  'selectSelectedRequest',
  'selectProjectID',
  'selectRequestFormData',
  'selectIsReadOnly',
  ({
    doUploadAndUpdateViolationComplaint,
    doUploadAndUpdatePreApp,
    doUploadAndUpdateJD,
    doUploadAndUpdatePermits,
    doGenerateDraftDocuments,
    doModalOpen,
    doUpdateRelativeUrl,
    selectedRequest,
    requestFormData,
    header,
    isReadOnly,
    disableButtons,
    disableBack,
    disableSaveProgress,
    disableLocationButton,
  }) => {
    const [date, setDate] = useState(new Date());

    const formatter = (value, unit, suffix) => {
      if (unit !== 'second' && suffix !== 'from now') {
        return ['Last saved:', value, unit + (value !== 1 ? 's' : ''), suffix].join(' ');
      }

      if (suffix === 'ago') {
        return 'Last saved: a few seconds ago';
      }

      if (suffix === 'from now') {
        return 'Last saved: Just now';
      }
    };
    useEffect(() => {
      if (requestFormData?.requestLastUpdatedDate || requestFormData?.createdDate) {

        const fixedDateString = requestFormData?.requestLastUpdatedDate ? requestFormData.requestLastUpdatedDate : requestFormData?.createdDate ? requestFormData.createdDate : null ?? requestFormData?.createdDate;

        const lastModifiedZulu = new Date(fixedDateString);
        //temp fix as time is being returned from DB as Zulu time when it is actually local server time

        setDate(lastModifiedZulu);
      }
    }, [requestFormData?.requestLastUpdatedDate, requestFormData?.createdDate]);

    const saveFormDraft = () => {

      const payload = filterNullEmptyObjects(requestFormData);

      switch (selectedRequest) {
        case '1':
          doUploadAndUpdateJD(payload, 'Draft');
          break;
        case '2':
          doUploadAndUpdateJD(payload, 'Draft');
          break;
        case '3':
          doUploadAndUpdatePermits(payload, 'Draft');
          break;
        case '4':
          doUploadAndUpdatePermits(payload, 'Draft');
          break;
        case '5':
          doUploadAndUpdatePreApp(payload, 'Draft');
          break;
        case '6':
          doUploadAndUpdateViolationComplaint(payload, 'Draft');
          break;
        default:
          break;
      }
    };

    const handleLocationClick = () => {
      doModalOpen(ProjectLocationModal);
    };

    const onBackClickHandler = () => {
      isReadOnly ? doUpdateRelativeUrl('/dashboard') : doModalOpen(NavbarNavigateWarningModal, { url: '/' });
    };

    return (
      <div className='container-fluid'>
        <div className='row form-app-header'>
          <div className='col-sm-12 col-lg-1  exit-form-col d-flex'>
            {!disableButtons && !disableBack &&
              <Button title='Exit Form' className='usa-button--secondary exit-form-button' onClick={onBackClickHandler} tabIndex={0}><Icon title='Exit Form' className='form-app-icon' path={mdiArrowLeftThick} size={1} /><span className='exit-form-text'>Exit Form</span></Button>}
          </div>
          <div className='col-sm-6 col-md-6 col-lg-3 align-items-center project-name-col'>
            <div className='row ml-1 project-name-row'>
              <Alert noIcon='true' heading={header} headingLevel='h4' className='project-name-alert'>{requestFormData?.projectName && <div>{requestFormData?.projectName}</div>}</Alert>
            </div>
          </div>
          <div className='col-sm-12 col-md-8 col-lg-8 save-prog-col d-flex'>
            {!disableButtons && !disableSaveProgress && !isReadOnly && <div className='last-saved mr-2'> {date && <div><TimeAgo date={date} formatter={formatter} /></div>}</div>}
            {!disableButtons && !disableSaveProgress && !isReadOnly &&
              <Button title='Save Progress' className='usa-button save-prog-button' onClick={saveFormDraft} tabIndex={0}><Icon title='Save Progress' className='form-app-icon' path={mdiCloudUploadOutline} size={1} />
                Save Progress</Button>}
            {!disableButtons && !isReadOnly &&
              <Button title='Download all draft documents with currently entered information for this request' className='usa-button draft-pdf-button' onClick={() => doGenerateDraftDocuments()} tabIndex={0}><Icon title='Download all draft documents with currently entered information for this request' className='form-app-icon' path={mdiFilePdfBox} size={1} />
                Download Draft PDF(s)</Button>}
            {isReadOnly && <div className='col'></div>}
            {!disableButtons && !disableLocationButton &&
              <Button title={`${selectedRequest === '6' ? 'Site Location' : 'Project Location'}`} className='usa-button proj-loc-button' onClick={handleLocationClick} tabIndex={0}><Icon title={`${selectedRequest === '6' ? 'Site Location' : 'Project Location'}`} className='' path={mdiMapMarkerRadius} size={1} />
                {`${selectedRequest === '6' ? 'Site Location' : 'Project Location'}`}</Button>
            }
          </div>
          <div className='col-sm-6 col-md-6 collapsed-last-saved'>
            {!disableButtons && !disableSaveProgress && !isReadOnly && <div className='last-saved mr-2'> {date && <div><TimeAgo date={date} formatter={formatter} /></div>}</div>}
          </div>
          <div className='col-md-12 collapsed-form-header-button-group'>
            <ButtonGroup type='segmented'>
              {!disableButtons && !disableBack &&
                <Button title='Exit Form' className='usa-button--secondary' onClick={onBackClickHandler} tabIndex={0}><Icon title='Exit Form' className='form-app-icon' path={mdiArrowLeftThick} size={1} />Exit Form</Button>}
              {!disableButtons && !disableSaveProgress && !isReadOnly &&
                <Button title='Save Progress' className='usa-button save-prog-button' onClick={saveFormDraft} tabIndex={0}><Icon title='Save Progress' className='form-app-icon' path={mdiCloudUploadOutline} size={1} />
                  Save Progress</Button>}
              {!disableButtons && !isReadOnly &&
                <Button title='Download all draft documents with currently entered information for this request' className='usa-button' onClick={() => doGenerateDraftDocuments()} tabIndex={0}><Icon title='Download all draft documents with currently entered information for this request' className='form-app-icon' path={mdiFilePdfBox} size={1} />
                  Download Draft PDF(s)</Button>}
              {!disableButtons && !disableLocationButton &&
                <Button title={`${selectedRequest === '6' ? 'Site Location' : 'Project Location'}`} className='usa-button proj-loc-button' onClick={handleLocationClick} tabIndex={0}><Icon title={`${selectedRequest === '6' ? 'Site Location' : 'Project Location'}`} className='' path={mdiMapMarkerRadius} size={1} />
                  {`${selectedRequest === '6' ? 'Site Location' : 'Project Location'}`}</Button>}
            </ButtonGroup>
          </div>
        </div>
      </div >
    );
  });
export default FormHeader;
