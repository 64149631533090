import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { connect } from 'redux-bundler-react';
import * as turf from '@turf/turf';
import { createColumnHelper } from '@tanstack/react-table';

import TanStackTable from '../../../../app-components/table/TanStackTable.jsx';
import { TableCell } from '../../../../app-components/table/tableCellComponents/TableCell';
import ARTypeTableCell from '../../../../app-components/table/tableCellComponents/ARTypeTableCell.jsx';
import RegulationTypeTableCell from '../../../../app-components/table/tableCellComponents/RegulationTypeTableCell.jsx';
import MeasurementUnitTableCell from '../../../../app-components/table/tableCellComponents/MeasurementUnitTableCell.jsx';
import JDTypeTableCell from '../../../../app-components/table/tableCellComponents/JDTypeTableCell.jsx';
import { EditCell } from '../../../../app-components/table/tableCellComponents/EditCell';
import FieldHeader from '../../components/Form/FieldHeader';
import ResourceMap from '../../../../app-components/map/resourceMap';
import LinkButton from '../../../../app-components/link/linkButton.jsx';
import Accordion from '../../../../app-components/accordion/index.js';

import { stateAbbreviations } from '../_helper.jsx';
import { formatCoordFlt, formatCoordStr, LatLngOutsideUS } from '../../../../utils/helpers.jsx';
import { FileTypes, TemplateFiles } from '../../../../utils/enums.tsx';
import { latRegex, lngRegex, setNumberValue } from '../../../../utils/regex.jsx';
import { Row } from '../../../_shared/helper';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Icon from '@mdi/react';
import { mdiFolderUploadOutline, mdiMagnify, mdiPlus, mdiMinus, mdiMapMarker, mdiTrashCanOutline, mdiLayersTriple } from '@mdi/js';

import { fullscreenSVG, polyLineSVG, polygonSVG } from '../../../../utils/svg.jsx';
import ErrorSummary from '../../../../app-components/error-summary/ErrorSummary.jsx';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import useErrorFocus from '../../../../customHooks/useErrorFocus.jsx';

export const JDFormAquaticResourcesMetadata = {
  sectionName: 'Aquatic Resource Inventory',
  isSection: true,
  lastSection: false,
  firstSection: false,
};

const mapStateToLongForm = (state) => {
  if (state) {
    const longname = stateAbbreviations[state?.toUpperCase()];
    if (longname) {
      return longname;
    }
    else {
      return null;
    }
  }
  else {
    return null;
  }
};

const JDFormAquaticResources = connect(
  'doDownloadFile',
  'doUpdateSectionValidity',
  'doUpdateRequestAquaticResources',
  'selectJDData',
  'selectGDBResourcesResults',
  'selectRequestFormData',
  'selectRequestAPIData',
  'selectIsReadOnly',
  'selectLookupTableData',
  'selectSteps',
  'selectActiveStep',
  ({
    doDownloadFile,
    doUpdateSectionValidity,
    doUpdateRequestAquaticResources,
    jDData,
    gDBResourcesResults,
    requestFormData,
    requestAPIData,
    isReadOnly,
    stepNo,
    lookupTableData,
    steps,
    activeStep
  }) => {
    const [resourceData, setResourceData] = useState({
      type: 'FeatureCollection',
      features: []
    });
    const tableData = useMemo(() => resourceData.features.map(feature => feature.properties), [resourceData]);
    const isWaterNameUnique = useCallback((waterName) => resourceData?.features?.filter(feature => feature?.properties?.waterName?.toUpperCase() === waterName?.toUpperCase()).length <= 1, [resourceData]);
    const [tableInvalid, setTableInvalid] = useState(true);
    const [tableKey, setTableKey] = useState(0);
    const [rowErrors, setRowErrors] = useState([]);
    const columnHelper = createColumnHelper();
    const linearOptions = useMemo(() => [{ value: 'FOOT', label: 'Foot' }], []);
    const areaOptions = useMemo(() => [{ value: 'SQ_FT', label: 'Sq Ft.' }, { value: 'ACRE', label: 'Acre' }], []);
    const projectState = requestFormData?.location?.state ? mapStateToLongForm(requestFormData?.location?.state) : '' ?? '';
    const thisSectionStepStatus = useMemo(() => steps?.find(step => step.id === stepNo)?.touched, [steps, stepNo]);

    const schema = yup.object().shape({});

    const methods = useForm({ resolver: yupResolver(schema), mode: 'onBlur' });
    const { formState: { errors }, setError, clearErrors, trigger } = methods;

    const JDType = jDData?.jurisdictionalDetermination && jDData?.jurisdictionalDetermination !== 'Preliminary+Approved' ? jDData?.jurisdictionalDetermination : null ?? null;
    const JDRequests = requestFormData?.request?.jdRequests;

    yup.addMethod(yup.string, 'uniqueWaterName', function (message) {
      return this.test('unique-water-name', message, function (value) {
        const isUnique = isWaterNameUnique(value);
        return isUnique || new yup.ValidationError(message, null, this.path);
      });
    });

    const validationSchema = yup.object().shape({
      jurisdictionalDeterminationType: yup.string().nullable(),
      waterName: yup.string().required('Please enter a water name').uniqueWaterName('Water name must be unique'),
      state: yup.string().required('Please select a state'),
      latitude: yup
        .string()
        .test('latFormat', 'Latitude format is incorrect. Must be +-XX.XXXXXX and include at least 6 decimal places.', val => latRegex.test(val))
        .test({
          name: 'latInRange',
          exclusive: true,
          message: 'Latitude must be between 0 and 90',
          test: val => !LatLngOutsideUS(requestFormData?.location?.latitude, requestFormData?.location?.longitude) ? Number(val) <= 90 && Number(val) >= 0 : true,
        })

        .required('Latitude is required'),
      longitude: yup
        .string()
        .test('lngFormat', 'Longitude format is incorrect. Must be +-XXX.XXXXXX and include at least 6 decimal places.', val => lngRegex.test(val))
        .test({
          name: 'lngInRange',
          exclusive: true,
          message: 'Longitude must be between -180 and 0',
          test: val => !LatLngOutsideUS(requestFormData?.location?.latitude, requestFormData?.location?.longitude) ? Number(val) <= 0 && Number(val) >= -180 : true,
        })
        .required('Longitude is required'),
      measurementAmount: yup.string().required('Please enter a measurement amount').test('zeroTest', 'Value must be more than 0', function (item) { return item > 0; }),
      measurementType: yup.string().required('Please select a measurement type'),
      measurementUnit: yup.string().required('Please select a unit type'),
      hgmCode: yup.string().nullable(),
      localWaterway: yup.string().nullable(),
      cowardinCode: yup.string().nullable(),
      aquaticResourceType: yup.string().nullable(),
    });

    const ARColumns = useMemo(() =>
      [
        columnHelper.display({
          id: 'edit',
          cell: isReadOnly ? <></> : EditCell,
          size: 20,
          enableResizing: false,
          enableSorting: false,
        }),
        columnHelper.accessor('waterName', {
          header: 'Water Name/Label',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
          meta: {
            type: 'text',
            required: true,
          },
        }),
        columnHelper.accessor('state', {
          header: 'State',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
          meta: {
            required: true,
            type: 'select',
            options: [
              ...Object.entries(stateAbbreviations).map(([abbreviation, fullName]) => ({
                value: fullName,
                label: fullName,
              })),
            ],
          },
        }),
        columnHelper.accessor('latitude', {
          header: 'Latitude',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
          meta: {
            required: true,
            maxLength: '12',
            formatCoordStr: formatCoordStr
          },
        }),
        columnHelper.accessor('longitude', {
          header: 'Longitude',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
          meta: {
            required: true,
            maxLength: '12',
            formatCoordStr: formatCoordStr
          },
        }),
        columnHelper.accessor('measurementType', {
          header: 'Measurement Type',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
          meta: {
            type: 'select',
            required: true,
            options: [
              { value: 'Area', label: 'Area' },
              { value: 'Linear', label: 'Linear' },
            ],
          },
        }),
        columnHelper.accessor('measurementAmount', {
          header: 'Measurement Amount',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
          meta: {
            type: 'number',
            required: true,
          },
        }),
        columnHelper.accessor('measurementUnit', {
          header: 'Measurement Unit',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : MeasurementUnitTableCell,
          meta: {
            type: 'select',
            required: true,
            options: [areaOptions, linearOptions],
          },
        }),
        columnHelper.accessor('regulationType', {
          header: 'Regulation Type',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : RegulationTypeTableCell,
          enableHiding: true,
          meta: {
            type: 'text',
            readOnly: true,
            required: true,
            state: projectState,
            fallbackRegulation: 'PRE2015_POSTSACKETT'
          },
        }),
        columnHelper.accessor('localWaterway', {
          header: 'Local Waterway',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
          meta: {
            type: 'text',
            required: false,
          },
        }),
        columnHelper.accessor('cowardinCode', {
          header: 'Cowardin Code',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
          meta: {
            type: 'select',
            options:
              lookupTableData.Cowardins.map((code) => ({
                value: code.shortCode,
                label: code.shortCode,
              })),
          },
        }),
        columnHelper.accessor('hgmCode', {
          header: 'HGM Code',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
          meta: {
            type: 'select',
            options: lookupTableData.HGMCodes.map((code) => ({
              value: code.shortCode,
              label: code.shortCode,
            })),
          },
        }),
        columnHelper.accessor('jurisdictionalDeterminationType', {
          header: 'JD Type',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : JDTypeTableCell,
          enableHiding: true,
          meta: {
            type: 'select',
            initialJDType: JDType,
            options: [
              { value: 'No JD Requested', label: 'No JD Requested' },
              { value: 'Preliminary', label: 'Preliminary' },
              { value: 'Approved', label: 'Approved' },
            ],
          },
        }),
        columnHelper.accessor('aquaticResourceType', {
          header: 'Aquatic Resource Type',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : ARTypeTableCell,
        }),
      ], [JDType, areaOptions, columnHelper, isReadOnly, linearOptions, lookupTableData.Cowardins, lookupTableData.HGMCodes, projectState]);

    const determineRegulation = useCallback((state) => {
      const regulationIndex = lookupTableData?.StateRegulations?.findIndex(statereg => statereg?.state?.toUpperCase() === state?.toUpperCase());
      const regulation = lookupTableData?.StateRegulations?.[regulationIndex]?.regulationType;
      return regulation;
    }, [lookupTableData?.StateRegulations]);

    const toProperCase = useCallback((str) =>
      str.split(' ')
        .map(word => word?.charAt(0)?.toUpperCase() + word.slice(1).toLowerCase())
        .join(' '), []);

    const toJDType = useCallback((str) => {
      const upperStr = str?.toUpperCase();
      if (upperStr === 'NO JD REQUESTED') {
        return 'No JD Requested';
      }
      else {
        return toProperCase(str);
      }
    }, [toProperCase]);

    const mapToMeasurementUnit = useCallback((val) => {
      const valToUpper = val?.toUpperCase();
      switch (valToUpper) {
        case 'SQUARE FEET':
        case 'SQUARE FOOT':
        case 'SQUARE FT':
        case 'SQUARE FT.':
        case 'SQ FT':
        case 'SQ. FT.':
        case 'SQ FT.':
        case 'SQ. FT':
        case 'SQ_FT':
          return 'SQ_FT';
        case 'ACRE':
        case 'ACRES':
        case 'ACREAGE':
          return 'ACRE';
        case 'FOOT':
        case 'FT':
        case 'LINEAR FOOT':
        case 'LINEAR FEET':
        case 'FEET':
          return 'FOOT';
        default:
          return null;
      }
    }, []);

    const formatState = useCallback((state) => {
      // Normalize input to uppercase
      const upperState = state?.toUpperCase();

      // Check if the input is an abbreviation
      if (stateAbbreviations.hasOwnProperty(upperState)) {
        return stateAbbreviations[upperState];
      }

      const fullStateName = Object.entries(stateAbbreviations).find(([key, value]) => value === upperState);
      if (fullStateName) {
        return fullStateName[1];
      }

      console.error('No State Found!');
      return null;
    }, []);

    const addFeature = useCallback((optionalProperties, optionalGeometry) => {

      const defaultGeometry = {
        type: 'Point',
        coordinates: [optionalProperties?.longitude ? formatCoordFlt(optionalProperties.longitude) : 0 ?? 0, optionalProperties?.latitude ? formatCoordFlt(optionalProperties.latitude) : 0 ?? 0], // Default coordinates
      };

      const newFeature = {
        type: 'Feature',
        properties: {
          ...optionalProperties,
          latitude: optionalProperties?.latitude ? formatCoordStr(optionalProperties.latitude) : null,
          longitude: optionalProperties?.longitude ? formatCoordStr(optionalProperties.longitude) : null,
          measurementAmount: optionalProperties?.measurementAmount ? Number(optionalProperties.measurementAmount) : null,
          state: optionalProperties?.state ? formatState(optionalProperties.state) : (projectState && projectState !== null) ? projectState : null ?? null,
          regulationType: optionalProperties?.regulationType ? optionalProperties.regulationType : (projectState && projectState !== '') ? determineRegulation(projectState) : 'PRE2015_POSTSACKETT',
          jurisdictionalDeterminationType: optionalProperties?.jurisdictionalDeterminationType ? optionalProperties.jurisdictionalDeterminationType : JDRequests?.[0]?.jurisdictionalDetermination ?? null,
          hgmCode: optionalProperties?.hgmCode ? optionalProperties.hgmCode : null ?? null,
          cowardinCode: optionalProperties?.cowardinCode ? optionalProperties.cowardinCode : null ?? null,
          aquaticResourceType: optionalProperties?.aquaticResourceType ? optionalProperties.aquaticResourceType : null ?? null,
        },
        geometry: optionalGeometry || defaultGeometry
      };

      setResourceData((oldData) => {
        // Check if oldData is already a valid feature collection
        if (oldData && oldData.type === 'FeatureCollection' && Array.isArray(oldData.features)) {
          return { ...oldData, features: [...oldData.features, newFeature] };
        }

        // Initialize a new feature collection (if needed)
        return {
          type: 'FeatureCollection',
          features: [newFeature]
        };
      });
    }, [determineRegulation, projectState, JDRequests, setResourceData, formatState]);

    const updateResourceData = useCallback((rowIndex, columnId, updatedValue) => {
      setResourceData(oldData => {
        const newData = { ...oldData };
        if (newData.features && newData.features[rowIndex]) {
          if (columnId === 'latitude' || columnId === 'longitude') {
            if (!isNaN(updatedValue)) {
              newData.features[rowIndex].properties = {
                ...newData.features[rowIndex].properties,
                [columnId]: formatCoordStr(updatedValue)
              };
            }
          } else {
            newData.features[rowIndex].properties = {
              ...newData.features[rowIndex].properties,
              [columnId]: updatedValue
            };
          }

          if (newData?.features?.[rowIndex]?.geometry?.type === 'Point' &&
            (columnId === 'latitude' || columnId === 'longitude')) {
            const { latitude, longitude } = newData.features[rowIndex].properties;
            if (!isNaN(latitude) && !isNaN(longitude)) {
              newData.features[rowIndex].geometry.coordinates = [
                formatCoordFlt(longitude),
                formatCoordFlt(latitude)
              ];
            }
          }
        }
        return newData;
      });
    }, [setResourceData]);

    const addFeaturesFromRows = useCallback((rows) => {
      const columnToPropertyMap = {
        'Water Name/Label': 'waterName',
        'State': 'state',
        'Latitude': 'latitude',
        'Longitude': 'longitude',
        'Measurement Amount': 'measurementAmount',
        'Measurement Type': 'measurementType',
        'Measurement Unit': 'measurementUnit',
        'JD Type': 'jurisdictionalDeterminationType',
        'Cowardin Code': 'cowardinCode',
        'Aquatic Resource Type': 'aquaticResourceType',
        'Regulation Type': 'regulationType',
        'Local Waterway': 'localWaterway',
        'HGM Code': 'hgmCode',
      };

      const newFeatures = rows.map(row => {
        const properties = {};

        Object.entries(row).forEach(([key, value]) => {
          const propertyKey = columnToPropertyMap[key] || key;
          properties[propertyKey] = value;
        });
        // Apply any additional transformations or default values
        return {
          type: 'Feature',
          properties: {
            ...properties,
            latitude: properties?.latitude ? formatCoordStr(properties.latitude) : null,
            longitude: properties?.longitude ? formatCoordStr(properties.longitude) : null,
            measurementAmount: properties?.measurementAmount ? Number(properties.measurementAmount) : null,
            measurementType: properties?.measurementType ? toProperCase(properties.measurementType) : null,
            measurementUnit: properties?.measurementUnit ? mapToMeasurementUnit(properties.measurementUnit) : null,
            state: properties?.state ? formatState(properties.state) : projectState,
            regulationType: properties?.state ? determineRegulation(formatState(properties.state)) : 'PRE2015_POSTSACKETT',
            jurisdictionalDeterminationType: properties?.jurisdictionalDeterminationType ? toJDType(properties.jurisdictionalDeterminationType) : JDRequests?.[0]?.jurisdictionalDetermination ?? null,
            hgmCode: properties?.hgmCode ? properties.hgmCode.toUpperCase() : null,
            cowardinCode: properties?.cowardinCode ? properties.cowardinCode.toUpperCase() : null,
            aquaticResourceType: properties?.aquaticResourceType ? properties.aquaticResourceType.toUpperCase() : null,
          },
          geometry: {
            type: 'Point',
            coordinates: [properties.longitude ? formatCoordFlt(properties.longitude) : 0, properties.latitude ? formatCoordFlt(properties.latitude) : 0]
          },
        };
      });
      setResourceData(oldData => ({
        ...oldData,
        features: [...oldData.features, ...newFeatures],
      }));
      setTableKey(old => old + 1);
    }, [determineRegulation, projectState, JDRequests, toJDType, mapToMeasurementUnit, toProperCase, formatState]);

    const addFeatures = useCallback((featuresToAdd) => {
      setResourceData(oldData => {
        if (oldData && oldData.type === 'FeatureCollection' && Array.isArray(oldData.features)) {
          return {
            ...oldData,
            features: [...oldData.features, ...featuresToAdd]
          };
        }
        return {
          type: 'FeatureCollection',
          features: featuresToAdd
        };
      });
      setTableKey(old => old + 1);
    }, [setResourceData, setTableKey]);

    const removeMultipleFeatures = useCallback((indicesToRemove) => {
      setResourceData((oldData) => {
        const newFeatures = oldData.features.filter((_, index) => !indicesToRemove.includes(index));
        return { ...oldData, features: newFeatures };
      });
      setTableKey(old => old + 1);
    }, [setResourceData, setTableKey]);

    //GDB UPLOAD DATA PARSING
    useEffect(() => {
      if (gDBResourcesResults) {

        const ARState = requestFormData?.location?.state ? mapStateToLongForm(requestFormData.location.state) : '';
        //Populate table with data from each object

        const propertyMapping = {
          'jd_type': 'jurisdictionalDeterminationType',
          'waters_name': 'waterName',
          'state': 'state',
          'latitude': 'latitude',
          'longitude': 'longitude',
          'meas_type': 'measurementType',
          'units': 'measurementUnit',
          'amount': 'measurementAmount',
          'hgm_code': 'hgmCode',
          'local_waterway': 'localWaterway',
          'cowardin_code': 'cowardinCode',
          'regulation_type': 'regulationType',
          'waters_type': 'aquaticResourceType',
        };

        const gdbResourceFeatures = gDBResourcesResults.features.map((feature, i) => {

          const normalizedProperties = Object.keys(feature.properties).reduce((acc, key) => {
            const lowerCaseKey = key.toLowerCase();
            if (lowerCaseKey in propertyMapping) {
              acc[propertyMapping[lowerCaseKey]] = feature.properties[key];
            }
            return acc;
          }, {});

          let centroidCoordinates;

          switch (feature?.geometry?.type?.toUpperCase()) {
            case 'MULTIPOINT': {
              const geoJSONMultiPoint = turf.multiPoint(feature.geometry.coordinates);
              const centroid = turf.centroid(geoJSONMultiPoint);
              centroidCoordinates = centroid.geometry.coordinates;
              break;
            }
            case 'POINT': {
              const geoJSONPoint = turf.point(feature.geometry.coordinates);
              const centroid = turf.centroid(geoJSONPoint);
              centroidCoordinates = centroid.geometry.coordinates;
              break;
            }
            case 'MULTILINESTRING': {
              const geoJSONMultiLineString = turf.multiLineString(feature.geometry.coordinates);
              if (geoJSONMultiLineString.geometry.coordinates.length > 0 && geoJSONMultiLineString.geometry.coordinates[0].length > 0) {
                const centroid = turf.centroid(geoJSONMultiLineString);
                centroidCoordinates = centroid.geometry.coordinates;
              }
              else {
                centroidCoordinates = null;
              }
              break;
            }
            default: {
              const multiPolygon = Array.isArray(feature.geometry.coordinates[0][0][0]) ? feature.geometry.coordinates : [feature.geometry.coordinates];
              const geoJSONMultiPolygon = turf.multiPolygon(multiPolygon);
              const centroid = turf.centroid(geoJSONMultiPolygon);
              centroidCoordinates = centroid.geometry.coordinates;
            }
          }

          //@TODO Replace hardcoded RAPANOS with valid regulation type according to state and/or watersType
          return {
            ...feature,
            properties: {
              jurisdictionalDeterminationType: normalizedProperties?.jurisdictionalDeterminationType ? normalizedProperties.jurisdictionalDeterminationType : JDType !== 'Preliminary+Approved' ? JDType : null ?? null,
              waterName: normalizedProperties?.waterName ?? `${feature.geometry.type}_${i}`,
              state: normalizedProperties?.state ? formatState(normalizedProperties.state) : ARState ?? ARState,
              latitude: centroidCoordinates ? formatCoordStr(centroidCoordinates[1]) : null ?? null,
              longitude: centroidCoordinates ? formatCoordStr(centroidCoordinates[0]) : null ?? null,
              measurementType: normalizedProperties?.measurementType ? toProperCase(normalizedProperties.measurementType) : null ?? null,
              measurementUnit: normalizedProperties?.measurementUnit ? mapToMeasurementUnit(normalizedProperties.measurementUnit) : null ?? null,
              measurementAmount: normalizedProperties?.measurementAmount ? Number(normalizedProperties.measurementAmount) : null ?? null,
              hgmCode: normalizedProperties?.hgmCode ? normalizedProperties.hgmCode.toUpperCase() : null ?? null,
              localWaterway: normalizedProperties?.localWaterway ? normalizedProperties.localWaterway : null ?? null,
              cowardinCode: normalizedProperties?.cowardinCode ? normalizedProperties.cowardinCode.toUpperCase() : null ?? null,
              regulationType: normalizedProperties?.state ? determineRegulation(normalizedProperties.state) : 'PRE2015_POSTSACKETT' ?? 'PRE2015_POSTSACKETT',
              aquaticResourceType: normalizedProperties?.aquaticResourceType ? normalizedProperties.aquaticResourceType.toUpperCase() : null ?? null,
            },
          };
        });

        addFeatures(gdbResourceFeatures);
      }
    }, [gDBResourcesResults, addFeatures, determineRegulation, requestFormData.location.state, JDType, formatState, mapToMeasurementUnit, toProperCase]);

    useEffect(() => {
      doUpdateSectionValidity(JDFormAquaticResourcesMetadata.sectionName, !tableInvalid, stepNo, isReadOnly);
    }, [tableInvalid, doUpdateSectionValidity, stepNo, isReadOnly]);

    useEffect(() => {
      const tableEmpty = Object.values(tableData)?.length < 1;
      if (tableEmpty) {
        setError('aquaticResources', { type: 'custom', message: 'At least one aquatic resource is required' });
        setTableInvalid(true);
      }
      else if (rowErrors) {
        setError('aquaticResources', { type: 'custom', message: 'Table contains invalid rows' });
        setTableInvalid(true);
      }
      else if ((!rowErrors && !tableEmpty)) {
        clearErrors('aquaticResources');
        setTableInvalid(false);
      }

    }, [rowErrors, tableData, setTableInvalid, setError, clearErrors]);


    useEffect(() => {
      const determineJDType = (currentType) => {
        const specifiedType = jDData.jurisdictionalDetermination;

        if (specifiedType === 'Preliminary+Approved' && currentType) {
          return currentType;
        }
        else if (specifiedType === 'Approved') {
          return 'Approved';
        }
        else if (specifiedType === 'Preliminary') {
          return 'Preliminary';
        }
        else if (specifiedType === 'No JD Requested') {
          return 'No JD Requested';
        }
        else if (specifiedType === 'Unsure') {
          return 'Unsure';
        }
        else {
          return '';
        }

      };

      setResourceData(prevResourceData => {
        // Only proceed if resourceData is defined
        if (!prevResourceData) {
          return prevResourceData;
        }

        const updatedFeatures = prevResourceData.features.map(feature => {
          const newJDType = determineJDType(feature.properties.jurisdictionalDeterminationType);

          // Only update the feature if the jurisdictionalDeterminationType changes
          if (newJDType !== feature.properties.jurisdictionalDeterminationType) {
            return {
              ...feature,
              properties: {
                ...feature.properties,
                jurisdictionalDeterminationType: newJDType
              }
            };
          }

          // Return the feature unchanged if no updates are needed
          return feature;
        });

        // Construct the updated resourceData object
        const updatedResourceData = {
          ...prevResourceData,
          features: updatedFeatures
        };

        // Return the updated resourceData to update the state
        return updatedResourceData;
      });

    }, [jDData.jurisdictionalDetermination]);

    useEffect(() => {
      if (resourceData) {
        const aquaticResources = resourceData.features.map(feature => ({
          ...feature.properties,
          latitude: formatCoordFlt(feature.properties.latitude),
          longitude: formatCoordFlt(feature.properties.longitude),
          measurementAmount: setNumberValue(feature?.properties?.measurementAmount, false),
          geometry: feature.geometry,
        }));

        doUpdateRequestAquaticResources(aquaticResources);
      }

    }, [resourceData, doUpdateRequestAquaticResources]);

    // Load Aquatic Resources from DB
    useEffect(() => {
      if (requestAPIData?.request?.aquaticResources) {

        const mappedResources = {
          type: 'FeatureCollection', features: requestAPIData.request.aquaticResources.map(resource => {
            const { geometry, ...properties } = resource;

            const newProperties = {
              ...properties,
              latitude: formatCoordStr(properties.latitude),
              longitude: formatCoordStr(properties.longitude),
              measurementAmount: setNumberValue(properties?.measurementAmount, false)
            };

            return ({ type: 'Feature', properties: newProperties, geometry: geometry });

          })
        };
        setResourceData(mappedResources);
      }

    }, [requestAPIData]);


    useErrorFocus({ steps, stepNo, activeStep, trigger, noTrigger: true, isReadOnly });

    return (
      <FormProvider {...methods}>
        {errors && thisSectionStepStatus === 'true' && !isReadOnly &&
          <ErrorSummary errors={errors} sectionNo={stepNo} />
        }
        <FieldHeader
          text='Aquatic Resource Inventory'
          subtext={<>
            Provide an inventory of all aquatic resources within the project site. Aquatic resources can be provided in this section by adding them individually or bulk loading them using the provided bulk upload CSV or geodatabase (GDB) templates.
          </>}
        >
          <>
            <div className='mt-2'>
              Download PDF Bulk Upload Guide:
              <ul>
                <li>
                  <LinkButton onClick={() => doDownloadFile(TemplateFiles.BulkUploadGuide, FileTypes.Template)} title='Aquatic Resource Bulk Upload Guide [PDF, 6 pages]' content='Aquatic Resource Bulk Upload Guide [PDF, 6 pages]' />
                </li>
              </ul>
            </div>
            <div className='mt-2 mb-3'>
              Download GDB or CSV Bulk Upload Template:
              <ul>
                <li>
                  <LinkButton onClick={() => doDownloadFile(TemplateFiles.ARBulkUploadGDBTemplate, FileTypes.Template)} title='Aquatic Resource Bulk Upload GDB Template [ZIP, 71 KB]' content='Aquatic Resource Bulk Upload GDB Template [ZIP, 71 KB]' />
                </li>
                <ul>
                  <li>Use for uploading lines and polygons related to aquatic resources</li>
                </ul>
                <li>
                  <LinkButton onClick={() => doDownloadFile(TemplateFiles.ARBulkUploadCSV, FileTypes.Template)} title='Resource Bulk Upload CSV Template [CSV, 2 KB]' content='Resource Bulk Upload CSV Template [CSV, 2 KB]' />
                </li>
                <ul>
                  <li>Use for uploading centroids associated with aquatic resources</li>
                </ul>
              </ul>
            </div>
            <div className='pb-1 mb-2'>Use the “Add New Row" button to individually add aquatic resources to the inventory table. If you have many aquatic resources, you can use the drag and drop feature to add multiple aquatic resources at one time using the geodatabase or CSV bulk upload templates.</div>
            <div className='pb-1 mb-2'>** latitude and longitude must include at least 6 decimal places.</div>
            <div className='pb-1 mb-3'>Use the <Icon path={mdiFolderUploadOutline} size={'20px'} /> icon or the drag and drop feature within the table below to upload a geodatabase. Geodatabases must be compressed (.zip) </div>
            <div className='pb-1 mb-2'>Geodatabase geometries must use a Coordinate Reference System(CRS) of WGS84.</div>
            <Accordion.List className='mb-2 w-100'>
              <Accordion.Item headingText='Map Use Tips'>
                <div className='row'><div className='col pb-2'><Icon path={mdiPlus} size={'24px'} /> Zoom In - Click the plus sign to zoom in on the map. You can also click one in the map and use the scroll wheel on your mouse to zoom in and out. With the focus of your cursor on the map, you can pan the map with the hand icon.</div></div>
                <div className='row'><div className='col pb-2'><Icon path={mdiMinus} size={'24px'} /> Zoom Out - Click the minus sign to zoom out on the map. See above for further map navigation tips.</div></div>
                <div className='row'><div className='col pb-2'>{fullscreenSVG} Full Screen - Click the full screen icon to zoom out to the original extent of the map.</div></div>
                <div className='row'><div className='col pb-2'><Icon path={mdiFolderUploadOutline} size={'24px'} /> Upload GDB File - Click this icon to upload the project location information from a geodatabase (GDB) instead of digitizing the location on the map. The geodatabase must be compressed (.zip or .gdb).</div></div>
                <div className='row'><div className='col pb-2'>{polyLineSVG} Add a Line Feature - Select the line tool to add a project location as a line. Click the starting point location and any interim shape points. Double-click the final shape point of the line or click to set the location of the final shape point and then select “Finish” from the popup menu in the map.</div> </div>
                <div className='row'><div className='col pb-2'>{polygonSVG} Add a Polygon Feature - Select the polygon tool to add a project location as a polygon. Click the starting point location followed by the remaining shape points. When complete, either double-click the last shape point or click the set the location of the final shape point and then select “Finish” from the popup menu in the map.</div></div>
                <div className='row'><div className='col pb-2'><Icon path={mdiMapMarker} size={'24px'} /> Add a Point Feature - Select the point tool to add a project location as a single point. Click the location of the point.</div></div>
                <div className='row'><div className='col pb-2'><Icon path={mdiTrashCanOutline} size={'24px'} /> Delete Features - Select this option to delete the location information from the map. You will then need to re-create the project location. After clicking the trash can icon, select Finish, Cancel, or Remove All to delete the location entered on the map. </div></div>
                <div className='row'><div className='col pb-2'><Icon path={mdiMagnify} size={'24px'} /> Search - Click the magnifier icon in the upper right of the map to expand a search box which will allow you to search for a location via text (address, city, etc.)</div></div>
                <div className='row'><div className='col pb-2'><Icon path={mdiLayersTriple} size={'24px'} /> Layers - Mouse over the layers icon to view the available base maps. The base map can either be viewed as a street map, aerial imagery, or topographic map.</div></div>
              </Accordion.Item>
            </Accordion.List>
          </>
        </FieldHeader>
        <div className='col'>
          <ResourceMap setResourceData={setResourceData} resourceData={resourceData} updateResourceData={updateResourceData} addFeature={addFeature} isReadOnly={isReadOnly} />
        </div>
        <Row>
          <p className='h6 border-bottom w-100 pb-2 ml-2'>Aquatic Resources</p>
        </Row>
        <div id='aquaticResources'>
          <TanStackTable
            key={tableKey}
            data={tableData}
            columns={ARColumns}
            validationSchema={validationSchema}
            isReadOnly={isReadOnly}
            updateSourceData={updateResourceData}
            addFeature={addFeature}
            removeMultipleFeatures={removeMultipleFeatures}
            addFeaturesFromRows={addFeaturesFromRows}
            rowErrorCallback={setRowErrors}
            initialTableState={{ columnVisibility: { regulationType: false } }}
          />
        </div>
      </FormProvider >
    );
  });

JDFormAquaticResources.metadata = JDFormAquaticResourcesMetadata;

export default JDFormAquaticResources;