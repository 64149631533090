import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { connect } from 'redux-bundler-react';
import * as turf from '@turf/turf';
import { createColumnHelper } from '@tanstack/react-table';

import TanStackTable from '../../../../app-components/table/TanStackTable.jsx';
import { TableCell } from '../../../../app-components/table/tableCellComponents/TableCell.jsx';
import ARTypeTableCell from '../../../../app-components/table/tableCellComponents/ARTypeTableCell.jsx';
import RegulationTypeTableCell from '../../../../app-components/table/tableCellComponents/RegulationTypeTableCell.jsx';
import MeasurementUnitTableCell from '../../../../app-components/table/tableCellComponents/MeasurementUnitTableCell.jsx';
import { EditCell } from '../../../../app-components/table/tableCellComponents/EditCell.jsx';
import FieldHeader from '../../components/Form/FieldHeader.jsx';
import ResourceMap from '../../../../app-components/map/resourceMap.jsx';
import LinkButton from '../../../../app-components/link/linkButton.jsx';
import Accordion from '../../../../app-components/accordion/index.js';
import Tooltip from '../../../../app-components/tooltip/tooltip.jsx';
import ExternalLink from '../../../../app-components/link/externalLink.jsx';
import { Table } from '@trussworks/react-uswds';

import { stateAbbreviations } from '../_helper.jsx';
import { formatCoordFlt, formatCoordStr, LatLngOutsideUS } from '../../../../utils/helpers.jsx';
import { FileTypes, TemplateFiles } from '../../../../utils/enums.tsx';
import { latRegex, lngRegex, setNumberValue } from '../../../../utils/regex.jsx';
import { Row } from '../../../_shared/helper.jsx';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Icon from '@mdi/react';
import { mdiFolderUploadOutline, mdiMagnify, mdiPlus, mdiMinus, mdiMapMarker, mdiTrashCanOutline, mdiLayersTriple } from '@mdi/js';

import { fullscreenSVG, polyLineSVG, polygonSVG } from '../../../../utils/svg.jsx';
import ErrorSummary from '../../../../app-components/error-summary/ErrorSummary.jsx';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import useErrorFocus from '../../../../customHooks/useErrorFocus.jsx';

export const PermitsFormAquaticResourcesMetadata = {
  sectionName: 'Aquatic Resource Inventory',
  isSection: true,
  lastSection: false,
  firstSection: false,
};

const mapStateToLongForm = (state) => {
  if (state) {
    const longname = stateAbbreviations[state?.toUpperCase()];
    if (longname) {
      return longname;
    }
    else {
      return null;
    }
  }
  else {
    return null;
  }
};

const PermitsFormAquaticResources = connect(
  'doDownloadFile',
  'doUpdateSectionValidity',
  'doUpdateRequestAquaticResources',
  'selectGDBResourcesResults',
  'selectRequestFormData',
  'selectRequestAPIData',
  'selectIsReadOnly',
  'selectLookupTableData',
  'selectSteps',
  'selectActiveStep',
  ({
    doDownloadFile,
    doUpdateSectionValidity,
    doUpdateRequestAquaticResources,
    gDBResourcesResults,
    requestFormData,
    requestAPIData,
    isReadOnly,
    stepNo,
    lookupTableData,
    steps,
    activeStep,
  }) => {
    const [resourceData, setResourceData] = useState({
      type: 'FeatureCollection',
      features: []
    });
    const tableData = useMemo(() => resourceData.features.map(feature => feature.properties), [resourceData]);
    const isWaterNameUnique = useCallback((waterName) => resourceData?.features?.filter(feature => feature?.properties?.waterName?.toUpperCase() === waterName?.toUpperCase()).length <= 1, [resourceData]);
    const [tableInvalid, setTableInvalid] = useState(true);
    const [tableKey, setTableKey] = useState(0);
    const [rowErrors, setRowErrors] = useState([]);
    const columnHelper = createColumnHelper();
    const linearOptions = useMemo(() => [{ value: 'FOOT', label: 'Foot' }], []);
    const areaOptions = useMemo(() => [{ value: 'SQ_FT', label: 'Sq Ft.' }, { value: 'ACRE', label: 'Acre' }], []);
    const projectState = requestFormData?.location?.state ? mapStateToLongForm(requestFormData?.location?.state) : '' ?? '';
    const thisSectionStepStatus = useMemo(() => steps.find(step => step.id === stepNo)?.touched, [steps, stepNo]);

    const schema = yup.object().shape({});


    const methods = useForm({ resolver: yupResolver(schema), mode: 'onBlur' });
    const { formState: { errors }, setError, clearErrors, trigger } = methods;


    yup.addMethod(yup.string, 'uniqueWaterName', function (message) {
      return this.test('unique-water-name', message, function (value) {
        const isUnique = isWaterNameUnique(value);
        return isUnique || new yup.ValidationError(message, null, this.path);
      });
    });

    const validationSchema = yup.object().shape({
      jurisdictionalDeterminationType: yup.string().nullable(),
      waterName: yup.string().required('Please enter a water name').uniqueWaterName('Water name must be unique'),
      state: yup.string().required('Please select a state'),
      latitude: yup
        .string()
        .test('latFormat', 'Latitude format is incorrect. Must be +-XX.XXXXXX and include at least 6 decimal places.', val => latRegex.test(val))
        .test({
          name: 'latInRange',
          exclusive: true,
          message: 'Latitude must be between 0 and 90',
          test: val => !LatLngOutsideUS(requestFormData?.location?.latitude, requestFormData?.location?.longitude) ? Number(val) <= 90 && Number(val) >= 0 : true,
        })

        .required('Latitude is required'),
      longitude: yup
        .string()
        .test('lngFormat', 'Longitude format is incorrect. Must be +-XXX.XXXXXX and include at least 6 decimal places.', val => lngRegex.test(val))
        .test({
          name: 'lngInRange',
          exclusive: true,
          message: 'Longitude must be between -180 and 0',
          test: val => !LatLngOutsideUS(requestFormData?.location?.latitude, requestFormData?.location?.longitude) ? Number(val) <= 0 && Number(val) >= -180 : true,
        })
        .required('Longitude is required'),
      measurementAmount: yup.string().required('Please enter a measurement amount').test('zeroTest', 'Value must be more than 0', function (item) { return item > 0; }),
      measurementType: yup.string().required('Please select a measurement type'),
      measurementUnit: yup.string().required('Please select a unit type'),
      hgmCode: yup.string().nullable(),
      localWaterway: yup.string().nullable(),
      cowardinCode: yup.string().nullable(),
      aquaticResourceType: yup.string().nullable(),
    });

    const ARColumns = useMemo(() => [
      columnHelper.display({
        id: 'edit',
        cell: isReadOnly ? <></> : EditCell,
        size: 20,
        enableResizing: false,
        enableSorting: false,
      }),
      columnHelper.accessor('waterName', {
        header: 'Water Name/Label',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
        meta: {
          type: 'text',
          required: true,
        },
      }),
      columnHelper.accessor('state', {
        header: 'State',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
        meta: {
          required: true,
          type: 'select',
          options: [
            ...Object.entries(stateAbbreviations).map(([abbreviation, fullName]) => ({
              value: fullName,
              label: fullName,
            })),
          ],
        },
      }),
      columnHelper.accessor('latitude', {
        header: 'Latitude',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
        meta: {
          type: 'text',
          maxLength: '12',
          required: true,
          formatCoordStr: formatCoordStr
        },
      }),
      columnHelper.accessor('longitude', {
        header: 'Longitude',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
        meta: {
          type: 'text',
          maxLength: '12',
          required: true,
          formatCoordStr: formatCoordStr
        },
      }),
      columnHelper.accessor('measurementType', {
        header: 'Measurement Type',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
        meta: {
          type: 'select',
          required: true,
          options: [
            { value: 'Area', label: 'Area' },
            { value: 'Linear', label: 'Linear' },
          ],
        },
      }),
      columnHelper.accessor('measurementAmount', {
        header: 'Measurement Amount',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
        meta: {
          type: 'text',
          isNumber: true,
          required: true,
          maxLength: 17
        }
      }),
      columnHelper.accessor('measurementUnit', {
        header: 'Measurement Unit',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : MeasurementUnitTableCell,
        meta: {
          type: 'select',
          required: true,
          options: [areaOptions, linearOptions],
        },
      }),
      columnHelper.accessor('regulationType', {
        header: 'Regulation Type',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : RegulationTypeTableCell,
        enableHiding: true,
        meta: {
          type: 'text',
          readOnly: true,
          required: true,
          state: projectState,
          fallbackRegulation: 'PRE2015_POSTSACKETT'
        },
      }),
      columnHelper.accessor('localWaterway', {
        header: 'Local Waterway',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
        meta: {
          type: 'text',
          required: false,
        },
      }),
      columnHelper.accessor('cowardinCode', {
        header: 'Cowardin Code',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
        meta: {
          type: 'select',
          options:
            lookupTableData.Cowardins
              .map((code) => ({
                value: code.shortCode,
                label: code.shortCode,
              }))
              .sort((a, b) => a.label.localeCompare(b.label)),
        },
      }),
      columnHelper.accessor('hgmCode', {
        header: 'HGM Code',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
        meta: {
          type: 'select',
          options: lookupTableData.HGMCodes.map((code) => ({
            value: code.shortCode,
            label: code.shortCode,
          })),
        },
      }),
      columnHelper.accessor('jurisdictionalDeterminationType', {
        header: 'JD Type',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
        enableHiding: true,
        meta: {
          type: 'select',
          options: [
            { value: 'No JD Requested', label: 'No JD Requested' },
            { value: 'Preliminary', label: 'Preliminary' },
            { value: 'Approved', label: 'Approved' },
          ],
        },
      }),
      columnHelper.accessor('aquaticResourceType', {
        header: 'Aquatic Resource Type',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : ARTypeTableCell,
      }),
    ], [areaOptions, columnHelper, isReadOnly, linearOptions, lookupTableData.Cowardins, lookupTableData.HGMCodes, projectState]);

    const determineRegulation = useCallback((state) => {
      const regulationIndex = lookupTableData?.StateRegulations?.findIndex(statereg => statereg?.state?.toUpperCase() === state?.toUpperCase());
      const regulation = lookupTableData?.StateRegulations?.[regulationIndex]?.regulationType;
      return regulation;
    }, [lookupTableData?.StateRegulations]);

    const toProperCase = useCallback((str) =>
      str.split(' ')
        .map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase())
        .join(' '), []);

    const mapToMeasurementUnit = useCallback((val) => {
      const valToUpper = val?.toUpperCase();
      switch (valToUpper) {
        case 'SQUARE FEET':
        case 'SQUARE FOOT':
        case 'SQUARE FT':
        case 'SQUARE FT.':
        case 'SQ FT':
        case 'SQ. FT.':
        case 'SQ FT.':
        case 'SQ. FT':
        case 'SQ_FT':
          return 'SQ_FT';
        case 'ACRE':
        case 'ACRES':
        case 'ACREAGE':
          return 'ACRE';
        case 'FOOT':
        case 'FT':
        case 'LINEAR FOOT':
        case 'LINEAR FEET':
        case 'FEET':
          return 'FOOT';
        default:
          return null;
      }
    }, []);

    const formatState = useCallback((state) => {
      // Normalize input to uppercase
      const upperState = state?.toUpperCase();

      // Check if the input is an abbreviation
      if (stateAbbreviations?.hasOwnProperty(upperState)) {
        return stateAbbreviations[upperState];
      }

      const fullStateName = Object.entries(stateAbbreviations).find(([key, value]) => value === upperState);
      if (fullStateName) {
        return fullStateName[1];
      }

      console.error('No State Found!');
      return null;
    }, []);

    const addFeature = useCallback((optionalProperties, optionalGeometry) => {

      const defaultGeometry = {
        type: 'Point',
        coordinates: [optionalProperties?.longitude ? formatCoordFlt(optionalProperties.longitude) : 0 ?? 0, optionalProperties?.latitude ? formatCoordFlt(optionalProperties.latitude) : 0 ?? 0], // Default coordinates
      };

      const newFeature = {
        type: 'Feature',
        properties: {
          ...optionalProperties,
          latitude: optionalProperties?.latitude ? formatCoordStr(optionalProperties.latitude) : null,
          longitude: optionalProperties?.longitude ? formatCoordStr(optionalProperties.longitude) : null,
          measurementAmount: optionalProperties?.measurementAmount ? Number(optionalProperties.measurementAmount) : null,
          state: optionalProperties?.state ? formatState(optionalProperties.state) : (projectState && projectState !== null) ? projectState : null ?? null,
          regulationType: optionalProperties?.regulationType ? optionalProperties.regulationType : (projectState && projectState !== '') ? determineRegulation(projectState) : 'PRE2015_POSTSACKETT',
          jurisdictionalDeterminationType: null,
          hgmCode: optionalProperties?.hgmCode ? optionalProperties.hgmCode : null ?? null,
          cowardinCode: optionalProperties?.cowardinCode ? optionalProperties.cowardinCode : null ?? null,
          aquaticResourceType: optionalProperties?.aquaticResourceType ? optionalProperties.aquaticResourceType : null ?? null,
        },
        geometry: optionalGeometry || defaultGeometry
      };

      setResourceData((oldData) => {
        // Check if oldData is already a valid feature collection
        if (oldData && oldData.type === 'FeatureCollection' && Array.isArray(oldData.features)) {
          return { ...oldData, features: [...oldData.features, newFeature] };
        }

        // Initialize a new feature collection (if needed)
        return {
          type: 'FeatureCollection',
          features: [newFeature]
        };
      });
    }, [determineRegulation, projectState, setResourceData, formatState]);

    const updateResourceData = useCallback((rowIndex, columnId, updatedValue) => {
      setResourceData(oldData => {
        const newData = { ...oldData };
        if (newData.features && newData.features[rowIndex]) {
          if (columnId === 'latitude' || columnId === 'longitude') {
            if (!isNaN(updatedValue)) {
              newData.features[rowIndex].properties = {
                ...newData.features[rowIndex].properties,
                [columnId]: formatCoordStr(updatedValue)
              };
            }
          } else {
            newData.features[rowIndex].properties = {
              ...newData.features[rowIndex].properties,
              [columnId]: updatedValue
            };
          }

          if (newData?.features?.[rowIndex]?.geometry?.type === 'Point' &&
            (columnId === 'latitude' || columnId === 'longitude')) {
            const { latitude, longitude } = newData.features[rowIndex].properties;
            if (!isNaN(latitude) && !isNaN(longitude)) {
              newData.features[rowIndex].geometry.coordinates = [
                formatCoordFlt(longitude),
                formatCoordFlt(latitude)
              ];
            }
          }
        }
        return newData;
      });
    }, [setResourceData]);


    const addFeaturesFromRows = useCallback((rows) => {
      const columnToPropertyMap = {
        'Water Name/Label': 'waterName',
        'State': 'state',
        'Latitude': 'latitude',
        'Longitude': 'longitude',
        'Measurement Amount': 'measurementAmount',
        'Measurement Type': 'measurementType',
        'Measurement Unit': 'measurementUnit',
        'JD Type': 'jurisdictionalDeterminationType',
        'Cowardin Code': 'cowardinCode',
        'Aquatic Resource Type': 'aquaticResourceType',
        'Regulation Type': 'regulationType',
        'Local Waterway': 'localWaterway',
        'HGM Code': 'hgmCode',
      };

      const newFeatures = rows.map(row => {
        const properties = {};

        Object.entries(row).forEach(([key, value]) => {
          const propertyKey = columnToPropertyMap[key] || key;
          properties[propertyKey] = value;
        });
        // Apply any additional transformations or default values
        return {
          type: 'Feature',
          properties: {
            ...properties,
            latitude: properties?.latitude ? formatCoordStr(properties.latitude) : null,
            longitude: properties?.longitude ? formatCoordStr(properties.longitude) : null,
            measurementAmount: properties?.measurementAmount ? Number(properties.measurementAmount) : null,
            measurementType: properties?.measurementType ? toProperCase(properties.measurementType) : null,
            measurementUnit: properties?.measurementUnit ? mapToMeasurementUnit(properties.measurementUnit) : null,
            state: properties?.state ? formatState(properties.state) : projectState,
            regulationType: properties?.state ? determineRegulation(formatState(properties.state)) : 'PRE2015_POSTSACKETT',
            jurisdictionalDeterminationType: properties?.jurisdictionalDeterminationType ? properties.jurisdictionalDeterminationType : null,
            hgmCode: properties?.hgmCode ? properties.hgmCode.toUpperCase() : null,
            cowardinCode: properties?.cowardinCode ? properties.cowardinCode.toUpperCase() : null,
            aquaticResourceType: properties?.aquaticResourceType ? properties.aquaticResourceType.toUpperCase() : null,
          },
          geometry: {
            type: 'Point',
            coordinates: [properties.longitude ? formatCoordFlt(properties.longitude) : 0, properties.latitude ? formatCoordFlt(properties.latitude) : 0]
          },
        };
      });
      setResourceData(oldData => ({
        ...oldData,
        features: [...oldData.features, ...newFeatures],
      }));
      setTableKey(old => old + 1);
    }, [determineRegulation, projectState, mapToMeasurementUnit, toProperCase, formatState]);

    const addFeatures = useCallback((featuresToAdd) => {
      setResourceData(oldData => {
        if (oldData && oldData.type === 'FeatureCollection' && Array.isArray(oldData.features)) {
          return {
            ...oldData,
            features: [...oldData.features, ...featuresToAdd]
          };
        }
        return {
          type: 'FeatureCollection',
          features: featuresToAdd
        };
      });
      setTableKey(old => old + 1);
    }, [setResourceData, setTableKey]);

    const removeMultipleFeatures = useCallback((indicesToRemove) => {
      setResourceData((oldData) => {
        const newFeatures = oldData.features.filter((_, index) => !indicesToRemove.includes(index));
        return { ...oldData, features: newFeatures };
      });
      setTableKey(old => old + 1);
    }, [setResourceData, setTableKey]);

    //GDB UPLOAD DATA PARSING
    useEffect(() => {
      if (gDBResourcesResults) {

        const JDType = 'Approved';
        const ARState = requestFormData?.location?.state ? mapStateToLongForm(requestFormData.location.state) : '';
        //Populate table with data from each object

        const propertyMapping = {
          'jd_type': 'jurisdictionalDeterminationType',
          'waters_name': 'waterName',
          'state': 'state',
          'latitude': 'latitude',
          'longitude': 'longitude',
          'meas_type': 'measurementType',
          'units': 'measurementUnit',
          'amount': 'measurementAmount',
          'hgm_code': 'hgmCode',
          'local_waterway': 'localWaterway',
          'cowardin_code': 'cowardinCode',
          'regulation_type': 'regulationType',
          'waters_type': 'aquaticResourceType',
        };

        const gdbResourceFeatures = gDBResourcesResults.features.map((feature, i) => {

          const normalizedProperties = Object.keys(feature.properties).reduce((acc, key) => {
            const lowerCaseKey = key.toLowerCase();
            if (lowerCaseKey in propertyMapping) {
              acc[propertyMapping[lowerCaseKey]] = feature.properties[key];
            }
            return acc;
          }, {});

          let centroidCoordinates;

          switch (feature?.geometry?.type?.toUpperCase()) {
            case 'MULTIPOINT': {
              const geoJSONMultiPoint = turf.multiPoint(feature.geometry.coordinates);
              const centroid = turf.centroid(geoJSONMultiPoint);
              centroidCoordinates = centroid.geometry.coordinates;
              break;
            }
            case 'POINT': {
              const geoJSONPoint = turf.point(feature.geometry.coordinates);
              const centroid = turf.centroid(geoJSONPoint);
              centroidCoordinates = centroid.geometry.coordinates;
              break;
            }
            case 'MULTILINESTRING': {
              const geoJSONMultiLineString = turf.multiLineString(feature.geometry.coordinates);
              if (geoJSONMultiLineString.geometry.coordinates.length > 0 && geoJSONMultiLineString.geometry.coordinates[0].length > 0) {
                const centroid = turf.centroid(geoJSONMultiLineString);
                centroidCoordinates = centroid.geometry.coordinates;
              }
              else {
                centroidCoordinates = null;
              }
              break;
            }
            default: {
              const multiPolygon = Array.isArray(feature.geometry.coordinates[0][0][0]) ? feature.geometry.coordinates : [feature.geometry.coordinates];
              const geoJSONMultiPolygon = turf.multiPolygon(multiPolygon);
              const centroid = turf.centroid(geoJSONMultiPolygon);
              centroidCoordinates = centroid.geometry.coordinates;
            }
          }

          //@TODO Replace hardcoded RAPANOS with valid regulation type according to state and/or watersType
          return {
            ...feature,
            properties: {
              jurisdictionalDeterminationType: normalizedProperties?.jurisdictionalDeterminationType ? normalizedProperties.jurisdictionalDeterminationType : JDType !== 'Preliminary+Approved' ? JDType : null ?? null,
              waterName: normalizedProperties?.waterName ?? `${feature.geometry.type}_${i}`,
              state: normalizedProperties?.state ? formatState(normalizedProperties.state) : ARState ?? ARState,
              latitude: centroidCoordinates ? formatCoordStr(centroidCoordinates[1]) : null ?? null,
              longitude: centroidCoordinates ? formatCoordStr(centroidCoordinates[0]) : null ?? null,
              measurementType: normalizedProperties?.measurementType ? toProperCase(normalizedProperties.measurementType) : null ?? null,
              measurementUnit: normalizedProperties?.measurementUnit ? mapToMeasurementUnit(normalizedProperties.measurementUnit) : null ?? null,
              measurementAmount: normalizedProperties?.measurementAmount ? Number(normalizedProperties.measurementAmount) : null ?? null,
              hgmCode: normalizedProperties?.hgmCode ? normalizedProperties.hgmCode.toUpperCase() : null ?? null,
              localWaterway: normalizedProperties?.localWaterway ? normalizedProperties.localWaterway : null ?? null,
              cowardinCode: normalizedProperties?.cowardinCode ? normalizedProperties.cowardinCode.toUpperCase() : null ?? null,
              regulationType: normalizedProperties?.state ? determineRegulation(normalizedProperties.state) : 'PRE2015_POSTSACKETT' ?? 'PRE2015_POSTSACKETT',
              aquaticResourceType: normalizedProperties?.aquaticResourceType ? normalizedProperties.aquaticResourceType.toUpperCase() : null ?? null,
            },
          };
        });

        addFeatures(gdbResourceFeatures);
      }
    }, [gDBResourcesResults, addFeatures, determineRegulation, requestFormData.location.state, formatState, mapToMeasurementUnit, toProperCase]);

    useEffect(() => {
      doUpdateSectionValidity(PermitsFormAquaticResourcesMetadata.sectionName, !tableInvalid, stepNo, isReadOnly);
    }, [tableInvalid, doUpdateSectionValidity, stepNo, isReadOnly]);

    useEffect(() => {
      const tableEmpty = tableData.length < 1;

      if (tableEmpty) {
        setError('aquaticResources', { type: 'custom', message: 'At least one aquatic resource is required' });
        setTableInvalid(true);
      }
      else if ((rowErrors || tableEmpty) && tableInvalid !== true) {
        setError('aquaticResources', { type: 'custom', message: 'Table contains invalid rows' });
        setTableInvalid(true);
      }
      else if ((!rowErrors && !tableEmpty) && tableInvalid !== false) {
        clearErrors('aquaticResources');
        setTableInvalid(false);
      }

    }, [rowErrors, tableData.length, setTableInvalid, clearErrors, setError, tableInvalid]);

    useEffect(() => {
      if (resourceData) {
        const aquaticResources = resourceData?.features?.map(feature => ({
          ...feature.properties,
          latitude: formatCoordFlt(feature.properties.latitude),
          longitude: formatCoordFlt(feature.properties.longitude),
          measurementAmount: setNumberValue(feature?.properties?.measurementAmount, false),
          geometry: feature.geometry,
        }));

        doUpdateRequestAquaticResources(aquaticResources);
      }

    }, [resourceData, doUpdateRequestAquaticResources]);

    // Load data from DB
    useEffect(() => {
      if (requestAPIData?.request?.aquaticResources) {

        const mappedResources = {
          type: 'FeatureCollection', features: requestAPIData.request.aquaticResources.map(resource => {
            const { geometry, ...properties } = resource;

            const newProperties = {
              ...properties,
              latitude: formatCoordStr(properties?.latitude),
              longitude: formatCoordStr(properties?.longitude),
              measurementAmount: setNumberValue(properties?.measurementAmount, false)
            };

            return ({ type: 'Feature', properties: newProperties, geometry: geometry });

          })
        };
        setResourceData(mappedResources);
      }

    }, [requestAPIData]);

    const hgmCodeContent = (
      <>
        <thead>
          <tr>
            <th scope='col'>HGM Code</th>
            <th scope='col'>Name</th>
            <th scope='col'>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope='row'>DEPRESS</th>
            <td>
              Depressional
            </td>
            <td>Depressional is characterized by a water source consisting of return flow from groundwater and interflow with primarily vertical hydrodynamics.</td>
          </tr>
          <tr>
            <th scope='row'>ESTUARINEF</th>
            <td>
              Estuarine Fringed
            </td>
            <td>The water source of the estuarine fringe consists of overbank flow from estuaries, with bidirectional and horizontal hydrodynamics being dominant.</td>
          </tr>
          <tr>
            <th scope='row'>LACUSTRINF</th>
            <td>
              Lacustrine Fringe
            </td>
            <td>A Lacustrine fringe has a dominant water source of lake overbank flow, and the dominant hydrodynamics are bidirectional and horizontal.</td>
          </tr>
          <tr>
            <th scope='row'>MINSOILFLT</th>
            <td>
              Mineral Soil Flats
            </td>
            <td>Mineral soil flats have a water source of precipitation, and vertical hydrodynamics are dominant.</td>
          </tr>
          <tr>
            <th scope='row'>ORGSOILFLT</th>
            <td>
              Organic Soil Flats
            </td>
            <td>Organic soil flats have precipitation as the water source, and its dominant hydrodynamic is vertical.</td>
          </tr>
          <tr>
            <th scope='row'>RIVERINE</th>
            <td>
              Riverine
            </td>
            <td>Riverine is characterized by a water source of overbank flow from a channel, and hydrodynamics which are predominantly unidirectional and horizontal.</td>
          </tr>
          <tr>
            <th scope='row'>SLOPE</th>
            <td>
              Slope
            </td>
            <td>The Slope wetland class is characterized by a water source of return flow from groundwater, with principally unidirectional and horizontal hydrodynamics.</td>
          </tr>
        </tbody>
      </>
    );

    const gDBTemplateTooltip = 'Aquatic resources are the resources within a project location upon which USACE would perform a jurisdictional determination and/or permit impacts or mitigation. There may be multiple aquatic resources within a project location. Please provide a list of aquatic resources where the impacts are occurring, or for permittee responsible mitigation, the compensatory mitigation site, their location, and dimensions. Aquatic resources include wetland waters (i.e., vernal pools, swales, freshwater marsh, other seasonal wetlands, etc.), non-wetland waters (i.e., ponds, lakes, streams, rivers, drainages, ditches, etc.), and riparian areas (i.e., mixed riparian scrub, mixed riparian woodland, etc.).';

    const cowardinCodesLink = () => (
      <p>
        A complete list of Cowardin codes can be downloaded from&nbsp;
        <ExternalLink href='https://www.fws.gov/program/national-wetlands-inventory/classification-codes' title='U.S. Fish & Wildlife Service' content='U.S. Fish & Wildlife Service.' />
        .
      </p>
    );

    useErrorFocus({ steps, stepNo, activeStep, trigger, noTrigger: true, isReadOnly });

    return (
      <FormProvider {...methods}>
        {errors && thisSectionStepStatus === 'true' && !isReadOnly &&
          <ErrorSummary errors={errors} sectionNo={stepNo} />
        }
        <FieldHeader
          text='Aquatic Resource Inventory'
          subtext={<>
            Provide an inventory of all aquatic resources within the project site. Aquatic resources can be provided in this section by adding them individually or bulk loading them using the provided bulk upload CSV or geodatabase (GDB) templates. <Tooltip name='gDBTemplateTooltip' content={gDBTemplateTooltip} />
          </>}
        >
          <>
            <div className='mt-2'>
              Download PDF Bulk Upload Guide:
              <ul>
                <li>
                  <LinkButton onClick={() => doDownloadFile(TemplateFiles.BulkUploadGuide, FileTypes.Template)} title='Aquatic Resource Bulk Upload Guide [PDF, 6 pages]' content='Aquatic Resource Bulk Upload Guide [PDF, 6 pages]' />
                </li>
              </ul>
            </div>
            <div className='mt-2 mb-3'>
              Download GDB or CSV Bulk Upload Template:
              <ul>
                <li>
                  <LinkButton onClick={() => doDownloadFile(TemplateFiles.ARBulkUploadGDBTemplate, FileTypes.Template)} title='Aquatic Resource Bulk Upload GDB Template [ZIP, 71 KB]' content='Aquatic Resource Bulk Upload GDB Template [ZIP, 71 KB]' />
                </li>
                <ul>
                  <li>Use for uploading lines and polygons related to aquatic resources</li>
                </ul>
                <li>
                  <LinkButton onClick={() => doDownloadFile(TemplateFiles.ARBulkUploadCSV, FileTypes.Template)} title='Resource Bulk Upload CSV Template (CSV)' content='Resource Bulk Upload CSV Template (CSV)' />
                </li>
                <ul>
                  <li>Use for uploading centroids associated with aquatic resources</li>
                </ul>
              </ul>
            </div>
            <div className='pb-1 mb-2'>Use the "Add New Row" button to individually add aquatic resources to the inventory table. If you have many aquatic resources, you can use the drag and drop feature to add multiple aquatic resources at one time using the geodatabase or CSV bulk upload templates.</div>
            <div className='pb-1 mb-2'>** latitude and longitude must include at least 6 decimal places.</div>
            <div className='pb-1 mb-2'>Use the <Icon path={mdiFolderUploadOutline} size={'20px'} /> icon or the drag and drop feature within the table below to upload a geodatabase. Geodatabases must be compressed (.zip). </div>
            <div className='pb-1 mb-2'>Geodatabase geometries must use a Coordinate Reference System(CRS) of WGS84.</div>
            <div className='pb-1 mb-3'>To delete one or multiple Aquatic Resources entered - Use the checkbox to the left of the “Waters Name/Label” box OR Click on the checkbox to the left of the “Add New Row” button to select multiple rows and then click on "Remove Selected Rows". </div>
            <Accordion.List className='mb-2 w-100'>
              <Accordion.Item headingText='Map Use Tips'>
                <div className='row'><div className='col pb-2'><Icon path={mdiPlus} size={'24px'} /> Zoom In - Click the plus sign to zoom in on the map. You can also click one in the map and use the scroll wheel on your mouse to zoom in and out. With the focus of your cursor on the map, you can pan the map with the hand icon.</div></div>
                <div className='row'><div className='col pb-2'><Icon path={mdiMinus} size={'24px'} /> Zoom Out - Click the minus sign to zoom out on the map. See above for further map navigation tips.</div></div>
                <div className='row'><div className='col pb-2'>{fullscreenSVG} Full Screen - Click the full screen icon to zoom out to the original extent of the map.</div></div>
                <div className='row'><div className='col pb-2'><Icon path={mdiFolderUploadOutline} size={'24px'} /> Upload GDB File - Click this icon to upload the project location information from a geodatabase (GDB) instead of digitizing the location on the map. The geodatabase must be compressed (.zip or .gdb).</div></div>
                <div className='row'><div className='col pb-2'>{polyLineSVG} Add a Line Feature - Select the line tool to add a project location as a line. Click the starting point location and any interim shape points. Double-click the final shape point of the line or click to set the location of the final shape point and then select “Finish” from the popup menu in the map.</div> </div>
                <div className='row'><div className='col pb-2'>{polygonSVG} Add a Polygon Feature - Select the polygon tool to add a project location as a polygon. Click the starting point location followed by the remaining shape points. When complete, either double-click the last shape point or click the set the location of the final shape point and then select “Finish” from the popup menu in the map.</div></div>
                <div className='row'><div className='col pb-2'><Icon path={mdiMapMarker} size={'24px'} /> Add a Point Feature - Select the point tool to add a project location as a single point. Click the location of the point.</div></div>
                <div className='row'><div className='col pb-2'><Icon path={mdiTrashCanOutline} size={'24px'} /> Delete Features - Select this option to delete the location information from the map. You will then need to re-create the project location. After clicking the trash can icon, select Finish, Cancel, or Remove All to delete the location entered on the map. </div></div>
                <div className='row'><div className='col pb-2'><Icon path={mdiMagnify} size={'24px'} /> Search - Click the magnifier icon in the upper right of the map to expand a search box which will allow you to search for a location via text (address, city, etc.)</div></div>
                <div className='row'><div className='col pb-2'><Icon path={mdiLayersTriple} size={'24px'} /> Layers - Mouse over the layers icon to view the available base maps. The base map can either be viewed as a street map, aerial imagery, or topographic map.</div></div>
              </Accordion.Item>
            </Accordion.List>
          </>
        </FieldHeader>
        <div className='col'>
          <ResourceMap setResourceData={setResourceData} resourceData={resourceData} updateResourceData={updateResourceData} addFeature={addFeature} isReadOnly={isReadOnly} />
        </div>
        <Row>
          <p className='h6 border-bottom w-100 pb-2 ml-2'>Aquatic Resources</p>
        </Row>
        <Accordion.List className='mb-2 w-100'>
          <Accordion.Item headingText='Detailed HGM Code Information'>
            <Table compact bordered striped>{hgmCodeContent}</Table>
          </Accordion.Item>
        </Accordion.List>
        {cowardinCodesLink()}
        <div id='aquaticResources'>
          <TanStackTable
            key={tableKey}
            data={tableData}
            columns={ARColumns}
            validationSchema={validationSchema}
            isReadOnly={isReadOnly}
            updateSourceData={updateResourceData}
            addFeature={addFeature}
            removeMultipleFeatures={removeMultipleFeatures}
            addFeaturesFromRows={addFeaturesFromRows}
            rowErrorCallback={setRowErrors}
            initialTableState={{ columnVisibility: { regulationType: false } }}
          />
        </div>
      </FormProvider>
    );
  });

PermitsFormAquaticResources.metadata = PermitsFormAquaticResourcesMetadata;

export default PermitsFormAquaticResources;