import React from 'react';
import { ModalContent, ModalHeader, ModalFooter } from '../../../app-components/modal';

const DeleteModal = ({
  deleteClick,
  data
}) => (
  <ModalContent>
    <ModalHeader title='Confirm Cancellation' />
    <section className='modal-body'>
      <div className='container-fluid'>
        <p className='mb-2'>Are you sure you want to cancel this request?</p>
        <ul>
          <li><b>Request Type:</b> {data.requestType}</li>
          <li><b>Request Name:</b> {data.projectName}</li>
        </ul>
      </div>
    </section>
    <ModalFooter
      onSave={() => deleteClick()}
      saveText='Yes'
      cancelText='No'
      onCancel={() => { }}
    />
  </ModalContent>
);

export default DeleteModal;
