import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { ImpactsActivity } from '../../../utils/enums.tsx';

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const AmountTypeTableCell =
  ({
    getValue,
    row,
    column,
    table,
    cell,
  }) => {
    const columnMeta = column.columnDef.meta;
    const tableMeta = table.options.meta;
    const initialValue = getValue();
    const initialOptions = columnMeta?.options;
    const [value, setValue] = useState(initialValue);
    const [amountOptions, setAmountOptions] = useState(initialOptions);
    const [activity, setActivity] = useState();
    const rowActivity = useMemo(() => row.getValue('activity'), [row]);
    const debouncedUpdateRef = useRef();

    const handleDefaultOption = (options, currentValue, setValue, updateValue) => {
      if (options?.length === 1) {
        const secondOptionValue = options[0]?.value;
        if (secondOptionValue && secondOptionValue !== currentValue) {
          setValue(secondOptionValue);
          updateValue(secondOptionValue);
        }
      }
    };

    useEffect(() => {
      debouncedUpdateRef.current = debounce((newValue) => {
        if (tableMeta?.updateData) {
          tableMeta.updateData(row.index, column.id, newValue);
        }
      }, 500);
    }, [row.index, column.id, tableMeta?.updateData, columnMeta?.type, tableMeta]);

    const updateValue = useCallback((newValue) => {
      debouncedUpdateRef.current(newValue);
    }, []);

    useEffect(() => {
      rowActivity && setActivity(rowActivity);
    }, [rowActivity]);

    useEffect(() => {
      let options;
      switch (activity) {
        case ImpactsActivity.ConversionWatersType:
        case ImpactsActivity.DischargeFillMaterial:
        case ImpactsActivity.DischargeDredgedMaterial:
        case ImpactsActivity.EcologicalRestoration: {
          options = [{ value: 'Fill Area', label: 'Fill Area' }];
          break;
        }
        case ImpactsActivity.DredgingSection: {
          options = [{ value: 'Removal Area', label: 'Removal Area' }, { value: 'Removal Volume', label: 'Removal Volume' }];
          break;
        }
        case ImpactsActivity.Other: {
          options = [{ value: 'Fill Area', label: 'Fill Area' }, { value: 'Removal Area', label: 'Removal Area' }, { value: 'Structure Area', label: 'Structure Area' }];
          break;
        }
        case ImpactsActivity.Removal: {
          options = [{ value: 'Removal Area', label: 'Removal Area' }];
          break;
        }
        case ImpactsActivity.Structure: {
          options = [{ value: 'Structure Area', label: 'Structure Area' }];
          break;
        }
        case ImpactsActivity.TransportDredgedMaterial: {
          options = [{ value: 'Fill Volume', label: 'Fill Volume' }];
          break;
        }
        default: {
          options = initialOptions;
        }
      }
      handleDefaultOption(options, value, setValue, updateValue);
      setAmountOptions(options);
    }, [activity, initialOptions, updateValue, value]);

    const handleBlur = (e) => {
      // tableMeta?.updateData(row.index, column.id, columnMeta?.type === 'number' ? Number(e.target.value) : e.target.value ?? e.target.value);
    };

    const handleChange = (e) => {
      setValue(e.target.value);
      updateValue(e.target.value);
    };

    return (
      <select
        id={cell.id}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value ?? ''}
        required={columnMeta?.required}
        disabled={columnMeta?.readOnly}
        style={{ width: '100%' }}>
        <option key={0} value='' className='none' style={{ display: 'none' }}>-- Select a value --</option>
        {amountOptions.map((option) => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    );
  };

export default AmountTypeTableCell;